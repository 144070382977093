
@import "/src/assets/scss/layout/filters";


.container-fluid{
  max-width:100%;
  padding: 0 40px;
}

.hero-section{
  min-height: 618px;
  background-size: cover;
  background-position: center;
  @include phonel{
    min-height: 200px;
  }
}
.pr-0{
  padding-right: 0px;
}

.hero-filter-wrapper{
  width:100%;
  height:100%;
  position: relative;
  padding-top: 68px;
  margin-top: -266px;
  z-index: 1;

  @include tabl{
    padding-left: 20px;
    padding-right: 20px;
  }
  @include phonel{
    margin-top: -130px;
  }
  &:after{
    position: absolute;
    min-height: 266px;
    content: "";
    width:100%;
    left:0;
    top: 0;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: -1;
    @include phonel{
      min-height:136px;

    }
  }
  .filter-inner-wrapper{
    background: rgba(#fff , 0.92);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
    max-width: 1128px;
    margin: auto;
    padding: 28px 60px 48px;
    @include tabm{
      padding: 28px 30px 40px;
      @include phonel{
        padding: 20px 20px 37px;
      }
    }
    .filter-title{
      font-style: normal;
      //font-weight: 300;
      @include remy(44);
      line-height: 53px;
      color:$fc-primary;
      margin-bottom: 17px;
      font-family: $font_Souvenir_light;
      @include phonel{
        @include remy(36);
        line-height: 43px;
      }
    }
    .filter-form{
      border: 2px solid #E5E5E5;
      background:#fff;
      border-radius:4px;
      display: flex;
      align-items: center;
      padding-right: 30px;
      @include phonel{
        flex-wrap: wrap;
        border:none;
        padding-right: 0;
      }
      .form-group{
        &:first-child{
          width:266px;
          @include phonel{
            width:100%;
          }
        }
        &:nth-child(2){
          width:55%;
          @include phonel{
            width:100%;
          }
        }
        &:nth-child(3){
          width:18%;
          text-align: right;
          @include phonel{
            display: none;
          }
        }

        .custom-input-1{
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color:#333333;
          width:100%;
          height:88px;
          border: none;
          &::placeholder {
            color:#333333;
          }
          &:focus,
          &:hover{
            outline: none;
            box-shadow: none;
          }
          &:focus::placeholder {
            color: #C7C7C7;
            @include transition(0.2s ease-in-out);
          }

          @include phonel{
            height:56px;
            border: 2px solid #E0E1E5;
            @include remy(18);
            line-height:21px;
            padding:0 54px 0 15px;
          }
        }
      }
    }
  }

  .search_dropdown{
    position: relative;
    max-width: 266px;
    @include phonel{
      max-width: 100%;
    }
    &:after{
      position: absolute;
      content: "";
      height:50px;
      width:2px;
      background:$border-light;
      right:0;
      top:50%;
      transform: translateY(-50%);
      @include phonel{
        display: none;
      }
    }
    .multiSelectDropdown{
      border: none;
      min-width:266px;
      max-width: 266px;
      height:88px ;
      @include tabm{
        min-width: 230px;
        max-width: 230px;
        @include phonel{
          height:56px;
          min-width: 100%;
          max-width: 100%;
          border: 2px solid #E0E1E5;
          margin-bottom: 22px;
        }
      }

      .p-placeholder{
        color: #333333;
      }
      .p-dropdown-label{
        padding-left: 28px;
        padding-right: 16px;
        flex:initial;
        min-width:135px;
        max-width:224px;
        width:auto;
        font-size: 20px;
        line-height: 24px;
        display: inline-flex;
        align-items: center;
        @include transition(0.2s ease-in-out);
        @include tabm{
          max-width:180px;
          @include phonel{
            max-width: initial;
            min-width: initial;
            flex: 1 1 auto;
            width: 1%;
            padding-left: 15px;
            @include remy(18);
            line-height: 21px;
          }
        }
        &:hover {
          color: $orange;
        }
        div{
          width: 100%;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          word-break: normal;
        }
      }
      &.p-dropdown-open{
        .p-dropdown-trigger .pi-chevron-down{
          margin: 3px 0 0 0 !important;
        }
        .p-dropdown-label{
          color: $orange;
        }
      }
      .p-dropdown-trigger{
        padding: 0;
        width:auto;
        @include phonel{
          padding: 0 20px;
        }

        .pi-chevron-down{
          background:url("/assets/images/active_down_icon.svg")no-repeat;
          width: 13px;
          background-size: 100%;
          height: 9px;
          margin: 10px 0 0 0 !important;
        }
      }
      .p-dropdown-panel {
        top:98px!important;
        border: none;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
        max-width: 266px;
        @include phonel{
          top:60px !important;
        }

        .p-dropdown-header{
          border: none;
          .p-dropdown-filter{
            padding-left: 20px;
            padding-right: 50px;
            border-bottom: 1px solid #E0E1E5;
          }
        }
      }
      .p-dropdown-items .p-dropdown-item{
        padding:10px 18px;
        div{
          white-space: normal;
        }
      }
    }
  }
}

.search-products{
  position: relative;
  .custom-input-1{
    display: inline-block;
    width: 326px;
    background-color: $fc-white;
    border: 2px solid $fc-white;
    height: 56px;
    padding-right: 50px;
    padding-left: 28px;
    @include tabm{
      padding-right:30px;
    }
    //box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    &::placeholder{
      font-style: normal;
      font-weight: 400;
      @include remy(18);
      line-height: 21px;
      color: $fc_gray400;
    }
    &:hover{
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    }
    &:focus{
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    }
  }
  .search-icons-button {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 54px;
    padding: 17px 16px 17px;
    height: 100%;
    display: inline-flex;

    &:hover {
      border-color: $fc-white;
      //box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    }
    &:focus{
      border-color: $fc-white;
      //  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    }
  }
}

.search-product-by-category{
  padding-top: 49px;
  padding-bottom: 5px;
  @include phonel{
    padding-top: 25px;
    .mw_1440{
      padding: 0 0px;
    }
    .heading_wrapper{
      padding: 0 20px;
    }
  }
  .heading_wrapper {
    margin-bottom: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading11{
      @include remy(46);
      line-height: 46px;
    }
    @include tabl{
      .heading11{
        @include remy(32);
        line-height: 38px;
      }
    }
    @include tabm{
      flex-direction: column;
      align-items:flex-start;
      .heading11{
        margin-bottom: 20px;
      }
    }
    @include phonel{
      margin-bottom: 40px;
      flex-direction: column;
      align-items: flex-start;
      @include phonem{
        margin-bottom:12px;
      }
      .heading11{
        margin-bottom: 30px;
      }
    }
    .heading8{
      font-weight: 500;
      text-align: left;
      color: $fc-primary;
    }
    .search-products{
      width:320px;
      @include tabm{
        width:100%;
      }
      .fg-inner{
        display: flex;
        align-items: center;
      }
      input{
        padding: 0;
        border: none;
        border-bottom:1px solid transparent;
        height:30px;
        padding-bottom:6px;
        @include remy(16);
        line-height: 19px;
        font-weight: 400;
        color:$fc-primary;
        width:300px;
        @include trans2s;
        padding-right: 0px;
        border-radius:0;
        text-overflow: ellipsis;
        @include phonel{
          text-overflow: ellipsis;
          @include remy(15);
        }
        &::placeholder{
          color:$fc-primary;
          @include trans2s;
          @include remy(16);
          @include phonel{
            text-overflow: ellipsis;
            @include remy(14);
            line-height: 17px;
          }
        }
        &:hover{
          box-shadow: none;
          //color:$fc-hint;

        }
        &:focus {
          border-bottom: 1px solid #E0E1E5;
          box-shadow: none;
          //border-radius: 0;
          @include tabm{
            border-bottom: 2px solid #E0E1E5;
          }

          &::placeholder {
            color: $fc-hint;
          }

           +.search_btn{
              svg path {
                stroke: $orange;
              }
            }

        }

        &.space{
          padding-right: 30px;
          border-bottom:1px solid #E0E1E5;
          @include tabm{
            padding-right: 40px;
          }
          + .cross_btn +.search_btn{
            svg path{
              stroke:$orange;
            }
            @include tabm{
              opacity: 0;
              visibility: hidden;
              display: none;
            }
          }
        }
        &:valid{
         // border-bottom:1px solid #E0E1E5;

          @include tabm{
            border-bottom:1px solid #E0E1E5;
          }
          + .cross_btn{
            opacity: 1;
            visibility: visible;
          }

        }

        &.ng-touched.ng-invalid{
          //border: none;
          //border-bottom:1px solid #E0E1E5;
        }

        @include tabm{
          width:100%;
          border: 1px solid #E0E1E5;
          height: 42px;
          font-size: 14px;
          line-height: 17px;
          border-radius: 32px;
          padding:0 40px 0 15px;
          text-overflow: ellipsis;

        }

      }

      .cross_btn{
        position: absolute;
        width:24px;
        height:24px;
        right:30px;
        top: 0;
        border: none;
        background: none;
        opacity: 0;
        visibility:hidden;
        @include trans2s;
        .mob_cross{
          display: none;
        }
        @include  tabm{
          right:10px;
          top:50%;
          transform: translateY(-50%);
          width:25px;
          height: 25px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          .mob_cross{
            display: inline-flex;
            svg{
              width:100%;
            }
          }
          .dsktp_cross{
            display: none;
          }
        }
      }
      .search_btn{
        border: none;
        background: none;
        @include trans2s;
        width:24px;
        height: 24px;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-left: 6px;
        @include phonel{
          width:25px;
          height: 25px;

        }
        @include trans2s;
         .mob_search{
          display: none;
        }
        @include  tabm{
          position: absolute;
          right: 12px;
          svg{
            @include trans2s;
          }
           .mob_search{
            display: inline-flex;
          }
          .dsktp_search{
            display: none;
          }
        }
        &:hover{
          svg path{
            stroke:$orange;
          }
        }
      }
    }


  }
  .icon-list-item {
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0px 0px;
    text-align: center;
    display: flex;

    li {
      min-width: 135px;
      max-width: 135px;
      padding: 0px 5px 2px 5px;
      margin-bottom: 45px;
      @include tabl{
        min-width:12.2%;
        max-width:12.2%;
        @include tabm{
          min-width:14%;
          max-width:14%;
          @include phonel{
            min-width: 25%;
            max-width: 25%;
            margin-bottom: 32px;
          }
        }
      }

      a {
        display: inline-block;

        .icon-list-text {
          font-style: normal;
          font-weight: 700;
          @include remy(12);
          line-height: 15px;
          text-align: center;
          color: $fc-primary;
          padding-top: 9px;
          padding-right: 5px;
          padding-left: 5px;
          word-break: break-word;
        }
        .icon{
          min-height:38px;
          align-items: center;
          display: inline-block;
          max-width: 80px;
          vertical-align: middle;
          max-height:38px;
          display: inline-flex;
          align-items: center;
          img{
            width:100%;
          }
          @include phonel{
            min-height: 30px;
            img{
              height:28px;
            }
          }
        }

        &:hover {
          //color: #DC5A4B;
          //transition: .2s all ease;
          //filter: invert(49%) sepia(66%) saturate(3118%) hue-rotate(333deg) brightness(94%) contrast(83%);
          filter: invert(38%) sepia(66%) saturate(1256%) hue-rotate(332deg) brightness(87%) contrast(77%);
        }
        &.selectedCategory{
          filter: invert(38%) sepia(66%) saturate(1256%) hue-rotate(332deg) brightness(87%) contrast(77%);
        }
      }
    }
  }
}
.available-brands-product{
  //margin-top: 10px;
  min-height: 720px;
  @include phonel {
    margin-top: 10px;
  }
  .mob-heading{
    display: none;
    @include phonel{
      display: block;
      margin-bottom:4px;
    }
  }
  .filter-sorts-product{
    padding-bottom:15px;
    .filter_top_bar{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include phonel{
        width: 100%;
        align-items: flex-end;
      }
    }
    .filter_bottom_bar{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top:8px;
      @include phonel{
        margin-top:16px
      }
      .brand_sorting{
        display: none;
        @include phonel{
          display: inline-flex;
        }
      }
      .clear-filter-btn{
          @include remy(14);
          line-height: 17px;
          color:$orange;
          font-family: 'Helvetica Neue md';
        padding-left: 0;
           @include phonel{
            padding-left: 0;
          }
        p{
          @include remy(14);
          line-height: 17px;
          color:$orange;
          //font-family: 'Helvetica Neue md';
          font-weight: normal;
          letter-spacing:0.3px;
        }
        .clear-cross-icon{
          display: inline-flex;
          margin-left: 5px;
        }
      }
    }
    .brands-heading{
      @include phonel{
        width: calc(100% - 50px);

      }
      p{
        display: flex;
        @include remy(18);
        .result_btn{
          @include remy(18);
          svg{
            height:16px;
          }
        }
        @include phonel{
          display: inline;
        }
      }
    }
    .sort_dropdown{
      .dropdown_btn{
        padding: 0;
        .icon{
          display: inline-flex;
        }
      }
      .dropdown-menu{
        background: #FFFFFF;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
      }
      .group-dropdown{
        label{
          font-weight: 700;
          @include remy(14);
          line-height: 22px;
        }
      }
    }
    @include phonel{
      align-items: center;
      padding-bottom: 10px;
      .brands-heading{
        align-items: center;
        display:inline-flex;
        //font-size:0;
        line-height: 19px;
        p{
          //font-size: 0;
        }
      }
    }
    .overlay{
      z-index: 2;
    }
  }
  @include tabv{
    .mw_1440{
      padding: 0 30px;
    }
    @include phonel{
      .mw_1440{
        padding: 0 20px;
      }
    }
  }
}
.mob_search_btn{
  display: none;
  @include phonel{
    display: block;
    position: absolute;
    right: 0;
    background: no-repeat;
    border: none;
    width: 54px;
    height: 100%;
    top: 0;
  }
}

.nav_link_ico {
  display: inline-block;
  max-width: 24px;
  margin-left: 10px;
}

/*shop browse modal*/

.brand_info_section:not(:has(.brand_offer_n_link)) .offer_detail .offer_text.subtext {
  @include phonel {
    margin-bottom: 30px;
  }
}
.brand_info_section:has(.brand_offer_n_link) .offer_detail .offer_text.subtext {
  @include phonel {
    margin-bottom: 0;
  }
}

.brand_detail{
  @include dib100;
  text-align: center;
  .brand_info_section{
    @include dib100;
    max-width:520px;
    text-align: center;
    padding: 40px 20px 15px 20px;
    @media screen and (max-width: 575px){
      padding: 39px 15px 25px 15px;
    }
    .brand_logo{
      @include dib100;
      max-width: 150px;
      height: 50px;
      object-fit: contain;
      margin-bottom: 30px;
    }
    .brand_description{
      @include dib100;
      text-align: left;
      max-width: 440px;
      @include remy(14);
      line-height: 1.3;
      color: $fc-primary;
      margin-bottom: 14px;
      word-break: break-word;
    }

    .description_wrapper{
      text-align: left;
      margin-bottom: 15px;
      @include phonel{
        margin-bottom: 35px;
      }
      label{
        @include remy(14);
        line-height: 24px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        font-weight: 700;
        font-family:var(--helvetica700);
      }
      .short_desc, .short_desc * {
        color: $fc-primary !important;
        background: $fc-white !important;
      }
      p{
        font-family: 'Helvetica Neue';
      }
      .brand_description{
        @include remy(16);
        line-height: 24px;
        color:$fc-primary;
        max-width: 100%;
      }
    }
    .offer_detail{
      word-break: break-word;
      p{
        br{
          display:none;
        }
      }

      label, p.offer_text, p.offer_text span{
        @include remy(20);
        line-height: 23.86px;
        //text-transform: uppercase;
        color:#444444 !important;
        margin-bottom: 10px;
        margin-top: 10px;
        font-family: 'Helvetica Neue';
        background:transparent !important;
        @include phonel{
          @include remy(16);
          margin-bottom: 16px;
          font-weight: 400;
          line-height: 19.09px;
          margin-top: 5px;
        }
      }
      h4{
        //@include remy(52);
        font-size: 2.85rem;
        line-height: 62.4px;
        font-weight: 300;
        color:$fc-primary;
        font-style: normal;
        font-family: $font_Souvenir_light;
        @include phonel{
          font-weight: 300;
          @include remy(42);
          line-height: 42px;
          text-align: center;
        }
      }
      h5{
        font-size: 18px;
        font-weight: 500;
        font-family: 'Helvetica Neue md';
        line-height: 22.2px;
        letter-spacing: 0.03em;
        color: #DC5A4B;
        margin-bottom: 12px;
        @include phonel{
          @include remy(16);
          line-height: 19.73px;
        }
      }
    }
    .brand_offer_n_link{
      margin-top: 15px;
      margin-bottom: 40px;
      @include phonel{
        margin-top: 24px;
        margin-bottom: 30px;
      }
      .btn_primary{
          &:active{
             background: #c65144;
          }
      }
    }
  }
  .brand_offer_section{
    @include dib100;
    display: inline-flex;
    width: 100%;
    position: relative;
    height: 264px;
    align-items: center;
    justify-content: center;
    &::before{
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
    }
    .brand_logo{
      display: inline-flex;
      z-index: 2;
      max-height:80px;
      max-width: 320px;
      @include  phonel{
        max-height: 60px;
        max-width: 245px;
        width:auto;
        @media screen and (max-width: 400px){
          max-height: 60px;
        }
      }
    }
    .brand_bg_image{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .brand_offer_n_link{
      @include dib100;
      padding: 60px 80px;
      position: relative;
      z-index: 2;
      @media screen and (max-width: 600px){
        padding: 30px 30px;
      }
      .offer_detail{
        @include dib100;
        margin-bottom: 30px;
        label{
          @include dib100;
          font-size: 16px;
          line-height: 120%;
          text-align: center;
          text-transform: uppercase;
          color: #FFFFFF;
          margin-bottom: 4px;
        }
        h4{
          @include remy(46);
          line-height: 1.2;
          text-align: center;
          letter-spacing: 0.02em;
          text-transform: capitalize;
          color: #FFFFFF;
        }
      }
    }
  }
}

.listing_container{
  @include phones{
    text-align: center;
    justify-content: center;
  }
}

.btn-modal-company{
  margin-right: 20px;
  display: inline-block;
  width: auto;
  padding: 10px 20px;
  background:$orange;
  border-radius: 4px;
  color: $fc-white;
  @include remy(16);
  line-height: 19px;
  letter-spacing: 0.02em;
  transition: .3s all ease;
  @media screen and (max-width:767px){
    @include remy(14);
    padding: 12px 24px;
    @include phones{
      margin-right: 0;
      display: block;
      margin: auto;
      min-width:200px;
      max-width: 200px;
      margin-bottom: 10px;
    }
  }
  &:hover,
  &:focus{
    color: $fc-white;
    background: $orange-tint;
    border-color: $orange-tint;
    //transform: translateY(-2px);
    position: relative;
  }
}

.btn_extension{
  display: inline-block;
  width: auto;
  padding: 10px 20px;
  background: #C3BEB4;;
  border-radius: 4px;
  color: $fc-white;
  @include remy(16);
  line-height: 19px;
  letter-spacing: 0.02em;
  transition: .3s all ease;
  @media screen and (max-width:767px){
    @include remy(14);
    padding: 12px 24px;
    @include phones{
      margin-right: 0;
      display: block;
      margin: auto;
      max-width: 200px;
      margin-bottom: 10px;
    }
  }
  &:hover,
  &:focus{
    color: $fc-white;
    background: #b2ada2;
    border-color:#b2ada2;
    // transform: translateY(-2px);
    position: relative;
  }
}



/* categoery page  css*/

.navigate-to-back{
  padding: 20px 0px 28px 0px;
  align-items: center;
  display: flex;
  position: sticky;
  top:0;
  background: $fc_white;
  width:100%;
  z-index: 1;
  display: flex;
  @include tabm{
    display: none;
  }
}



/*serach page scss */

.sorting_item{
  padding: 36px 0px 16px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  div{
    @media (min-width:992px) {
      width: 33.3%;

      div {
        width: initial;
      }
    }
  }
  @include tabm{
    flex-direction: column;
    padding: 26px 0px 0px 0px;
  }
  .result-of-items{
    font-style: normal;
    font-weight: 300;
    @include remy(17);
    line-height: 21px;
    color: $fc_gray400;
    span{
      word-break: break-word;
    }
    @include tabm{
      order: 2;
      text-align: left;
      width:100%;
      margin-bottom: 10px;
      @include phonel{
        font-style: normal;
        font-weight: 300;
        @include remy(14);
        line-height: 17px;
      }
    }
  }
  .sort_dropdown{
    right: 0;
    @include tabm{
      order: 3;
    }
    .el-text{
      color: #EB674E;
    }
  }
}


//search bar
.search-product{
  position: relative;
  text-align: center;
  @include tabm{
    max-width:100%;
    order: 1;
    margin-bottom: 16px;
  }
  .custom-input-1{
    display: inline-block;
    max-width: 436px;
    background-color: $fc-white;
    //border: 1px solid #E5E5E5;
    height: 50px;
    padding-right: 50px;
    padding-left: 20px;
    border-radius: 0px;
    @include tabm{
      max-width: 100%;
    }
    &::placeholder{
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 19px;
      color: $fc_gray400;
    }

    &:focus{
      box-shadow: none;
      border-color: #96999F;

      &::placeholder{
        color: transparent;
      }
    }
    &:focus {
      & + {
        .search-button {
          opacity: 0;
          visibility: hidden;
        }
        .search-button + .search-clear-icon {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .search-button {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 54px;
    padding: 0px 20px 0px;
    height: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include trans4s;
    &:hover {
      border: 1px solid #E5E5E5;
      border-left-style: none;
      //box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    }
    &:focus{
      border: 1px solid #E5E5E5;
      border-left-style: none;
      //box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    }

  }
  .search-clear-icon{
    position: absolute;
    min-width: 54px;
    right: 0;
    top:0;
    height:100%;
    padding:0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    opacity:0;
    visibility: hidden;
    @include trans4s;
  }

    .fg-inner{
      position: relative;
      @media (min-width: 992px) {
        max-width: 436px;
      }
    }

}

.browse-page-content {
  .mob-filter-wrap {
      @media (min-width:992px){
        display: flex;
        justify-content: flex-end;
      }
  }
}

.browse_content_wrapper{
  width: 100%;
  display: flex;
  margin-top:50px;
  @include tabm{
    margin-top: 24px;
    .overlay{
      z-index: 2;
    }
  }
}



.left-side-wrapper{
  width: 358px;
  padding-right: 10px;
  .filter_close{
    display:none;
    @include tabm{
      display: block;
      border: none;
      background: none;
      margin-top:5px;
    }
  }
  @include tabm{
    //display: none;
    position: fixed;
    height: 100%;
    top: 0;
    transform: initial;
    width: 320px;
    right:-320px;
    padding: 28px 20px 28px 28px;
    border-radius: 0;
    opacity:0;
    visibility: hidden;
    z-index: 2;
    @include transition(0.2s ease-in-out);
    &.active{
      opacity: 1;
      visibility: visible;
      right:0;
      background: #fff;
    }

    .filter-title-wrap{
      display: flex;
      align-items: flex-start;
      justify-content:space-between;
      padding-bottom: 20px;
    }

  }
  .product-type-subcategory{
    padding-bottom: 15px;
    font-weight: 700;
    color: $fc-primary;
    font-size: 16px;
    line-height: 20px;
    @include tabm{
      padding-bottom: 0;
      font-style: normal;
      font-weight: 300;
      font-size: 28px;
      line-height: 33px;
      padding-right: 15px;
    }
  }
}
.right-side-wrapper{
  width: calc(100% - 358px);
  @include tabm{
    width:100%;
  }
}

.icon-list-items {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 28px 0px;
  text-align: center;
  @include tabm{
    padding: 28px 0 28px;
    justify-content: center;
    height: calc(100vh - 62px);
    overflow-y: auto;
  }

  li {
    min-width: 125px;
    max-width: 125px;
    padding: 0px 5px;
    margin-bottom: 28px;
    @include tabm{
      min-width: 130px;
      max-width: 130px;
    }


    a {
      display: inline-block;
      @include tabm{
        img{
          height: 28px;
        }
      }

      .icon-list-texts {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #444444;
        padding-top: 9px;
        padding-right: 5px;
        padding-left: 5px;
        word-break: break-word;
      }

      &:hover {

        //transition: .2s all ease;
        filter: invert(49%) sepia(66%) saturate(3118%) hue-rotate(333deg) brightness(94%) contrast(83%);
        //fill: #DC5A4B;
      }

      .icon{
        min-height: 38px;
        align-items: center;
        display: inline-block;
        max-width: 80px;
        vertical-align: middle;
        max-height: 38px;
        display: inline-flex;
        align-items: center;

        img{
         max-height: 38px;
          max-width: 60px;
        }
      }
    }
  }
}




.item_carousel_wraper{
  margin-bottom: 30px;
  @include phonel{
    margin-bottom: 22px;
    display: none;
  }
  &.lastslide{
    .slick-list{
      &:after{
        display: none;
      }
    }
  }
  .slick-arrow.slick-disabled{
    + .slick-list{
      &:before{
        display: none;
      }
    }
  }
  .slick-list{
    .slick-slide{
      &:first-child {
        .brand_card {
          padding-left: 0px;
        }
      }
    }
    @include phonem{
      margin: 0 -20px;
      .slick-slide{
        &:first-child{
          //padding-left: 20px;
          margin-left:20px
        }
      }
    }
    &:after{
      position: absolute;
      content: "";
      background: linear-gradient(270deg, #FFFFFF 6.58%, rgba(255, 255, 255, 0) 100%);
      height: 64px;
      width:40px;
      top:0;
      right:0px;
      opacity: 1;
      @include phonel{
        right:-3px;
       // width:40px;
        height:100%;
      }
    }

    &:before{
      position: absolute;
      content: "";
      background: linear-gradient(270deg, #FFFFFF 6.58%, rgba(255, 255, 255, 0) 100%);
      transform:rotate(180deg);
      height: 64px;
      width:40px;
      top:0;
      left:0px;
      z-index: 1;
      opacity: 1;
      @include phonel{
        left:-3px;
       // width:60px;
        height:100%;
      }
    }
  }
  .slick-arrow{
    display: none;
    background: #FFFFFF;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid #C7C7C7;
    z-index: 3;
    @include  trans2s;
    @include phonel{
      width:36px;
      height:36px;
      opacity: 0;
      &:focus{
        opacity: 1;
      }
    }
    &:hover{
      border: 1px solid $orange;
      //box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    }
    &:before{
      display: none;
    }
    img{
      //width:15px;
      height: 14px;
      @include phonel{
        height:14px;
      }
    }
    &.slick-disabled{
      display:none !important;
    }
    &.slick-next{
        &:after{

        }
      }

    &.slick-next{
      right:-11px;
      @include phonel{
        right:-10px;
      }
    }
    &.slick-prev{
      left:0px;
      @include phonel{
        left:-10px;
      }
    }
  }

}


.brand_card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 20px;
  @include phonel{
    /*max-width:80px;
    min-width:80px;*/
    /*padding: 0 16px;*/
    margin:0 5px;
    padding:0;
    .content_wrap{
      min-width:70px;
    }
  }
  @media (min-width:420px) and (max-width: 767px){
    margin:0 8px;
  }
  .icon{
    height: 36px;
    margin-bottom: 10px;
    text-align: center;
    @include phonel{
      height:26px;
    }
    svg g[filter] path{
        fill: none;
    }
    svg path, svg circle{
      @include trans2s;
    }
    svg{
      height:36px;
      max-width: 80px;
      @include trans2s;
      @include phonel{
        height:26px;
      }
    }
    img{
      width:100%;
    }
  }
  input{
    position: absolute;
    left: 0;
    top: 0;
    width:100%;
    height: 100%;
    opacity: 0;
    //visibility: hidden;
    z-index: 1;
    cursor: pointer;
    &:checked{
      + .content_wrap {
        .icon {
          svg path, circle{
            fill:$orange;
          }
          svg g[filter] path{
            fill: none;
          }
          // transition: .12s all linear;
          //filter: brightness(87%) invert(38%) sepia(66%) hue-rotate(332deg) saturate(1256%) contrast(77%);
        }

        .category_name {
          color: $orange;
        }
      }
      &:hover{
        + .content_wrap {
          .icon {
           svg{
             path, circle{
               fill:$orange-shade2;
             }
           }
            svg g[filter] path{
              fill: none;
            }
          }

          .category_name {
            color:$orange-shade2;
          }
        }
      }
    }
  }
  .category_name{
    @include remy(12);
    line-height:15px;
    font-weight:700;
    color:$fc-primary;
    @include trans2s;
    text-align: center;
    word-break: break-word;
    @include phonel{
      word-break: inherit;
    }
  }
  &:hover {
    .icon {
     svg{
       path, circle{
         fill:$orange;
       }
     }
      svg g[filter] path{
        fill: none;
      }
    }
    .category_name{
      color:$orange;
    }
  }
  img{
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 100%
  }
}


.search_result_wrap{
  display:inline-block;
    .result_btn{
      @include remy(22);
      line-height: 27px;
      color:$orange;
      display: inline-flex;
      align-items: center;
      padding: 0 10px 0 5px;
      text-decoration-line: underline;
      text-decoration-skip-ink: none;
      text-decoration-thickness: 1px;
      text-underline-position: under;
      position: relative;
      @include trans2s;
      &.search_text{
        padding-right:0;
      }
      @include phonel{
        @include remy(14);
        line-height: 17px;
        display: inline;
        word-break: break-word;
        padding:0px;
        text-decoration: none;
        span{
          //display: inline-flex;
          text-decoration-line: underline;
          text-decoration-skip-ink: none;
          text-decoration-thickness: 1px;
          text-underline-position: under;
        }

      }
      &:after{
        position: absolute;
        content: "";
        width:calc(100% - 40px);
        height: 1px;
        bottom: 0;
        left: 5px;
        background:$orange;
        @include trans2s;
        display: none;
      }
      svg{
        @include phonel{
          width: 14px;
          height: 14px;
        }
        path{
          @include trans2s;
        }
      }
      &:hover{
        color: $orange-shade2;
        &:after{
          background:$orange-shade2;
        }
        .icon{
          svg path{
            stroke: $orange-shade2;;
          }
        }
      }

      .icon{
        margin-left: 5px;
        //display: inline-flex;
        align-items: center;
      }
    }

  .spacer{
    padding: 0 0 0 5px;
    @include phonel{
      padding: 0 5px 0 5px;
    }
  }
}

.no_result_found{
  @include phonel {
    border-top: 8px solid #F9FAFB;
    margin: 10px -20px 0;
  }
  .no_result_found_inner{
    padding: 40px 0;
    @include phonel{
      padding: 40px 20px 60px;
    }
    .shop_browse_noresult_img{
      display: flex;
      justify-content: center;
      figure{
        margin-bottom: 0;
      }
      .no_result_img{
        width: 124px;
        @include phonel{
          width: 90px;
        }
      }
    }
    .no_result_title{
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 22px;
      text-align: center;
      color: #666666;
      margin-top: 25px;
      margin-bottom: 10px;
      @include phonel{
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
    .no_result_desc{
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #96999F;
      margin-bottom: 23px;
      @include phonel{
        margin-bottom: 18px;
      }
    }
    .clear_search_btn_wrap{
      display: flex;
      align-items: center;
      justify-content: center;
      .link-primary-btn{
        text-transform: capitalize;
        .el-icon{
          margin-right: 4px;

        }
        .el-text{
          font-style: normal;
          //font-weight: 500;
          font-family: 'Helvetica Neue md';
          font-size: 16px;
          line-height: 20px;
        }
      }
    }



  }
}

.brand_sorting{
  display: flex;
  width: 50px;
  justify-content: flex-end;
  .sort_dropdown{
    .dropdown_btn{
      @include phonel{
        font-size: 14px;
        line-height: 17px;
      }

    }
  }
}

.mob_head_wrapper{
  .heading11{
    @include phonel{
      display: none;
    }
  }
  .mobile_heading{
    font-family: $font_Souvenir_light;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 34px;
    color: #444444;
    margin-bottom: 12px;
    display: none;
    @include phonel{
      display: block;
    }
  }
}


.mobile_category_list{
  display: none;
  @include phonel {
    padding-right:1px;
    display: flex;
    position: relative;
    overflow: hidden;
    &.isFirstElement{
      &:before{
        display: none;
      }
    }
    &.isLastElement{
    &:after{
      display: none;
    }
    }
    &:before{
      position: absolute;
      content: "";
      background: linear-gradient(270deg, #FFFFFF 6.58%, rgba(255, 255, 255, 0) 100%);
      transform: rotate(180deg);
      height: 77px;
      width: 80px;
      left: 0px;
      z-index: 1;
    }
    &:after{
      position: absolute;
      content: "";
      background: linear-gradient(270deg, #FFFFFF 6.58%, rgba(255, 255, 255, 0) 100%);
      height: 77px;
      width: 80px;
      right: 0px;

    }
  }
  width: 100%;
  .category_list{
    display: flex;
    align-items: flex-start;
    overflow-x: auto;
    padding-left: 20px;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
      display: none;
    }
  }
  .brand_card{
    &:first-child{
      padding-left: 0px;
      margin-left:0;
      width:auto;
     // min-width: auto;
    }
  }
}

.modal-category-list{
  margin-bottom: 16px;
  ul{
    padding: 0;
    margin: 0;
    display:flex;
    flex-wrap: wrap;
    li{
      display: inline-flex;
      @include remy(16);
      font-style: normal;
      font-weight: 400;
      line-height: 19.09px;
      color: $fc-primary;
      margin-bottom: 10px;
      margin-right: 10px;
      background: $bg-gray;
      padding: 4px 10px;
      border-radius: 8px;
    }
  }
}

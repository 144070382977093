

.chrome-download-content{
  .content-outer{
    padding-top: 38px;
    .content-inner{
      padding-bottom: 60px;
      .install_text{
        color: #444;
        font-size: 18px;
        font-style: normal;
        //font-weight: 500;
        font-family: 'Helvetica Neue md';
        line-height: normal;
        letter-spacing: 1.8px;
        margin-bottom: 15px;
      }
      .heading13{
        color: #444;
        font-size: 54px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 25px;
      }
      .desc-text{
        max-width: 549px;
        color: #444;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

    }
  }
}

.chrome-download-img{
  padding-bottom:30px;
  .chrome-img-inner{
    .chrome-image{
      max-width: 700px;
      filter: drop-shadow(0px 8.819px 23.517px rgba(0, 0, 0, 0.08));
    }

  }
}

.extension_heading{
  .inner_header_wrap{
    padding: 15px 0;
    .logo_wrap{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .logo_ui{
        display: inline-flex;
        height: 30px;
        img{

        }
      }
    }
  }
}

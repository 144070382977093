@import "/src/assets/scss/layout/filters";
@import "/src/assets/scss/base/colors";
@import "/src/assets/scss/helpers/mixins";

.onboarding_header{
  padding:32px 0 25px;
  position: absolute;
  left: 0;
  top:0;
  z-index: 2;
  width:100%;
  &.header_sticky {
    background: #ffffff;
    .logo {
      display: inline-block;
      background: #fff;
    }
  }
  @media (max-width:991px){
    display:none;
  }
  .container{
    @media (min-width:1140px){
      padding:0 40px;
      max-width:100%;
    }
  }
}
.onboarding_container {
  .login_form_wrap {
    .header_wrap {
      margin-top: 100px;
      @include phonel{
        margin-top:0;
      }
    }
    @include phonel{
      .mobile_banner{
        // display:none;
      }
      .body_content_wrap{
        //padding-top:62px;
      }
    }

    .info_icon {
      position: absolute;
      right: 0px;
      top: 24px;
      padding: 0;
      display: inline-flex;
      border: 0px;
      padding-right: 0px;
      width: 24px;
      height: 14px;
      justify-content: flex-end;
      outline:none;
      border:none;
      @media  (min-width:992px) {
        &.mobile_tooltip{ display:none;}
      }
      @media  (max-width:991px) {
        &.desktop_tooltip{
          display:none;
        }
      }

      @include phonel{
        width: 32px;
        height: 24px;
        z-index:9;
        display: inline-flex;
        min-width: 32px;
        min-height:32px
      }

    }
  }
  .floating-label{
    margin-bottom:19px;
  }
}


.custom-tooltip{
  transform: translate3d(105px, -37px, 0px) !important;
  opacity: 1 !important;

  @media (max-width:991px) and (min-width:768px){
    transform: translate3d(59px, -43px, 0px) !important;
    inset: initial !important;
    top: -57px !important;
    right: 32px !important;
  }
  @include phonel{
    transform: translate3d(59px, -43px, 0px) !important;
    inset: initial !important;
    top: -57px !important;
    right: 32px !important;
  }
  .tooltip-arrow{
    left:initial !important;
    right:30px !important;
    bottom:2px !important;
    transform:initial !important;
    &:before{
      border:none;
      position: absolute;
      content: "";
      width:18px;
      height:15px;
      background:url("../../images/onboarding/tooltip_arrow.svg");

    }
  }
  .tooltip-inner{
    border-radius: 2px;
    background: #292929;
    min-width:280px;
    padding:14px 15px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }
}
.onboarding_wrapper{
  &.full_height{
    height:100vh;
    width:100%;
    .inner_wrapper{
      display: flex;
      align-items: center;
      justify-content: left;
      height:100%;
      width:100%;
      @media (max-width:991px){
        flex-direction: column;
      }
      .steps_wrapper{
        display:flex;
        align-items:center;
        height:100%;
        width:100%;
        padding-bottom:0;
      }
    }
    .left_wrapper{
      background:#e6f0f1;
      height:100%;
      min-width:910px;
      width:60%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width:1400px){
        min-width:auto;
        max-width:100%;
        width:62%;
      }
      @media (max-width:991px){
        width:100%;
        display:block;
      }
      @include phonel{
        height:auto;
        min-height:396px;
      }
      &.step-4,&.step-5{
        background: #F7DAD2;
      }
      &.step-6{
        background:#fff3dc;
      }
      .video_container{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        //height: 100%;
        position: relative;
        min-height: auto;
        min-width: auto;
        padding: 0 20px;
        max-width:92%;
        @media (max-width:1199px){
          padding: 0 0px;
        }
        @media (min-width: 1401px){
          min-height: 588px;
          min-width:810px;
          padding:0;
        }
        @media (min-width:992px) and (max-width:1400px) {
          min-height: 488px;
         width:100%;
        }

        @media (max-width:991px){
          max-width:100%;
          height:100%;
          padding:0 12px;
        }
        img{
          max-width:779px;
          @media (max-width:1380px){
            max-width:550px;
          }
        }
        @include phonel{
          .desktop_image{
            display:none;
          }
        }

        .mob_image{
          display:none;
          @include phonel{
            display: flex;
            width: 100%;
            height: 100%
          }
        }
        video{
          max-width:810px;
          @media (min-width:1600px){
            max-width:910px;
          }
          @media (max-width:1400px){
            max-width:780px;
          }
          @media (max-width:1380px){
            max-width:550px;
          }
        }
        &.video_parent{
          video{
            max-width: 760px;
            @media (min-width:1600px){
              max-width:774px;
            }
            @media (max-width:1420px){
              max-width:650px;
            }

            @media (max-height: 700px) {
              max-width: 590px;
              padding-top: 40px;
            }
          }
          img{
            max-width: 750px;
            @media (max-width:1420px){
              max-width:590px;
            }
            @media (max-width:1280px){
              max-width:550px;
            }
          }
        }
        &.step-4{
          video{
            display:none;
          }
          img{
            display:inline-flex;
              max-width: 750px;
              width: 100%;
            @media (max-width: 1420px) {
              max-width: 590px;
            }
            @media (max-width:1280px){
              max-width:550px;
            }
            @media (max-height: 700px) {
              max-width: 590px;
              padding-top: 40px;
            }
          }
          .play_btn_wrap{
            display:none;
          }
        }
      }
      &.step-4{
        .video_container{
          width:100%;
        }
      }
      &.step-5{
        video{
          display:none;
        }
        .video_container.video_parent img{
          max-width:750px;
          padding-top: 40px;
          @media (max-width:1420px) {
            max-width: 590px;
          }
          @media (max-width:1280px){
            max-width:550px;
          }
          @media (max-width: 991.98px) {
            max-width: 100%;
            width:100%;
          }
          @media (max-height: 700px) {
            max-width: 590px;
            padding-top: 40px;
          }
          @media (max-height: 650px) {
            transform: translateY(6px);
          }
        }
        .play_btn_wrap{
          display:none;
        }
      }
    }
    .right_wrapper{
      height:100%;
      width:40%;
      max-width:40%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding:0 50px;
      position: relative;
      @media (max-width:991px){
        width:100%;
        max-width:100%;
        align-items: flex-start;
      }
      @include phonel{
        padding:0 24px;
        height:auto;
      }
      .content_wrapper{
        max-width:412px;
        min-width:200px;
        @media (max-width:991px){
          padding-top:30px;
          padding-bottom:30px;
          max-width:100%;
        }
        @include phonel{
          padding-top:18px;
          padding-bottom:20px;
          max-width:412px
        }
        .subtitle{
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 1.6px;
          color:$teal-primary;
          display: flex;
          margin-bottom: 30px;
          @media (max-width:991px){
            font-size: 12px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0.08em;
            text-align: left;
            color:$orange;
            margin-bottom:14px;
          }
        }
        .heading1{
          font-size: 40px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          color:$fc-primary;
          margin-bottom:30px;
          font-family:$font_Souvenir_light;
          @include phonel{
            font-size: 30px;
            margin-bottom:25px;
          }
        }
        .ml-8{
          margin-left:8px;
          display: inline-flex;
        }
      }
      .step-1{
        padding-right: 14px;
        .steps{
          max-width: 399px;
        }
      }
      .step-2{
        .steps {
          max-width: 400px;
        }
      }
      .step-3{
        .steps {
          max-width: 400px;
        }
      }
      .step-4{
        .desc{
         // display:none;
        }
        .steps {
          max-width: 400px;
          .steps_header{
            p {
              line-height: 46px;
              margin-bottom: 10px;
            }
          }
          .desc{
            padding-right: 26px;
          }
        }
      }
      .step-5{
        .steps{
          max-width: 399px;
          .steps_header p {
            font-size: 32px;
            font-weight: 300;
            line-height: 44px;
            padding-right: 28px;
            font-family: "itc_souvenir_stdlight";
            color: #444444;
            margin-bottom: 10px;
            position: relative;
          }
        }
      }
      .step-6{
        .steps {
          max-width: 417px;
          .steps_header .count_ui {
            font-family: 'Helvetica Neue';
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0.1em;
            color: #006878;
            margin-bottom: 20px;
            display: inline-flex;
          }
          .steps_header p {
            font-size: 32px;
            font-weight: 300;
            line-height: 44px;
            margin-bottom: 10px;
            padding-right: 0;
          }
          .desc{
            max-width: 410px;
          }
        }
      }
    }
  }
  .heading_sec{
    margin-bottom:66px;
    padding-top:20px;
    .subtitle{
      color:$fc-primary;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.8px;
      margin-bottom: 10px;
      @media (max-width:1199px){
        font-size: 16px;
        letter-spacing: 1.6px;
      }
    }
    .title{
      font-size: 54px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      color:$fc-primary;
      @media (max-width:1199px){
        font-size: 48px;
      }
    }
  }
}

.steps_wrapper{
  display: flex;
  width:100%;
  justify-content: space-between;
  padding-bottom: 30px;

  //.border_wrapper{
  //  width:2px;
  //  height:445px;
  //  background:#D9D9D9;
  //  position: relative;
  //  @media (max-width:1199px){
  //    height:290px;
  //  }
  //  .border_bg{
  //    background:#DC5A4B;
  //    position: absolute;
  //    left: 0;
  //    top: 0;
  //    width:2px;
  //    height:142px;
  //    transition:0.2s ease-in-out;
  //    @media (max-width:1199px){
  //      height:33%;
  //    }
  //
  //  }
  //  &.step-3{
  //    height:419px;
  //    @media (max-width:1199px){
  //      height:270px;
  //    }
  //    .border_bg{
  //      height:170px;
  //      @media (max-width:1199px){
  //        height:52%;
  //        top:50% !important;
  //      }
  //    }
  //  }
  //
  //}

  //&.ready_shop_wrapper{
  //  .border_wrapper{
  //    height:325px;
  //    @media (max-width:1199px){
  //      height:228px;
  //    }
  //    &.step-1{
  //      .border_bg{
  //        height:109px;
  //        @media (max-width:1199px){
  //          height:83px;
  //        }
  //      }
  //    }
  //
  //    &.step-2{
  //      .border_bg{
  //        height:243px;
  //        @media (max-width:1199px){
  //          height:162px;
  //        }
  //      }
  //    }
  //  }
  //}
  .right_sec{
    width:55%;
    min-height: 500px;
    max-height: 500px;
    @media (max-width:1199px){
      min-height: 360px;
      max-height: 360px;
    }
    video{
      height: 100%;
      //object-fit: cover;
      object-fit: contain;
    }
    img, video{
      width:100%;
      position:relative;
      top:-10px;
    }
  }
  .left_sec{
    width:42%;
    display: flex;
  }
  .right_sec{
    width:750px;
    display: flex;
  }

  .steps{
    margin-bottom:0px;
    max-width:420px;
    padding-left:0px;
    position: relative;
    display:none;
    &.active{
      display:block;
    }
    @media (max-width:1199px){
      //padding-left:0px;
      //max-width:380px;
      //margin-bottom:30px;
    }

    &:last-child{
      .steps_header {
        .recommended_text {
          display: inline-flex;
        }
      }
    }
    .steps_header{
      color:#96999F;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @include trans2s;
      @media (max-width:1199px){
        font-size:20px;
      }
      .count_ui{
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.6px;
        color:$teal-primary;
        margin-bottom:30px;
        display:inline-flex;
      }
      p{
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 46px;
        padding-right: 14px;
        font-family:$font_Souvenir_light;
        color:$fc-primary;
        margin-bottom:10px;
        position: relative;
      }
      .recommended_text{
        color: #006878;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-top: 15px;
        opacity: 1;
        align-items: center;
        display: none;
        background: #E4EFF1;
        //padding: 5px 8px;
        padding: 4px 16px 4px 11px;
        border-radius: 30px;
        margin-left: 18px;
        .icon{
          width: 11px;
          margin-top: 1px;
          margin-right:6.2px;
          display:inline-flex;
          position: relative;
          top:-1px;
        }
      }
    }
    .steps_body{
      display: none;
      padding:0px 0 0;
      //height:0;
      transition:0.2s ease-in-out;
      opacity: 0;
      color: $fc-primary;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      .italic{
        font-style: italic;
      }
      .sm_fs {
        font-size: 14px;
        line-height: 26px;
      }
      @media (max-width:1199px){
        font-size: 16px;
        line-height: 22.2px;
      }
      .btn_primary{
        margin-top:30px;
        //margin-top:10px;
        .icon{
          display:inline-flex;
          align-items: center;
          margin-left:8px;
        }
        &:disabled{
          background-color: #F8DEDB !important;
          border-color: transparent !important;
        }
        &.step-6{
          display:none;
        }
      }
      .text_btn{
        color:#96999F;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border:none;
        background:none;
        outline:none;
        box-shadow:none;
        padding:0 10px;
        margin-left:20px;
        height:42px;
        display:inline-flex;
        align-items: center;
        //margin-top:15px;
        transition: 300ms ease-out;
        &:hover{
          color:$orange;
        }
      }
    }
    &.complete{
      .steps_header {
        cursor:pointer;
      }
      &:hover {
        .steps_header {
          color: $fc-primary;
        }
      }
    }
    &.active{
      .steps_body{
        display: block;
        height:auto;
        opacity: 1;
      }
      .steps_header {
        color: $fc-primary;

      }
    }
  }
}

.desktop_steps{
  @media (max-width:992px){
    //display:none;
    .third_steps{
      display:none;
    }
  }
}

.finish_wrapper{
  width:100%;
  height:100vh;
  @include phonel{
    height:100%;
    .onboarding_header{
      display:none;
    }
  }
  .onboarding_header{
    position:absolute;
    top:0;
    left:0;
    width:100%
  }


  .finish_content{
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    @include phonel{
      align-items:flex-start;
      padding-top:145px;
    }
  }
  .inner_content{
    text-align: center;
    .btn_primary{
      min-width: 330px;
      height: 50px;
      @media (max-width:480px){
        position: absolute;
        left:0;
        right:0;
        bottom:18px;
        min-width:auto;
        max-width:90%;
        margin:auto;
      }
    }
    .gif_icon{
      width:148px;
      height:148px;
      margin-bottom: 30px;
      display: inline-flex;
      img{
        width:100%;
      }
    }

    .desc{
      margin-bottom: 70px;
      span{
        color: #444;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.8px;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
        @include phonel{
          color:$teal-primary;
          text-align: center;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0.88px;
          text-transform: uppercase;
        }
      }
      p{
        color:$fc-primary;
        text-align: center;
        font-family: $font_Souvenir_light;
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        @include phonel{
          color:#444;
          text-align: center;
          font-size: 30px;
          font-style: normal;
          font-weight: 300;
          line-height: 37px;
          max-width:252px;
          margin:auto;
        }
      }
    }
  }
}

.last_step{
  display:none;
  &.step-5{
    display:none;
  }
  &.step-6{
    display:block;
    margin-top:30px;
    .btn_primary{
      margin-top:0 !important;
    }
    .text_btn {
      margin-top: 17px;
      margin-left: 0 !important;
      height: 19px!important;
      padding-left: 5px!important;
    }
  }
}

[data-aos=fade-up]{
  transform:translate3d(0,30px,0);
}
/* mobile css start here  */
.mobile_steps{
  @media (min-width:992px){
    display:none;
  }
  height:100%;
  .steps .step_counter{
    display:flex !important;
    position:static !important;
    .step_ctr_label{
      color: teal;
      font-family: Helvetica Neue LT Pro;
      font-size: 12px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.08em;
    }
  }
  .progress {
    height: 3px;
    border-radius: 26px;
    overflow: hidden;
    width: calc(100% + 4px);
    position: relative;
    left: -2px;
    right: -2px;
    top: -0.5px;
    background-color: #E0E1E5;
    .progress-bar {
      background-color: #000000;
      border-radius: 10px;
    }
  }

  .phone_view_slide{
    &.step-4{
      //background: #F9E5E0 !important;
      video{
        display: none;
      }
    }
    &.step-5{
      //background: #F9E5E0 !important;
      video{
        display: none;
      }
    }
    &.step-6{
      //background: #FFF1D6 !important;
      video {
        padding-left: 0;
        object-position: left;
      }
    }
    .sm_phone_img{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      &.image4 {
        max-width: 228px;
        width: 228px;
        margin:auto;
        img{
          width:100%;
        }
      }
    }
    &:nth-child(5){
      .sm_phone_img {
        &.image4 {
          max-width: 100%;
          width: 100%;
          margin: auto;
          img {
            width: 100%;
          }
        }
      }
    }
    &:nth-child(6){
      background: #FFF1D6 !important;
    }
  }
  .video_sec{
    height:100dvh;
    @media (max-width:480px){
      //height:85vh;
      height:100dvh;
    }
    .slider_sec, .slick-slider, .slick-list,
    .slick-track, .slide, .brand_card, .mob_video{
      height:100%;
      transition:0.1s ease-in-out;
    }
    .slider_sec{
      position:relative;
    }
    .slick-slide{
      background:#e7f0f2;

      //video{
      //  background:#e7f0f2;
      //}
      &:nth-child(4){
          background: #F9E5E0;
          background-clip: border-box;
        video{
          display:none;
        }
        .sm_phone_img img{
          max-width: 228px;
          width: 228px;
        }
      }
      &:nth-child(5){
          padding:0;
          background:#F9E5E0;
          background-clip: border-box;
          //height: auto;
        video{
          padding:0;
          display: none;
        }
        .sm_phone_img img{
          max-width: 100%;
          width: 100%;
        }
      }
    }
    video{
      width:100%;
      height:100%;
      object-fit:contain;
      padding:0 10px;

    }
    .mob_video{
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .content_sec{
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    //min-height: 131px;
    background:#fff;
    transition:0.12s ease-in-out;
    &.step-4{
      .play_btn_wrap {
        display: none;
      }
    }
    &.step-5{
      .play_btn_wrap {
        display: none;
      }
    }
  }
  .step_container{
    transition:2s ease-in-out;
    //min-height: 131px;
  }
  .border_wrapper{
    background:#D9D9D9;
    width:100%;
    height:3px;
    position: relative;
    .border_bg{
      background:$orange;
      //min-width:78px;
      width:20%;
      height:3px;
      position: absolute;
      top:0;
      left:0;
      transition:0.2s ease-in-out;
    }
  }

  .steps{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding:13px 20px;
    transition: height 0.12s ease-out;
    .left_content{
      .subtitle{
        color:$orange;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0.96px;
        text-transform: uppercase;
      }
      .desc{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        color:$fc-primary;
        &.desc_wd{
          @media(max-width: 480px){
            max-width: 280px;
          }
        }
        @include trans2s;
        .bold{
          font-weight:700;
        }
      }
    }
    .next_btn{
      min-width:52px;
      margin-left:10px;
      width: 52px;
      height: 52px;
      border-radius: 100%;
      background:$orange;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
    }
  }
}
@media (max-width:1199px)  {
  .onboarding_header .container,
  .onboarding_wrapper .container {
    max-width:100%;
    padding:0 40px;
  }
}
@include phonel{
  .floating-label .tooltip_field .floating-input{
    padding-right:40px;
  }
}

.pagination_list{
  position: absolute;
  right: 0;
  left: 0;
  bottom: 53px;
  width:99px;
  margin: auto;
  ul{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    li{
      display:inline-flex;
      width:auto;
      margin:0 6px;
      &:nth-child(4){
        //display:none;
      }
      span{
        width: 8px;
        height:8px;
        display: inline-flex;
        background:#E0E1E5;
        border-radius:100%;
      }
      &.complete{
        cursor:pointer;
      }
      &.active{
        span{
          background:$orange;
        }
      }
    }
  }
}


.progress_container {
  position: absolute;
  bottom:-58px;
  width: 252px;
  height: 4px;
  background: #FFFFFF;
  border-radius:11px;
  overflow: hidden;
  .progress_bar{
     background:$teal-primary;
    width:0;
      height:100%;
    transition: width 0.3s ease;
  }
}

.hide_Sec{
  .progress_container,
  .video_container video,
  .steps_body .desc,
  .play_btn_wrap{
    display:none;
  }
  .video_container img{
    display:inline-flex !important;
    width:780px;
  }
}

.play_btn_wrap {
  position: absolute;
  bottom: -6px;
  left: -13px;
  height:32px;
  width:32px;
  background:#96999F;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius:100%;
  @media (max-width:991px){
    top:-42px;
    left:10px;
  }
  @media (max-height: 750px) {
    bottom: 20px;
  }
}

.play_pause_button{
  height:100%;
  width:100%;
  background:transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius:100%;
  border: none;
  z-index:2;
  position: relative;

  img{
    display:inline-flex !important;
    height:14px;
  }
  span{
    display:inline-flex;
    align-items:center;
    justify-content:center;
  }
  i{
    display:inline-flex;
  }
  .fas{

    height:14px;
    display:inline-flex;
    align-items:center;
  }

  .fa-play{
    img{
      height:14px;
      position: relative;
      right:-1px;
    }
  }
}
.pl1yz2e[class][class] {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  stroke: #fff;
  stroke-width: 2px;
  transition: opacity 350ms;
  opacity:0;
}
.pl1yz2e[class][class].dir-ltr[class][class] {
  transform: rotate(-90deg);
  opacity:1;
}
.dir.pl1yz2e[class][class] {
  margin: auto;
  transition: stroke-dashoffset 250ms;
}


.error_wrapper.name_error{
  top:96%;
}

body:has(.step-4){
  @include tabm {
    background: #F9E5E0 !important;
  }
}
body:has(.step-2){
  @include tabm {
    background: #e7f0f2 !important;
  }
}

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  min-height: 100%;
  position: relative;
  font-family: 'Helvetica Neue';
  font-weight: 400;
  background-color: #fff;
  overflow-y: auto;

}
.mx-1440{
  max-width: 1440px;
  padding:0 40px;
  margin: auto;
  @include phonel{
    padding: 0 20px;
  }
}
.mx-1320{
  max-width: 1320px;
  padding:0 40px;
  margin: auto;
  width:100%;
  @include phonel{
    padding: 0 20px;
  }
}

.mx-989{
  max-width: 989px;
  padding:0 20px;
  margin: auto;

}
.mx-930{
  max-width: 935px;
  padding:0 20px;
  margin: auto;
}
.mw_1440{
  max-width: 1440px;
  padding:0 40px;
  margin: auto;
  @include phonel{
    padding: 0 20px;
  }
}
.mw_1665{
  max-width: 1665px;
  padding:0 50px;
  margin: auto;
  @media (max-width: 1280px) {
    padding: 0 40px;
  }
  @media (max-width: 834px) {
    padding: 0 20px;
  }
}
//.mw_1665:has(.type_banner){
//  @media (max-width: 834px) {
//    padding: 0 20px;
//  }
//}
.mw_940{
  max-width: 989px;
  padding:0 20px;
  margin: auto;
  &.p_lr_0{
   @include phonel{
     padding:0;
   }
  }
}

/* width */
.self_product_wrapper, .spaces_list_wrapper,
.product_list, .preview_wrapper, .dummy_card_list, .dropdown-menu, .p-dialog-content,
.p-listbox-list-wrapper, .inner_dropdown_list, .p-multiselect-panel, .brand_list_section,
.carousel_body .carousel, .quote_list{
  &::-webkit-scrollbar {
    width: 0px;
    height:0;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
.p-overflow-hidden{
  @include tabl {
    position: fixed;
    overflow: hidden;
    right: 0;
    left: 0;
    top: 0;
    max-height:none;
    &:has(.bottom_modal_ui){
      position:initial;
    }
  }
}
body{
  &.overflow, &.overflow-scroll {
    overflow: hidden !important;
  }
}
body.cart_ui{
  background: #F9FAFB;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

ul,
li,
a,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin-bottom: 0px;
}
ul,
ol {
  padding-left: 0;
}
.pr-0{
  padding-right: 0;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}
button:focus {
  outline: none;
}
.wrapper {
  //padding-top: 106px;
}


.filter_product{
  .icon-list-item {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 28px 0px;
    text-align: center;


    li {
      //padding-right: 52px;
      min-width: 135px;
      max-width: 135px;
      padding: 0px 5px 2px 5px;
      margin-bottom: 45px;

      a {
        display: inline-block;

        .icon-list-text {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: #444444;
          padding-top: 9px;
          padding-right: 5px;
          padding-left: 5px;
          word-break: break-word;
        }

        &:hover {
          //filter: invert(49%) sepia(66%) saturate(3118%) hue-rotate(333deg) brightness(94%) contrast(83%);
          filter: invert(38%) sepia(66%) saturate(1256%) hue-rotate(332deg) brightness(87%) contrast(77%);
        }

      }
    }
  }

}

.spacer-20{
  height: 20px;
}
.spacer-30{
  height: 30px;
}
.spacer-40{
  height: 40px;
}
.spacer-50{
  height:50px;
}
.spacer-60{
  height:60px;
}


//Bootstrap 5 specific changes

.row{
  --bs-gutter-x: 30px;
}

//app-header + .wrapper{
//  padding-top:70px;
//}
.p-toast{
  z-index: 99999 !important;
}
.gray-bg{
  background:$gray300;
}

.windows {
  .quotes_card_container .brand_bar .brand_bar_inner  {
    .brand_name{
      .brand_heading {
        top: 3px !important;
        margin-top: 2px !important;
      }
      .non_minoan_icon{
        top: 3px;
        position: relative;
      }
    }
  }
  .quote-custom-tooltip{
    padding: 32px 25px 29px 25px;
  }
  .onboarding_wrapper.full_height .left_wrapper .video_container video {
    aspect-ratio: 17 / 12;
  }
  .p-accordion .accordian_card .p-accordion-header .step_count{
    top:-3px;
    align-items:flex-end;
  }

}

.intercom-lightweight-app-launcher{
  bottom: 20px!important;
  right: 20px!important;
 @include phonel{
   bottom: 70px!important;
   z-index: 990!important;
 }
}


.intercom-namespace .intercom-1dwjqh, .intercom-dfosxs {
  @include phonel{
    bottom: 70px!important;
    z-index: 990!important;
  }
}

.intercom-namespace{
  .intercom-with-namespace-52k34s{
    @include phonem{
      bottom: 65px!important;
      z-index: 990!important;
    }
  }
}



.for-chrome{
  .scrolling_top_for_chrome{
    height: 75px;
    padding-bottom: 15px;

  }
  .scrolling_bottom_for_chrome{
    height: 75px;
    padding-bottom: 15px;


  }
  .back-top-wrapper.scrolled {
    bottom: 104px;
  }
  .intercom-lightweight-app-launcher {
    @include phonel{
      bottom: 100px!important;
    }
  }
  .intercom-namespace .intercom-1dwjqh, .intercom-dfosxs {
    @include phonel{
      bottom: 100px!important;
    }
  }
  .pagination_wrapper{
    @include phonel{
      padding-bottom: 45px;
    }
  }
  .card_listing_btn{
    @include phonel{
      padding-bottom: 15px;
    }
  }
}

// When intercom opens, this div gets content giving it height and width. On close, intercom removes all content from it.
.intercom-messenger-frame {
  z-index: 2147483001 !important;
  @include phonem {
    bottom:134px !important;
  }
}



.hide-intercom{
  .intercom-lightweight-app{
    display:none !important;
  }
}

.sticky-on-scroll{
  .sticky_header{
    border-bottom: 1px solid #EAECF0;
  }
}

.acc-close-space{
  .mobile_cart_summary .p-accordion .accordian_card .p-accordion-header .p-accordion-header-link {
    padding: 15px 0 0 0;
    margin-bottom: 10px;
  }
  .quote_accordian_header .quote_price {
    margin-top: 9px!important;
  }
}

.short_desc{
  ol,ul{
    display: inline-block;
    width: 100%;
    padding-left: 15px;
  }
}

.showroom{
  .intercom-lightweight-app-launcher{
    bottom:70px;
  }
}


.drag_cursor .self_product_card.cdk-drag-preview{
  div.drop_ui{
    display:flex !important;
  }
}
.self_product_card.cdk-drag-preview{
    background: transparent !important;
    border: none !important;
    height: auto !important;
    width: auto !important;
    min-width: 130px;
  app-product-card{
    display:none !important;
  }
  div.drop_ui{
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);

    .icon{
      margin-right:5px;
    }
    p{
      padding: 6px 8px 6px 8px;
      border-radius: 4px;
      background: #444444CC;
      color:#fff;
      font-size: 13px;
      font-weight: 400;
      line-height: 9px;
      text-align: center;

    }
  }
}

.pac-container:after{
  content:none !important;
}
.no-image{
  border: 2px solid #EA1212;
  margin-bottom: 25px;
}

.custom_skeleton{
  .p-skeleton {
    background: linear-gradient(270deg, #FFFFFF -27.17%, #F5F5F5 100%);
    border-radius: 4px;

  }
}
.container-group {
  .slide_block {
    .mw-1270 {
      max-width: 989px;
      padding-left:0;
      padding-right:0;
    }
      .images .img2{
        padding-right:40px;
      }
     .images .img3{
      padding-right:31px;
    }
  }
}

.container-group{
  .mw-1270 {
    max-width: 959px;
    padding-left:0;
    padding-right:0;
    @media(max-width: 991.98px){
      max-width: 100%;
    }
  }
  .feature-sections{
    .ftr-block{
        max-width: 280px;
      @media(max-width: 767.98px){
        max-width: 100%;
      }
      @media(max-width: 480px){
        max-width: 387px;
      }
    }
  }
  &.slide_block .images {
    top: 300px;
    margin-top: 220px;
    height: 350px;
  }

  .comission-section {
    .comis-image {
      padding-right:2px;
      &.image-2 {
        padding-right: 18px;
      }
      &.image-3 {
        padding-right: 9px;
      }
    }
    .comis-content {
      margin-left: 14px;
    }
    .com-step-2{

    }
    .com-step-3{
      .comis-content {
        margin-left: 22px;
      }
    }
  }

  .comis-left-col{
    width: 56%;
    @media(max-width: 991.98px){
      width: 100%;
    }
    @media(max-width: 767.98px){
        width: 100%;
    }
  }
  .comis-right-col{
    width: 44%;
    @media(max-width: 991.98px){
      width:100%;
    }
    @media(max-width: 767.98px){
      width: 100%;
    }
  }
  .still_question_container {
    .still_question_inner {
      .still_question_accordian {
        padding-right: 0;
        width:609px;
        @include tabm{
          width:100%;
        }
      }
    }
  }

}
.banner-section:has(.dashboard_wrapper) body{
  overflow: hidden;
  overflow-y: auto;
}
.dashboard_wrapper:has(.container-group) .dashboard_top_section {
  padding-bottom: 53px;
  @include phonel{
    padding-bottom:0;
  }
}
.dashboard_wrapper:has(.container-group) .text_desc_mob {
  padding-bottom: 40px;
}


.link-primary-btn.add_link_btn{
  margin-left: 0;
  .icon{
    display: inline-flex;
    vertical-align: middle;
    margin-right: 5px;
    transition: 0.2s all ease;
    width:20px;
    height:20px;
  }
}

.hide_getting_started{
  .getting_start_wrapper{
    .getting_start_btn {
      display:none;
    }
  }
}

body:not(.non-showroom){
  .bottom_navbar_outer{
    display:none;
  }
}

.self_product_card.cdk-drag-preview{
  transition:none !important;
}

body:has(.qr_overflow).p-overflow-hidden,
body:has(.bottom_modal_ui, .position_initial){
  position: initial;
}

.self_product_assignment .row .my_items_wrapper {
  &.loader_overlay{
    height:100vh;
    .loader_wrapper {
      height: calc(100vh - 122px);
    }
  }
}

.truncate_2_line{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.curated_by{
  .p-listbox-header{display: none}
  .dropdown{
    cursor: pointer;
    padding: 0;
    &.show{
      border: 2px solid #96999F;
      color: #C7C7C7;
    }
  }
  .dropdown-toggle{
    position: relative;
    padding: 12px 30px 12px 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:after {
      content: url("/assets/images/self_serve/curated_Icon.svg");
      width: 12px;
      height:7px;
      border: none;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      display: inline-flex;
      align-items: center;
    }
  }
  .dropdown-menu{
    position: absolute;
    transform: none !important;
    left: -2px !important;
    width: calc(100% + 4px);
    top: 55px !important;
    transition: none !important;
    cursor: text;
    &.show{
      position: absolute;
      padding:0;
      left: -2px !important;
      width: calc(100% + 4px);
      box-shadow: 2px 2px 6px 0px #00000033;
      transform: none !important;
      border-radius: 4px;
      border: 2px solid #E0E1E5;
      top: 55px !important;
      transition: none !important;
    }
  }
  .p-listbox{
    border: none !important;
    box-shadow: none !important;
  }
  .p-listbox-list{
    padding:0 !important;
    padding-bottom: 10px !important;
    li{
      display: flex;
      position: relative;
      gap:9px;
      align-items: center;
      padding: 10px 14px 10px 14px!important;
      cursor: pointer;
      margin-top: 39px !important;
      overflow: visible;
      &.p-highlight{
        background: rgba(85, 146, 160, 0.1) !important;
        box-shadow: none;
      }
      &:hover{
        background: rgba(85, 146, 160, 0.1) !important;
        box-shadow: none !important;
      }
      &:focus{
        background: rgba(85, 146, 160, 0.1) !important;
        box-shadow: none !important;
      }
      .item_name{
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        text-align: left;
        color:#444444
      }
      .name_type{
        position: absolute;
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
        text-align: left;
        color: #444444;
        top: -39px;
        cursor: text;
        pointer-events: none;
        padding-top: 12px;
        &:hover{
          pointer-events: none;
        }
      }
      .p-checkbox{
        margin: 0 !important;
        width: 20px;
        height: 20px;
        .p-checkbox-box{
          height: 20px;
          border-radius: 4px;
          &:hover{
            border-color: #96999F !important;
          }
        }
        .p-highlight{
          background: #5592A0;
          border-color: #5592A0;
          width: 20px;
          height: 20px;
          &:hover{
            border-color: #5592A0 !important;
            background: #5592A0 !important;
          }
        }
      }
    }
  }
}
//onboarding stepper
.parent_wrapper{
  .onboarding_wrapper.full_height {
    padding-bottom: 85px;
  }
  .footer_stepper{
    display:block !important;
    @media (max-width: 991.98px) {
      display:none !important;
    }
  }
  .step_welcome{
    display:block;
    @media (max-width: 991.98px) {
      display:none;
    }
  }
}

.hide_logo {
  .logo_wrap{
    display: none;
  }
}

.inner_float_group {
  .p-multiselect .p-multiselect-trigger {
    margin-right: 11px;
  }
  .multiselect-ui-outer .multi-select-count {
    right: 61px;
  }
  .new_property_wrapper .multiselect_group_by .p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 23px 35px 10px 16px;
  }
  .fix_label{
    position: relative;
    .float_label_txt{
      position: absolute;
      left: 17px;
      top: -16px;
      padding: 8px;
      padding-right: 10px;
      background-color: #fff;
      transform: translateY(0);
      color: #96999F;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      transition: all 0.3s ease-in;
      z-index: 1;
    }
    .form-control{
      &:focus{
        &::placeholder {
          color: transparent;
        }
      }
      &:not(:focus)::placeholder {
        color: transparent;
      }
    }
  }
  .float_label {
    position: relative;
    .float_label_txt {
        position: absolute;
        left: 17px;
        top: 50%;
        background-color: #fff;
        font-size: 22px;
        font-weight: 300;
        line-height: 22px;
        color: #C7C7C7;
        pointer-events: none;
        transition:all 0.2s ease-in;
        padding: 7px 8px;
        cursor: text;
        transform: translateY(-50%);
    }
    .form-control {
      &::placeholder {
        color:transparent;
      }
      &:focus {
        + {
          .float_label_txt {
            top: -15px;
            transform: translateY(0);
            font-size: 16px;
            font-weight: 400;
            opacity: 1  ;
            visibility: visible;
          }
        }
        &::placeholder {
          color: transparent;
        }
      }

      &:not(:placeholder-shown) {
        + {
          .float_label_txt {
            top: -15px;
            transform: translateY(0);
            font-size: 16px;
            font-weight: 400;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

body:has(.fix_label) .p-multiselect-label{
  padding-left: 25px !important;
}



@include phonel{
  .type_banner {
    margin: 0 -20px;
  }
}

.p-component {
  font-family: 'Helvetica Neue';
}

.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: .5rem;
}

.p-button:focus {
  box-shadow: none;
}

// For one-schema iframe
iframe#_oneschema-iframe {
  z-index: 9;
}

.quote_card_desc .card_top_bar, .quote_brand_mobile .quote_brand_heading_mobile {
  .skeleton_div {
    width: 80%;
    .p-component.p-skeleton.variant_skeleton {
      margin-top: 8px;
    }
  }
}
.curations_section{
  overflow-x:hidden;
}

.position_fixed {
  position: fixed;
}

.offer_text {
    .text_section span{
      font-size: 15px;
      font-weight: 400;
      line-height: 17.9px;
      letter-spacing: -0.01em;
      color: #006878 !important;
      text-decoration: none;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  p{
    strong{
      font-weight: 700 !important;
      display: inline;
    }
    b{
      font-weight: 400 !important;
      display: inline;
    }
    em{
      font-style: italic;
    }
    u{

    }
    s {
      text-decoration: line-through;
    }
    ul, ol, li, h1, h2, h3, h4, h5, h6, span, a{
      font-size: 15px;
      font-weight: 400;
      line-height: 17.9px;
      letter-spacing: -0.01em;
      color:#006878!important;
      text-decoration: none;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  p:empty, p:has(> br:only-child), *:empty {
    display: none;
  }
}
.brands_section{
  .brand_list_section{
    .brand_data{
      &.live_sale{
        .button_section{
          .text_section {
            .offer_text{
              font-size: 15px;
              font-weight: 400;
              line-height: 17.9px;
              letter-spacing: -0.01em;
              color:#006878;
              @media (max-width: 430px) {
                font-size: 14px;
                line-height: 16.7px;
                letter-spacing: -0.01em;
              }
            }
          }
        }
      }
      .button_section{
        .text_section {
          .offer_text{
            font-size: 15px;
            font-weight: 400;
            line-height: 17.9px;
            letter-spacing: -0.01em;
            color:#006878;
            @media (max-width: 430px) {
              font-size: 14px;
              line-height: 16.7px;
              letter-spacing: -0.01em;
            }
            span{
              color:#006878 !important;
            }
          }
        }
      }
    }
  }
}

.brands_section {
  .brand_list_section {
    &.loader_only {
      .brand_data {
        outline-color: white !important;
        outline-width: 2px !important;
        p-skeleton {
          width: 100%;
          height: 158.33px;
        }
        .p-component {
          border-radius: 0 !important;
        }
        &:hover {
          &:after {
            content: none !important;
          }
        }
      }
    }
  }
}


.p-skeleton {
  background-color:rgba(224, 225, 229, 0.4392156863);
  border-radius:2px;
  &:after{
    background:linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  }
}

.skeleton_div {
  display: flex;
  gap:12px;
  align-items: center;
  .radius_ui .p-skeleton {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: inline-block;
  }
}
.PinturaRootWrapper{
  width: 100%;
  height: auto;
  .pintura-editor{
    height: auto;
    min-height: 440px;
  }
  .PinturaNavTools{
    padding-top: 0px!important;
  }
}

.pintura-editor {
  --color-preview-outline: 199, 199, 199;
  .PinturaNavSet{
    border: none!important;
    box-shadow: none!important;
    button {
      border: none!important;
      &:hover{
        background-color: transparent!important;
      }
    }
  }
  .PinturaNavTools{
    justify-content: flex-start;
    .PinturaNavSet{
      .PinturaButton {
        margin-right: 20px;
        display: inline-flex;
        &.PinturaButtonIconOnly{
         font-size: 24px;
          .PinturaButtonInner {
            width: auto;
            height: auto;
              .implicit {
                font-family: 'Helvetica Neue';
                position: relative;
                overflow: visible;
                width: auto;
                height: auto;
                clip: initial;
                clip-path: initial;
                padding-left: 8px;
                font-size: 15px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.6px;
                text-align: left;
                color: #000000;
              }
          }
        }
      }
    }
  }

  .PinturaRectManipulator{
  }

}

.PinturaRectManipulator[data-shape~=circle] {
  background-color: rgba(83, 83, 92, 0.7);
  border-radius: 1px!important;
  border: 1.5px solid #C7C7C7;
  width: 10px;
  height: 10px;
}



@include tabv-min{
  .quote_right_rail_sticky{
    &.check_scroll{
      position: relative;
      z-index: 11;
    }
    .sticky_sec{
      position: sticky;
      top:30px;
      max-height: calc(100dvh - 47px);
      //overflow: scroll;
      @include trans2s;
      margin: 0 -10px 0 -10px;
      padding: 0 10px 60px 10px;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      &::-webkit-scrollbar-track {
        background:transparent;
      }
      &.scrollup{
        //top:90px;
        top: 61px;
        max-height: calc(100dvh - 78px);
        &.checked{
          //top:140px;
          top:115px;
          max-height: calc(100dvh - 132px);
        }
      }
      &.checked{
        //top:85px;
        top:56px;
        max-height: calc(100dvh - 73px);
      }
    }

  }
}

.right-rail-pinned{
  overflow: auto;
}

body:has(.scroll_down_action.is-pinned.scrolling_top) {
  .header_container.scrolling_top::after{
    box-shadow: none;
  }
  .getting_start_wrapper {
    top: 63px;
    transition: 0.4s ease-out;
   &.show{
     transition:none;
   }
  }
}

body:has(.scroll_down_action.is-pinned.scrolling_bottom) {
  .header_container.scrolling_bottom::after{
    transition: all 0.4s ease-out;
    transition-delay: 0.4s;
  }
  .getting_start_wrapper {
    top: 63px;
    transition: 0.2s ease-out;
    &.show{
      transition: none;
    }
  }
}

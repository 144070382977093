@import "/src/assets/scss/base/colors";
@import "/src/assets/scss/helpers/mixins";

.mw-1270 {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 1280px){
    padding-left: 45px;
    padding-right: 45px;
  }
  @media (max-width: 991.98px) {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.ffm-itc {
  font-family: var(--font_Souvenir_light);
}
.link_details {
  color: #006878;
  &:hover{
    text-decoration: underline;
    color: #006878;
  }
}
.heading-14 {
  font-family: var(--font_Souvenir_light);
  font-size: 26px;
  font-weight: 300;
  line-height: 32px;
  @media (max-width: 1198.98px) {
    font-size: 21px;
  }
}
.mute-link {
  font-family: "Helvetica Neue";
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #96999f;
  display: block;
  max-width: 350px;
  padding: 0 36px;
}

.heading-15 {
  font-family: var(--font_Souvenir_light);
  font-size: 38px;
  font-weight: 300;
  line-height: 44px;
  @media (max-width: 1198.98px) {
    font-size: 32px;
  }
  @media (max-width: 767.98px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.para-4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  font-family: "Helvetica Neue";
  @media (max-width: 1198.98px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media (max-width: 767.98px) {
    line-height: 24px;
  }
}
.para-5 {
  font-family: "Helvetica Neue";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 1198.98px) {
    font-size: 16px;
    line-height: 26px;
  }
}

.text-grey {
  color: #444444;
}

.banner-section {
  position: relative;
  overflow: hidden;
  .heading2 {
      font-size: 52px;
      line-height: 65px;
    @media (max-width: 1550px) {
      font-size: 48px;
      line-height: 61px;
    }
    @media(max-width: 1199.98px){
      font-size: 40px;
      line-height: 50px;
    }
    @media (max-width: 991.98px) {
      font-size: 31px;
      line-height: 44px;
      letter-spacing: 0.01em;
      text-align: center;
    }
  }
  .full-vh {
    height: calc(100vh - 60px);
    min-height: 700px;
    @media (max-width: 1280px) {
      min-height: 498px;
      height: auto;
    }
    @media (max-width: 991.98px) {
      min-height: auto;
    }
  }
  .phone-banner {
    margin-bottom: 33px;
  }
  .banner-content {
    max-width: 609px;
    margin: 0 auto;
    margin-right: 58px;
    @media (min-width:1550px){
      margin: 0 auto;
    }
    @media (max-width: 1550px) {
      max-width: 562px;
    }

    @media (max-width: 1280px){
      margin-right:0;
    }

    @media (max-width: 1100px) {
      max-width: 447px;
    }
    @media (max-width: 991.98px) {
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      margin-right:auto;
    }
  }
  .banner-title {
    margin-bottom: 26px;
    @media (max-width: 2498px) {
      margin-bottom: 12px;
    }
    @media (max-width: 1198.98px) {
      margin-bottom: 25px;
    }
    @media (max-width: 991.98px) {
      max-width:375px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 17px;

    }
  }
  .banner-desc {
    margin-bottom: 36px;
    max-width: 646px;
    @media (max-width: 1540px) {
      max-width: 507px;
      margin-bottom: 32px;
    }
    @media (max-width: 1198.98px) {
      margin-bottom: 25px;
    }
    @media (max-width: 991.98px) {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }
  }

  .banner-btn {
    gap: 31px;
    @media (max-width: 1199.98px) {
      gap: 17px;
    }
    @media (max-width: 991.98px) {
      row-gap: 17px;
      flex-direction: column;
    }
    .primary_text_btn {
      cursor: pointer;
      font-family: "Helvetica Neue";
      font-size: 16px;
      font-weight: 400;
      transition: 0.2s all ease;
      color: #dc5a4b;
      transition: 0.2s all ease;
      cursor: pointer;
      &:hover {
        color: #e37b6f !important;
      }
    }
  }
  .content-col {
    padding-left: 0;
    padding-right: 0;
    order: 2;
    @media(max-width: 1280px){
      padding-left: 45px;
    }
    @media(max-width: 1199.90px){
      padding-left: 45px;
      padding-right: 45px;
    }
    @media (max-width: 991.98px) {
      padding-bottom: 67px;
      padding-left: 0px;
      padding-right:0px;
    }

  }
  .baner-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    @media (min-width: 2500px) {
      width: 50%;
    }
    @media (max-width: 1280px) {
      min-height:478px;
    }
    @media (max-width: 1280px) {
      width: 478px;
    }
    @media (max-width: 991.98px) {
      min-height: auto;
      position: static;
      width: 100%;
      order: 1;
      margin-bottom: 33px;
    }
  }
  .banner-rt-img img{
    width: 100%;
    height:100%;
    object-fit: cover;
    @media (max-width: 991.98px) {
      height: auto;
    }
  }
  .btn_primary {
    font-size: 16px;
    font-weight: 400;
    border-radius: 5px;
    min-width: 214px;
    height: 45px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: "Helvetica Neue";
  }
}

.sec-pad {
  padding-top: 90px;
  padding-bottom: 90px;
}

.sec-pad-220 {
  padding-top: 220px;
  padding-bottom: 220px;
  @media(max-width: 1199.98px) {
    padding-top: 220px;
    padding-bottom:208px;
  }
  @media (max-width: 991.98px) {
    padding-top: 112px;
    padding-bottom: 162px;
  }
  @media (max-width: 767.98px) {
    padding-top: 89px;
    padding-bottom: 151px;
  }
}

.feature-sections {
  background-color: #f7f7f7;
  .ftr-col {
    position: relative;
    @media (max-width: 767.98px) {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
        &::before {
          content: none;
        }
      }
      &::before {
        content: "";
        position: absolute;
        width: 279px;
        height: 1px;
        bottom: -30px;
        background-color: #e0e1e5;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  .ftr-block {
    max-width: 315px;
    @media (max-width: 767.98px) {
      max-width: 100%;
      margin: 0 auto;
      text-align: center;
    }
  }

  .ftr-icon {
    margin-bottom: 24px;
    @media (max-width: 767.98px) {
      margin-bottom: 15px;
    }
  }
  .ftr-heading {
    margin-bottom: 15px;
    max-width: 221px;
    @media (max-width: 1198.98px) {
      padding-right: 32px;
    }
    @media (max-width: 767.98px) {
      padding-right: 0;
      max-width: 100%;
    }
  }
}

.comission-section {
  padding-top: 130px;
  padding-bottom: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.full-vh-screen {
    height: 100vh;
    min-height: 600px;
    @media (max-width: 991.98px) {
      height: auto;
      min-height: auto;
      padding-top: 127px;
      padding-bottom: 127px;
    }
  }
  .comis-image {
    padding-right: 65px;
    @media (max-width: 1298.98px) {
      padding-right: 15px;
    }
    @media (max-width: 991.98px) {
      padding-right: 0;
    }
    &.image-2 {
      @media (max-width: 1298.98px) {
        padding-right: 41px;
      }
      @media (max-width: 991.98px) {
        padding-right: 0;
      }
    }
    &.image-3 {
      @media (max-width: 1298.98px) {
        padding-right: 46px;
      }
      @media (max-width: 991.98px) {
        padding-right: 0;
      }
    }
  }
  .comis-content {
    max-width: 389px;
    margin-left: 8px;
    @media (max-width: 1198.98px) {
      max-width: 100%;
      margin-left: 0;
    }

    @media (max-width: 991.98px) {
      margin-bottom: 29px;
    }
  }

  .fix-content {
    max-width: 338px;
  }
  .fix-content-2 {
    max-width: 298px;
  }
  .fix-heading-1 {
    max-width: 389px;
    padding-right: 12px;
  }
  .fix-heading-2 {
    max-width: 241px;
  }
  .label-count {
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.1em;
    font-family: "Helvetica Neue";
  }
}

.teal-bg {
  background-color: $teal-active;
}
.rose-bg {
  background-color: $bg_4;
}
.primary-text {
  color: $teal-primary;
}
.mb-12 {
  margin-bottom: 12px;
}
.img-note {
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #666666;
  margin-top: 17px;
  display: block;
  @media(max-width: 1199.98px) {
    max-width: 445px;
  }
  @media(max-width: 767.98px) {
    max-width: 100%;
    font-size: 11px;
  }
}
.still_question_container {
  .still_question_inner {
    display: flex;
    justify-content: space-between;
    @media (max-width: 991.98px) {
      flex-direction: column;
    }
    .question_heading {
      .question_heading_inner {
        font-family: var(--font_Souvenir_light);
        @include remy(48);
        font-weight: 300;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        color: var(--primary);
        @include tabv {
          font-size: 30px;
          font-weight: 300;
          line-height: 41px;
          letter-spacing: 0.01em;
          margin-bottom: 62px;
          margin-left: 0;
        }
      }
    }
    .still_question_accordian {
      width: 688px;
      margin-left: 25px;
      padding-right: 79px;
      @media (max-width: 1198.98px) {
        padding-right: 35px;
        width:644px;
      }
      @media (max-width:1080px){
      width: 609px;
      padding-right:0px;
    }
      @media (max-width: 991.98px) {
        max-width: 100%;
        margin-left: 0;
        width: 100%;
        padding-right: 0;
      }
    }
  }
}
.faq-section {
  background: #FBFBF9;
  .question_accordian_inner {
    .accordion {
      --bs-accordion-border-width: none;
      .accordion-item {
        border-bottom: 1px solid #e0e1e5;
        &:last-of-type {
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
        }
        &:first-child {
          .accordion-header {
            .accordion-button {
              padding-top: 0;
              &::after {
                top: 15px;
              }
            }
          }
        }

        .accordion-header {
          .accordion-button {
            padding: 35px 0px;
            @include remy(24);
            font-weight: 400;
            line-height: 32px;
            text-align: left;
            color: var(--primary);
            font-family: "Helvetica Neue";
            background: #FBFBF9;
            position: relative;
            padding-right: 40px;
            cursor: pointer;
            &:hover {
              color:#006878;
              @include phonel-m {
                &::after {
                  background-image: url('/assets/images/self_ser_dashboard/downarrow-icons_teal.svg') !important;
                }
              }
            }
            @media (max-width: 1198.98px) {
              padding: 38.5px 0px;
              padding-right: 40px;
              font-size: 18px;
              line-height: 26px;
            }
            @media (max-width: 767.98px) {
              font-size: 18px;
              line-height: 26px;
              padding: 30px 0px;
              padding-right: 40px;
            }
            @include phonel-m {
              &:hover {
                @include trans2s;
              }
            }
            @include phonel {
              padding-right:70px;
              &:focus, &:hover{
                color:$fc-primary;
              }
              &[aria-expanded="true"] {
                @include trans2s;
                color:#006878;

                &:after {
                  background-image: url("/assets/images/self_ser_dashboard/downarrow-icons_teal.svg") !important;
                }
              }

            }

            &:after {
              //display: none;
              transition: none;
              height: 12px;
              background-image: url("/assets/images/self_ser_dashboard/downarrow-icons.svg") !important;
              position: absolute;
              top: 50%;
              transform: translateY(-50%) rotate(180deg);
              right: 0;
            }
            &.collapsed{
              &:after{
                transform: translateY(-50%) rotate(0);
              }
            }
            &:focus {
              box-shadow: none !important;
            }
          }
        }

        .accordion-collapse {
          .accordion-body {
            padding: 0px 0px 35px;
            background: #FBFBF9;
            .accordion_inner_text {
              @include remy(20);
              font-weight: 400;
              line-height: 30px;
              text-align: left;
              color: var(--primary);
              @media (max-width: 768px) {
                font-size: 18px;
                line-height: 26px;
              }
            }

            .accordion_small_text {
              @include remy(14);
              font-weight: 400;
              font-style: italic;
              line-height: 22px;
              text-align: left;
              color: var(--primary);
              margin-top: 9px;
              max-width: 435px;
            }
          }
        }
      }
    }
  }
}

.slide_block {
  transition: background-color 1s ease;
  @media (min-width: 992px){
    min-height: 100vh;
    height: 100%;
  }
  @media (max-width: 767.98px) {
    overflow: hidden;
  }
  .mw-1270{
    @media(max-width: 991.98px) {
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
      max-width: 100% !important;
    }
  }
  .sticky-block{
    position: sticky;
    top: 0;
    height: 100vh;
    min-height: 700px;
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  .images {
    width: 100%;
    height: 100%;
    @media (max-width: 991.98px) {
      display: none;
    }
    .img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: auto;
      width: 100%;
      overflow: hidden;
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
    .img1 {
      padding-right: 18px;
      margin-left: -5px;
      @media (max-width: 1199.98px) {
        padding-right: 0;
        padding-left: 31px;
        img{
          max-width:495px;
        }
      }
    }
    .img2 {
      padding-right: 64px;
      @media (max-width: 1199.98px) {
        padding-right: 45px;
        padding-left: 39px;
        img{
          max-width:450px;
        }
      }
    }
    .img3 {
      padding-right: 66px;
      @media (max-width: 1199.98px) {
        padding-left: 37px;
        padding-right: 44px;
        img{
          max-width:450px;
        }
      }
    }
  }

  &.color-teal {
    background-color: #e4eff1;
    .img1 {
      opacity: 1;
      @media (max-width: 767.98px) {
        display: none;
      }
    }
  }
  &.color-rose {
    background-color: #f7dad2;
    .img3 {
      opacity: 1;
      @media (max-width: 767.98px) {
        display: none;
      }
    }
  }
  &.color-white {
    background-color: #FBFBF9;
    .img2 {
      opacity: 1;
      @media (max-width: 767.98px) {
        display: none;
      }
    }
  }
  .phone-view {
    width: 55%;
    flex: 0 0 55%;
    @media (min-width: 992px) {
      display: none;
    }
    @media (max-width: 767.98px) {
      width: 100%;
      flex: 0 0 auto;
    }
  }
  .content {
    height: 100vh;
    min-height: 700px;
    display: flex;
    align-items: center;
    padding: 100px 0 100px;
    @media (max-width: 991.98px) {
      height: auto;
      min-height: auto;
      padding: 130px 20px 130px;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }
    @media (max-width: 767.98px) {
      flex-direction: column;
      justify-content: start;
      align-items: start;
      padding: 90px 20px 90px;
    }
    &.content1{
     @media (min-width:992px){
       //padding-top:400px;
     }
      @media (max-width: 991.98px) {
        background-color: #e4eff1;
      }
    }
    &.content2 {
      @media (max-width: 991.98px) {
        background-color: #fff;
      }
      .comis-content {
        max-width: 339px;
        margin-left: 20px;
        @media (max-width: 767.98px) {
          margin-left: 0;
        }
      }
    }
    &.content3 {
      @media (max-width: 991.98px) {
        background-color: #f7dad2;
      }
      .comis-content {
        max-width: 299px;
        margin-left: 16px;
        @media (max-width: 767.98px) {
          margin-left: 0;
        }
      }
    }
    .comis-content {
      max-width: 389px;
      margin-left: 14px;
      @media (min-width: 992px) {
        opacity: 0;
      }
      @media (max-width: 1200px) {
        margin-left: 0;
      }
      @media (max-width: 991.98px) {
        margin-left: 30px;
        padding: 0;
        //opacity: 1!important;
      }
      @media (max-width: 767.98px) {
        margin-left:0;
        margin-bottom: 30px;

      }
    }
  }
}




@media all and (max-width: 767px) {
  .aos-animate{
    transition-delay:0s !important;
  }
  .banner-desc.aos-animate{
    transition-delay: 0.2s !important;
  }
 .banner-btn.mob_btn.aos-animate{
    transition-delay: 0.4s !important;
  }
}


.banner-section{
  @include tabm {
    .row {
      --bs-gutter-x: 0;
    }
    .banner-section .baner-img{
      padding:0;
    }
  }

  @media (min-width:1550px){
    .para-4{
      @include remy(21);
      line-height:32px;
    }
  }
}

.card_blk1{
  background: #FFFFFF;
  border: 2px solid rgba(229, 229, 229, 0.5);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 20px 28px 30px;
  @include phonel{
    padding: 30px 28px 30px;
  }
  label{
    font-style: normal;
    font-weight: 700;
    @include remy(18);
    line-height: 22px;
    color:$fc-primary;
    margin-bottom: 11px;
  }
  .desc{
    font-style: normal;
    font-weight: 400;
    @include remy(14);
    line-height: 20px;
    color:$fc-primary;
    min-height: 118px;
    margin-bottom: 22px;
  }
}

.card_blk2{
  background: #FFFFFF;
  border-radius: 4px;
  padding:19px 26px 23px;
  position: relative;
  .info-icon{
    position: absolute;
    right: -10px;
    top: -9px;
  }
  label{
    font-style: normal;
    font-weight: 700;
    @include remy(16);
    line-height: 20px;
    color:$fc-primary;
    margin-bottom: 11px;
  }
  .desc{
    font-style: normal;
    font-weight: 400;
    @include remy(14);
    line-height: 20px;
    color:$fc-secondary;
  }
  .el-text{
    font-style: normal;
    font-weight: 700;
    @include remy(16);
    line-height: 20px;
    color:$orange;
    display:block;
    padding-top: 4px;
    &:hover{
      color:$orange-shade;
      .icon svg{
        color:$orange-shade;
      }
    }
    .icon{
      margin-left: 8px;
    }
  }
}

//.card_blk3{
//  background: #F5F5F5;
//  border: 2px solid rgba(229, 229, 229, 0.5);
//  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
//  border-radius: 14px;
//  padding: 30px 40px;
//  .image-section{
//    flex: 0 0 auto;
//    width: 20%;
//  }
//  .description-section{
//    flex: 0 0 auto;
//    width: 80%;
//    .desc{
//      font-style: normal;
//      font-weight: 400;
//      @include remy(16);
//      line-height: 22px;
//      color:$fc-primary;
//      min-height: 135px;
//      margin-bottom: 24px;
//      .bold{
//        font-weight: 700;
//      }
//    }
//  }
//}

/*product card */

.product_card{
  margin-bottom: 30px;
  @include transition(0.2s ease-in-out);
  @include phonel{
    margin-bottom:23px;
  }
  &:hover{
    .btn_blks{
      opacity: 1;
      visibility: visible;
      //bottom: 22px;
      @include transition(0.2s ease-in-out);
    }
  }
  .btn_blks{
    position: absolute;
    text-align: center;
    //bottom: 0px;
    bottom: 22px;
    width:100%;
    opacity: 0;
    visibility: hidden;
    @include  trans2s;
    .text_btn{
      background: rgba(#ffffff , 0.8);
      border-radius: 30px;
      padding:5px 20px;
      margin: 0 8px;
      @include remy(15);
      line-height: 18px;
      color: #222222;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @include trans2s;
      svg path{
        @include trans2s;
      }
      &:hover{
        color:$orange;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.35);
        .icon{
          svg path{
            stroke:$orange;
          }
        }
      }
      .icon{
        display: inline-flex;
        margin-left: 6px;
      }
    }
    @include tabv{
      display: none;
    }
  }
  figure{
    height:250px;
    position: relative;
    margin-bottom: 0;
    overflow: hidden;
      .product_banner{
        @include transition(all 1s ease);

      }
    &:hover{
      .product_banner{
        transform: scale(1.04);
      }
      .card_overlay{
        background: rgba(#000 , 0.50);
      }
    }
    @include phonel{
      height:200px;
    }
    .card_url{
      position: absolute;
      width:100%;
      height:100%;
      left:0;
      top:0;
    }
    .product_banner{
      height:100%;
      width: 100%;
      object-fit: cover;
      @include phonel{
        object-position: center;
      }
    }
    .card_overlay{
      background: rgba(#000 , 0.50);
      width:100%;
      height:100%;
      position: absolute;
      left:0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition(all 1s ease);
    }
  }
  .product_content{
    padding:15px 0 0;
    @include phonel{
      padding-top: 8.53px;
    }
    .head_blk{
      display: flex;
      align-items:flex-start;
      justify-content: space-between;
      padding-bottom: 0px;
      flex-direction: column;
      .product_title{
        font-style: normal;
        font-weight: 700;
        @include remy(22);
        line-height: 27px;
        text-align: left;
        color:$fc-primary;
        display: inline-block;
        width:100%;
        p{
          br{
            display:none;
          }
        }
        @include tabl{
          @include remy(22);
          line-height: 27px;
        }
      }
    .offer_text, .offer_text span{
      font-style: normal;
      font-weight: 700;
      line-height: 24.42px;
      @include remy(20);
      color:$teal-primary !important;
      background: transparent !important;
      width: 100%;
      display: inline;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 1px;
      font-family:var(--helvetica700);
      @include tabl{
        @include remy(18);
        line-height: 22px;
      }
      @include phonel{
        @include remy(16);
        font-weight: 700;
        line-height: 19.54px;
      }
      p{
        display: inline;
      }
      &.text-decoration-line-through{
        color: #5592A0 !important;
      }
      &.subtext{
        font-size: 16px;
        font-weight: 400;
        line-height: 19.09px;
        display: inline;
        @include phonel{
          font-size: 15px;
          font-weight: 400;
          line-height: 17.9px;
        }
      }
    }
    .category-list{
      color: #666;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.09px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;


    }
    }
    .btn_blks{
      display: flex;
      align-items: center;
    }
    .text_btn{
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 19px;
      color: $fc_gray400;
      transition: 0.2s ease-in-out;
      &:hover{
        color:$fc-primary;
      }
      @include phonel{
        @include remy(18);
        line-height: 21px;
      }
    }
    .seprater{
      height:16px;
      width:1px;
      background:$orange-300;
      margin: 0 8px;
      display: inline-flex;
    }
  }
  .brand_icon{
    display: flex;
    align-items: center;
    max-width: 260px;
    img{
      width:auto;
      max-width: 260px;
      max-height:80px;
      height: auto;
    }
  }
}
.no_sale_discount{
  @include remy(20);
  line-height: 23px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #666666;
}

.card_blk3{
  background: rgba(245, 245, 245, 0.5);
  border: 3px solid $bg-gray;
  border-radius: 14px;
  padding: 30px 40px;
  display: flex;
  align-items: flex-start;
  @include tabl{
    padding: 30px 24px;
    @include tabm{
      flex-direction: column;
    }
  }
  figure, .image-section{
    width:90px;
    @include tabm{
      margin-bottom: 0;
    }
  }
  .content_blk, .description-section{
    //width:calc(100% - 90px);
    //padding:0 20px 0 40px;
    @include tabm{
      width:100%;
      padding:24px 0px 0 0px;
    }
    .desc{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 24px;
      color:$fc-primary;

      strong, .bold{
        font-weight: 700;
      }
    }
  }
}


/* shop browse slider */
.ls-hero-section{
  .slider-title{
    font-style: normal;
    font-weight: 300;
    @include remy(36);
    line-height: 43px;
    color: #FFFFFF;
    margin-bottom: 15px;
    font-family: $font_Souvenir_light;
  }
  .slick-list{
    margin:0 -16px;
  }
  .slick-card{
    margin: 0 16px;
  }
  .slick-arrow{
    top:calc(50% - 14px);
    &:hover{
      transform: scale(1.06) translateY(-50%);
      top:calc(50% - 14px);
    }
  }

  .slick-prev{
    left:-19px;
    width:42px;
    height:42px;
    background: url("/assets/images/icon-prev.svg");
    z-index:1;
    @include  transition(0.2s ease-in-out);
    &:before{
      opacity: 0;
    }
    &:hover{
      opacity:0.95;
    }
    @include phonel{
      left:-12px;
    }

  }

  .slick-next{
    right:-19px;
    width:42px;
    height:42px;
    background: url("/assets/images/icon-next.svg");
    @include  transition(0.2s ease-in-out);
    z-index: 1;
    &:before{
      opacity: 0;
    }
    &:hover{
      //opacity:0.95;
      color:$orange-tint;
    }
    @include phonel{
      right:-10px;
    }
  }
}
.productlisting-card{
  padding: 0 15px;
  .product-img{
    height:243px;
    margin-bottom: 0;
    img{
      width:100%;
      height:100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .product-name{
    text-align: center;
    font-style: normal;
    font-weight: 400;
    @include remy(16);
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding-top: 9px;
  }
}


.browse-page-content{
  .product_card{
    figure{
      height:227px;
    }
  }
}




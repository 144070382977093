//@import
//'./src/assets/scss/pages/_home.scss';

.dropdown{
  .dropdown_btn {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    border: none;
    @include transition(0.2s ease-in-out);
    &:after{
      display: none;
    }

  }
  .dropdown-menu{
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0;
    border:none;
    visibility: hidden;
    opacity: 0;
    display: block;
    @include transition(0.2s ease-in-out);
    top:-2px;
    &.show{
      opacity: 1;
      visibility: visible;
      top: 0;
    }
    li{
      list-style: none;
      a{
        background: transparent;
        &:hover,
        &:hover{
          background: transparent;
        }
      }
      label{
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $fc-primary;
      }
    }
  }
}


.hamburger-menu{
  display: none;
  @include phonel {
    display: inline-flex;
    align-items: center;
    z-index: 10;
    order: 2;
  }
  .overlay{
    @include phonel{
      display: none;
    }
  }
}

.menu-btn{
  width:25px;
  height:18px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  border: none;
  background: transparent;
  z-index: 2;
  &:after{
    position: absolute;
    content: "";
    width:32px;
    height:32px;
    background:#fff;
    left:-4px;
    top:-8px;
    border-radius: 2px;
    z-index: -1;
    transition:0.2s ease-in-out;
  }
    span{
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background:$fc-primary;
      border-radius: 4px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

    }
    span:nth-child(1) {
    top: 0px;
    transform-origin: left center;
  }

    span:nth-child(2) {
    top: 8px;
    transform-origin: left center;
  }

  span:nth-child(3) {
    top: 16px;
    transform-origin: left center;
  }

  &.active {
    @include phonel{
     span{ background:$orange;}
    }
    span:nth-child(1) {
      transform: rotate(45deg);
      top: -3px;
      left: 3px;
    }
    span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
      top: 15px;
      left: 3px;
    }
  }
}

.overlay{
  position:fixed;
  width:100%;
  height:100%;
  left:0;
  top: 0;
  background: rgba(#000 , 0.35);
  opacity: 0;
  visibility: hidden;
  overflow-y: hidden;
  @include transition(0.2s ease-in-out);
  &.active{
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
}



/*new header wrapper*/
.sticky-header{
  .header_container:after{
    box-shadow: none;
  }
}
.sticky-header-shadow{
  .tabs_header{
    box-shadow: 0px 0px 8px 0px #00000033;
  }
}

.header_container{
  padding:12px 0 11px;
  height:60px;
  position: sticky;
  //position: relative;
  top:0;
  width:100%;
  left: 0;
  z-index:13;
  //transition: 0.4s ease-in-out;
  transition: 0.4s ease-out;
  &.active{
    position: fixed;
    @include phonel{
      z-index: 9999;
    }
  }
  &.scrolling_top{
    position: sticky;
    top: 0;
    @include phonel{
      top: 0;
      //position: fixed;
    }
  }
  &.scrolling_bottom{
    position: sticky;
    top: -60px;
    @include phonel{
      top: -60px;
      //position: fixed;
    }
  }
  @include phonel{
    //min-height: auto;
    //padding:16px 0 16px;
    ////height: auto;
    //position: fixed;
    //top: 0;
  }
  .mx-1440{
    padding: 0 30px;
    max-width: 1500px;
    @include tabm{
      padding: 0 20px;
    }
  }
  &:after{
    position: absolute;
    content: "";
    height:100%;
    width:100%;
    left: 0;
    top: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    z-index: 1;
    @include phonel{
      filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
      box-shadow: none;
    }
  }
  &.chrome_page{
    &:after{
      box-shadow: none;
    }
  }
  .container-fluid{
    padding: 0 30px;
    @include tabl{
      padding: 0 20px ;
    }
  }
  .inner_header_wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:relative;
    z-index:2;
    .logo_wrap .logo_ui{
      @include phonel{
        max-width:108px;
        height:28px;
        img{
          width:100%;
        }
      }
    }
    .logo_wrap{
      @include tabl-m{
        width: 30%;
      }
      @media (min-width:991px) and (max-width: 1199px){
        width:25%;
      }
      @media (min-width:768px) and (max-width: 991px){
        width:20%;
      }
      @include phonel{
        display: inline-flex;
      }
      .logo_ui{
        display: inline-flex;
        height: 30px;
        @include phonel{
          height: 28px;
        }
      }
    }
    .profile_ui.dropdown{
      @include tabl-m{
        max-width: 75%;
        //max-width: 326px;
        display: flex;
        justify-content: flex-end;

      }
      @media (min-width:991px) and (max-width: 1199px){
        //width:75%;
        max-width: 75%;
        display: flex;
        justify-content: flex-end;
      }
      @media (min-width:768px) and (max-width: 991px){
        //width:20%;
        justify-content: flex-end;
        display: flex;
       .dropdown_btn{
         width:100%;
       }
      }

    }
  }
  .navbar-wrapper{
    margin-left:-35px;
    @include tabl-m{
      width: 40%;
      justify-content: center;
      display: flex;
    }
    @media (min-width:991px) and (max-width: 1199px){
      width:50%;justify-content: center;
      display: flex;
      margin-left:-100px;
    }
    @media (min-width:768px) and (max-width: 991px){
      width:60%;justify-content: center;
      display: flex;
      margin-left:0;
    }
    .profile_ui{
      display: none;
    }
    @include phonel {
      position: fixed;
      width: 100%;
      height: 100%;
      background: #fff;
      left: 0;
      top: 0;
      padding: 60px 15px 20px;
      opacity: 0;
      visibility: hidden;
      @include trans2s;
      left: -100%;
      z-index: 9;
      display: none;
      &.active {
        opacity: 1;
        visibility: visible;
        left: 0;
        overflow-y: auto;
        display: none;

        .dropdown-menu.show{
          display: block;
        }
      }

      .profile_ui{
        display: block;
        }
    }
    ul{
      display: flex;
      list-style: none;
      @include phonel{
        flex-direction: column;
      }
      li{
        padding:0 17px;
        @include tabm{
            padding:0 10px
        }
        @include phonel{
          margin:0 0 16px;
          padding: 0;
        }
        @media(max-width: 999px) {
          &.tab_hide {
            display: none;
          }
        }
        a{
          font-style: normal;
          font-weight: 400;
          @include remy(16);
          line-height: 19px;
          color: $fc-primary;
          white-space: nowrap;
          @include trans2s;
          position: relative;
          @include tabl{
            @include remy(15);
          }

          &:after{
            position: absolute;
            content: "";
            width:35px;
            height: 3px;
            background:$orange;
            left:0;
            right:0;
            bottom: -9px;
            margin:auto;
            opacity: 0;
          }
          &:hover{
            color: $orange;
          }
          &.active{
            color: $orange;
            &:after{
              opacity: 1;
            }
            .badge{
              //display:none;
            }
          }
        }
      }
    }
  }
  .profile_ui {

    @include phonel{
      display: none;
    }
    .dropdown_btn{
      height: auto;
      align-items:flex-start;
      padding-right: 0;
      padding: 0;
      @include tabl-v{
        //max-width:100%;
        max-width:200px;
        justify-content: flex-end;
      }
      &.show{
        .content_ui{
          label{
            color: $orange;

          }
          .user_icon{
            svg path{
              stroke: $orange;
            }
          }
        }
        .dropdown_icon{
          svg path{
            fill: $orange;
          }
          transform: rotate(180deg);
          top: 4px;
          transform-origin: center 8px;
        }
      }
      &:hover{
        .content_ui{
          label{
            color: $orange;

          }
          .user_icon{
            svg path{
              stroke: $orange;
            }
          }
        }
        .dropdown_icon{
          svg path{
            fill: $orange;
          }
        }
      }
      .dropdown_icon{
        position: relative;
        top: 0px;
        margin-left: 5px;
        @include trans2s;
        svg path{
          @include trans2s;
        }
      }
    }
    .content_ui {
      text-align:right;
      @include tabl-v{
        max-width: 100%;

      }
      @media (max-width:991px) and (min-width: 768px){
        width:100%;
      }
      @include phonel{
        text-align: left;
      }
        label{
          font-style: normal;
          @include remy(16);
          line-height: 18px;
          color: $fc-primary;
          margin-bottom:0px;
          font-family:$font_Souvenir_light;
          @include trans2s;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @include tabl-m{
            max-width: 100%;
            width:100%;
          }
          @media (max-width:991px) and (min-width: 768px){
            width:100%;
          }
          @include tabm{
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            @media (max-width:800px) {
              max-width: 135px;
            }

          }
        }
      .user_icon{
        margin-left: 5px;
        svg path{
          @include trans2s;
        }
      }
        p{
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color:$fc-secondary;
          max-width: 170px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @include tabl-m{
            max-width: 100%;
          }
        }
    }

    .dropdown-menu{
      min-width:320px;
      max-width: 320px;
      background: #FFFFFF;
      inset:0px -2px auto auto !important;
      transform: translate3d(0, 52.8889px, 0px) !important;
      z-index: -1;
      padding:20px 27px 14px;
      overflow: hidden;
      background: #FFFFFF;
      border: 2px solid #EAECF0;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 6px;
      @include tabl{
        inset:0px -2px auto auto !important;
      }
      @include phonel{
        inset: 0px 10px auto auto !important;
        min-width:250px;
        max-width: 250px;
        display: none;
      }
      .account_name{
        text-align: center;
        margin-bottom: 18px;
        label{
          font-style: normal;
          font-weight: 500;
          @include remy(18);
          line-height: 22px;
          color:$fc-primary;
          margin-bottom: 3px;
          word-break: break-word;
        }
        p{
          font-style: normal;
          font-weight: 400;
          @include remy(13);
          line-height: 22px;
          color: $fc-secondary;
          line-height: 18px;
          margin-top: 5px;
          p{
            line-height: normal;
            margin-top: 0;
          }
        }
      }
      .dropdown-item{
        padding: 0;
        display: inline-flex;
        align-items: center;
        @include trans2s;
        svg path{
          @include trans2s;
        }
        &:hover{
          color: $orange;
          background: #fff;
          svg{
            path{
              stroke:$orange;
            }
          }
        }
        &:focus, &:active{
          background: transparent;
        }
        .icon{
          margin-right: 8px;
          display: inline-flex;
          min-width:18px;

        }
        &.logout_ui{
          color:$fc-primary;
          &:hover{
            color:$orange;
          }
        }
      }
      .group-dropdown{
        padding-bottom:10px;
        border-bottom: 1px solid $border-light;
        margin-bottom: 16px;
        li{
          a{
            padding:0px 0 8px;
            display: flex;
            align-items: center;
            @include trans2s;
            color:$fc-primary;
            &:hover{
              color: $orange;
            }
            .icon{
              display: inline-flex;
              margin-right:8px;
              min-width: 18px;
            }
          }
        }
      }
      .terms_listing{
        margin-top: 14px;
        ul{
          display: flex;
          li{
            margin-right: 10px;
            line-height: normal;
            a{
              color:$fc-secondary;
              font-style: normal;
              font-weight: 400;
              @include remy(11);
              line-height: 24px;
              @include trans2s;
              &:hover{
                color: $orange;
              }
            }
          }
        }
      }


    }

  }
}


.property_dropdown_ui{
   margin-bottom: 12px;
  .multiSelectDropdown{
    border-radius: 18px;
    height: 42px;
    border: 1px solid $border-light;
    box-shadow: none !important;
    outline: none !important;
    @include phonel{
      min-width: 100%;
      max-width: 100%;
    }

    &.p-dropdown:not(.p-disabled).p-focus{
      border: 1px solid $border-light;
    }
    &:hover,
    &.p-dropdown-open{
      border-color:$fc-secondary;

    }
    .p-dropdown-label{
      padding:7px 16px;
      font-style: normal;
      font-weight: 400;
      @include remy(14);
      line-height: 22px;
      color:$fc-primary;
      display: flex;
      align-items: center;

    }
    &.p-dropdown .p-dropdown-panel{
      width:263px;
      background: #FFFFFF;
      border: 1px solid #E0E1E5;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      overflow: hidden;
      top:48px !important;
      max-height: 175px;
      @include phonel{
        top:55px !important;
      }
      .p-dropdown-header{
        background: #fff;
        padding: 0;
      }
      .p-dropdown-items-wrapper{
        max-height: 132px !important;
        padding-top: 4px;
      }
      input{
        padding-left: 20px;
        padding-right: 50px;
        box-shadow: none;
        outline: none;
        border: none;
        border-radius: 0;
        height: 40px;
        @include remy(14);
        @include phonel{
          height:50px;
          @include remy(16);
        }
        &::-webkit-input-placeholder{
          color: $fc-hint;
        }
      }
      .pi-search{
        width:20px;
        height:20px;
        background-size: 100%;
      }
      .p-dropdown-items{
        padding-top: 0;
        .p-dropdown-item{
          padding: 5px 18px;
          font-style: normal;
          font-weight: 400;
          @include remy(14);
          line-height: 22px;
          color:$fc-primary;
          @include trans2s;
          @include phonel{
            @include remy(16);
            padding: 8px 18px;
          }
          &.p-highlight{
            background: rgba(#5592A0 , 0.1);
          }
          &:hover{
            background: rgba(#5592A0 , 0.1);
          }
          &.logout_ui{
            font-style: normal;
            font-weight: 500;
            @include remy(16);
            line-height: 24px;
            color:$fc-primary;
          }
        }
      }
    }
  }


}
.switch_account{
  margin-bottom: 9px;
  .switch_btn{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: none;
    border: none;
    .icon{
      margin-right: 4px;
      min-width: 18px;
      svg path{
        @include trans2s;
      }
    }

    &:hover{
      .switch_content{
        label{
          color:$orange;
        }
      }
      .icon svg path{
        stroke:$orange;
      }
    }
  }
  .switch_content{
   text-align: left;
    label{
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 24px;
      color:$fc-primary;
      cursor: pointer;
      @include trans2s;
    }
    p{
      font-style: normal;
      font-weight: 400;
      @include remy(14);
      line-height:18px;
      color:$fc-secondary;
      display: inline-block;
      width: 100%;
      vertical-align: top;
    }
  }
}

.multiple_account_wrapper {
  position: absolute;
  background: #fff;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px 27px 12px;
  left:100%;
  opacity: 0;
  visibility: hidden;
  @include trans2s;
  @include phonel{
    height:calc(100vh - 20px);
    position:fixed;
    overflow-y: hidden;
  }
  &.active{
    opacity: 1;
    visibility: visible;
    left: 0;
  }
  .back_btn{
    display: inline-flex;
    position:absolute;
    top:15px;
    left:28px;
    width:24px;
    height:24px;
    @include trans2s;
    @include phonel{
      position: relative;
      top:0;
      left: 0;
      padding:0;
    }
    &:hover{
      opacity:0.7;
    }
    img{
      width:14px;
    }
  }
  .search_wrapper{
    position: relative;
    max-height:calc(100% - 76px);
    overflow-y: auto;
    @include phonel{
      max-height:calc(100% - 200px);
      margin-top: -5px;
      overflow: hidden;
    }
    .search_field{
      //position: relative;
      position: sticky;
      top:0px;
      background: #fff;
      padding:5px 0;
      &:before{
        position: absolute;
        content: "";
        height: 6px;
        background: #fff;
        width: 100%;
        left: 0;
        top: -3px;
      }
      @include phonel{
        margin-bottom: 20px;
      }
    }
    input {
      background: #FFFFFF;
      border: 1px solid $border-light;
      border-radius: 18px;
      height: 42px;
      width: 100%;
      padding: 0 48px 0 16px;
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 24px;
      color:$fc-primary;
      @include trans2s;
      &:focus{
        border-color:$fc-primary;
      }
      &::-webkit-input-placeholder{
        color: $fc-hint;
      }
    }
    .search_btn, .cross_btn{
      position: absolute;
      right:20px;
      width:20px;
      height:20px;
      border:none;
      background: none;
      top:50%;
      transform: translateY(-50%);
      display: inline-flex;
      img{
        width:100%;
      }
    }
  }

  .account_listing{
    //margin-top: 12px;
    ul{
      li{
        margin-bottom: 4px;
        a{
          font-style: normal;
          font-weight: 400;
          @include remy(16);
          line-height: 24px;
          color:$fc-primary;
          @include trans2s;
          word-break: break-word;
          &.active{
            font-weight: 500;
          }
          &:hover{
            color:$orange;
          }
        }
      }
    }
    //.close_btn{
    //  position: fixed;
    //  right:10px;
    //  width:32px;
    //  height:32px;
    //  top:10px;
    //}
  }
}


.overlay_ui{
  position: fixed;
  left: 0;
  top: 0px;
  height:100%;
  width:100%;
  //background: #000;
  opacity: 0;
  visibility: hidden;
  &.active{
    opacity: 1;
    visibility: visible;
    z-index: -1;
  }
}

.mobile_menu{
  display: none;
}

@include phonel {
  .mobile_menu {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 21px;
    opacity: 0;
    visibility: hidden;
    @include trans2s;
    left: -100%;
    z-index: 9;
    .virtual_scroll{
      height: 100%;
    }
    &.active{
      opacity: 1;
      visibility: visible;
      left: 0;
      overflow-y: auto;
    }


    .account_name {
      margin-bottom: 22px;
      padding-right: 30px;
      label {
        font-family: $font_Souvenir_light;
        font-style: normal;
        //font-weight: 300;
        @include remy(22);
        line-height: 26px;
        margin-bottom: 2px;
        word-break: break-word;
      }

      p {
        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 22px;
        color: $fc-secondary;
      }
    }
    .property_dropdown{
     .property_dropdown_ui{
       margin-bottom: 30px;
       p-dropdown{
         width:100%;
       }
       .multiSelectDropdown{
          height: 50px;
         border-radius: 30px;
         .p-dropdown-label{
          @include remy(16);
         }
       }
     }
    }

    .menu_link{
      li{
        position: relative;
        list-style: none;
        margin-bottom: 20px;
        a{
          font-style: normal;
          font-weight: 400;
          @include remy(18);
          line-height: 22px;
          color:$fc-primary;
          display: flex;
          align-items: center;
          &:focus,
          &.active{
            color:$orange;
            svg{
              path, rect{
                stroke:$orange;
              }
            }
            .badge{
              //display:none;
            }
          }
          .icon{
            width:22px;
            display: inline-flex;
            margin-right: 15px;
            position: relative;
            .count{
                position: absolute;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background:$orange;
                min-width:14px;
                height:14px;
                border-radius: 100%;
                @include remy(10);
              line-height: normal;
                color:#fff;
                font-style: normal;
                font-family: 'Helvetica Neue md';
                right: -5px;
                top: -4px;
                padding: 0 2px;
              &.more_count{
                right: -10px;
                top: -4px;
                padding: 0 3px;
              }
              &.more_counts{
                right: -12px;
                top: -4px;
                padding: 0 3px;
                height:16px;
              }

            }
          }
        }
      }
    }
    .seprater1{
      background:#E0E1E5;
      height:1px;
      width:100%;
      margin-bottom: 20px;
    }
    .primary_nav{
      li{
        list-style: none;
        margin-bottom: 20px;
        a{
          font-style: normal;
          font-weight: 400;
          @include remy(18);
          line-height: 22px;
          color:$fc-secondary;
          display: flex;
          align-items: center;
          @include trans2s;
          @include phonel {
            &:hover {
              color: $fc-primary;

              .icon {
                svg path {
                  stroke: $fc-primary;
                }
              }
            }
          }
          .icon{
            width:22px;
            display: inline-flex;
            margin-right: 16px;
            @include phonel {
              svg path {
                @include trans2s;
              }
            }
          }
        }
      }
    }
    .secondary_nav{
      &.pt_10{
        padding-top: 10px;
      }
      ul{
        list-style: none;
      }
        li{
          margin-bottom:15px;
          a{
            font-style: normal;
            font-weight: 400;
            @include remy(16);
            line-height:24px;
            color:$fc-secondary;
            display: flex;
            align-items: center;
            &:focus,
            &.active{
                color: $orange;
            }
            @include phonel {
              &:active {
                color:$fc-secondary;
              }
              &:hover{
                color: $orange;
              }
            }
          }
        }
    }
    .account_dropdown{
      .btn{
        background: transparent;
        border:none;
        padding: 0;
        margin:0px 0px 16px;
        width:100%;
        text-align: left;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $fc-secondary;
        display: inline-flex;
        align-items: center;
        position: relative;
        &:after{
          position: absolute;
          content: "";
          background: url("/assets/images/account_mg/ic_account_angel.svg");
          width:15px;
          height:8px;
          background-size: 100%;
          right:0;
          top:50%;
          transform: translateY(-50%);
        }
        &[aria-expanded="true"]{
          &:after{
            transform: translateY(-50%) rotate(180deg);
          }
      }
        .icon{
          margin-right: 16px;
          display:inline-flex;
        }
      }
      ul{
        padding-left:35px;
        list-style: none;
        padding-bottom: 10px;
        li{
          margin-bottom: 16px;
          a{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color:$fc-secondary;
          }
        }
      }
    }
    .multiple_account_wrapper {
      .account_name{
        padding-top: 30px;
        margin-bottom: 30px;
        label{
          font-style: normal;
          font-weight: 300;
          @include remy(25);
          line-height: 22px;
          color:$fc-primary;
          margin-bottom: 5px;
        }
        p{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
        }
      }
      .search_wrapper input{
        height:50px;
        border-radius: 30px;
        @include remy(18);
      }
      .account_listing {
        //margin-top: 30px;
        margin-top: 0;
        ul li {
        list-style: none;
          margin-bottom: 12px;
        a {
          font-style: normal;
          font-weight: 400;
          @include remy(18);
          line-height: 21px;
          &.active{
            font-weight: 500;
          }
        }
      }

      }
      .back_btn {

        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 22px;
        color: $orange;
        height: auto;
        width: auto;

        img {
          //width: auto;
          width: 8px;
          margin-right: 5px;
        }
      }
    }

  }
}


.checkout_header{
  padding: 6px 0px;
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9;
  transition: 0.4s ease-out;
  @include tabv{
    position: fixed;
  }
  &.scrolling_top{
    position: sticky;
    top: 0;
    @include tabv{
      top: 0;
      position: fixed;
    }
  }
  &.scrolling_bottom{
    position: sticky;
    top: -60px;
    @include tabv{
      top: 0;
      position: fixed;
    }
  }
  &:after{
    position: absolute;
    content: "";
    height:100%;
    width:100%;
    left: 0;
    top: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    z-index: -1;
    @include phonel{
      filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
      box-shadow: none;
    }
  }
  .mx-1440{
    max-width:100%;
    padding:0 30px;
  }
  .checkout_header_inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include phonel{
      justify-content: center;
    }
    .logo_ui{
      display: inline-flex;
      height: 30px;
    }
    .page_title{
      @include phonel{
        display: none;
      }
      h3{
        font-style: normal;
        font-weight: 300;
        @include remy(36);
        line-height: 48px;
        color:$fc-primary;
        font-family: $font_Souvenir_light;
      }

    }
    .link-primary {
      @include phonel{
        display: none;
      }
      &:hover{
        svg path{
          fill: #E37B6F;
        }
      }
      .el-icon{
          top: 1px;
          position: relative;
        svg path{
          @include trans2s;
        }
        }
    }
  }
}

.right_nav_sec{
  display: flex;
  align-items: center;
  justify-content:flex-end;
  width:30%;
  .cus-tooltip {
    .tooltip-inner{
      background-color: #222222;
      border-radius: 4px;
      font-family: "Helvetica Neue";
      padding: 4px 8px;
      font-size: 13px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      height: 21px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 768px) {
          display: none;
      }
    }
    .tooltip-arrow::before{
      content:none;
    }
  }
  .wishlist_nav{
    margin-left: 20px;
    display: inline-flex;
    &.hide_for_mob{
      @include phonel{
        display: none;
      }
    }
    .cus-tooltip {
      visibility: visible;
      opacity: 1;
      padding: 0;
      inset: 6px auto auto 3px !important;
      .tooltip-inner{
        min-width: 62px;
      }
    }
    a{
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 19px;
      color: $fc-primary;
      @include trans2s;
      position: relative;
      font-family:$font_Souvenir_light;
      display: inline-flex;
      align-items: center;
      position: relative;
      &.active, &:hover{
        color:$orange;
        .icon{
          svg path{
            stroke:$orange;
          }
        }
      }
    }
  }
  .cart_nav{
    //&.hide_for_mob{
    //  @include phonel{
    //    display: none;
    //  }
    //}
    &.cart_group{
       a .icon {
         margin-right: 0;
         margin-left: 0;
      }
    }
    .cus-tooltip {
      .tooltip-inner{
        min-width: 41px;
      }
    }
    margin-left: 10px;
    display: inline-flex;
    @include phonel{
      margin-right:20px;
      .navbar_name{
        display: none;
      }
    }
    a{
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 19px;
      color: $fc-primary;
      @include trans2s;
      position: relative;
      font-family:$font_Souvenir_light;
      display: inline-flex;
      align-items: center;
      position: relative;
      .icon{
        display: inline-flex;
        align-items: center;
        margin-left: 5px;
        svg path{
          @include trans2s;
        }
        @include phonel{
         svg {
           width: 25px;
           height: 25px;
         }
        }
      }
      &.active, &:hover{
        color:$orange;
        .icon{
          svg path{
            stroke:$orange;
          }
        }
      }
      .count{
        min-width:17px;
        padding:0 2px;
        height: 17px;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background:$orange;
        color:#fff;
        font-size: 12px;
        line-height: 8;
        font-family: 'Helvetica Neue md';
        right: 31px;
        top: -5px;
        position: absolute;
        &.more_count{
          padding: 0 3px;
          @include remy(10);
          right: 29px;
          //top: -7px;
          @include phonel{
            right:-7px;
          }
        }
        &.more_counts{
          padding: 0 4px;
          @include remy(10);
          right: 22px;
          height: 18px;
          @include phonel{
            height:16px;
            padding: 0 3px;
            right:-15px;
          }
        }

        @include phonel{
          min-width:15px;
          height:15px;
          @include remy(11);
          right:-6px;
        }
      }

    }
  }

  .virtual_scroll{
    height: 155px !important;
    margin-top: 10px;
  }
}


@include phonel {
  .account_listing {
    height: calc(100vh - 300px) !important;
    overflow-y:auto;
    padding-bottom: 50px;
    .cdk-virtual-scroll-content-wrapper{
      height:100%;
      contain:initial;
    }
  }
}

.credit_zero{
  @include phonel{
    display: none!important;
  }
  .credit_payment_inner{
    cursor: pointer;
  }
  &:hover{
    .tooltip_pill_for_refer{
      .text-desc{
        color: #006878;
      }
      .ref-zero-icon{
        svg path,circle{
          stroke:#006878;
        }
      }
    }
  }
  //.tooltip{
  //  inset: 0 auto auto 70px!important;
  //  width: 350px!important;
  //  --bs-tooltip-max-width: 350px!important;
  //  --bs-tooltip-bg: #FFFFFF!important;
  //  border-radius: 16px!important;
  //  top: 13px!important;
  //  background: #FFF;
  //  //box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.22);
  //  @include phonel{
  //    inset: 0px auto auto 0px!important;
  //  }
  //  .tooltip-arrow{
  //    &:before {
  //      border-top-width: 8px;
  //      border-bottom-width: 0!important;
  //      border-left-width: 0!important;
  //      border-right-width: 0!important;
  //    }
  //    left: -9px!important;
  //    @include phonel{
  //      left: 16px!important;
  //    }
  //    &:before{
  //      border-top-width: 21px!important;
  //    }
  //  }
  //  .tooltip-inner {
  //    //padding: 9px 20px 12px 20px!important;
  //    padding: 33px 26px 27px 26px!important;
  //    border-radius: 16px;
  //    &:after{
  //      content: "";
  //      width: 382px;
  //      height: 252px;
  //      left: -14px;
  //      background-repeat: no-repeat;
  //      background-size: contain;
  //      background-position: center;
  //      top: -28px;
  //      z-index: -1;
  //      position: absolute;
  //      background-image: url(/assets/images/zerocredit-background.png);
  //    }
  //
  //  }
  //}
}

.credit_payment_outer{
  @media (max-width: 999px) {
    display: none;
  }
  @include phonel{
    display: inline-block;
  }
  .credit_payment_inner{
    min-width: 114px;
    //max-width: 127px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 34px;
    background-color: #E4EFF1;
    margin-right: 18px;
    padding: 7px 13px 8px 13px;
    @include tabl{
      //padding: 7px 10px 8px 10px;
      //min-width: initial;
      margin-right: 12px;
      @media only screen and (max-width: 1060px) {
        min-width: initial;
        padding: 7px 11px 8px 11px;
        margin-right: 9px;
        @include phonel{
          margin-right: 6px;
        }
      }
    }

    @include phonel{
      margin-top: 16px;
      display: flex;
      align-items: center;
      padding: 7px 10px 6px 16px;
      //max-width: 127px;
    }
    .tooltip_pill_for_refer{
      display: flex;
      align-items: center;
      .text-desc{
        margin-right: 5px;

      }
      .ref-zero-icon{
        width: 26px;
        height: 17px;
        display: inline-flex;
        align-items: center;
      }


    }
    p{
      color: #444;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      //height: 17px;
      white-space: nowrap;

      span{
        color: #444;
        font-family: 'Helvetica Neue md';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

      }
    }
    .mobile_credit_icon{
      display: none ;
      @include phonel{
        display: inline-flex;
        width: 13px;
        height: 14px;
        margin-left: 7px;
        align-items: flex-start;

      }
    }
  }
}


.payment_tooltip{
  position: relative;
  .tooltip{
    transform: translate3d(-63.75px, 55px, 0px)!important;
    --bs-tooltip-max-width: 350px!important;
    width: 237px;
    --bs-tooltip-bg: #292929;
    border-radius: 2px;
    padding: 0px;
    --bs-tooltip-opacity: 1!important;
    opacity: 1;
    @include phonel{
      transform: translate3d(0.25px, 70px, 0px) !important

    }
    .tooltip-arrow{
      transform: translate3d(104px, 0px, 0px)!important;
      @include phonel{
        transform: translate3d(81px, 0px, 0px)!important;
      }
      &:before{
        bottom: 0;
        border-top-width: 8px;
        border-bottom-width: 19px;
        border-left-width: 12px;
        border-right-width: 12px;
      }
    }
    .tooltip-inner{
      padding: 9px 17px 12px 20px;
      p{
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        color: #fff;
        text-align: left;
        margin-bottom: 7px;
        span{
          font-weight: 700;
        }
        &:last-child{
          margin-bottom: 0px;
        }
      }

    }
  }
}

.credit_payment_outer{
  &.desk_show{
    @include phonel{
      display: none;
    }
  }
  &.mob_show{

  }
}


.referral_available{
  .header_container{
    .inner_header_wrap{
      .logo_wrap{
        @include tabl-m{
          width: 30%;
        }
        @media only screen and (max-width: 1260px){
          width:22%;
        }
        @media (min-width:991px) and (max-width: 1199px){
          width:20%;
        }
        @media (min-width:768px) and (max-width: 991px){
          width:14%;
        }
        @include phonel{
          display: inline-flex;
        }
        .logo_ui{
          display: inline-flex;
          height: 30px;
          @include phonel{
            height: 28px;
          }
        }
      }
      .profile_ui.dropdown {
        @include tabl-m {
          //width: 75%;
          //max-width: 256px;

        }
      }
    }
  }


  .navbar-wrapper{
    @media (min-width:991px) and (max-width: 1260px){
      width:50%;justify-content: center;
      display: flex;
    }
    ul{
      li{
        @include tabl{
          padding:0 12px;
          @media(max-width: 999px) {
            &.tab_hide {
              display: none;
            }
          }
          @media only screen and (max-width: 900px) {
            padding:0 7px;
          }
        }
      }
    }
  }
  .profile_ui {
    .content_ui {
      label{
        //@include tabl-m{
        //  //max-width: 100%;
        //  max-width: 175px;
        //  width:100%;
        //}
        @media (min-width: 1400px) {
          max-width: 164px;
          width:100%;
        }
        @include desk{
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          @include tabm{
            max-width: 110px;
            @media only screen and (max-width:820px) {
              max-width: 120px;
            }
          }
        }

      }

    }
  }
  .right_nav_sec{
    @media only screen and (max-width: 1308px) {
      width:40%;
    }
    @include tabv{
      width:38%;
      @include tabm{
        width:30%;
        @media (max-width: 930px) {
          width:30%;

        }
      }
    }
    .cart_nav{
      @include tabm{
        margin-left: 10px;
        @include phonel{
          margin-right:20px;
          .navbar_name{
            display: none;
          }
        }
      }
    }
  }
}


.content_align{
  display: flex;
  align-items: center;
}

.no_credit_inner{
  .inner-heading{
    color: #006878;
    text-align: center;
    font-family: $font_Souvenir_light;
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    margin-bottom: 12px;
  }
  .text-desc{
    text-align: center;
    font-size: 18px!important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px!important;
    color: #444!important;
    margin-bottom: 23px;
    white-space: initial!important;
  }
  .learn-desc{
    color: #DC5A4B;
    text-align: center!important;
    font-family: 'Helvetica Neue md';
    font-size: 14px;
    font-style: normal;
    //font-weight: 500;
    line-height: normal;
    letter-spacing: 1.4px;
    text-decoration-line: underline;
    display: flex;
    justify-content: center;

  }
}




.custom_tooltip_zero{
  position: relative;
  display: inline-block;
  margin-left: 5px;
  &:after{
    position: absolute;
    content: "";
    width: 164px;
    height: 62px;
    left: -13px;
    z-index: 100;
    right: 0;
    bottom: -16px;
    margin: auto;
    visibility: visible;
    opacity: 1;
    @media (max-width:1060px){
      width: 151px;
      @media (max-width:795px ){
        width: 138px;
        left: -2px;
      }
    }

  }
  &:hover{
    .tooltip_content{
      opacity: 1;
      visibility: visible;
    }
    //&:after{
    //  opacity: 1;
    //  visibility: visible;
    //
    //}

  }

  .tooltip_content{
    position: absolute;
    width: 350px;
    background: #ffffff;
    padding: 33px 23px 27px 23px;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    border: 1px solid #F5F5F5;
    right: 0;
    top: 40px;
    margin: auto;
    opacity: 0;
    visibility: hidden;
    left: 126%;
    transform: translateX(-50%);
    cursor: default;
    box-shadow: 3px 3px 10px 0px #0000001A;
    .cstm_tooltip_inner{
      overflow-y: auto;
      //max-height: 91px;
    }
  }
}

.quote-custom-tooltip{
  &.mob-show{
    display: none;
   @include phonel{
     display: block;
   }
  }
  &.desk_show{
    @include phonel{
      display: none;
    }
  }
    inset: 36px auto auto 0px!important;
  --bs-popover-max-width: 420px;
  box-shadow: 0px 0px 28px 0px #00000038;
  background: #FFFFFF;
  padding: 32px 29px 29px 29px;
  border: none;
  --bs-popover-zindex: 2!important;
  @include phonel{
    inset: 28px auto auto 0px !important;
    padding: 24px 33px 33px 32px;
    box-shadow: 3px 3px 10px 0px #0000001A;
    --bs-popover-max-width: 348px;
    min-width: 348px;
    @media screen and (max-width: 430px) {
      --bs-popover-max-width: calc(100% - 40px);
      min-width: auto;
    //min-width: 300px;
    //--bs-popover-max-width: 300px;
    //  inset: 28px auto auto 0px !important;
    //  padding: 24px 25px 33px 25px;


    }

  }
  .popover-body{
    padding: 0px;
  }
  .popover-arrow{
    transform: translate(202px, -6px) !important;
    @include phonel{
      display: none;
    }
    &:before{
      display: none;
    }
    &:after{
      border-top-width: 0px;
      border-bottom-width: 15px;
      border-left-width: 10px;
      border-right-width: 10px;
    }
  }
  .popover-header{
    background-color: #FFFFFF;
    border-bottom: none;
    border-top-left-radius: var(--bs-popover-inner-border-radius);
    border-top-right-radius: var(--bs-popover-inner-border-radius);

  }

}


.quote-tooltip-container{
  .content-heading{
    //font-family: ITC Souvenir Std;
    font-family: $font_Souvenir_light;
    font-size: 32px;
    font-weight: 300;
    line-height: 42px;
    text-align: center;
    color: #006878;
    margin-bottom: 16px;
    @include phonel{
      margin-bottom: 11px;
      font-size: 28px;

    }
  }
  .text-desc{
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 25px;
    color: #444444;
  }
  .check-out-btn{
    color: #DC5A4B!important;
    font-style: normal;
    text-decoration-line: underline;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 700!important;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-align: center;
  }

}

   //new mobile nav bar

.mobile_nav_wrapper {
  display: none;
  @include phonel{
    display: block;
  }
  position: relative;
}
.bottom_navbar_outer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  z-index: 1000;
  border-top: 1px solid #E0E1E5;
  transition: 0.2s ease-out;
  &.scrolling_top_for_chrome{
    bottom: -90px;
    transition: 0.2s ease-out
  }
  .bottom_navbar_inner{
    list-style-type: none;
    margin: 0;
    //padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 20px 0 20px ;
    li{
      &:first-child{
        .navbar_inner_container{
          padding-left: 5px;
        }
      }
      &:last-child{
        .navbar_inner_container{
          padding-right: 5px;
        }
      }
      .navbar_inner_container {
        text-decoration: none;
        color: #444444;
        display: inline-block;
        padding: 2px 10px 6px 10px ;
        text-align: center;
        position: relative;
        &.active{
          .nav_bar_icons{
            &.for_fill{
              svg{
                path{
                  fill: $orange;
                }
              }
            }
            &.for_stroke{
              svg{
                path,rect{
                  stroke: $orange;
                }
              }

            }
          }
          .inner-text{
            color: $orange;
          }

        }
        .nav_bar_icons{
          display: inline-flex;
          width: 35px;
          height: 35px;
          align-items: center;
          justify-content: center;
        }
        .count{
          min-width: 15px;
          height: 15px;
          font-size: 0.6875rem;
          font-style: normal;
          padding: 0 2px;
          border-radius: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: #DC5A4B;
          color: #fff;
          line-height: 8;
          font-family: "Helvetica Neue md";
          //right: -9px;
          top: 3px;
          right: 5px;
          position: absolute;
          &.more_count{
            padding: 0 3px;
            @include remy(10);
            //right: -14px;
            right:0px;
            //top: -7px;
          }
          &.more_counts{
            @include remy(10);
            height:16px;
            padding: 0 3px;
            right:-4px;
          }

        }
        .inner-text{
          font-size: 12px;
          font-family: 'Helvetica Neue md';
          font-weight: 500;
          line-height: 15px;
          text-align: center;
          color: #96999F;
          margin-top: 1px;


        }
      }
    }
  }
}



.bottom-navbar ul li a:hover {
  background-color: #555;
}
.hide_bottom_bar{
  display: none;
}

.quote_header{
   @include tabv{
     .scrolling_top{
       top: -100px;
     }
     .scrolling_bottom{
       top: -100px;
     }
   }
}

.top-selling-products {
  margin-bottom: 27px;
  .selling-products-inner{
    display: inline-block;
    .shop_selected{
      @include remy(18);
      font-weight: 400;
      line-height: 21.6px;
      text-align: left;
      color: #444;
      margin-bottom: 14px;
    }
    ul {
      width: 100%;
      display: flex;
      li {
        margin-right: 10px;
        list-style: none;
        height: 60px;
        width: 60px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background-color: #F7F7F7;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 60px;
          max-height: 60px;
        }
      }
    }
  }

}


  .header_container{
      .badge{
        background-color: #E4EFF1;
        color: #006878;
        padding: 4px 5px 4px 5px;
        border-radius: 5px;
        font-size: 12px;
        font-family: 'Helvetica Neue';
        font-weight: 500;
        max-height: 19px;
        display: inline-flex;
        align-items: center;
        margin-left:5px;
        position:absolute;
        top:1px;
        @include  phonel{
          font-size: 13px;
          position: relative;
        }
      }
  }

.navbar_name{
  margin-left: 7px;
}

/* Color Pallet */

  //Main Colors
  //Purple
  $purple: #6C455A;
  $purple-shade: #563748;
  $purple-tint: #8A6A7B;

  //Orange
  $orange: #DC5A4B;
  $orange-shade: #C65144;
  $orange-tint: #E37B6F;
  $orange-400:#EB674E;
  $orange-300:#EA8069;
  $orange-light:#F8DEDB;
  $orange-light1:#EECBC7;
  $orange-shade2:#E27B71;

  //Peach
  $peach: #F49468;
  $peach-shade: #DC855E;
  $peach-tint: #F7B495;

  //Yellow
  $yellow: #FAC55E;
  $yellow-shade: #DC855E;
  $yellow-tint: #FCDC9E;

//teal

$teal:#1A4B5B;
$teal-shade: #1A4B5B;
$teal-tint: #5592A0;
$teal-shade-2:#376878;
$teal-primary:#006878;
$teal-active:#E4EFF1;



//Font Colors
$fc-primary: #444444;
$fc-secondary: #96999F;
$fc-hint: #C7C7C7;
$fc-blue: #1B72F3;
$fc-red: #EA1212;
$fc-green: #2DCE3A;
$fc-white: #FFFFFF;
$fc-pink:#FF17CC;
$fc_gray400:#888888;

//Background Colors

$bg-gray: #F5F5F5;
$gray-800:#222222;
$bg_3:#3C5C66;
$fc_7: #444444;
$fc_9: #DB5E52;
$fc_8: #739198;
$bg_4:#F7DAD2;
//Misc. Colors
$border-light: #E0E1E5;
$border-dark: #96999F;
$border-gray100:#EAECF0;


// gray shade

$gray300:#F9FAFB;

/* Color Pallet */

:root {
  --helvetica700:'Helvetica Neue 700';
  --helvetica500: 'Helvetica Neue md';
}


.btn_primary{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $orange;
  border: none;
  border-radius: 34px;
  font-weight: 400;
  @include remy(18);
  line-height: 21px;
  color: $fc-white;
  padding: 0px 25px 0px 25px;
  height:42px;
  min-width: 125px;
  text-align: center;
  transition: .2s all ease;
  -webkit-appearance: none;
  &.w_icon{
    .el-icon {
      display: inline-flex;
      vertical-align: middle;
      margin-left:10px;
      img{
        @include dib100;
        max-width: 18px;
      }
    }
  }
  &.w-icon{
    .el-icon{
      display: inline-flex;
      vertical-align: middle;
      margin-right: 6px;
      img{
        @include dib100;
        max-width: 18px;
        //line-height: 1;
      }
    }
    .el-text{
      @include dib;
      font-weight: 400;
      @include remy(18);
      color: $fc-white;
      vertical-align: middle;
      //line-height: 1;
    }
  }
  &:hover{
    background-color:$orange-tint;
    color: $fc-white;
    @include phonel{
      background-color:$orange;
    }
  }
  &:focus{
    background-color:$orange;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-white;
    &:active{
      border-color: transparent;
      outline: none;
      box-shadow: none;
      color: $fc-white;
    }
  }
  &:focus-visible{
    background-color:#c44c3e;
    //background:$orange-shade;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-white;
  }
  &:focus-within{
    background-color:$orange;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-white;
  }
  &:active{
    background-color: $orange-shade !important;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-white !important;
    @include phonel{
      background-color: #E37B6F;
    }
  }

  &:disabled{
    background-color:$orange-light;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-white;
  }

  &.loader{
      background:$orange-shade;
      &:before{
        position: absolute;
        content: "";
        left:0;
        right:0;
        top:0;
        bottom: 0;
        margin: auto;
        width:28px;
        height: 28px;
        background: url("/assets/images/btn_loader.svg") no-repeat;
        animation: btn-loader 1.4s linear infinite;
      }
    }

}

.btn_secondary{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $fc-white;
  border: none;
  border-radius: 34px;
  font-weight: 400;
  border: 2px solid $orange;
  @include remy(18);
  line-height: 21px;
  color: $orange;
  padding: 0px 25px 0px 25px;
  height:42px;
  min-width: 125px;
  text-align: center;
  transition: .2s all ease;
  .icon{
    margin-left: 7px;
    display: inline-flex;
    svg path{
      @include trans2s;
    }
  }
  &:hover{
    background-color: $fc-white;
    color: $orange-tint;
    border-color:$orange-tint;
    svg path{
      stroke: $orange-tint;;
    }
  }
  &:focus{
    background-color: $fc-white;
    outline: none;
    box-shadow: none;
    color: $orange-shade;
    &:active{
      outline: none;
      box-shadow: none;
    }
  }
  &:focus-visible{
    background-color: $fc-white;
    outline: none;
    box-shadow: none;
    color: $orange-shade;
  }
  &:focus-within{
    background-color: $fc-white;
    outline: none;
    box-shadow: none;
    color: $orange-tint;
    border-color:$orange-tint;
  }
  &:active{
    background-color: #fff !important;
    border-color:$orange-shade !important;
    outline: none;
    box-shadow: none;
    color: $orange !important;
  }
  &:disabled{
    background-color:$fc-white;
    border-color:$orange-light1;
    outline: none;
    box-shadow: none;
    color:$orange-light1;
  }

  &.loader{
    &:before{
      position: absolute;
      content: "";
      left:0;
      right:0;
      top:0;
      bottom: 0;
      margin: auto;
      width:28px;
      height: 28px;
      background: url("/assets/images/btn_loader_orange.svg") no-repeat;
      animation: btn-loader 1.4s linear infinite;
    }
  }
}

.btn_tertiary{
  display: inline-flex;
  align-items: center;
  background-color:$orange-light;
  border: none;
  border-radius: 34px;
  font-weight: 400;
  @include remy(14);
  line-height: 17px;
  color: $orange;
  padding: 0px 15px;
  height:38px;
  text-align: center;
  transition: .2s all ease;
  .el-icon{
    display: inline-flex;
    align-self: center;
    margin-right: 6px;
    vertical-align: middle;
    img{
      font-weight: 400;
      @include remy(18);
      line-height: 21px;
      color: $orange;
    }
  }
  .el-text{
    align-self: center;
    display: inline-flex;
    vertical-align: middle;
    font-weight: 400;
    @include remy(14);
    line-height: 17px;
    color: $orange;
  }
  &:hover{
    background-color:#FAE5E3;
    color: $orange-shade;
  }
  &:disabled{
    background-color:#FCF2F1;
    border-color:transparent;
    outline: none;
    box-shadow: none;
    color:#F1BDB7;
    .el-icon{
      img{
        opacity:0.5;
      }
    }
  }
  &:focus{
    background-color: #F1BDB7;
    outline: none;
    box-shadow: none;
    color: $orange-shade;
    &:active{
      outline: none;
      box-shadow: none;
    }
  }
  &:focus-visible{
    background-color: #F1BDB7;
    outline: none;
    box-shadow: none;
    color: $orange-shade;
  }
  &:focus-within{
    background-color: #FAE5E3;
    outline: none;
    box-shadow: none;
    color: $orange-shade;
  }
  &:active{
    background-color: #F1BDB7;
    outline: none;
    box-shadow: none;
    color: $orange-shade;
  }
}

.link-primary{
  display: inline-flex;
  align-items: center;
  transition: .2s all ease;
  background: transparent;
  border: none;
  color:$orange !important;
  .el-icon{
    display: inline-flex;
    vertical-align: middle;
    margin-right: 7px;
    svg{
      max-width: 18px;
    }
  }
  &:disabled, &.disabled{
    background-color:transparent;
    border-color:transparent;
    outline: none;
    box-shadow: none;
    color:$orange-light1;
    .el-icon{
      .inner-svg{
        fill:$orange-light1;
      }
    }
    .el-text{
      color:$orange-light1;
    }
    &:hover{
      .el-icon{
        .inner-svg{
          fill:$orange-light1;
        }
      }
      .el-text{
        color:$orange-light1;
      }
    }
  }
  .el-text{
    display: inline-flex;
    vertical-align: middle;
    font-style: normal;
    font-weight:500;
    font-family:'Helvetica Neue md';
    letter-spacing:0.2px;
    @include remy(16);
    line-height: 19px;
    color: $orange;

  }
  &:hover{
    .el-text{
        color:$orange-tint;
    }
    .inner-svg{
      fill:$orange-tint;
    }
  }
  &:focus{
    .el-text{
      color: #C65144;
    }
    .inner-svg{
      fill: #C65144;
    }
  }
}

.link-primary-btn{
  display: inline-flex;
  align-items: center;
  transition: .2s all ease;
  background: transparent;
  border: none;
  //font-style: normal;
  //font-weight: 500;
  //@include remy(15);
  //line-height: 18px;
  color:$orange !important;
  .el-icon{
    display: inline-flex;
    vertical-align: middle;
    margin-right: 7px;
    transition: .2s all ease;
    svg{
      max-width: 18px;
    }
  }
  &:disabled, &.disabled{
    background-color:transparent;
    border-color:transparent;
    outline: none;
    box-shadow: none;
    color:$orange-light1;
    .el-icon{
      .inner-svg{
        fill:$orange-light1;
      }
    }
    .el-text{
      color:$orange-light1;
    }
    &:hover{
      .el-icon{
        svg path{
          stroke:$orange-light1;
        }
      }
      .el-text{
        color:$orange-light1;
      }
    }
  }
  .el-text{
    display: inline-flex;
    align-items: center;
    font-style: normal;
    @include remy(15);
    font-family:'Helvetica Neue md';
    letter-spacing: 0.2px;
    line-height:18px;
    color: $orange;
    position: relative;
    transition: .2s all ease;

  }
  &:hover{
    .el-text{
      color:$orange-tint;
    }
    svg path{
      stroke:$orange-tint;
    }
  }
  &:active{
    .el-text{
      color: #C65144;
    }
    svg path{
      stroke: #C65144;
    }
  }
}



.btn4{
  font-style: normal;
  font-weight: 300;
  @include remy(14);
  line-height: 17px;
  color:$fc-primary;
  display: inline-flex;
  @include transition(0.2s ease-in-out);
  align-items: center;
  padding:0 0px;
  height:30px;
  border:none;
  .inner-svg{
    @include transition(0.2s ease-in-out);
  }

  &:focus,
  &:hover{
    border: none;
    color:$orange;
    .icon svg path{
      fill:$orange;
    }
  }
  .icon{
    display: inline-flex;
    margin-right:9px;
  }
}



/*btn loader */
.btn{
  &.loader{
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
    .el-text{
      visibility: hidden;
    }
  }
}

@keyframes btn-loader {
  0% { -webkit-transform: rotate(0deg) ;}
  100% { -webkit-transform: rotate(360deg) ; }
}





.btn_back {
  @include remy(16);
  line-height: 19px;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  @include transition(0.2s ease-in-out);
  &:hover{
    p{
      color:$orange;
    }
    .el-icon path{
      fill:$orange;
    }
  }
  .el-icon {
    margin-right: 7px;
    fill:$fc-secondary;
    display: inline-flex;
  }
  img {
    height: 14px;
    width: 14px;
  }
  p{
    font-style: normal;
    font-weight: 400;
    @include remy(16);
    line-height: 19px;
    color:$fc-secondary;
  }
}

@import "/src/assets/scss/helpers/mixins";
@import "/src/assets/scss/base/colors";




/*sort dropdown*/

.sort_dropdown, .filter_dropdown{
  .dropdown_btn{
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color:$orange;
    @include transition(0.2s ease-in-out);
    @include phonel{
      @include remy(16);
      padding-bottom: 0;
    }
    .icon{
      margin-left: 4px;
    }
    &:hover{
      color:$orange-tint;
      svg{
        path{
          fill:$orange-tint;
        }
      }
    }
    svg path{
      @include transition(0.2s ease-in-out);
    }
  }
  .group-dropdown{
    margin-bottom: 8px;
    @include phonel{
      //margin-bottom: 24px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }

  .dropdown-menu{
    min-width: 137px;
    padding:6px 17px 11px;
    border-radius: 4px;
    right:0 !important;
    left: initial !important;
    //@include phonel{
    //  position: fixed !important;
    //  height: 100%;
    //  top: 0 !important;
    //  transform: initial !important;
    //  width: 80%;
    //  right:-80% !important;
    //  padding: 28px 20px 28px 28px;
    //  border-radius: 0;
    //  @include transition(0.2s ease-in-out);
    //  &.show{
    //    right:0 !important;
    //    + .overlay{
    //      visibility: visible;
    //      opacity: 1;
    //      z-index: 2;
    //    }
    //  }
    //}
    li{
      @include phonel{
        margin-bottom:0px;
      }

      &.active{
        .dropdown-item{
          color:$orange;
        }
      }
      .active{
        &.dropdown-item{
          color:$orange;
        }
      }
      .dropdown-item{
        padding:0px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        @include transition(0.12s ease-in-out);
        &:hover{
          background: transparent ;
          color:   $orange;
        }
        @include phonel{
          @include remy(14);
          line-height: 22px;
          margin-bottom:0px;
        }
      }
    }
  }

  .mob-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    display: none;
    @include phonel{
      display: flex;
      flex: 1 1;
    }
    h4{
      font-style: normal;
      font-weight: 300;
      font-size: 28px;
      line-height: 22px;
      color:$fc-primary;
    }
    .filter_close{
      background: none;
      border: none;
    }
  }
}

.filter_dropdown{
  .dropdown-menu{
    @media (min-width:768px) {
      min-width: 214px;
      padding: 11px 16px;
    }
    li{
      margin-bottom: 0px;
      @include phonel{
         margin-bottom: 14px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      .dropdown-item{
        line-height: 25px;
      }
    }
  }
  .dropdown_btn{
    padding-right: 0;
    margin-right: 5px;
    @include phonel{
      .el-text{
        padding-right: 4px;
      }
      .mob-hide{
        display: none;
      }
    }
  }
  @include phonel{
    .mob-header{
      margin-bottom:55px;
    }
  }
}

/*clear filter btn*/
.clear-filter-btn {
  display: inline-flex;
  align-items:center;
  justify-content: center;
  background-color: $fc-white;
  border: none;
  font-weight: 700;
  @include remy(14);
  line-height: 16px;
  color: #EB674E;
  height: 17px;
  //min-width: 92px;
  text-align: left;
  padding: 2px;

  @include tabm{
    .clear-cross-icon{
      align-items: center;
      display: flex;
      margin-left: 3px;
      padding: 2px 0px 0px 2px;
    }
    .mob-hide{
      display: none;
    }
  }


  &:focus, &:hover {
    background-color: $fc-white;
    outline: none;
    box-shadow: none;
    color: #E37B6F;
    svg{
      path{
        fill:#E37B6F;
      }
    }
    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  &:active {
    background-color: $fc-white;
    outline: none;
    box-shadow: none;
    color: #EB674E;
  }
}

//serach  filter section start

.filter-section {
  padding-top: 16px;
  justify-content: space-between;
  display: flex;
  //margin-bottom: 34px;
  align-items: center;

  @include phonel{
    flex-direction: row-reverse;
    align-items: flex-start;
  }
  .filter-items{
    width: calc(100% - 200px);
    @include phonel{
      width: calc(100% - 70px);
    }
    .found-filter-item{
      display: flex;
      flex-wrap: wrap;
      @include phonel{
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: flex-start;
        overflow-x: scroll;
        &::-webkit-scrollbar{
          width: 0;
          height: 0;
        }
      }
      li{
        list-style-type: none;
        font-style: normal;
        font-weight: 400;
        font-size: 13.824px;
        line-height: 16px;
        text-align: center;
        color: #EB674E;
        background: #FDEAE1;
        border-radius: 36.288px;
        padding: 5px 2px 7px 11px;
        margin: 2px 2px 2px 0px;
        @include transition(0.2s ease-in-out);
        &:hover{
          background:rgba(#FDEAE1 , 0.8);
        }
        .clear-cross-icon{
          padding-left: 8px;
          padding-right:9px;
          cursor: pointer;
        }

      }
    }
  }
  .clear-filter-item {
    min-width: 103px;
    text-align: right;
    @include tabm{
      min-width:60px;
    }
    @include phonel{
      text-align: left;
      padding-top: 3px;
    }
    .clear-filter-btn {
      .clear-cross-icon{
        padding: 0px 0px 0px 7px;
        @include tabm{
          padding: 2px 0px 0px 2px;
        }
      }
    }
  }
}


@import "/src/assets/scss/base/colors";
//@import "/src/assets/scss/base/fonts";
@import "/src/assets/scss/helpers/mixins";
.getting_start_wrapper{
  position: relative;
  top:9px;
  text-align:center;
  width: 189px;
  margin: 0 auto;
  &.show{
    width:100%;
  }
  @include phonel{
    display:none;
  }
}
.windows .getting_start_btn{
  font-family:var(--helvetica700);
}
.getting_start_btn{
  position: relative;
  z-index: 1;
  background:transparent;
  color:#fff;
  padding:0 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.65px;
  letter-spacing: 0.1em;
  text-align: left;
  min-width:189px;
  height:30px;
  display:inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0 0 20px 20px;
  @include trans2s;
  &:after{
    display:none;
  }
  svg path{
    @include trans2s;
  }
  &:hover{
    .btn_bg{
        svg path{
          fill:$teal;
        }
    }
  }
  .btn_text{
    position:relative;
    top:2px;
  }
  .btn_bg{
    position:absolute;
    left:0;
    right:0;
    height:100%;
    width:100%;
    z-index:-1;
  }
  .icon{
    display:inline-flex;
    margin-left:6px;
  }

}

.steps_detail_wrapper {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  background: #006878 !important;
  top: 1px !important;
  z-index: 2;
  text-align: left;
  transform: initial !important;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  &.open{
    max-height: 517px;
    @include tabm{
      max-height: 576px;
    }
  }
  .heading_sec{
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:30px 0;
    .heading_widget {
      flex: 0 1 auto;
      display: flex;
      gap:30px;
    }
    h4{
      font-family:$font_Souvenir_light;
      font-size: 32px;
      font-weight: 300;
      line-height: 38.4px;
      text-align: left;
      color:#fff;
    }
    .close_btn{
      border:none;
      background:none;
      display:flex;
      align-items: center;
      svg path{
        @include trans2s;
      }
      &:hover{
        svg path{
          opacity:1;
        }
        .group_opacity{
          opacity: 1;
        }
        .btn_sub_txt{
          color: #ffffff;
        }
      }
      .close_svg{
        //width:17px;
        //height: 17px;
      }
      .btn_sub_txt{
        font-family:'Helvetica Neue md';
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        display:inline-block;
        margin-left: 7px;
        color: rgba(255,255,255,0.6);
        transition: ease-in .2s;
      }
    }
  }
  .bottom_sec{
    display:flex;
    align-items:flex-start;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top:30px;
    gap:12px;
  }
}


.install_steps{
  //padding-bottom:30px;
  display:flex;
  @media (max-width:1430px){
    overflow-x:auto;
    margin: 0 -40px;
    padding: 0 40px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.ftux_card{
  width:210.93px;
  min-width:210px;
  height:191px;
  padding: 19px 20px 20px 20px;
  border-radius: 8px;
  margin-right:13px;
  background:#fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right:70px;
  overflow: hidden;
  position: relative;
  transition: ease-out 150ms;
  cursor: pointer;
  .image_ui{
    svg path{
    transition:0.3s ease-in-out;
    }
  }
  &:hover{
    background:#FAFAFA;
    box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.25);
    .image_ui{
      svg path{
        stroke:$orange;
      }
    }
  }
  &:nth-child(4){
    .stroke, .fill{
      transition:0.3s ease-in-out;
    }
    &:hover{
      .image_ui{
        .stroke{
          stroke:$orange;

        }
        .fill{
          fill:$orange;
          stroke:none;
        }

      }
    }
  }
  &:nth-child(5){
    .stroke, .fill{
      transition:0.3s ease-in-out;
    }
    &:hover{
      .image_ui{
        .fill{
          fill:$orange;
          stroke:none;
        }

      }
    }
  }
  .top_ui{
    label{
      cursor: pointer;
    }

  }
  &:last-child{
    margin-right:0;
  }
  p{
    max-width:325px;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    margin-top:9px;
  }
  p, .btn_sec{
    opacity:0;
    position:absolute;
    visibility: hidden;
  }

  &.active{
    width:458px;
    min-width:458px;
    cursor: initial;
    label{
      font-weight:700;
      font-family: 'Helvetica Neue';
      position:relative;
      top:1px;
      cursor: initial;
    }
    p, .btn_sec{
      display:block;
      position:relative;
      opacity:1;
      visibility: visible;
      transition:0.2s ease-in-out;
      transition-delay: 150ms;
    }
    &.open_card{
      p, .btn_sec{
        transition-delay:0ms;
      }
    }
    .btn_sec{
      bottom:0px;
      .icon_text_btn {
        pointer-events: auto;
      }
    }
    .image_ui{
      display:none;
    }
    .check_icon{
      //svg circle{
      //  fill:#12B76A;
      //  stroke:#12B76A;
      //}
    }
  }
  &.complete{
    p, .btn_sec{
      opacity:0;
      position:absolute;
      visibility: visible;
    }
    .check_icon{
      svg circle{
        fill:#12B76A;
        stroke:#12B76A;
      }
    }
    &.active{
      label{
        font-weight:700;
        position:relative;
        top:1px;
      }
      p, .btn_sec{
        display:block;
        position:relative;
        opacity:1;
        visibility:visible;
        transition:0.2s ease-in-out;
        transition-delay: 150ms;
      }
      .image_ui{
        display:none;
      }
      .check_icon{
        //svg circle{
        //  fill:#12B76A;
        //  stroke:#12B76A;
        //}
      }

    }
  }
  .check_icon {
    position: absolute;
    top:20px;
    right: 27px;
    display: inline-flex;
    width: 20px;
    height: 20px;
    svg{
      width:100%;
      height:100%;
    }
  }
  label{
    font-size: 18px;
    font-weight: 500;
    font-family:'Helvetica Neue md';
    line-height: 24px;
    text-align: left;
    color:$fc-primary;
  }
  .icon_text_btn{
    background: transparent;
    border: none;
    width:auto;
    font-size: 15px;
    font-weight: 500;
    font-family:'Helvetica Neue md';
    align-items: center;
    line-height: 18.32px;
    text-align: left;
    color:$orange;
    display: inline-flex;
    @include trans2s;
    pointer-events: none;
    .icon{
      margin-right:7px;
      display:inline-flex;
      svg path{
        @include trans2s;
      }
    }
    &:hover{
      color:$orange-tint;
      .icon{
        svg path{
          stroke:$orange-tint;
        }
      }
    }
  }
}

.help_card{
  //width:49%;
  width: 33.33%;
  h5{
    font-size: 13px;
    font-weight: 700;
    line-height: 15.87px;
    letter-spacing: 0.1em;
    text-align: left;
    color:#F7B495;
    margin-bottom:12px;
  }
}

.help_step_card{
    background: #00606E;
    //padding:12px;
    border-radius:8.71px;
    display: flex;
  @media (max-width:991px){
    flex-direction: column;
  }
  .card_ui{
    padding:27px 23px 23px 23px;
    display:flex;
    align-items:flex-start;
    width: 100%;
    max-width:100%;
    border-radius: 9.58px;
    //@include trans2s;
    transition:ease-out 500ms;
    position:relative;
    background:rgba(#1A4B5B , 0);
    @media (max-width:1220px){
      align-items:center;
    }
    @include tabm{
      max-width:100%;
    }
    .anchor_link{
      position:absolute;
      left:0;
      right:0;
      width:100%;
      height:100%;
      top:0;
    }
    &:hover{
      background:rgba(#1A4B5B , 0.5);
    }
    .icon{
      display:inline-flex;
      margin-right:18px;
    }
    label{
      font-size: 17px;
      font-weight: 700;
      line-height: 22px;
      text-align: left;
      color:#fff;
      @media (max-width:860px){
        font-size: 15px;
      }
      @media (max-width:814px){
        font-size: 13px;
      }
    }
    p{
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color:#fff;
      @media (max-width:1295px){
        font-size: 14px;
      }
      @media (max-width:1240px){
        font-size: 13px;
      }
      br{
        @include phonel{
          display:none;
        }
        @media (max-width:1285px){
          display:none;
        }
      }
      @media (max-width:1220px){
        display:none;
      }
      .arrow_icon{
        display:inline-flex;
        margin-left:5px;
        align-items: flex-start;
        position: relative;
        top: 2px;
      }
    }
  }
  .do_no_show{
    margin-top: 39px;
    text-align: center;
    button{
      display: inline-flex;
      align-items: center;
      font-family:'Helvetica Neue md';
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: rgba(255,255,255,0.6);
      img{
        margin-right:7px;
      }
      &:active{
        color: rgba(255,255,255,0.6);
      }
    }
  }
}


/* mob scss*/
.mobile_getting_started_sec{
  position: relative;
  //padding-top: 60px;
  background:#fff;
  .started_btn{
    padding:15px 18px;
    display:flex;
    align-items:center;
    justify-content: space-between;
    width:100%;
    background: $teal-primary;
    border:none;
    color:#fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.65px;
    letter-spacing: 0.1em;
    text-align: left;
    .btn_text{
      display:inline-flex;
      position:relative;
      top:2px;
    }
  }
  .inner_sec{
    background: $teal-primary;
  }
}

.mobile_getting_started_sec{
  display:none;
  @include phonel{
    display:block;
  }
}
.mob_ftux_card {
  padding:0 18px;
  .mob_check_icon{
    display:inline-flex;
    width:17px;
    height:17px;
    position:absolute;
    right:17px;
    top:15px;
  }
  .card_inner{
    position: relative;
    padding:15px;
    background:#fff;
    box-shadow: 0px 4px 14.899999618530273px 0px #00000014;
    border-radius:10px;
    margin-top:-33px;
    label{
      font-size: 15px;
      font-weight: 700;
      line-height: 18.32px;
      text-align: left;
      color:$fc-primary;
      display:flex;
      flex-wrap:wrap;
      padding-right:40px;
      position:relative;
      top:2px;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color:$fc-primary;
      margin-top:8px;
      margin-bottom:10px;
      display:flex;
      padding-right:40px;

    }
  }
  .icon_text_btn {
    background: transparent;
    border: none;
    width: auto;
    font-size: 15px;
    font-weight: 500;
    font-family:'Helvetica Neue md';
    align-items: center;
    line-height: 18.32px;
    text-align: left;
    color: #DC5A4B;
    display: inline-flex;
    .icon{
      margin-right: 7px;
      display: inline-flex;
    }
  }
}

.getting_start_visible{
  @include phonel{
    .search-product-by-category {
      padding-top: 25px;
    }
    .quotes_main_wrapper .detail_wrapper{
      padding-top:10px !important;
    }
    .quotes_list_main_wrapper{
      padding-top:0px !important;
    }
    .whislist_head_section{
      padding-top:9px !important;
    }

    .ac_main_wrapper,
    .referral_wrapper{
      padding-top:16px !important;
    }
    .mobile_breadcrumb{
      margin-top:0 !important;
    }
  }
}

.mobile_getting_started_modal{
  background:$teal-primary;
  width:100%;
  height:100dvh;
  position:fixed;
  left:0;
  top:0;
  z-index:9990;
  overflow-y:auto;
  transform:translateX(100%);
  @include trans2s;
  opacity:0;
  visibility: hidden;
  display:none;
  @media (max-width:769px){
    display:block;
  }
  &.active{
    transform:translateX(0);
    visibility:visible;
    opacity:1;
  }
}
.getting_started_page{
  background:$teal-primary;
  width:100%;
  padding-bottom: 50px;
  @media (min-width: 768px){
    display:none;
  }
  .heading_sec{
    padding:8px 20px 13px;
    display:flex;
    align-items:center;
    justify-content: space-between;
    width:100%;
    h4{
      font-size: 12px;
      font-weight: 700;
      line-height: 14.65px;
      letter-spacing: 0.1em;
      text-align: left;
      color:#fff;
      position:relative;
      top:3px;
    }
    .close_btn{
      background:none;
      border: none;
      width:30px;
      height:30px;
      display:inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .accordian_warpper{
    padding:0 20px;
  }
  .bottom_sec{
    padding:34px 20px 0;
    .help_card{
      width:100%;
      h5{
        margin-bottom:15px;
      }
      &:nth-child(2){
        .help_step_card{
          padding-bottom:0;
        }
      }
    }
  }

  .help_step_card{
    padding:0 0 35px;
    background:transparent;
    .card_ui{
        background:#00606E;
        margin-bottom:10px;
        padding:20px 16px 20px 20px;
      label{
        @include remy(15);
        line-height:18.32px;
      }
      .icon{
        width:41px;
        height:41px;
      }
        &:last-child{
          margin-bottom:0;
        }
    }
  }
  .p-accordion-header-text{
    display:none;
  }
  .p-accordion {
    border-radius: 10px;
    overflow:hidden;
    p-accordiontab{
      &:last-child {
        .p-accordion-tab {
          border-bottom: 0;
        }
      }
      &:first-child{
        .p-accordion-tab{
          .p-accordion-content{
            padding-bottom:23px;
          }
        }
      }
    }
    .p-accordion-tab{
      margin:0;
      border-bottom:1px solid #E0E1E5;
    }
    .p-accordion-tab-complete{
      .circle_icon{
        svg circle{
          fill:#12B76A;
        }
      }
    }
    .p-accordion-header .p-accordion-header-link {
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 19px;
      background: #fff !important;
      border: #fff !important;
      box-shadow: none !important;
      border-radius: 0;
      @include trans2s;
      .p-accordion-toggle-icon{
        margin:0;
      }
      .down_icon{
        background:url("/assets/images/icon_down.svg") no-repeat;
        display: flex;
        width: 11px;
        height: 9px;
        background-size: 100%;
      }
      .up_icon{
        background:url("/assets/images/icon_up.svg") no-repeat;
        display: flex;
        width: 11px;
        height: 9px;
        background-size: 100%;
      }

      &[aria-expanded="true"] {
        padding-bottom:10px;
      }

      .circle_icon {
        display: inline-flex;
        margin-right: 13px;
        position: relative;
        top: 1px;
      }

      .p-icon-wrapper {
        color: #96999F;

        svg {
          margin-right: 0 !important;

          path {
            //stroke:#96999F !important;;
          }
        }
      }

      .content_sec {
        font-size: 15px;
        font-weight: 500;
        font-family:'Helvetica Neue md';
        line-height: 18.32px;
        text-align: left;
        color: $fc-primary;
        display: inline-flex;
        align-items: flex-start;
      }
    }
  }
  .p-accordion-content{
    background:#fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color:$fc-primary;
    padding:0 18px 0 49px;
    border-radius:0;
    margin-top:0px;
    position:relative;
    .italic{
      font-style: italic;
    }
  }
  .btn_sec{
    padding:15px 0 19px;
    display: flex;
    align-items: center;
    .icon_text_btn{
      background: transparent;
      border: none;
      width:auto;
      font-size: 15px;
      font-weight: 500;
      align-items: center;
      font-family:'Helvetica Neue md';
      line-height: 18.32px;
      text-align: left;
      color:$orange;
      display: inline-flex;
      .icon{
        margin-right:7px;
        display:inline-flex;
      }
    }
  }
}

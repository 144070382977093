@import "/src/assets/scss/helpers/mixins";
@import "/src/assets/scss/base/colors";

//layout
.mr-17{
  margin-right: 17px;
}
.mw_780{
  max-width: 810px;
  margin: auto;
  padding: 0 15px;
  @include tabl{
    max-width: 590px;
    padding:0 15px;
     @include tabm{
      padding:0 30px;
    }
    @include phonel{
      padding: 0 20px;
    }
  }


}
.mw-204{
  max-width: 204px !important;
}
.mw-433{
  max-width: 433px !important;
  width:100%;
}
.minoan-logo{
  max-width:174px ;
}
.container-fluid{
  padding:0 40px;
}

@include phonel{
  .content_wrapper{
    padding-top: 30px;
  }
}

.b2b_main_wrapper {
  width: 100%;
  height: 100%;
  @include phonel {
    > .d-flex {
      flex-direction: column;
    }
  }
}
.left-side-panel{
  display: flex;
  flex-direction: column;
  padding: 57px 30px 40px 30px;
  width: 300px;
  height: 100vh;
  overflow-y: auto;
  background: linear-gradient(180deg, #1A4B5B 0%, #376878 48.94%, #5592A0 100%);

  a{
    display: inline-block;
  }
  nav{
    margin-top: 64px;
    margin-bottom:30px  ;
    @include phonel{
      width:100%;
      max-width:100%;
      margin: auto;
      margin-top: 30px;
    }
  }
  .card_blk2{
    margin-top: auto;
    @include phonel{
      display: none;
    }
  }

  @media (max-width:1100px){
    width:250px;
    padding:57px 20px 30px;
    @include phonel {
      width:100%;
      height: auto;
      text-align: center;
      .logo{
        max-width:108px;
        margin: auto;
        img{
          width:100%;
        }
      }
    }
  }
}

.right-side-panel {
  width: calc(100% - 300px);
  height: 100vh;
  overflow-y: auto;
  @include tabv{
    width:calc(100% - 250px);
    @include phonel{
      width:100%;
      overflow: initial;
      padding-bottom: 80px;
      height:100%;
    }
  }
  .header-button-wrapper {
    width: 100%;
    padding: 59px 40px 48px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top:0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+51,ffffff+71,ffffff+84,ffffff+100&1+0,1+51,1+71,0.72+84,0.09+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 71%, rgba(255,255,255,0.72) 84%, rgba(255,255,255,0.09) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 71%,rgba(255,255,255,0.72) 84%,rgba(255,255,255,0.09) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 71%,rgba(255,255,255,0.72) 84%,rgba(255,255,255,0.09) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#17ffffff',GradientType=0 ); /* IE6-9 */
    z-index:2;
    margin-bottom: 9px;
    @include phonel{
      position: fixed;
      bottom: 0;
      left:0;
      width:100%;
      top: initial;
      background: #FFFFFF;
      box-shadow: 0px -4px 12px rgba(224, 225, 229, 0.25);
      padding: 20px;
      justify-content: center;
      margin-bottom: 0;

      .left_blk{
        .link-primary{
          display: none;
        }
      }
      .right_blk{
        width:100%;
        display: flex;
        max-width: 450px;

        .btn{
          width:100%;
          height:39px;
        }
      }
    }
  }
}

.list_item{
  list-style-type: none;
  position: relative;
  @include phonel{
      display: flex;
    justify-content:space-between;
  }

  li{
    position: relative;
    padding-bottom: 22px;
    width:100%;
    @include phonel{
      margin:0px 0 0;
      padding-bottom: 0;
    }
    &:after{
      position: absolute;
      content: "";
      left:8px;
      top:-27px;
      width:2px;
      height:66%;
      border-left:1px dashed rgba(#fff , 0.3);
      @include phonel{
        left: -46%;
        top: 7px;
        width: 91%;
        height: 2px;
        border-top: 2px dashed rgba(255, 255, 255, 0.3);
      }
    }
    &:before{
      position: absolute;
      content: "";
      border-radius: 100%;
      width:16px;
      height:16px;
      left:0;
      top:5px;
      border: 3px solid rgba(#fff , 0.3);
      @include phonel{
        right: 0;
        margin: auto;
        top:0;
      }
    }
    &:first-child{
      &:after{
        display: none;
      }
    }
    &.active{
      &:before{
        border-color:$yellow;
      }
      &:after{
        border-color: #fff;
      }
      a{
        color: rgba(#fff , 1);
        @include transition(0.12s ease-in-out);
        font-weight:500;
        &:hover{
          color: rgba(#fff , 0.5);
        }
      }
    }
    a{
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 400;
      @include remy(19.2);
      line-height: 24px;
      color: rgba(#fff , 0.3);
      padding-left: 28px;
      position: relative;
      @include phonel{
        text-align: center;
        padding: 0;
        @include remy(13);
        padding-top:20px;
        line-height: 16px;
      }


    }
  }
}

.para1{
  span{
    font-weight: 500;
  }
}

/*welcome page css start */
.welcome_wrapper{
  .heading-wrap{
    margin-bottom: 50px;
    @include tabl{
      margin-bottom: 30px;
    }
    .heading2{
      margin-bottom: 22px;
      @include phonel{
        margin-bottom: 16px;
      }
    }
    @include phonel{
      padding:0px 0px 0;
    }
  }
  .form_block{
    @include tabl{
      max-width: 372px;
    }
    .heading5{
      margin-bottom:26px;
    }
    .form-group{
      margin-bottom: 30px;
    }
  }
}
.heading-wrap {
  .heading2 {
    margin-bottom:22px;
  }
}
@include phonel{
  .mob_back_btn{
    margin-bottom: 13px;
    .link-primary .el-text{
      font-weight: 400;
    }
  }
}
.property_wrapper{

  .heading-wrap {
    .para1 {
      margin-bottom: 6px;
    }
  }
}

.border-seprater{
  margin: 40px 0;
  display: flex;
  height:1px;
  background: $border-light;
  width: 100%;
}

.property_wrapper,
.owned_property{
  .heading5{
    margin-bottom: 26px;
  }
}

.form_block {
  .form-head-blk {
    margin-bottom: 26px;
      .d-flex{
        justify-content: space-between;
        @include phonel{
          flex-direction: column;
          .link-primary{
            margin-top: 16px;
          }
        }
      }
  }
  .form_inner_container{
    .form-group{
      margin-bottom: 30px;

        .iwc_ui {
          .checkbox-button-label{
            color: $fc-secondary;
            @include remy(14);
          }
        }

    }
  }
}

.form_inner_container{
  max-width: 433px;
}
.property_label{
  font-style: normal;
  font-weight: 700;
  @include remy(14);
  line-height: 17px;
  color: $teal-tint;
  margin-bottom: 7px;
}


.property_container{
  .form-block{
    margin-bottom: 60px;
  }
}


/*account member css start */
.account_member_wrapper{
  .heading-wrap{
    .para1{
      margin-bottom: 6px;
    }
  }
  @include tabm{
    .card_blk1{
      margin-bottom: 25px;
    }
  }
}

/*table css start here */
.table_wrapper{
  @include phonel{
    display: none;
  }
  .table_header_wrap{
    margin-bottom:24px;
    .table-heading{
      margin-bottom:6px;
      font-weight: 400;
      font-style: normal;
      @include remy(24);
      line-height: 29px;

    }

  }
}
.table-container{
  table{
    width:100%;
    thead{
      border-bottom: 2px solid $border-light;
      th{
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color:$fc-primary;
        padding:0 7px 12px;
        text-align: center;
        vertical-align:top;
        &:first-child{
          text-align: left;
          font-size: 14px;
          line-height: 17px;
          padding-left: 0;
          vertical-align: bottom;
        }
        &:last-child{
          padding-right: 0;
        }
        .check_text{
          display: flex;
          justify-content: center;
          max-width: 100px;
          margin: auto;
          margin-bottom: 13px;
        }
        .custom-checkbox-group{
          justify-content: center;
        }
      }
    }


    tbody{
      tr{
        border-bottom: 1px solid $border-light;
        transition: .12s all ease;
        td{
          &:first-child{
            padding-left: 0;
          }
          &:last-child{
            padding-right: 0;
            text-align: right;
          }
        }

        &:last-child{
          border: none;
        }
        &:hover{
          //box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
          background:#fff;
          .delete_btn{
            opacity:1;
          }
        }
      }
    }

    td{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color:$fc-primary;
      padding:12px 7px 12px;
      text-align: center;
      &:first-child{
        text-align: left;
      }
      .custom-checkbox-group{
        justify-content: center;
      }
      .lgray-text{
        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 26px;
        color:$fc-secondary;
      }
    }
  }
  .custom-checkbox-group .custom-checkbox-button{
    margin-right: 0;
  }
  .delete_btn{
    background: none;
    border: none;
    width:20px;
    opacity: 0;
    svg, img{
      width:18px;
      height:22px;
    }
  }
}
.fc-secondary{
  color:$fc-secondary;
}



.invite_client_modal{
  .radio-btn-wrapper{
    .form-label{
      font-style: normal;
      font-weight: 500;
      @include remy(16);
      line-height: 22px;
      margin-bottom: 10px;

    }
    .fc-desc{
      margin-bottom:17px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $fc-primary;
    }
  }
}

.property_form_wrapper{
  .link-primary .el-text{
    font-weight:700;
  }
}



/*admin user */

.admin_user_wrapper{
  .heading-wrap{
    .para1{
      margin-bottom:10px;
      //color:$fc-secondary;
    }
  }

  .table_wrapper{
    margin-bottom: 18px;
  }

  .table-container .delete_btn{
    //opacity: 1;

    svg path{
        fill:$border-light;
      @include transition(0.12s ease-in-out);
      }
    &:hover{
      svg path{
        fill:$orange;
      }
    }
  }
}



.client_account{
  .para1{
    margin-bottom: 6px;
  }
}

.owned_property{
  .para1{
    margin-bottom: 10px;
  }
}

.linkedaccount_wrapper{
  position: relative;
  &:nth-last-child(2){
    .border-seprater{
      background: none;
      margin:0 0 30px;
    }
  }
  .delete-wrapper{
    text-align: right;
    margin-bottom:0;
    position: absolute;
    right:138px;
    top:38px;
    z-index:1;
    .el-icon{
      margin: 0;
    }
    svg{
      width:18px;
      height:22px;
    }
  }
}


.property_listing{
 table{
   width:100%;
   thead{
     border-bottom: 2px solid #E0E1E5;
     th{
       font-style: normal;
       font-weight: 700;
       font-size: 14px;
       line-height: 17px;
       color: #444444;
       padding-bottom: 15px;
     }
   }
   tbody{
     tr{
       border-bottom: 1px solid #E0E1E5;
       &:hover{
         background: #FFFFFF;
         //box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
         .delete_btn{
           opacity: 1;
           visibility: visible;
         }
       }
       &:last-child{
         border-bottom: 0;
       }
     }
     td{
       padding:10px 9px;
       font-style: normal;
       font-weight: 400;
       font-size: 16px;
       line-height: 26px;
       color:$fc-primary;
       text-align: left;
       &:first-child{
         width: 40px;
       }
       &:last-child{
         width: 60px;
       }
     }
     .delete_btn{
       //padding: 0px 6px;
       opacity: 0;
       visibility: hidden;
       transition: 0.2s ease-in-out;
       display: inline-flex;
       align-items: flex-start;
     }
     .edit-btn{
       width: 20px;
       height: 24px;
       background: none;
       border: none;
       display: inline-flex;
       align-items: center;
       justify-content: center;
       transition: 0.12s ease-in-out;
       &:hover {
         svg path{
            fill:$fc-primary;
            opacity:0.6;
         }
       }
     }
   }
 }
}

.invitaion_list_wrapper{
  table{
    tbody {
      td {
        &:first-child {
          width: auto;
        }
      }
    }
  }
}

.phone-field-wrapper .p-inputtext:enabled:hover{
  border-color:   #E0E1E5;
}

.add-property{
  .property_repeat_wrapper{
    &:first-child{
    }
    &:last-child .border-seprater {
      display: none;
    }
  }
}

.search_dropdown{
  .p-dropdown-header {
    background-color: transparent;
    padding: 0;
      .p-dropdown-filter{
        border: none;
        border-bottom: 2px solid #E0E1E5;
        border-radius: 0;
        padding: 0px 15px;
        @include remy(18);
        height: 50px;
        box-shadow: none;
        outline: none;
          &::placeholder {
            color:$fc-hint;
          }
        }
      .p-dropdown-filter-icon{
        color: $fc-hint;
        @include remy(20);
      }
  }
    .p-dropdown-label.p-placeholder{
      color: $fc-hint;
    }
    .p-dropdown:hover{
     border-color: #E0E1E5;
    }
    .p-inputwrapper-focus .multiSelectDropdown {
      border: 2px solid #96999F;
      box-shadow: none;
    }
    .p-dropdown-trigger-icon{
      font-size: 0.75rem;
    }
    .p-dropdown-items {
      padding: 0;
      .p-dropdown-item {
          font-style: normal;
          font-weight: 400;
          @include remy(16);
          line-height: 22px;
          color: $fc-primary;
          padding: 10px 15px;
          &.p-highlight{
            color:$fc-primary;
            background: rgba(85, 146, 160, 0.1);
          }
          &:hover {
            background: rgba(85, 146, 160, 0.1) !important;
          }
        }
      .p-dropdown-empty-message{
        color: $border-dark;
      }
    }

    .p-dropdown-panel{
      border: 2px solid #E0E1E5;
      filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2));
      border-radius: 4px;
    }


}

.doller_ui .ng-touched.ng-invalid {
  border-left: 0;
  + .input-group-text {
    border-color: $fc-red;
  }
}


.delete-wrapper {
  text-align: right;
  margin-bottom:0px;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow:none;
  border-color: #E0E1E5;
}


.custom-accordian_wrapper{
  .p-accordion {
    .p-accordion-header {
        padding:0 0 30px;
      &:hover {
        .p-accordion-header-link {
          background: #fff !important;
          border: none;
        }
      }

      .p-accordion-header-link {
        background: #fff !important;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: $fc-primary;
        padding: 0 0 0px;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &:hover {
          background: #fff;
          border: none;
        }

        .p-accordion-toggle-icon {
          position: relative;
          top: 2px;
          color: $orange;
          margin-right: 12px;
        }
      }

      .pi-chevron-right {
        &:before {
          content: "\e902";
        }
      }

      .pi-chevron-down {
        &:before {
          content: "\e903";
        }
      }
    }
  }

  .p-accordion-content{
    border: none;
    padding: 0px 0 0px 32px;
    .delete_btn{
      display: inline-flex;
      align-items: center;
      color: $border-dark;
      position: relative;
      top: -23px;
      @include transition(0.12s ease-in-out);
      &:hover{
        color:$orange;
        .icon{
          svg path{
            fill:$orange;
          }
        }
      }
      .icon{
        display: inline-flex;
        align-items: center;
        margin-right: 8px;
        svg{
          path{
            fill: $border-dark;
          }
        }
      }
    }
  }

}


@include tabl{
  .mb-0{
    .col-md-12{
      &:first-child{
        .form-group{
          margin-bottom:30px !important;
        }
      }
    }
  }
  .modal-body{
    .mb-0 .col-md-12:first-child .form-group{
      margin-bottom:0 !important;
    }
  }
}

.mob_back_btn{
  display: none;
  @include phonel{
    display: block;
  }
}
/*mob table layout css start*/
.mob_table-wrapper{
  display: none;
  @include phonel{
    display: block;
  }
  .table_header_wrap{
    border-bottom: 2px solid #E0E1E5;
    margin-bottom:0px;
  }
  .table-heading{
    font-style: normal;
    font-weight: 400;
    @include remy(22);
    line-height: 25px;
    color:$fc-primary;
    margin-bottom: 10px;
  }
  .para3{
    font-style: normal;
    font-weight: 400;
    @include remy(16);
    line-height: 22px;
    color: $fc-secondary;
    padding-bottom: 16px;
  }
  .table-container{
    table{
     tr {
       border-bottom: 1px solid #E0E1E5;
       td {
         padding: 0;

         .delete_btn {
           opacity: 1;
           visibility: visible;

         }

         table {
           table-layout: fixed;
           tr{
             border: none;
           }
           tr:first-child {
             td:last-child {
               text-align: right;
               padding-right: 20px;
             }
             td{
               padding-top: 25px;
             }
           }

           tr:last-child {
             td {
               font-style: normal;
               font-weight: 400;
               @include remy(12);
               line-height: 14px;
               text-align: center;
               color: $fc-primary;
               padding:15px 10px 25px;
             }
           }
         }
         label{
           margin-bottom: 10px;
           display: block;
           font-style: normal;
           font-weight: 400;
           @include remy(13);
           line-height: 16px;
           text-align: center;
         }
       }
     }
      .title-ui{
        font-style: normal;
        font-weight: 500;
        @include remy(16);
        line-height: 26px;
        color:$fc-primary;
      }
    }
  }
}

.back-top-wrapper{
  display: none;
  &.scrolled{
    display: block;
    text-align: center;
  position: sticky;
    bottom: 70px;
  left:50%;
  right: 0;
  display: inline-block;
  transform: translateX(-50%);
    .btn_primary{
      box-shadow:0px 0px 12px rgba(0, 0, 0, 0.25);
    }
  }

}


.filter-product-category{
  display: none;
}

@include  tabm {
  .mob-filter-wrap {
    order: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    .sort_dropdown .dropdown_btn{
      padding-top: 0;
    }
  }
  .filter-product-category {
    display: block;
    .mob_filter_btn {
      border: none;
      background: transparent;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: $orange;
      .icon {
        margin-left: 6px;
      }
    }
  }
}




.quotes_main_wrapper{
  //background:$gray300;
 // height: calc(100vh - 60px);
  //overflow: hidden;
  &.quote_page{
    &.mtm-150{
      .right_ui_wrapper{
        margin-top: -150px;
      }
    }
    .right_ui_wrapper{
      margin-top: -174px;
      @include tabv{
        margin-top: 30px;
      }
    }
    .detail_wrapper{
      @include tabv{
        display: none;
      }
    }
  }

  &.cart_page{
    .right_ui_wrapper{
      //margin-top: -124px;
      margin-top: -110px;
      @include tabv{
        //margin-top: 30px;
      }
    }
  }
  .container{
    padding: 0 30px;
    @include phonel{
      padding: 0px;
    }
  }
    .detail_wrapper{
      background: #fff;
      padding:0px 0 27px;
      @include tabv{
        padding: 16px 0;
      }
      @include phonel{
        padding:0px 0 16px;
      }
      .heading11{
        margin-top: 35px;
        word-break: break-word;
        @include tabv{
          font-size: 36px;
          margin-top: 0px;
          @include phonel{
            margin-top: 15px;
            font-size: 28px;
            line-height: 33px;
            padding: 0px 20px;
          }

        }

      }
      .heading12{
        margin-top: 35px;
        word-break: break-word;
        position: relative;
        padding-right: 40px;
        display: inline-flex;
        .edit_btn{
          position: absolute;
          right: 0;
          top:0;
        }
        @include phonel{
          display: none;
        }
      }
      .detail_inner_wrap{
        display: flex;
        justify-content: space-between;
        @include tabm{
          position: relative;
        }
        .detail_left_wrap{
          //width:calc(100% - 380px);
          width:100%;
          @include tabv{
            width: 100%;
          }
        }
        .detail_right_wrap{
          width:auto;
          display: flex;
          justify-content:flex-end;
          align-items:center;
          padding-top:0px;

          @include tabm{
            position: absolute;
            right: 0;
            top: 0;
            @include phonel{
              display: none;
            }
          }
        }
      }
    }

  .quotes_content_wrapper{
    padding: 0px 0 16px;
    background: $gray300;
    //height: calc(100% - 110px);
    @include phonel{
      height: auto;
    }
    > .container{
      height: calc(100% - 50px);
      @include phonel{
        height: auto;
      }
    }

    .content_inner_wrap{
      display: flex;
      justify-content:space-between;
      height: 100%;
      @include tabv{
        flex-direction: column;
        width:100%;
      }
    }
  }
  .left_ui_wrapper{
    width:calc(100% - 380px);
    padding-right: 52px;
    //overflow: hidden;
    @include tabl{
      padding-right: 25px;
      width:calc(100% - 350px);
      @include tabv{
        width:100%;
        padding-right: 0;
      }
    }

  }
  .item_list_wrapper{
    width:100%;
    min-height: 540px;
    //overflow: hidden;
    @include phonel{
      min-height: auto;
      padding-bottom: 60px;
    }
  }
  .right_ui_wrapper{
    width:380px;
    margin-top:-95px;
    @include tabl{
      width: 350px;
    }
    @include tabv{
      margin-top: 0;
      width:100%;
      display: none;
    }
    @include phonel{
      display: none;
    }
    .card_white_bg{
      z-index: 2;
      position: relative;
    }
  }

  .card_white_bg{
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.12), 0px 4px 6px -2px rgba(16, 24, 40, 0.07);
    border-radius: 4px;
    padding: 22px 30px 30px;
    margin-bottom: 30px;
    &.noshadow{
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);

    }
  }
}

.breadcrumb_wrap{
  padding-top: 9px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  .left_content_ui{
    display:flex;
    align-items: center;
  }
  @include tabv{
    padding-left:0px;
    padding-top: 0;
    width: calc(100% - 40px);
  }
  ul{
    display: flex;
    list-style: none;
    align-items: center;
    @include tabv{
      width: 100%;
    }
    li{
      font-style: normal;
      font-weight: 400;
      @include remy(15);
      line-height: 32px;
      color:$fc-primary;
      position: relative;
      margin-right:16px;
      @include tabv{
        margin-right: 10px;
      }
      &:last-child{
        margin-left:4px;
        @include tabv {
          padding-left: 10px;
          margin-left:0px;
        }
       @include tabv{
         width: 80%;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
       }
        &:after{
          display: none;
        }
      }
      &:after{
        position: absolute;
        content: "";
        background: url("/assets/images/quotes/ic_breadcrum.svg") no-repeat;
        font-style: normal;
        font-weight: 400;
        @include remy(15);
        line-height: 32px;
        color:$fc-secondary;
        right:-11px;
        top:53%;
        transform: translateY(-50%);
        width: 4px;
        height: 7px;

      }
      a{
        font-style: normal;
        //font-weight: 500;
        @include remy(15);
        line-height: 32px;
        text-decoration-line: underline;
        color:$fc-primary;
        font-family: 'Helvetica Neue md';
      }
    }
  }
  .update_ui{
        position: relative;
        margin-left:6px;
      p{
        font-style: normal;
        font-weight: 400;
        @include remy(15);
        line-height: 32px;
        color: $fc-secondary;
      }
      &:before{
        position: absolute;
        content: "|";
        font-style: normal;
        font-weight: 400;
        @include remy(14);
        line-height: 32px;
        color:$fc-secondary;
        left:-12px;
        top: 50%;
        transform: translateY(-50%);

      }
  }
}

.product_titte{
  position: relative;
  .p-tooltip-text{
    background: #F5F5F5;
    box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.18),  0px 17px 13px rgb(0 0 0 / 1%);
    font-style: normal;
    font-weight: 400;
    @include remy(14);
    line-height: 19px;
    color:$fc-primary;
    width:100%;
    padding: 14px 18px;
  }
  .p-tooltip{
    max-width: 379px;
    left: 0 !important;
    top: initial !important;
    bottom: 104% !important;
  }
  .p-tooltip-arrow,
  .p-tooltip-left .p-tooltip-arrow,
  .p-tooltip-right .p-tooltip-arrow{
    border-width: 0.75em 0.55em 0 !important;
    bottom: -6px !important;
    border-color: transparent;
    border-top-color:#F5F5F5 !important;
    left: 40px;
  }
}
.estimated_delivery{
  position:relative;
  .p-tooltip{
    max-width:251px;
    left: -10px !important;
    bottom: 34px !important;
    top:initial !important;
    z-index: 8!important;
    @include phonel{
      bottom: 39px !important;
    }
    .p-tooltip-text{
      background: #292929;
      border-radius: 2px;
      width:100%;
      font-style: normal;
      font-weight: 400;
      @include remy(14);
      line-height: 18px;
      color:#FFFFFF;
      padding:15px 15px;
    }
    .p-tooltip-arrow{
      bottom: -7px;
      left: 18px;
      margin-left: -0.25rem;
      border-width: 0.75em 0.55em 0;
      border-top-color:#292929;
    }
  }
}


.estimate_delivery{
  position: relative;
  .p-tooltip, .p-tooltip-left{
    max-width:251px;
    left: -10px !important;
    bottom: 34px !important;
    top:initial !important;
    z-index: 8!important;
    @include phonel{
      bottom: 39px !important;
    }
    .p-tooltip-text{
      background: #292929;
      border-radius: 2px;
      width:100%;
      font-style: normal;
      font-weight: 400;
      @include remy(14);
      line-height: 18px;
      color:#FFFFFF;
      padding:15px 15px;
    }
    .p-tooltip-arrow{
      bottom: -7px;
      left: 18px;
      margin-left: -0.25rem;
      border-width: 0.75em 0.55em 0;
      border-top-color:#292929;
      border-left-color: transparent;
      top: initial;
    }
  }
}

.out_stock_not_avial, .final_sale_ui,.non-minoan,.refund_issue_outer,.refund_issue_tooltip{
  position:relative;
  .p-tooltip{
    max-width:251px;
    left:100px !important;
    bottom: 34px !important;
    top:initial !important;
    z-index: 8 !important;
    .p-tooltip-text{
      background: #292929;
      border-radius: 2px;
      width:100%;
      font-style: normal;
      font-weight: 400;
      @include remy(14);
      line-height: 18px;
      color:#FFFFFF;
      padding:15px 15px;
    }
    .p-tooltip-arrow{
      bottom: -7px;
      left: 47%;
      margin-left: -0.25rem;
      border-width: 0.75em 0.55em 0;
      border-top-color:#292929;
      @include phonel{
        //bottom: -9px;
        top: 100%;
        left: 50%;
        margin-left: -1.25rem;
        border-width: 0.75em 0.45em 0;
        border-top-color: #292929;
        border-right-color: transparent;
        border-left-color: transparent;
        margin-top: -0.25rem;

      }
    }
  }
}
.out_stock_not_avial {
  .p-tooltip{
    max-width:190px;
    left: 120px !important;
    bottom: 40px !important;
    @include phonel{
      left: 74px!important;
    }

    .p-tooltip-arrow{
      left:62%;
      @include phonel{
        left:95%;
      }
    }
  }
}
.final_sale_ui{

  .final_sale_tooltip{
    position: relative;
  }
  .p-tooltip{
    min-width: 251px;
    left: -25px !important;
    @include phonel{
      min-width: 230px;
      bottom: 32px !important;
      left: -4px !important;

    }
  }
}

.refund_issue_outer{

  .refund_issue_tooltip{
    position: relative;
    .p-tooltip{
      min-width: 319px;
      left: -7px !important;
      bottom: 30px !important;
    }
  }

}

.non-minoan{

  .non_minoan_tooltip{
    position: relative;
    display: flex;

  }
  .p-tooltip{
    min-width: 251px;
    left: 104px !important;
    @include phonel{
      min-width: 230px;
      bottom: 40px !important;
      left: 106px !important;

    }
  }
}


.checkout_card_listing {
  .product_desc {
    .quote_title {
      margin-bottom: 1px !important;
    }

    .quote_title_des {
      margin-bottom: 2px !important;
    }
  }
}


.modal-backdrop{
  background: rgba(#1A4B5B, 0.95);
  opacity: 1 !important;
}
.custom-modal{
  .p-dialog-content{
    padding: 0;
    max-height: 686px;
    overflow-y:auto;
    padding-bottom: 130px;
    &:last-of-type{
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    @include phonel{
      border-radius: 8px !important;
    }
  }
  .close-btn {
    position: fixed;
    content: "";
    right: 19px;
    top: 17px;
    background: none;
    border: none;
    z-index: 1;
    @include phonel{
      right:18px;
      top:18px;
      background: #fff;
      width:32px;
      height:32px;
      border-radius: 4px;
    }

    &:hover {
      svg {
        transition: 0.2s ease-in-out;

        path {
          fill: $fc-primary;
        }
      }
    }
  }
  //.modal-content{
  //  border: none;
  //  border-radius: 0;
  //}
  //.close-btn{
  //  position: absolute;
  //  content: "";
  //  right:19px;
  //  top:17px;
  //  background:none;
  //  border: none;
  //  &:hover{
  //    svg {
  //      transition: 0.2s ease-in-out;
  //      path {
  //        fill: $fc-primary;
  //      }
  //    }
  //  }
  //}
}
.mask-class-1{
  background: rgba(#1A4B5B, 0.95) !important;
}
.modal_lg_600{
  width: 100%;
  max-width: 600px;
}
.modal_lg{
  width: 100%;
  max-width: 780px;
  &.modal_lg_600{
    width: 100%;
    max-width: 600px;
  }

  .modal-header {
    padding: 49px 86px 0;
    position: relative;
    border: none;
    @include phonel{
      padding:47px 20px 0;
    }

    .heading3{
      font-weight: 300;
      margin-bottom: 8px;
    }
    .para2{
      margin-bottom: 26px;
    }
  }

  .modal-body{
    padding: 0px 86px 0;
    @include phonel{
      padding:0 20px;
    }
    .modal-body-description{
      margin-bottom: 20px;
    }
    .fg-inner{
      &.dflex{
          display: flex;
        flex-direction: column;
      }
    }
  }
  .modal-footer{
    border: none;
    padding: 70px 86px 25px;
    position: fixed;
    bottom: 0;
    left:0;
    right:0;
    width:100%;
    z-index:1;
    justify-content: center;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+20,ffffff+31,ffffff+51,ffffff+100&0.1+0,0.84+19,0.84+31,1+51,1+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.84) 19%, rgba(255,255,255,0.84) 20%, rgba(255,255,255,0.84) 31%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.84) 19%,rgba(255,255,255,0.84) 20%,rgba(255,255,255,0.84) 31%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.84) 19%,rgba(255,255,255,0.84) 20%,rgba(255,255,255,0.84) 31%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1affffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

    @include phonel{
      padding:45px 20px 25px;
      width:100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      .btn{
        width:100%;
      }
    }
    .btn{
      margin:0 7.5px;
    }
  }
}


/*modal sm */
.modal_sm{
  width: 100%;
  max-width: 630px;

  .modal-body{
    padding: 45px 86px 0;
    @include phonel{
      padding:30px 20px 0;
    }
    .modal-body-description{
      margin-bottom: 20px;
    }
    .fg-inner{
      &.dflex{
        display: flex;
        flex-direction: column;
      }
    }
  }
  .modal-footer{
    border: none;
    padding: 70px 86px 25px;
    position: fixed;
    bottom: 0;
    left:0;
    right:0;
    width:100%;
    z-index:1;
    justify-content: center;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+20,ffffff+31,ffffff+51,ffffff+100&0.1+0,0.84+19,0.84+31,1+51,1+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.84) 19%, rgba(255,255,255,0.84) 20%, rgba(255,255,255,0.84) 31%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.84) 19%,rgba(255,255,255,0.84) 20%,rgba(255,255,255,0.84) 31%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.84) 19%,rgba(255,255,255,0.84) 20%,rgba(255,255,255,0.84) 31%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1affffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

    @include phonel{
      padding:45px 20px 25px;
      width:100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      .btn{
        width:100%;
      }
    }
    .btn{
      margin:0 7.5px;
    }
  }
}


/*modal md */

.modal_md{
  width: 100%;
  max-width: 545px;
  .modal-body{
    padding:54px 67px 0;
  }
  .modal-footer{
    border: none;
    padding: 30px 86px 25px;
    .btn{
      margin:0 7.5px;

    }
  }
}


@include tabm{
  .p-dialog-mask{
    padding: 0 20px;
  }
}


/* remove access modal */
.remove-access-modal{
  text-align: center;
  .export-icon{
    margin-bottom:24px;
  }
  .heading3{
    margin-bottom: 16px;
  }
  .para2{
    margin-bottom: 0px;
  }
}

.brand_info{
  .p-dialog {
   @include phonel{
     max-height: 100%;
   }
  }
  .p-dialog-content{
    padding-bottom: 0;
    border-radius: 12px !important;
    box-shadow: 16px 24px 40px rgba(16, 24, 40, 0.25);
    max-height:initial;
    @include phonel{
      border-radius: 0px !important;
      max-height: 100%;
    }
  }
  .close-btn{
    z-index: 2;
    position: absolute;
    @include phonel{
      background: transparent;
    }
    &:hover{
      svg{
        path{
          fill:#fff;
          opacity:0.8;
        }
      }
    }
    svg{
      width:18px;
      height: 18px;
      path{
        fill:#fff;
      }
    }
  }
}





/* account management modal starts */
  .p-dialog{
    .btn_primary:hover{
      //background:$orange-shade2;
      //background:$orange;
    }
    .form-label{
      @include remy(15);
    }
  }

.a-management-modal,.cart_modal, .csv_modal_wrapper{
  .p-dialog-mask{
   overflow-y: auto;
  }
  &.p-dialog{
    max-height: 100%;
    @include phonel{
      max-height: 94%;
    }
  }
  .singleSelectDropdown:not(.p-disabled).p-dropdown-open.p-focus .p-dropdown-label.p-placeholder {
    opacity: 1;
  }
  &.overflow_ui{
    .p-dialog-content{
      overflow-y: initial;
      //changed for angular 15
      @include tabm {
        overflow-y: auto;
      }
    }
  }
  .p-dialog-content{
    max-height: 704px;
    overflow-y:auto;
    padding: 0 0 0px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    position: relative;
    box-shadow: 16px 24px 40px rgba(16, 24, 40, 0.25);
    &:after{
      position: fixed;
      content: "";
      height:20px;
      width:100%;
      background: #fff;
      left: 0;
      bottom: 0;
      border-radius:0 0 12px 12px;
    }

    &:last-of-type{
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    }

  }
  .close-btn {
    position: fixed;
    content: "";
    right:10px;
    top:10px;
    background: none;
    border: none;
    z-index: 1;
    width:36px;
    height:36px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include phonel{
      position: absolute;
    }
    @include phonem{
      top: 15px;
      background: #fff;

    }
    svg {
      width:18px;
      @include phonem{
        width:13px;
        height:13px;
      }
    }

    &:hover {
      svg {
        transition: 0.2s ease-in-out;

        path {
          stroke: $fc-primary;
        }
      }
    }
  }

  .custom-input-1{
    @include remy(16);
    line-height: 19px;
    &:disabled{
      color:$fc-secondary;
    }
    &::placeholder{
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 19px;
      color: $fc-hint;
    }
  }

  .user_role_dropdown {
    .dropdown_text {
      label {
      font-weight: 700;
        line-height: 22px;
    }
      p{
        @include remy(16);
        line-height: 22px;
      }
  }

  }

  .multiselect_w_check .multiSelectDropdown .p-multiselect-panel .p-multiselect-header p{
    font-weight: 500;
    @include remy(16);
  }


  .multiselect_w_check .multiSelectDropdown .p-multiselect-panel{
    //max-height:108px;
    overflow-y: auto;
    //width:calc(100% + 4px);
    left:-2px !important;
  }
  .singleSelectDropdown {
    .p-dropdown-label.p-placeholder,
    .p-dropdown-label {
      @include remy(16);
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
      //background:transparent;
      background: rgba(85, 146, 160, 0.1);
    }
  }
  .equal_width_ui{
    .singleSelectDropdown .p-dropdown-panel{
      width:calc(100% + 4px);
      left:-2px !important;
    }
  }

  .alert_message{
    margin-bottom: 30px;
    p{
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 21px;
      color: $fc-red;
    }
  }

  .form-group.fg_info{
    margin-bottom: 20px !important;
    .form-label{
      margin-bottom: 0px;
      font-style: normal;
      font-weight: 500;
      @include remy(16);
      line-height: 25px;
    }
    p{
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 25px;
    }
  }
  .form-group{
    position: relative;
    .fg-inner{
      margin-bottom: 0;
    }
    .form-label{
      font-weight: 500;
      @include remy(15);
      line-height: 18px;
    }
  }

  .modal-body-description{
    .form-group{
      &:last-child{
        &.user_role_dropdown{
          .p-dropdown-panel{
            max-height: 115px;
            overflow-y: auto;
            .p-dropdown-items-wrapper{
              max-height:100% !important;
            }
          }
        }
        &.property_access_dropdown{
          .multiselect_w_check .multiSelectDropdown .p-multiselect-panel{
            max-height:170px;
            overflow-y: auto;
            .p-dropdown-items-wrapper{
              max-height:100% !important;
            }
          }
        }
      }
    }
  }
  .remove_modal{
    margin-top: -18px;
    margin-bottom: 25px;
    .para2{
      font-style: normal;
      font-weight: 400;
      @include remy(18);
      line-height: 24px;
      color: #667078;
    }
    .remov_property{
      margin-top: 7px;
      p{
        font-style: normal;
        font-weight: 400;
        @include remy(18);
        line-height: 24px;
        color: #667078;
      }
    }

  }
  .modal-footer{
    border: none;
    padding: 20px 15px 35px 15px;
    position: relative;
    bottom: 0;
    left:0;
    right:0;
    width:100%;
    //z-index:1;
    justify-content: center;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+20,ffffff+31,ffffff+51,ffffff+100&0.1+0,0.84+19,0.84+31,1+51,1+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.84) 19%, rgba(255,255,255,0.84) 20%, rgba(255,255,255,0.84) 31%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.84) 19%,rgba(255,255,255,0.84) 20%,rgba(255,255,255,0.84) 31%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.84) 19%,rgba(255,255,255,0.84) 20%,rgba(255,255,255,0.84) 31%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1affffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    @include  phonem{
      padding: 20px 10px 50px 10px;
    }
    .btn{
      margin:0 7.5px;
      .mob_hide{
        margin-left:4px;
        display: inline-flex;
        @include phonel{
          display: none;
        }
      }
      &.btn_primary, &.btn_secondary{
        @include phonel{
          padding: 0 16px;
          @include remy(16);
          @media (max-width:360px){
            @include remy(15);
            padding: 0 12px;
            min-width: 90px;
            margin: 0 5px;

          }
        }
      }
    }
    .btn_secondary{
     @include phones{
       min-width: 109px;
     }
    }
    .btn_primary{
      @include phones{
        min-width: 109px;
      }
    }
  }

  .custom-checkbox-group {
    .custom-checkbox-button{
      width:22px;
      height:22px;
      .p-checkbox-box{
        width:22px;
        height:22px;
      }
    }
    .checkbox-button-label{
      @include remy(18);
      line-height: 21px;
      color:$fc-primary;
      padding-left: 10px;
    }
  }

  .modal-header:after {
    position: fixed;
    content: "";
    top: 0;
    width: 100%;
    height: 18px;
    left: 0;
    border-radius: 10px 10px 0 0;
    background: #fff;
    z-index: 2;
  }

  .user_role_dropdown .p-dropdown-panel{
    //width:calc(100% + 4px);
    left:-2px !important;
    @media (min-width:991px){
      max-height: 270px !important;
    }

    .p-dropdown-items-wrapper{
      max-height:100% !important;
    }
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    align-self: flex-start;
    margin-right: .5rem;
  }


}
.mask-class-2{
  background: rgba(40, 49, 63, 0.6)!important;
}
.brand_modal_mask{
  background: rgba(40, 49, 63, 0.6)!important;
  padding: 0px;
  @include phonel{
    align-items: flex-start !important;
    .p-dialog {
      @include phonel {
        height: 100%;
      }
    }
  }
}


.modal_490{
  width: 100%;
  max-width: 490px;
}
.modal_450{
  width: 100%;
  max-width: 450px;
}
.modal_457{
  width: 100%;
  max-width: 457px;
}
.modal_473{
  width: 100%;
  max-width: 473px;
}
.modal_688{
  width: 100%;
  max-width: 688px;
}
.modal_590{
  width: 100%;
  max-width: 590px;
}

.modal_754{
  width: 100%;
  max-width: 754px;
}
.modal_450,.modal_457, .modal_490, .modal_688,.modal_590,.modal_473, .modal_540, modal_754, {

  .modal-header {
    padding: 40px 45px 0;
    position: relative;
    border: none;
    @include phonem{
      padding: 60px 20px 0 ;
    }

    .heading3{
      font-style: normal;
      font-weight: 700;
      @include remy(26);
      line-height: 30px;
      color:$fc-primary;
      margin-bottom: 30px;
      //@include phonel{
      //  line-height: 30px;
      //}
      &.cart_heading{
        margin-bottom: 45px;
      }
    }

  }

  .modal-body{
    padding: 0px 45px 0;
    @include phonem{
      padding: 0px 20px 0 ;
    }

    .modal-body-description{
      .form-group{
        margin-bottom: 25px;
      }
    }

  }

}

.modal_540{
  width: 100%;
  max-width:540px;
  .modal-header {
    padding-top:44px;
  }
}

//cart_modal_mobile
.modal_590_cart{
  width: 100%;
  max-width: 590px;
  .modal-footer {
    @include phonem {
      padding: 20px 10px 40px 10px;
    }
  }
  .modal-header {
    padding: 40px 45px 0;
    position: relative;
    border: none;
    @include phonem{
      padding: 56px 20px 0 ;
    }


    .heading3{
      font-style: normal;
      font-weight: 700;
      @include remy(26);
      line-height: 30px;
      color:$fc-primary;
      margin-bottom: 30px;
      @include phonel{
        @include remy(22);
        line-height: 27px;
      }
      &.cart_heading{
        margin-bottom: 45px;
      }
    }

  }

  .modal-body{
    padding: 0px 45px 0;
    @include phonem{
      padding: 0px 20px 0 ;
    }

    .modal-body-description{
      .form-group{
        margin-bottom: 20px;
        .fg-inner{
          .form-label{
            @include phonel {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 6px;
            }
          }
          .custom-input-1{
           @include phonel{
             @include remy(15);
             line-height: 18px;
             height: 48px;
             &::placeholder{
               //@include remy(16);
               //line-height: 19px;
               @include phonel{
                 @include remy(14);
                 line-height: 21px;
               }

             }
           }
          }
        }
      }
    }

  }
}

.showroom_items_modal{
  width: 100%;
  max-width: 1013.05px;
  height: 715px;
  overflow: hidden;
  border-radius: 16px !important;
  .my_items_wrapper{
    padding-left: 0 !important;
    height: 100%;
    .cdk-virtual-scrollable{
      position: relative;
      min-height: 100%;
      overflow-y: auto;
    }
  }
  .p-dialog-content{
    padding: 32px 42px 100px 50px;
    border-radius: 16px !important;
    overflow: hidden;
    .cdk-virtual-scrollable{
    }
  }
}

.modal_576_cart{
  width: 100%;
  max-width: 576px;
  .modal-footer {
    padding: 23px 15px 35px 15px;
    @include phonem {
      padding: 20px 10px 40px 10px;
    }
  }
  .modal-header {
    padding: 40px 45px 0;
    position: relative;
    border: none;
    @include phonem{
      padding: 56px 20px 0 ;
    }


    .heading3{
      font-style: normal;
      font-weight: 700;
      @include remy(26);
      line-height: 30px;
      color:$fc-primary;
      margin-bottom: 41px;
      @include phonel{
        @include remy(22);
        line-height: 27px;
      }
      &.cart_heading{
        margin-bottom: 45px;
      }
    }

  }

  .modal-body{
    padding: 0px 45px 0;
    @include phonem{
      padding: 0px 20px 0 ;
    }

    .modal-body-description{
      .form-group{
        margin-bottom: 20px;
        .fg-inner{
          .form-label{
            @include phonel {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 6px;
            }
          }
          .custom-input-1{
            @include phonel{
              @include remy(15);
              line-height: 18px;
              height: 48px;
              &::placeholder{
                //@include remy(16);
                //line-height: 19px;
                @include phonel{
                  @include remy(14);
                  line-height: 21px;
                }

              }
            }
          }
        }
      }
    }

  }
}

.modal_754_cart{
  width: 100%;
  max-width: 754px;
  .modal-footer {
    @include phonem {
      padding: 20px 10px 40px 10px;
    }
  }
  .modal-header {
    padding: 40px 45px 0;
    position: relative;
    border: none;
    @include phonem{
      padding: 56px 20px 0 ;
    }


    .heading3{
      font-style: normal;
      font-weight: 700;
      @include remy(26);
      line-height: 30px;
      color:$fc-primary;
      margin-bottom: 30px;
      @include phonel{
        @include remy(22);
        line-height: 27px;
      }
      &.cart_heading{
        margin-bottom: 45px;
      }
    }

  }

  .modal-body{
    padding: 0px 45px 0;
    @include phonem{
      padding: 0px 20px 0 ;
    }

    .modal-body-description{
      .form-group{
        margin-bottom: 20px;
        .fg-inner{
          .form-label{
            @include phonel {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 6px;
            }
          }
          .custom-input-1{
            @include phonel{
              @include remy(15);
              line-height: 18px;
              height: 48px;
              &::placeholder{
                //@include remy(16);
                //line-height: 19px;
                @include phonel{
                  @include remy(14);
                  line-height: 21px;
                }

              }
            }
          }
        }
        .add_item_modal_checkbox{
          margin-top: 14px;
          .custom-checkbox-group{
            align-items: center;
            .custom-checkbox-button{
              width: 20px;
              height: 20px;
              .p-checkbox-box{
                width: 20px;
                height: 20px;
              }

            }
            .checkbox-button-label{
              color: #96999F;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              vertical-align: middle;
            }
          }
        }
      }
    }

  }
}

.cart_modal {
  &.overflow_ui{
    .p-dialog-content{
      overflow-y: initial;
    }
  }
  .multiselect_w_check {
    .multiSelectDropdown {
      .p-multiselect-panel {
        overflow-y: auto;
        width: calc(100% + 4px);
        max-height: 175px;
        left: -2px !important;
      }
    }
  }
  .modal-footer {
    .btn_primary, .btn_secondary {
      @include phonel{
        min-width: 146px;
        height: 41px;
        @media (max-width:390px){
          @include remy(16);
          padding: 0 12px;
          min-width: 120px;
          margin: 0 5px;
          @media (max-width:360px){
            min-width: 103px!important;

          }

        }
      }

    }
  }
}

.quote_archive_modal{
  .modal-content{
    .modal-body{
      .modal-body-description{
        .remove_modal{
          margin-top: -19px;
          margin-bottom:7px;
        }
      }
    }
    .modal-footer{
      padding: 20px 15px 27px 15px;

    }
  }

}

.quote_request_modal{
  .modal-content{
    .modal-header{
      @include phonel{
        padding: 40px 24px 0px 24px;
      }
    }
    .modal-body{
      @include phonel{
        padding: 0px 24px 0px 24px;
      }
      .modal-body-description{
        .remove_modal{
          margin-top: -20px;
          margin-bottom:3px;
        }
      }
    }
    .modal-footer{
      padding: 20px 15px 31px 15px;
    }
  }

}


//.quote_modified_modal{
//  .modal-content{
//    .modal-header{
//      @include phonel{
//        padding: 40px 24px 0px 24px;
//      }
//    }
//    .modal-body{
//      @include phonel{
//        padding: 0px 24px 0px 24px;
//      }
//      .modal-body-description{
//        .remove_modal{
//          margin-top: -18px;
//          margin-bottom:26px;
//          &.post_modified{
//            margin-top: -11px;
//          }
//
//          @include phonel{
//            margin-bottom:15px;
//          }
//        }
//        .custom-checkbox-group{
//          margin-bottom: 16px;
//          .checkbox-button-label{
//            color: #667078;
//            font-size: 17px;
//          }
//        }
//      }
//    }
//    .modal-footer{
//      padding: 20px 15px 38px 15px;
//
//    }
//  }
//
//}


.quote_modified_modal{
  .modal-content{
    .modal-header{
      @include phonel{
        padding: 40px 24px 0px 24px;
      }
    }
    .modal-body{
      @include phonel{
        padding: 0px 24px 0px 24px;
      }
      .modal-body-description{
        .remove_modal{
          margin-top: -18px;
          margin-bottom:26px;
          &.post_modified{
            margin-top: -11px;
            margin-bottom:28px;
          }
          .para2{
            color: #666666;
          }
          @include phonel{
            margin-bottom:15px;
          }
        }
        .custom-checkbox-group{
          margin-bottom: 22px;
          .checkbox-button-label{
            color: #96999F;
            font-size: 17px;
          }
        }
      }
    }
    .modal-footer{
      padding: 20px 15px 38px 15px;
      &.footer_draft_state{
        padding-top: 24px;
        @include phonel{
         flex-direction: column-reverse;
        }
        .btn_secondary{
          padding: 0 31px;
          @include phonel{
            padding: 0 77px;
            margin-top: 15px;
          }
        }
        .btn_primary{
          padding: 0 38px;

        }
      }
      &.quote_update_footer{
        @include phonel{
          flex-direction: column-reverse;
        }
        .btn_secondary{
          padding: 0 30px;
          @include phonel{
            padding: 0 77px;
            margin-top: 15px;
          }
        }
        .btn_primary{
          padding: 0 32px;

        }

    }

    }
  }

}

.windows{
  .quote_modified_modal{
    .modal-content{
      .modal-header{
        padding: 40px 42px 0;

      }
      .modal-body{
        padding: 0px 42px;

      }
      .modal-footer{
        padding: 20px 15px 38px 15px;
        &.footer_draft_state{
          padding-top: 24px;
          .btn_secondary{
            padding: 0 31px;
          }
          .btn_primary{
            padding: 0 41px;

          }
        }
        &.quote_update_footer{
          .btn_secondary{
            padding: 0 31px;
          }
          .btn_primary{
            padding: 0 36px;

          }

        }

      }
    }

  }

}


.p-dialog {
  border-radius: 0px;
  box-shadow: none;
  border: 0 none;
}

/* account management modal ends */

.multiselect-ui-outer{
  .p-disabled{
    background: #F5F5F5;
    border-color: #F5F5F5;
    .pi-chevron-down{
      opacity:0.5;
    }
  }
}

.address_modal{
  .pl-10{
    padding-left: 10px;
  }
  .pr-10{
    padding-right: 10px;
    .error-1{
      letter-spacing: -0.2px;
      line-height: 13px;
      padding-top:3px
    }
  }
}
.edit_address_modal{
  .pl-10{
    padding-left: 10px;
  }
  .pr-10{
    padding-right: 10px;
    .error-1{
      letter-spacing: -0.2px;
      line-height: 13px;
      padding-top:3px
    }
  }
}


/* sidebar modal */
.p-dialog{
  &.sidebarmodal{
    position: fixed;
    right: 0;
    max-width: 587px;
    max-height: 100%;
    height: 100%;
    //transform: translateX(600px);
  }
}

p-dialog{
  .mask-class-2{
   //z-index: 1500 !important;
   // z-index: 11104 !important;
  }
}




.mob_quote_detail_wrapper{
  .modal-header .heading3{
    margin-bottom:8px;
     &.quote_modal_head{
      font-size: 26px;
      word-break: break-word;
    }

  }
  .lastupdate_ui{
    font-style: normal;
    font-weight: 400;
    @include remy(18);
    line-height: 21px;
    margin-bottom: 30px;
    color:$fc-secondary;
  }
  .form-label{
    flex-wrap: wrap;
    line-height: 19px;
    span{
      display: inline-flex;
      font-weight: 400;
    }
  }
  .multiselect_w_check .multiSelectDropdown.p-multiselect .p-multiselect-label{
    padding-right: 0;
  }
  .custom-input-1.ng-touched.ng-invalid {
    border: 2px solid #E0E1E5;
  }
}


.cstm_error_group{
  //.form-group{
  //  margin-bottom: 0 !important;
  //}
  .error_msg_ui{
    min-height: 24px;
    .error_wrapper{
      position:relative;
      top: 0;
    }
    @include phonel{
      .error-1{
        line-height: 16px;
      }
    }
  }
}


.tracking_modal{
  .modal-header {
    .heading3 {
      margin-bottom: 15px;
    }
  }
  .status_wrap{
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    .quote_shipto_outer{
      display: inline-block;
      vertical-align: top;
      .shipto_icon{
        display: inline-flex;
        margin-right: 7px;
        position: relative;
        &.ic_non_trackable{
          top: 1px;
        }
        &.ic_delivered{
          top:3px;
        }
        &.ic_transit,
        &.ic_out_delivery{
          top:2px;
        }
        &.ic_label_created{
          top:2px;
        }
      }
      .shipto_ui{
        font-style: normal;
        font-family: 'Helvetica Neue md';
        @include remy(18);
        line-height: 22px;
        color:$teal-primary;
      }
      .shipto_address{
        font-style: normal;
        font-weight:400;
        @include remy(18);
        line-height: 21px;
        padding-left: 4px;
      }
    }
  }
  .modal-body{
    padding-bottom: 24px;
  }
  .modal-footer{
    padding:24px 45px;
    border-top: 1px solid $border-gray100;
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 2;
    @include phones{
      padding:24px 20px;
    }
    .footer_inner_wrap {
      display: flex;
      justify-content: space-between;
      width:100%;
      @include phones{
        flex-direction: column;
      }
      .btn_primary{
       @include phones{
         margin: 0px;
       }
      }
    }
    .tracking_number_ui{
      @include phones{
        margin-bottom: 14px;
      }
      p{
        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 80.53%;
        color:$fc-primary;
        margin-bottom: 3px;
      }
      .tracking_number{
        font-style: normal;
        font-family: 'Helvetica Neue md';
        @include remy(16);
        line-height: 80.53%;
        text-decoration: underline;
        color:$fc-primary;
        align-items: center;
        .icon{
          margin-left: 8px;
        }
      }
    }
  }
}
.order-track {
  position: relative;
  &:last-child{
    padding-bottom:0;
    .order-track-step{
      padding-bottom: 25px;
      &:last-child{
        padding-bottom: 0;
        .order-track-status-line{
          display: none;
        }
      }
    }
    .order-track-status-line{
      display: none;
    }
  }

  &:first-child{
    .order-track-status-line{
      display: block;
    }
  }
  .order-track-step{
    display: flex;
    align-items:flex-start;
    .order-track-text{
      >.order-track-text-stat{
        margin-top: -4px;
      }
      .order-track-text-sub{
        + .order-track-text-stat{
          margin-top: 0px;
        }
      }
    }
  }
  .order-track-status{
    //position: relative;
    display: flex;
    margin-right: 14px;
    flex-direction: column;
    align-items: center;
  }
    .order-track-status-dot {
      display: block;
      width: 12px;
      height:12px;
      border-radius: 100%;
      background: #fff;
      border: 2px solid $fc-hint;
      margin-top: 0px;
      z-index: 1;
    }
  .order-track-status-line{
      display: block;
      //margin: 0px auto 0;
      width: 2px;
      //height:calc(100% - 12px);
    height:calc(100% - 24px);
      background:$fc-hint;
      position: absolute;
      content: "";
      //left:0;
      top:12px;
    }

  .order-track-text-sub{
    margin-bottom: 7px;
    display: flex;
    margin-top: -2px;
    @include phones{
      flex-direction: column;
    }
    span {
      font-style: normal;
      font-family: 'Helvetica Neue md';
      @include remy(15);
      line-height: 18px;
      text-transform: uppercase;
      &.bseprater{
        width: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        @include phones{
          display: none;
        }
      }
    }
  }
  .order-track-text-stat{
    font-style: normal;
    font-weight: 400;
    @include remy(16);
    line-height: 22px;
    color:$fc-primary;
  }
}

.modal_lg_610{
  max-width:610px;
 @include phonel{
   max-height: 100% !important;
 }

}


.scrollable{
  &.bottom{
    .p-dialog-content{
      &:after{
        display: none;
      }
    }
  }
  .p-dialog-content{
    &:before{
      position: fixed;
      content: "";
      bottom: 0;
      left: 0;
      width:100%;
      height:24px;
      background: #fff;
      border-radius:0 0 12px 12px;
      z-index: 1;
    }
    &:after{
      position: fixed;
      content: "";
      bottom: 24px;
      left: 0;
      width:100%;
      height:63px;
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      transform: rotate(180deg);
    }

  }
}


.csv_modal_wrapper {
  &.p-dialog{
    max-height:90%;
  }
  &.overflow_ui {
    .p-dialog-content {
      max-height: inherit;
      overflow-y: auto;
    }
  }
  .modal-footer {
    padding: 30px 15px 35px 15px;
    position: sticky;
    bottom: 0;
    z-index: 2;
  }
}
.add_product_row{
  &:has(.col-md-9){
    .cart_quantity_section_modal{
      @media (min-width: 768px) {
        padding-left: 1px;
      }
    }
  }
}
.cart_quantity_section_modal{
  &.for_add_item{
    .form-group{
      @include phonel{
        margin-bottom: 0px!important;
      }
    }
  }
  padding-left: 1px;
  @include phonel{
    padding-left: 15px;
  }
  .form-group{
    .fg-inner{
      .cart_quantity{
        margin-top: 3px;
      }
    }
  }
}



//add item cardt carousal

.product_slide {
  width: 146px;
  position: relative;
  padding-top:0px;
  @include phonel {
    display: none;
  }

  h4{
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 18px;
    color: #444444;
    margin-bottom: 7px;
  }
  //justify-content: space-between;
  //flex-direction: column-reverse;
}

.mobile_carousel_selector {
  display: none;
  @include phonel{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 25px;
  }
}
.no_images_mob{
  justify-content: flex-end;
  .btn_outline{
    display: none;
  }

}

.product_slide.single_image {
  width: 100px;
  .main_image {
    &:hover {
      img {
        opacity: 1;
      }
    }
    margin: 0;
  }
  .thumbnail_list {
    display: none;
  }
}
.thumbnail_list {
  //max-height: 100px;
  //overflow-y: auto;
  //overscroll-behavior: contain;
  max-height:94px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  overscroll-behavior: contain;
  margin-right: -16px;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    //background: var(--white);
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 20px;
    &:hover {
      background: #1A4B5B;
    }
  }
}
.thumbnail_card {
  width: 40px;
  height:40px;
  margin-right: 10px;
  margin-bottom: 9px;
  &.desk_hover_only{
    &:hover{
      opacity: 0.6;
    }
  }
  &.images_disabled{
    opacity:0.3;
    cursor: auto;
    img{
      cursor: auto;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }
}
.main_image {
  width: 138px;
  height: 138px;
  //margin-left: 8px;
  margin-bottom:15px;
  position: relative;
  border:2px solid #E0E1E5;
  border-radius: 4px;
  .check_icon{
    position: absolute;
    right: -8px;
    bottom: -9px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    transition: 0.2s ease-in-out;
    border-radius:4px;
  }
  //&:hover {
  //  img {
  //    opacity: 0.6;
  //  }
  //  .slide_btn {
  //    opacity: 1;
  //    visibility: visible;
  //  }
  //}
}
.more_than_one_img{
  img {
    cursor: pointer;
  }
  &:hover {
    img {
      opacity: 0.6;
    }
    .slide_btn {
      opacity: 1;
      visibility: visible;
    }
  }
}
.no_img_outer{
  margin-bottom: 13px;
}

.image_skeleton {
  border: none !important;
}

.slide_btn {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  .btn {
    background: #ffffff;
    border: none;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s ease-in-out;
    position: absolute;
    padding: 0;
  }
}
.prev_btn {
  border-radius: 0 4px 4px 0;
  left: 0px;
}
.next_btn {
  border-radius: 4px 0px 0 4px;
  right: 0px;
}

.col-md-9.left_form_sec{
  padding-right:20px;
  width:calc(100% - 168px);
  flex: 0 0 calc(100% - 168px);
}

.add_item_carousal_section{
  margin-left:20px;
  width: 138px;
  flex: 0 0 138px;
  padding:0;

  .custom-checkbox-group  .checkbox-button-label{
    color: #96999F;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@include phonel {
  .add_product_row {
    flex-direction: column;

    .add_item_carousal_section {
      order: 1;
      width: 100%;
      flex: 0 0 100%;
      margin-left:0px;
      padding-left:15px;
      padding-right:15px;
    }

    .left_form_sec {
      order: 2;
      width: 100%;
      flex: 0 0 100%;
    }
  }
  .mobile_image {
    width:100px;
    height:100px;
    img{
      width:100%;
      height:100%;
    }
  }
}

.btn_outline {
  border: 1px solid #D0D5DD;
  height: 32px;
  background: #fff;
  padding: 0 20px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  color:$fc-primary;
  span{
    display: inline-flex;
    align-items: center;
    margin-right:6px;
    position: relative;
    top:-1px;
  }
  &:hover{
    border-color:$fc-primary;
  }
}

@include phonel {
  .modal-header .pos_ui .heading3 {
    font-size: 1.375rem;
    line-height: 27px;
    position: absolute;
    width: 60%;
    display: inline-flex;
    left: 45px;
    top: 59px;
    @media (max-width:599px){
      left:20px;
      width: 55%;
    }
  }
}
.mobile_wrap{
  max-width: 330px;
  @media (max-width:450px){
    max-width: 312px;
      margin: auto;
    @media (max-width:370px){
      max-width: 297px;

    }
  }

}
.mobile_thumbnail_wrap{
  display: grid;
  grid-template-columns: auto auto auto;
  max-width: 330px;
  margin: auto;
  margin-right: -16px;
  @include phonel{
    @media (max-width:450px){
      max-width: 312px;
      margin-right: -10px;
      @media (max-width:370px){
        max-width: 297px;
        margin-right: -10px;

      }
    }
  }
  .thumbnail_card{
    width: 94px;
    height: 94px;
    margin-right: 16px;
    margin-bottom: 16px;
    position: relative;
    border:2px solid transparent;
    @media (max-width:450px){
      width:90px;
      height:90px;
      margin-right: 14px;
    }

    @media (max-width:370px){
      width:85px;
      height:85px;
      margin-right: 14px;
    }

    //&:nth-child(3n){
    //  margin-right:0;
    //
    //  @media (max-width:390px){
    //    margin-right:16px;
    //  }
    //}

    &.image_checked{
      border:2px solid #12B76A;
    }
    .check_icon{
      position: absolute;
      right: -11px;
      bottom: -10px;

    }
    img{
      width:100%;
      height:100%;
    }
  }
}


.padding0 {
  padding: 0;
  .modal_body{
    padding:0 40px;
    @media (max-width:450px){
      padding:0 20px;
    }
  }
  @media (max-width:599px){
    .modal-header{
      padding:56px 40px 0;
    }
  }
  @media (max-width:450px){
    .modal-header{
      padding:56px 20px 0;
    }
  }
}
.center-ui{
 .custom-checkbox-group {
   justify-content: center;
    .checkbox-button-label {
    margin-top: 3px;
   }
  }
}


.loader_wrapper{
  border-radius:6px;
}

.add_item_cancel_btn{
  display: none;
  @include phonel{
    display: inline-block;
  }
}

.p_sticky_mob{
  position: sticky!important;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #ffffff!important;
}

.pickup_modal{
  .p-dialog-content{
    max-height: calc(100vh - 50px);
  }
  .modal-content{
    .modal-header{
      .heading_wrap{
        .heading3{
         margin-bottom: 15px;
          @include phonel{
            font-size: 22px;
            margin-bottom: 16px;
          }
        }
      }
    }
    .modal-body{
      .modal-body-description{
        .body_desc_inner{
          .pickup-content_desc{
            color: #666;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 32px;
            @include phonel{
              font-size: 16px;
              margin-bottom: 34px;
              color: #444444;
            }
            span{
              font-style: italic;
            }
          }
          .order-detail-container{
            margin-bottom: 27px;
            @include phonel{
              margin-bottom: 25px;
            }
            .brand-heading{
              color: #444;
              text-align: center;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
              &.o_number{
                font-weight: 400;
              }
            }

          }
          .pickup-detail-container{
            margin-bottom: 32px;
            text-align: center;
            @include phonel{
              margin-bottom: 25px;
            }
            &.schedule_pickup_btn{
              margin-top: -32px;


            }
            &.pick_location{
             @include phonel{
               padding-bottom: 25px;
             }
            }
            &.barcode-container{
              display: none;
              @include phonel{
                display: block;
                margin-bottom: 37px;
              }
            }
            &.arrived_for_pickup{
              display: none;
              @include phonel{
                padding-bottom: 15px;
                display: block;
              }
            }
            .inner-heading{
              color: #444;
              text-align: center;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 26px; /* 144.444% */
            }
            .inner-text{
              color: #444;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              &.hold-product{
                font-style: italic;
              }
              .call_for_pickup{
                color: #444;

              }
              &.barcode_desc{
                padding-bottom: 16px;
              }
            }
            .reschedule-btn{
              margin-top: 8px;
              .el-icon{
                margin-right: 7px;
                svg{
                  max-width: 24px;
                }
              }
              .el-text{
                text-align: center;
                font-size: 18px;
                font-style: normal;
                //font-weight: 700;
                line-height: 28px;
                text-decoration-line: underline;
              }

            }
            .schedule-btn{
              margin-top: 12px;
              .el-icon{
                margin-right: 5px;
                svg{
                  max-width: 20px;
                }
              }
              .el-text{
                text-align: center;
                font-size: 16px;
                font-style: normal;
                line-height: 20px;
                font-family: 'Helvetica Neue md';
              }
            }
            .barcode-inner{
              img{
                width: 198px;
                height: 99px;
              }

            }


          }


        }
      }
    }
    .modal-footer{
      .btn_primary{
        @include phonel{
          width: 100%;
          font-size: 18px;
        }
      }

    }

  }

}


//.mask-class-2 {
//  &.add_item_1 {
//    padding: 0px !important;
//   @include phonel{
//     animation: add-item-modal 200ms forwards;
//   }
//  }
//  &.pd_1 {
//    padding: 0px !important;
//  }
//}
//@keyframes add-item-modal {
//  from {top: 100%;
//  }
//  to {top: 0;
//  }
//}

/* animate css update start */
.animate_wrap_mask{
  padding:0 !important;
}

.p-component-overlay-enter{
  .animate_wrap{
    @include phonel{
      transform: translateZ(0) !important;
      &.ng-trigger {
        animation: animateUp 0.2s ease;
        padding: 0;
      }
      .mask-class-2{
        padding:0;
      }
    }
    &.ng-animating {
      animation: animateUp 0.2s ease;
    }
  }
}
.p-component-overlay-leave{
  .animate_wrap {
    &.ng-animating {
      animation: animateDown 0.2s ease;
    }
  }
}

.p-component-overlay-enter{
  .animate_wrap.preview_modal_wrapper {

    &.ng-animating {
      animation: animateupslide 0.2s ease;
    }
  }
}

.preview_wrap_mask{
  background:transparent !important;
}

@keyframes animateupslide {
  from {
    top:100%;
    transform:scale(1);
  }

  to {
    top:100%;
    transform:scale(1);
  }
}



@keyframes animateUp {
  from {
    top:100%;
  }

  to {
    top:0;
  }
}


@keyframes animateDown {
  to {
    top: 100%;
  }
  from {
    top: 100%;
  }
}


/* animate css update end */




.add_item_fh_step1{
  @include phonel{
    height: 100% !important;
    max-height: 100% !important;
    max-width: 100%!important;
  }
    .p-dialog-content{
     @include phonel{
       height: 100%;
       max-height: 100%;
       border-top-right-radius: 0px;
       border-top-left-radius: 0px;
     }
     &:last-of-type {
     @include phonel{
       border-bottom-right-radius: 0px;
       border-bottom-left-radius: 0px;
     }
    }
      &:after{
        display: none;
      }
    }

  .modal-content{
    .modal-header{
    @include phonel{
      padding: 53px 40px 0;
      @media (max-width: 600px) {
        padding: 53px 20px 0;
      }
    }
      .heading_wrap{
        .heading3{
          font-size: 26px;
          font-weight: 700;
          line-height: 32px;
          text-align: left;
          color: #444444;
          margin-bottom: 20px;
        }
      }
      .close-btn{
        &.for_desk{
          @include phonel{
            display: none;
          }
        }
        &.for_mob{
          display: none;
         @include phonel{
           width: 24px;
           height: 24px;
           display: flex;
           right: 38px;
           top: 15px;
           position: fixed!important;
           @media (max-width: 600px) {
             right: 18px;
             top: 15px;
           }
           svg{
             width: 19px;
             height: 19px;
           }
           &:hover {
             svg {
               transition: 0.2s ease-in-out;
               path {
                 stroke: $orange;
               }
             }
           }
         }
        }
        &.back_arrow{
          display: none;
          @include phonel{
            width: 24px;
            height: 24px;
            right: initial;
            left: 20px;
            display: flex;
            svg{
              width: 24px;
              height: 24px;
            }
            &:hover {
              svg {
                transition: 0.2s ease-in-out;
                path {
                  fill: $orange;
                  stroke: none;
                }
              }
            }

          }
        }
      }

    }
    .modal-body{
      .modal-body-description{
        .url_detail{
          display: inline-block;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #96999F;
          margin-bottom: 20px;
        }
        .add-item-url{
          margin-bottom: 0px;
          @include phonel{
            margin-bottom: 6px;
          }
        }
        .custom-input-1{
          padding-left: 50px;
          &.ng-invalid.ng-touched {
            border: 2px solid #E0E1E5;
            &:focus{
              border-color: #96999F;
            }
            &.show_error{
              border: 2px solid #EA1212;
            }
          }
        }
        .link_icons {
          position: absolute;
          display: inline-flex;
           align-items: center;
          left: 15px;
          top: 41px;
          height: 21px;
          width: 21px;
         @include phonel{
           display: inline-block;
           top: 35px;
         }
        }
      }
    }
    .error_msg_ui{
      min-height: 30px
    }
    .modal-footer{
      padding: 0px 15px 30px 15px;
     @include phonel{
       padding: 0px 45px 40px 45px;
       @media (max-width: 600px) {
         padding: 0px 20px 40px 20px;

       }
       .btn_primary{
         width: 100%;
         margin: 0px;
       }
     }

    }
    .modal_bottom{
      padding: 0px 45px 0;
      background: #E4EFF1;
      @include phonel{
        display: none;
      }
      .bottom_description{
        padding: 35px 0px;
        .bottom_inner_content{
          .shop_assistant_head{
            font-family: 'Helvetica Neue md';
            font-size: 18px;
            line-height: 21.98px;
            text-align: center;
            color: #444444;
            margin-bottom: 15px;
          }
          .extension_desc{
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: #444444;
            margin-bottom: 15px;

          }
          .install_ext_btn{
            display: flex;
            justify-content: center;

          }

        }

      }

    }

  }
}


.add_item_fh_step2{
 @include phonel{
   height: 100% !important;
   max-height: 100% !important;
 }
  .p-dialog-content{
   @include phonel{
     height: 100%;
     max-height: 100%;
     border-top-right-radius: 0px;
     border-top-left-radius: 0px;
   }
    &:last-of-type {
     @include phonel{
       border-bottom-right-radius: 0px;
       border-bottom-left-radius: 0px;
     }
    }
  }

  .modal-content{
    @include phonel{
      position: initial;
    }
    .modal-header{
     @include phonel{
       padding: 53px 20px 0;
       &:after{
         content: "";
         width: 100%;
         height: 53px;
         top: 0;
         position: fixed;
         background: #FFFFFF;
         z-index: 3;
       }
     }

      .heading_wrap{
        .heading3{
          @include phonel{
            &.heading_for_wish{
              @media (max-width: 460px) {
                font-size: 22px;
                @media (max-width: 400px) {
                  font-size: 21px;
                }
              }
            }
            font-size: 26px;
            font-weight: 700;
            line-height: 32px;
            text-align: left;
            color: #444444;
            margin-bottom: 20px;
            @media (max-width: 400px) {
              font-size: 23px;
              line-height: normal;

            }
          }

        }
      }
      .close-btn{
        &.for_desk{
          @include phonel{
            display: none;
          }
        }
        &.for_mob{
          display: none;
          @include phonel{
            width: 24px;
            height: 24px;
            display: flex;
            right: 38px;
            top: 15px;
            z-index: 4;
            position: fixed;
            @media (max-width: 600px) {
              right: 18px;
              top: 15px;

            }
            svg{
              width: 19px;
              height: 19px;
            }
            &:hover {
              svg {
                transition: 0.2s ease-in-out;
                path {
                  stroke: $orange;
                }
              }
            }
          }
        }
        &.back_arrow{
          display: none;
          @include phonel{
            width: 24px;
            height: 24px;
            right: initial;
            left: 40px;
            top: 15px;
            display: flex;
            z-index: 4;
            position: fixed;
            @media (max-width: 600px) {
              left: 20px;
              top: 15px;
            }
            svg{
              width: 24px;
              height: 24px;
            }
            &:hover {
              svg {
                transition: 0.2s ease-in-out;
                path {
                  fill: $orange;
                  stroke: none;
                }
              }
            }

          }
        }
      }

    }
    .modal-body{
      .modal-body-description{
        //.url_detail{
        //  display: none;
        //  @include phonel{
        //    display: inline-block;
        //    font-size: 18px;
        //    font-weight: 400;
        //    line-height: 24px;
        //    text-align: left;
        //    color: #96999F;
        //    margin-bottom: 20px;
        //  }
        //
        //}
        .input_for_product{
          @include phonel{
            padding-left: 50px;
          }
        }
        .link_icons{
          display: none;
          @include phonel{
            position: absolute;
            display: inline-block;
            left: 15px;
            top: 35px;
            height: 21px;
            width: 21px;
          }
        }
         .error_space{
          @include phonel{
            margin-bottom: 35px;
          }
         }
      }
    }
    .modal-footer{
     @include phonel{
       //position: fixed;
       //bottom: 0;
       //width: 100%;
       background: linear-gradient(177.41deg, rgba(255, 255, 255, 0) -8.9%, #FFFFFF 15.08%)!important;
       padding: 30px 45px 10px 45px ;
       @media  (max-width:600px){
       padding-left: 20px;
       padding-right: 20px;
       }
     }
     .add_item_btn_wrap{
       display: none;
       @include phonel{
         display: inline-block;
         width: 100%;
         .cart_btn_wrapper{
           display: flex;
           .btn_primary{
             width: 100%;
             margin: 0;
             .el-text{
               @media (max-width: 385px) {
                 font-size: 15px;
                 @media (max-width: 369px) {
                   font-size: 15px;
                 }
               }
             }

           }

         }
         .quote_wish_btn_wrapper{
           display: flex;
           margin-top: 15px;
           .add_item_quotes_btn{
             color: #444444;
             font-size: 16px;
             font-weight: 400;
             line-height: 19px;
             text-align: center;
             margin: 0;
             @media (max-width: 385px) {
               font-size: 15px;
               @media (max-width: 369px) {
                 font-size: 15px;
               }

             }

           }

         }
       }
     }

    }

  }

}

.filter_dropdown_sec{

  &.for_item_modal{
    width: 100%;
    margin-right: 20px;
    .add_item_quotes_btn{
      &:after{
        display: none;
      }
    }
    .dropdown-menu.show {
      opacity: 1;
      visibility: visible;
      //top: 0;
      transform: translate(0px, 0px) !important;
      transition: 0.2s ease-in-out;

    }
    .dropdown-menu{
      padding: 20px 35px 36px 35px;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
      border-radius: 24px 24px 14px 14px;
      width: 100%;
      position: fixed!important;
      inset: auto auto 0px 0px!important;
      margin: 0px;
      //transform: translate(0px, 0px) !important;
      left: 0!important;
      right: 0!important;
      top: 301px;
      max-height: 465px;
      transform: translate(0px, 900px) !important;
      @include phonel{
        max-height: 528px;
        padding: 25px 20px 20px 17px;
        border-radius: 24px 24px 0 0;
      }

      .dropdown_listing{
        .dropdown_heading{
          margin-bottom: 18px;
          position: relative;
          @include phonel{
            margin-bottom: 21px;
          }
          .inner_heading{
            font-family:$font_Souvenir_light;
            font-size: 30px;
            font-weight: 300;
            line-height: 36px;
            text-align: center;
            color: #444444;
          }
          .quote_inner_back_icon{
            position: absolute;
            top: 4px;
            left: -2px;
            width: 27px;
            height: 24px;
            cursor: pointer;
          }

        }
        .btn_wrapper{
          padding-top: 10px;
          @include phonel{
            padding-top: 25px;
          }
          .btn_primary{
            width: 100%;
            margin: 0;
            @include phonel{
              margin: 0 0 15px 0;
            }

          }
          .add_item_quotes_btn{

          }
        }
      }


      .p-listbox-header {
        padding: 0;
        background: #fff;
        position: relative;
        border-bottom: 0px solid $border-light;
        margin-bottom: 4px;
        @include phonel{
          margin-bottom: 16px;
        }
        .spacer22{
          display: none;
        }
        .p-listbox-filter-icon{
          color: #C7C7C7;
          position: absolute;
          top: 50%;
          line-height: 0!important;
        }
        .p-checkbox {
          display: none;
          &.p-checkbox-disabled{
            +  .p-listbox-filter-container{
              //.p-listbox-filter-icon{
              //  width:18px;
              //  height:18px;
              //  right: 0;
              //  top: 0;
              //  background: url("/assets/images/quotes/quotelist/filter_cross.svg");
              //}
            }
          }
        }
        .p-listbox-filter-container{
          input{
            border: 2px solid #e0e1e5;
            box-shadow: none;
            padding: 10px 50px 10px 16px;
            border-radius: 18px;
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 21px;
            height: 50px;
            color: #444444;
            width: 100%;
            &::-webkit-input-placeholder { /* Edge */
              color: $fc-hint;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: $fc-hint;
            }

            &::placeholder {
              color: $fc-hint;
            }

            &:focus{
              box-shadow: none;
              outline: none;
            }
          }
        }
      }
    }
    .p-listbox .p-listbox-list {
      padding: 6px 0 7px;
      li {
        padding: 8px 0px !important;
        color: #444444;
        font-weight: 400;
        font-size: 1rem;
        line-height: 22px;
        display: flex;
        align-items: flex-start;
      }
    }
    .p-listbox-list-wrapper {
      overflow: auto;
      max-height: 190px!important;
      min-height: 190px!important;
      @include phonel{
        max-height: 227px!important;
        min-height: 190px!important;

      }
    }
  }
}

.addItemLinkModal {
  .modal-content {
    .modal-footer {
      padding: 20px 45px 35px 45px;
      @include phonel{
        padding: 20px 45px 35px;
        @media (max-width:600px){
          padding: 20px 20px 35px;
        }
      }
      .edit-item_button_wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        @include phonel{
          flex-direction: column;
        }
        .cart_btn_wrapper{
          margin: 0px 10px 0px 0px;
          width: 100%;
          @include phonel{
            margin: 0px 15px 0px 0px;
          }
          .btn_primary{
            width: 100%;
            margin: 0;
            .el-text{
              font-size: 16px;
            }
          }

        }
        .quote_wish_btn_wrapper{
          display: flex;
          width: 100%;
          @include phonel{
            margin-top: 15px;
          }

          .filter_dropdown_sec {
            margin-right: 15px;
            .dropdown-toggle{
              &:after{
                display: none;
              }
              @include phonel{
                margin: 0;
              }
            }
            .btn_wrapper{
              width: 330px;
              margin: auto;
              @include phonel {
                width: 100%;
              }
              .add_item_quotes_btn{
                margin: 15px 0 0 0;
              }
            }

            .p-listbox {
              width: 330px !important;
              margin: auto;
              @include phonel {
                width: 100% !important;
              }
            }
          }

          .add_item_quotes_btn{
            color: #444444;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            text-align: center;
            margin: 0;
            width: 100%;
            padding: 0px 10px 0px 10px;
            min-width: 158px;
            @include phonel{
              min-width:125px;
            }

          }
        }

      }
    }
  }
}

.ftux_modal{
  width: 100%;
  max-width: 879px;
  &.p-dialog{
    height: initial;
    max-height: 698px;
  }
  .p-dialog-content{
    overflow-y: hidden;
    padding: 0 0 0px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    position: relative;
    box-shadow: 16px 24px 40px rgba(16, 24, 40, 0.25);
  }
  .modal-content{
    iframe{
      max-height: 700px;

    }
  }
}

.product_carousal_modal{
  .p-dialog{
    width: 100%;
    max-width: 572px;
  }
  .cart_quantity_section_modal{
    align-self: flex-end;
  }
  .modal-footer{
    @include phonel{
      padding-top: 50px !important;
    }
    .edit-item_button_wrap{
      flex-direction: column;
    }
    .cart_btn_wrapper{
      margin: 0px 0px 20px 0px!important;
      @include phonel{
        margin: 0px!important;
      }
    }
  }
  .heading_for_product{
    display: none;
    @include phonel{
      display: inline-flex;
    }
  }
}
.edit_item_product_carousal{
  .product_carousal_content{
    margin-bottom: 30px;
  }
  .product_modal_content{
    .brand_name{
      @include remy(16);
      font-weight: 400;
      line-height: 19.09px;
      text-align: left;
      color: #666666;
      margin-bottom: 4px;
    }
    .product_name{
      @include remy(20);
      font-weight: 700;
      line-height: 24.42px;
      text-align: left;
      color: #444444;
      margin-bottom: 9px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      &:hover{
        color: #006878;
      }
    }
    .save_and_off{
      font-size: 15px;
      font-weight: 400;
      line-height: 17.89px;
      text-align: left;
      color: #006878;
      margin-bottom: 4px;
    }
    .price{
      font-size: 18px;
      font-weight: 400;
      line-height: 21.47px;
      text-align: left;
      color: #006878;
    }
  }

}

.space_top_30{
  margin-top: 30px;
  @include phonel{
    margin-top: 25px;
    margin-bottom: 15px!important;
  }
}

.view_product_link{
  .el-text{
    font-size: 11px;
    font-weight: 500;
    line-height: 13.43px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
  }
  &.show_mobile{
    display: none;
    @include phonel{
      display: inline-flex;
    }

  }
  &.desk_show{
    margin-bottom: 30px;
    @include phonel{
      display: none;
    }

  }
}

.view_product_btn{
  justify-content: center;
  margin-top: 40px;
  @include phonel{
    margin-top: 20px;
  }
  .el-text{
    font-family: 'Helvetica Neue md';
    @include remy(15);
    font-weight: 500;
    line-height: 18.31px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
    @include phonel{
      @include remy(11);
    }
  }
  .el-icon svg {
    @include phonel{
      max-width: 13px;
    }
  }

}


.product_img_warapper{
  .product_inner_img{
    @include phonel{
      display: flex;
      justify-content: flex-end;
    }
    a{
      display: inline-block;
      img{
        width: 150px;
        @include phonel{
          width: 100px;
          height: 100px;
        }
      }
    }
  }

}



.add_space_modal{
  width: 100%;
  //max-width: 555px;
  max-width: 590px;

  .modal-header {
    .heading3 {
      margin-bottom: 53px;
    }
  }

  .modal-body{
    padding: 0 45px 0 45px;
    &.for_custom_space{
      padding: 0 20px 0 45px;
    }
    .modal-body-description {
      .form-group {
        margin-bottom: 35px;
        position: relative;
        &.space_for_error{
          margin-bottom: 45px;
          .error_msg_ui{
            .error_wrapper{
              .error-1{
                width: 66%;
                line-height: 18px;
              }
            }
          }
        }
        .char_count{
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0.004em;
          text-align: left;
          color: #C7C7C7;
          position: absolute;
          right: 35px;
        }
        .trans_icons{
          cursor: pointer;
          position: absolute;
          display: inline-flex;
          bottom: 17px;
          width: 20px;
          height: 20px;
          right: 5px;
          visibility: hidden;
          opacity: 0;
          svg path{
            transition: 0.3s ease-in-out;
          }
          &:hover{
            svg{
              path{
                stroke: #444444;
              }
            }
          }
        }
        .input_wrapper{
          padding-right: 35px;
          &:hover{
             .trans_icons{
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .modal-footer{
    .outline_btn{
      height: 45px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.09px;
      text-align: center;
      color: #444444;
      min-width: 100px;
      margin: 0 10px;
      padding: 0 23px;


    }
    .primary_btn{
      height: 45px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.09px;
      border-radius: 5px;
      text-align: center;
      color: #ffffff;
      min-width: 100px;
      padding: 10px 24px 10px 24px;
      margin: 0 10px;
    }
  }
}


.self_add_item{
  .modal-content{
    .modal-header{
      padding: 0px;
      &:after{
        display: none;
      }
      .heading_wrap{
        img{
          height: 225px;
        }
        .heading3{
          margin-top:38px;
          padding: 0px 45px;
          line-height: 22.4px;
        }
      }
    }
    .modal-body{
      .modal-body-description{
        .custom-input-1 {
          padding-left: 46px;
        }
        .link_icons{
          height: 18px;
          width: 18px;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .modal-footer{
      .primary_btn{
        height: 45px;
        padding: 5px 30px 5px 30px;
      }
    }
    &.from_any_store{
      .close-btn.for_desk{
        width: 25px;
        height: 22px;
        svg{
          width: 15px;
          height: 15px;
        }
      }
      .heading_wrap{
        .heading3{
          padding: 0 34px;
        }
      }
      .modal-body{
        padding: 0 34px;
      }
    }
  }

}


.self_add_item2{
  .p-dialog-content{
    overflow-y: initial;
  }
  .modal-body{
    .modal-body-description {
      .form-group {
        margin-bottom: 25px;
      }
      .add_item_right_container{
        max-width: 495px;
      }
      .tag_container{
        margin-bottom: 25px;
        .tag_inner{
          display: flex;
          align-items: center;
          gap: 54px;
        }
      }
    }

  }
  .modal-footer{
    .outline_btn{
      border-radius: 5px;
      height: 45px;
      padding: 10px 23px 10px 23px;
      min-width: 100px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.09px;
      text-align: center;

    }
    .primary_btn{
      height: 45px;
      padding: 5px 23px 5px 23px!important;
      min-width: 100px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      text-align: center;
    }
  }
}

.update_property_modal{
  max-width: 569px;
  width: 100%;
  &.del_modal{
    .modal-content{
      .modal-header{
        .heading_wrap{
          .heading3{
            margin-bottom: 14px;
            br{
            @include phonem{
              display: none;
            }
            }
          }
        }
      }
      .modal-footer{
        padding: 15px 15px 35px 15px;
        .btn_secondary{
          border-radius:5px;
          padding: 0px 21px 0px 21px;
          min-width: 125px;
        }
        .btn_primary{
          border-radius:5px;
          min-width: 125px;

        }
      }

    }

  }
  .modal-content{
    .modal-header{
      .heading_wrap{
        .heading3{
         @include remy(26);
          font-weight: 700;
          line-height: 32px;
          text-align: left;
          margin-bottom: 16px;
          color: #444444;
        }
      }
    }
    .modal-body{
      .modal-body-description{
        padding-bottom: 16px;
        .text_des{
          @include remy(18);
          font-weight: 400;
          line-height: 28px;
          text-align: left;
          color: #444444;
          span{
            font-family: 'Helvetica Neue md';
          }
        }
      }
    }
    .modal-footer{
      .btn_secondary{
        border-radius:5px;
        padding: 0px 21px 0px 21px;
      }
      .btn_primary{
        border-radius:5px;
        min-width: 228px;

      }
    }
  }
}

.showroom_share_modal{
  max-width: 353px;
  width: 100%;
  .modal-content{
    .modal-header{
      padding: 40px 20px 0;
      .heading3{
        font-size: 22px;
        font-weight: 700;
        line-height: 26.86px;
        text-align: left;
        color: $fc-primary;
        word-break: break-word;
        margin-bottom: 15px;
        display: inline-block;
        max-width: 293px;
      }
      .close-btn{
        width: 24px;
        height: 24px;
        background: transparent;
        z-index: 3;
      }
    }
    .modal-body{
      padding: 0px 20px 0;
      .modal-body-description{
        .text_des{
          @include remy(15);
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          word-break: break-word;
          color: #96999F;
          text-decoration: underline;
          text-underline-offset: 3px;
          max-width: 293px;
          display: inline-block;
        }
        .share_btn_wrapper{
          padding-top: 20px;
          .share_btn_modal{
            margin-right: 29px;
          }
          .link-primary-btn{
            .el-text{
              line-height: 18.31px;
              text-align: left;
            }
          }

        }
        .share_content_wrapper{
          display: flex;
          justify-content: center;
          padding-top: 20px;
          .qr_image_wrapper{
            width: 245px;
            img{
              width: 100%;
            }
          }
        }
        .save_camera_btn{
          display: flex;
          justify-content: center;
          padding-top: 10px;
          .link-primary-btn{
            .el-text{
              line-height: 18.31px;
              text-align: left;
            }
          }
        }
      }
    }
    .modal-footer align-self-center{
      padding: 20px 15px 28px 15px;
    }

  }
}

@import "/src/assets/scss/helpers/mixins";
@import "/src/assets/scss/base/colors";


//Custom Input field
.custom-input-1{
  @include dib100;
  background-color: $fc-white;
  border: 2px solid $border-light;
  height: 50px;
  border-radius: 4px;
  padding: 0px 14px;
  font-style: normal;
  font-weight: 400;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  @include remy(18);
  line-height: 21px;
  color: $fc-primary;
  &::placeholder{
    font-style: normal;
    font-weight: 400;
    @include remy(18);
    line-height: 21px;
    color: $fc-hint;
    transition:color 0.2s ease-in-out;
    opacity: 0.9;

  }
  &:focus{
    box-shadow: none;
    border-color: #96999F;
    &::placeholder{
      color: transparent;
    }
  }
  &.ng-touched{
    &.ng-invalid{
      border: 2px solid $fc-red;
      &::placeholder{
        color:$fc-hint;
      }
      color:$fc-primary;
    }
  }
  &:disabled{
    background-color: #F5F5F5;
    border: none;
    &::placeholder{
      color: #96999F;
    }
  }
  &.custom-text-area{
    height: 90px;
    resize: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 50px white inset !important;
    background:#fff !important;
    transition: 0.01s ease-in-out;
  }
}
.cl-error{
  display: flex !important;
  justify-content: space-between;
  .error-1{
    width: auto;
    &:last-child{
      padding-left: 5px;
    }
  }
}

//Form Group
.form_block{
  margin-bottom: 60px;
  .row.mb-0{
    .form-group{
      margin-bottom: 0px !important;
    }
  }
}

.fc-pink{
  color: $fc-pink;
  margin-right: 5px;
}
.form-group{
  @include dib100;
  .form-label{
    @include dib100;
    font-style: normal;
    font-weight: 700;
    @include remy(14);
    line-height: 17px;
    color: $fc-primary;
    margin-bottom: 7px;
    .light_ui{
      font-weight:400;
      display: inline-flex;
      margin-left: 5px;
    }


  }
  .fg-inner{
    @include dib100;
    margin-bottom: 4px;
    vertical-align: top;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .form-label{
    display: flex;
    align-items: center;
    .info_icon{
      margin-left: 7px;
      display: inline-flex;
      cursor: pointer;
    }
  }

  .form-control{
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .form-label-description{
    font-weight: 400;
    @include remy(16);
    line-height: 19px;
    margin-bottom: 17px;
  }
}


//Error Message in form
.error-1{
  @include dib100;
  font-style: normal;
  font-weight: 400;
  @include remy(14);
  line-height: 20px;
  color: $fc-red;
  @media (max-width:450px){
    @include remy(12);
  }
  @media (max-width:400px){
    @include remy(12);
  }
}

//Custom input group
.custom-input-group{
  .input-group-text{
    background-color: #FFFFFF;
    border-width: 2px;
    border-color: $border-light;
    border-right: 0px;
    font-style: normal;
    font-weight: 400;
    @include remy(20);
    line-height: 24px;
    color: $fc-hint;
    border-radius: 4px;
    border-radius:4px 0 0 4px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .custom-input-1{
    border-left: 0;
    order: 2;
    border-radius:0 4px 4px 0 !important;
    &:focus{
      ~.input-group-text{
        border-color: #96999F;
      }
    }
  }
  &.doller_ui{
    .custom-input-1{
      padding-left: 0;
    }
    .input-group-text{
      padding-right: 8px;
    }
  }
}


//Datepicker
.custom-datepicker{
  height: 50px!important;
  .p-inputtext{
    border: 2px solid $border-light;
    border-right: 0px;
    border-radius: 4px;
    &:hover{
      border: 2px solid $border-light;
      border-right: 0px;
    }
    &::placeholder{
      font-style: normal;
      font-weight: 400;
      @include remy(18);
      line-height: 21px;
      color: $fc-hint;
    }
  }
  .p-button{
    background-color: transparent;
    &:hover{
      background-color: transparent;
    }
    &:enabled{
      border: 2px solid $border-light;
      border-left: 0;
      border-radius: 0px 4px 4px 0px;
      &:hover{}
    }
    &:focus{
      box-shadow: none;
    }
    .p-button-icon{
      color: $fc-hint;
      @include remy(20);
    }
  }
  &.p-focus{
    .p-inputtext{
      box-shadow: none;
      border-color: $fc-secondary;
      ~.p-button{
        border-color: $fc-secondary;
      }
      &::placeholder{
        color: transparent;
      }
    }

  }
}




//Single Select Dropdown

.user_role_dropdown{
  .ng-touched.ng-invalid {
    .singleSelectDropdown{
      border: 2px solid #EA1212;
      &:hover{
        border: 2px solid #EA1212;
      }
    }
  }
}

.singleSelectDropdown{
  border: 2px solid $border-light;
  border-radius: 4px;
  min-width: 100px;
  width: 100%;
  @include transition2s;
  &:not(.p-disabled){
    &:hover{
      border: 2px solid $border-light;
    }
    &.p-dropdown-open{
      &.p-focus{
        box-shadow: none;
        border: 2px solid #96999F;
        .p-dropdown-label{
          &.p-placeholder{
            opacity: 0;
          }
        }
        .p-dropdown-trigger-icon{
          transform: rotate(180deg);
        }
      }
    }
    &.p-focus{
      box-shadow: none;
      border: 2px solid $border-light;
    }
  }
  .p-dropdown-label{
    font-style: normal;
    font-weight: 400;
    @include remy(18);
    line-height: 21px;
    color: $fc-primary;
    &.p-placeholder{
      font-style: normal;
      font-weight: 400;
      @include remy(18);
      line-height: 21px;
      color: $fc-hint;
    }
  }
  .p-dropdown-trigger-icon{
    @include remy(12);
  }
  .p-dropdown-panel{
    height: auto;
    max-height: 196px;
    overflow-y: auto;
    border: 2px solid $border-light;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    .p-dropdown-items {
      padding: 0px;
      .p-dropdown-item{
        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 22px;
        color: $fc-primary;
        padding: 10px 15px;
        background: transparent;
        &:not(.p-highlight){
          &:not(.p-disabled){
            &:hover{
              //background-color: rgba(85, 146, 160, 0.1);
              background-color: rgba(85, 146, 160, 0.04);
            }
          }
        }
      }
    }
  }
}

.singleselect-wrapper {
  .ng-touched {
    &.ng-valid {

    }

    &.ng-invalid {
      .singleSelectDropdown {
        border-color: $fc-red;
      }
    }
  }
}

//Multi Select Dropdown

.property_access_dropdown{
  .ng-touched.ng-invalid {
    .multiSelectDropdown{
      border: 2px solid #EA1212 !important;
      &:hover{
        border: 2px solid #EA1212;
      }
    }
  }
}

.multiselect-ui-outer{
  @include dib;
  position: relative;
  .multi-select-count{
    display: flex;
    align-items: center;
    height: 32px;
    width: 32px;
    background-color: $teal-tint;
    position: absolute;
    font-style: normal;
    font-weight: 400;
    @include remy(18);
    line-height: 21px;
    color: #FFFFFF;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    justify-content: center;
    border-radius: 4px;
  }
}

.multiSelectDropdown{
  border: 2px solid $border-light;
  border-radius: 4px;
  min-width: 300px;
  width: 100%;
  max-width: 300px;
  @include phonem{
    min-width: 100%;
  }
  &.p-multiselect{
    .p-multiselect-trigger{
      .p-multiselect-trigger-icon{
       @include remy(12);
      }
    }
    &:not(.p-disabled){
      &:hover{
        border: 2px solid $border-light;
      }
    }

    &.p-multiselect-open{
      border: 2px solid $border-dark;
      &:not(.p-disabled){
        &:hover{
          border: 2px solid $border-dark;
        }
      }
      .p-multiselect-trigger{
        .p-multiselect-trigger-icon{
          transform: rotate(180deg);
        }
      }
    }
    .p-multiselect-label{
      font-style: normal;
      font-weight: 400;
      @include remy(18);
      line-height: 21px;
      color: $fc-hint;
      padding-left: 18px;
    }
  }

  .p-multiselect-panel{
    border: 2px solid #E0E1E5;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    //max-height: 196px;
    .p-multiselect-header{
      background-color: transparent;
      padding: 0;
      border: none;
      .p-checkbox{
        display: none;
        .p-checkbox-box{
          box-shadow: none;
          border: none;
        }
      }
      .p-multiselect-close{
        display: none;
      }
      .p-multiselect-filter-container{
        .p-multiselect-filter{
          border: none;
          border-bottom: 2px solid $border-light;
          border-radius: 0;
          padding: 0px 15px;
          height: 50px;
          font-size:18px;
          &::placeholder {
            color:$fc-hint;
          }
          &:enabled{
            &:focus{
              box-shadow: none;
            }
          }
        }
        .p-multiselect-filter-icon{
          transform: translateY(-50%);
        }
      }
    }
    .p-multiselect-items-wrapper{
      max-height: 146px!important;
      .p-multiselect-items{
        padding: 0;
        .p-multiselect-item{
          padding: 0.75rem 17px;
          background: transparent;
          .p-checkbox{
            margin-right:0.5rem
          }
          &:focus{
            box-shadow: none;
          }
          &:hover{
            background-color: rgba(85, 146, 160, 0.1);
          }
          .p-checkbox{
            .p-checkbox-box{
              border: 2px solid $border-light;
              width: 20px;
              height: 20px;
              border-radius: 4px;
              top: 2px;
              position: relative;
            }
          }
          &.p-highlight,
          &[data-p-highlight="true"]{
            background-color: #FFFFFF;
            .p-checkbox{
              .p-checkbox-box{
                border: 2px solid $teal-tint;
                background-color: $teal-tint;
                &:disabled{
                  opacity: 0.4;
                }
              }
            }
          }

          >span{
            color: $fc-primary;
            @include remy(16);
            line-height: 22px;
          }
        }
      }
    }
  }
}

.pi-search{
  content: "";
  background: url("/assets/images/search_icon.svg");
  width:22px;
  height:22px;
  font-size:0;
  right:16px !important;
  margin-top: 0 !important;
  transform: translateY(-50%);
  &:before{
    font-size: 0;
  }
}
.form-group{
  .p-inputwrapper-focus{
    .multiSelectDropdown{
      border: 2px solid $border-dark !important;
    }
    .p-multiselect-trigger{
      transform: rotate(180deg);
    }
  }
}

.pi-chevron-down{
  content: "";
  background: url("/assets/images/up_angel_icon.svg") no-repeat;
  width:12px;
  height:7px;
  font-size:0;
  right:16px !important;
  margin-top: 7px !important;
  transform: translateY(-50%);
  &:before{
    font-size: 0;
  }
}
.p-multiselect-open{
  .pi-chevron-down{
    //margin-top:0px !important;
  }
}

.p-inputwrapper-filled,
.p-inputwrapper-focus{
  .pi-chevron-down{
   // transform: rotate(180deg);
   // margin-top: 0 !important;
  }
}
.p-dropdown-open{
  .pi-chevron-down{
    transform: translateY(-50%) rotate(180deg);
    //margin-top: 0 !important;
  }
}

.singleselect-wrapper{
  .p-inputwrapper-filled,
  .p-inputwrapper-focus{
    .pi-chevron-down{
      transform:initial;
      margin-top:initial !important;
    }
  }
}

//To change the color of label if any item selected in multiselect dropdown
.p-inputwrapper-filled{
  .multiSelectDropdown{
    &.p-multiselect{
      .p-multiselect-label{
        color: $fc-primary;
      }
    }
  }
}


//Custom border for half splitted ui
.custom-input-group-primeng{
  height: 50px;
  &.h56{
    height: 56px;
    border: 0px solid $fc-white;
    //box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);

    .p-inputtext {
      border: 2px solid $fc-white;
      border-right: 0px;
      border-radius: 4px 0px 0px 4px;
      padding-left: 16px;
      &:hover{
        border: 2px solid $fc-white;
        border-right: 0px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
      }
      &:enabled{
        &:focus{
          border: 2px solid $fc-white;
          border-right: 0px;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
          ~.p-button{
            border-color: $fc-white;
          }
        }
      }
      &::placeholder{
        font-style: normal;
        font-weight: 400;
        @include remy(18);
        line-height: 21px;
        color: $fc_gray400;
      }
    }

    .p-button{
      background-color: $fc-white;
      &:hover{
        background-color: $fc-white;
      }
      &:enabled{
        border: 2px solid $fc-white;
        //border-left: 0;
        border-radius: 0px 4px 4px 0px;
        &:hover{}
      }
      &:focus{
        box-shadow: none;
      }
      .p-button-icon{
        color: $fc-red;
        @include remy(12);
      }
    }
    &.p-inputwrapper-focus{
      .p-inputtext{
        box-shadow: none;
        border-color: $fc-white;
        ~.p-button{
          border-color: $fc-white;
        }
        &::placeholder{
          color: transparent;
        }
      }

    }
    .p-autocomplete-panel{
      border: 2px solid $border-light;
      filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2));
      border-radius: 4px;
      max-height: 196px!important;
      .p-autocomplete-items{
        padding: 0;
        .p-autocomplete-item{
          color: $fc-primary;
          padding: 10px 18px;

          &:hover{
            background-color: rgba(85, 146, 160, 0.1);
          }
          .list-item-ui{
            >div{
              font-style: normal;
              font-weight: 400;
              @include remy(16);
              line-height: 21px;
              color: $fc-primary;
            }
          }
        }
      }
    }
  }
  .p-inputtext{
    border: 2px solid $border-light;
    border-right: 0px;
    border-radius: 4px;
    &:hover{
      border: 2px solid $border-light;
      border-right: 0px;
      box-shadow: none;
    }
    &:enabled{
      &:focus{
        border: 2px solid $border-dark;
        border-right: 0px;
        box-shadow: none;
        ~.p-button{
          border-color: $fc-secondary;
        }
      }
    }
    &::placeholder{
      font-style: normal;
      font-weight: 400;
      @include remy(18);
      line-height: 21px;
      color: $fc-hint;
    }
  }

  .p-button{
    background-color: $fc-white;
    &:hover{
      background-color: $fc-white;
    }
    &:enabled{
      border: 2px solid $border-light;
      border-left: 0;
      border-radius: 0px 4px 4px 0px;
      &:hover{}
    }
    &:focus{
      box-shadow: none;
    }
    .p-button-icon{
      color: $fc-red;
      @include remy(12);
    }
  }
  &.p-inputwrapper-focus{
    .p-inputtext{
      box-shadow: none;
      border-color: $fc-secondary;
      ~.p-button{
        border-color: $fc-secondary;
      }
      &::placeholder{
        color: transparent;
      }
    }

  }
  .p-autocomplete-panel{
    border: 2px solid $border-light;
    filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2));
    border-radius: 4px;
    max-height: 196px!important;
    .p-autocomplete-items{
      padding: 0;
      .p-autocomplete-item{
        color: $fc-primary;
        padding: 10px 18px;
        &:hover{
          background-color: rgba(85, 146, 160, 0.1);

        }
        .list-item-ui{
          >div{
            font-style: normal;
            font-weight: 400;
            @include remy(16);
            line-height: 22px;
            color: $fc-primary;
          }
        }
      }
    }
  }
}




//Radio button UI
.radio-button-group{
  @include dib100;
  .radio-button-label{
    @include dib;
    font-style: normal;
    font-weight: 400;
    @include remy(16);
    line-height: 19px;
    color: $fc-primary;
    padding-left: 11px;
    cursor: pointer;
  }
  .custom-radio-button{
    margin-right: 0px;
    width: 20px;
    height: 20px;
    .p-radiobutton-box{
      width: 20px;
      height: 20px;
      border: 2px solid $border-light;
      &:not(.p-disabled){
        &:hover{
          border: 2px solid $border-light;
        }
      }
      &.p-focus{
        box-shadow: none;

      }
    }
    &.p-radiobutton-checked{
      .p-radiobutton-box {
        border-color: $teal-tint;
        background-color: #FFFFFF;
        &:not(.p-disabled) {
          &:hover {
            background-color: #FFFFFF;
            border-color: $teal-tint;
          }
        }
        .p-radiobutton-icon{
          background-color: $teal-tint;
        }
      }
    }
  }
}


//Custom Checkbox UI
.custom-checkbox-group{
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  &:last-child{
    margin-bottom: 0;
  }
  .checkbox-button-label{
    @include dib;
    font-style: normal;
    font-weight: 400;
    @include remy(16);
    line-height: 19px;
    color: $fc-primary;
    cursor: pointer;
    word-break: break-word;
    align-items: flex-start;
    padding-left: 11px;
    cursor: pointer;
  }
  .custom-checkbox-button{
    margin-right: 0px;
    width: 20px;
    height: 20px;
    .p-checkbox-box{
      width: 20px;
      height: 20px;
      border: 2px solid $border-light;
      border-radius: 4px;
      &.p-focus{
        box-shadow: none;
      }
    }
    &.checkboxsm{
      height: 13px;
      width: 13px;
      @include tabm{
        height: 20px;
        width: 20px;
      }
      .p-checkbox-box{
        width: 13px;
        height: 13px;
        border-radius: 2px;
        align-items: flex-start;
        display: inline-flex;
        margin-top: 3px;
        .p-checkbox-icon {
          transition-duration: 0.2s;
          color: #ffffff;
          font-size: 9px;
          background-position: center;
          background-size: 8px;
          @include tabm{
            background-size: 14px;
            //position: relative;
            width: 14px;
            height: 11px;
            font-size: 14px;
            top: 3px;
          }
        }
        @include tabm{
          height: 20px;
          width: 20px;
          margin-top: 0px;
        }
      }
    }
    &.p-checkbox-checked{
      .p-checkbox-box{
        border-color: $teal-tint;
        background-color: $teal-tint;
        &:hover{
          background-color: $teal-tint;
          border-color: $teal-tint;
        }
      }
      &.p-checkbox-focused{
        .p-checkbox-box{
          border-color: $teal-tint;
        }
      }
    }
    &:hover{
      .p-checkbox-box{
        border-color: $border-light;
      }
    }
    &.p-checkbox-focused{
      .p-checkbox-box{
        border-color: $border-light;
      }
    }
  }
}






// Cstm input number

.cstm-input-number {
  .p-inputnumber {
      min-width: 114px;
      border: 2px solid #E0E1E5;
    border-radius: 4px;
    height:50px;
    @include phonel{
      min-width: 160px;
    }
    .p-inputtext{
      width:30px;
      border: none;
      outline: none;
      box-shadow: none;
      height:45px;
      text-align: center;
      padding: 0;
      color:$fc-primary;
      &:focus{
        outline: none;
      }
    }
    .p-inputnumber-button{
      background: transparent;
      border: none;
      color:$fc-primary;
      width:40px;
      &:hover,
      &:focus{
        background: transparent;
        color:$fc-primary;
      }
      .pi-minus{
        color: $border-light;
        &:hover{
        color:$border-dark;
        }
      }
      .pi{
        font-size:14px;
      }
    }
  }

  .p-inputwrapper-focus{
    .p-inputnumber{
        border-color: $border-dark;
    }
  }
}

/*checbox css*/
.checkbox_wrapper{
    .checbox-label{
      font-style: normal;
      font-weight: 700;
      @include remy(14);
      line-height: 17px;
      margin-bottom: 6px;
      color:$fc-primary;
    }
  .info_text{
    font-style: normal;
    font-weight: 400;
    @include remy(14);
    line-height: 17px;
    color: $fc_gray400;
    margin-bottom: 20px;
  }

  .custom-checkbox-group{
    margin-bottom: 13px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.radio-button-group{
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  &:last-child{
    margin-bottom: 0;
  }
}

.heading5{
  .cstm_tooltip{
    display: inline-flex;
    top:3px;
    left:2px;
  }
}

/*tooltip css*/
.cstm_tooltip{
  position: relative;
  display: flex;
  .p-tooltip{
    min-width: 285px !important;
    top:-140px !important;
  }
  .p-tooltip-text{
    background: $teal-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 16px 20px;
    border-radius: 0;
  }
  .p-tooltip-arrow{
    border-top-color: $teal-primary !important;
    margin-left: -1rem !important;
    border-width: 1em 1em 0 !important;
    bottom:-5px;
  }
  h6{
    display: inline-flex;
    padding: 10px;
  }
}


.input_number_wrap{
  .form-label{
    margin-bottom: 16px ;
  }
}



.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
  background: url("/assets/images/icon_check.svg");
  background-repeat: no-repeat;
  position: relative;
  width: 13px;
  height: 10px;
  background-position: center;
  &:before{
    display: none;
  }
}


.radio-btn-wrapper{
  .form-label{
    margin-bottom: 16px;
    display: inline-block;
    .fc-pink{
      margin:0 5px;
    }
  }
}

.delete_btn{
  border: none;
  background: none;
}


.phone-field-wrapper{
  background: none;
  border: none;
  padding: 0;
  &.ng-touched{
    &.ng-invalid{
      border: none;
      .p-inputtext {
        border: 2px solid $fc-red;

        &::placeholder {
          color:$fc-hint;
        }

        color:$fc-primary;

        &:focus{
          border-color:$fc-red !important;
        }
      }
      &:hover{
        .p-inputtext{
          border-color:$fc-red;
        }
      }
    }
  }
  .p-inputtext{
    @include dib100;
    background-color: $fc-white;
    border: 2px solid $border-light;
    height: 50px;
    border-radius: 4px;
    padding: 0px 14px;
    font-style: normal;
    font-weight: 400;
    @include remy(18);
    line-height: 21px;
    color: $fc-primary;
    &::placeholder{
      font-style: normal;
      font-weight: 400;
      @include remy(18);
      line-height: 21px;
      color: $fc-hint;
    }
    &:focus{
      box-shadow: none;
      border-color: #96999F !important;
      &::placeholder{
        color: transparent;
      }
    }

    &:disabled{
      background-color: #F5F5F5;
      border: none;
      &::placeholder{
        color: #96999F;
      }
    }
    &.custom-text-area{
      height: 90px;
      resize: none;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.cstm-radio-ui{
  input{
    position: absolute;
    left:0;
    top:0;
    opacity:0;
    visibility: hidden;
    &:checked + .radio-button-label{
      &:after{
        opacity: 1;
        transform: translateZ(0) scale(1.0, 1.0);
        visibility: visible;
        @include transition(0.2s ease-in-out);
      }
      &:before{
        border-color: $teal-tint;
      }
    }
  }
  .radio-button-label{
    position: relative;
    padding-left: 31px;
    &:before{
      position: absolute;
      content: "";
      left:0;
      top:0;
      width: 20px;
      height: 20px;
      border: 2px solid #E0E1E5;
      display: inline-flex;
      border-radius: 100%;
      @include transition(0.2s ease-in-out);
    }
    &:after{
      position: absolute;
      content: "";
      left:4px;
      top:4px;
      width:12px;
      height:12px;
      background: $teal-tint;
      display: inline-flex;
      border-radius: 100%;
      opacity:0;
      visibility: hidden;
      transform: translateZ(0) scale(.1);
      @include transition(0.2s ease-in-out);
    }
  }
}




.tooltip_outer{
  .p-tooltip{
    max-width: 331px;
    width: 331px !important;
    @media (max-width:450px){
      max-width: 280px;
      width: 280px !important;
      @media (max-width:400px){
        max-width: 225px;
        width: 225px !important;
      }
    }
    .p-tooltip-text{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      background: #292929;
      border-radius:2px;
      padding:16px 14px 16px 15px;
      @include phones{
        padding: 16px 10px 16px 10px;
        font-size: 12px;
      }
    }
    &.p-tooltip-top {
      @media (min-width: 1050px) {
        left: -228px !important;
      }
      .p-tooltip-arrow {
        bottom: -6px;
        left: 50%;
        margin-left: -.25rem;
        border-width: 0.65em 0.45em 0;
        border-top-color: #292929;
        @media (min-width:1050px){
          left: 71%;
        }
      }
    }

    &.p-tooltip-left .p-tooltip-arrow{
      border-left-color:#292929;
      border-width:0.45em 0 0.45em 0.65rem;
      right: -6px;
    }

  }
  &.quote_drop{
    left: 0px!important;
    top: 6px!important;
    width: 238px!important;
  }
}
.acc-tooltip_outer{
  .p-tooltip{
    max-width: 301px;
    width: 301px !important;
    @media (max-width:450px){
      max-width: 280px;
      width: 280px !important;
      @media (max-width:400px){
        max-width: 225px;
        width: 225px !important;
      }
    }
    .p-tooltip-text{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      background: #292929;
      border-radius:2px;
      padding:16px 4px 16px 15px;
      @include phones{
        padding: 16px 10px 16px 10px;
        font-size: 12px;
      }
    }
    &.p-tooltip-top {
      @media (min-width: 1050px) {
        left: 0px !important;
        top:-80px !important;
      }
      .p-tooltip-arrow {
        bottom: -6px;
        left: 50%;
        margin-left: -.25rem;
        border-width: 0.65em 0.45em 0;
        border-top-color: #292929;
        @media (min-width:1050px){
          left: 71%;
        }
      }
    }

    &.p-tooltip-left .p-tooltip-arrow{
      border-left-color:#292929;
      border-width:0.45em 0 0.45em 0.65rem;
      right: -6px;
    }
    &:hover{
      visibility: visible;
    }
  }
}


.property_tooltip_wrapper{
  position: relative;
  .p-tooltip{
    max-width: 256px;
    width: 256px !important;
    @media (max-width:450px){
      max-width: 256px;
      width: 256px !important;
      @media (max-width:400px){
        max-width: 225px;
        width: 225px !important;
      }
    }
    .p-tooltip-text{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      background: #292929;
      border-radius:2px;
      padding:12px 6px 12px 12px;
      @include phones{
        padding: 16px 10px 16px 10px;
        font-size: 12px;
      }
    }
    &.p-tooltip-top {
      @media (min-width: 1050px) {
        left: 0px !important;
        top:-80px !important;
      }
      .p-tooltip-arrow {
        bottom: -6px;
        //left: 50%;
        margin-left: -.25rem;
        border-width: 0.65em 0.45em 0;
        border-top-color: #292929;
        @media (min-width:1050px){
          //left: 71%;
        }
      }
    }

    &.p-tooltip-left .p-tooltip-arrow{
      border-left-color:#292929;
      border-width:0.45em 0 0.45em 0.65rem;
      right: -6px;
    }
    &:hover{
      visibility: visible;
    }
  }
}




.icon_tooltip{
  display: inline-block;
  &.p-tooltip {
    margin-top:-8px !important;
    .p-tooltip-arrow{
      border-width:10px 9px 0;
      margin-left:-9px;
      border-top-color:#1D2939;
      bottom:-5px;
    }
    .p-tooltip-text {
      background: #1D2939;
      border-radius: 2px;
      font-style: normal;
      font-weight: 400;
      @include remy(14);
      line-height: 18px;
      color: #fff;
      padding: 6px 8px;
    }
  }
}


.ac_main_wrapper .phone-field-wrapper .p-inputtext{
  @include remy(16);
  line-height: 19px;
  &::placeholder{
    font-weight: 400;
    @include remy(16);
  }
}

//property management

.multiselect_group_by{
  width: 100%;
  .multiSelectDropdown {
    border: 2px solid #E0E1E5;
    border-radius: 4px;
    min-width: 100%;
    width: 100% !important;
    max-width:100% !important;
    height:50px;
    .p-overlay{
      left: -2px !important;
    }
  }

  &.multiselect-ui-outer .multi-select-count{
    color: $fc-secondary;
    background:rgba(#E0E1E5 , 0.6);
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    @include remy(18);
    line-height: 21px;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0px 0px;
    margin-right: 0px;
    background: #ffffff;
    color:$fc-primary;
    border-radius: 6px;
    @include remy(16);
    line-height: 19px;
    position:relative;
    &:last-child{
      &:after{
        display: none;
      }
    }
    &:after{
      position: absolute;
      content: ",";
      right:3px;
      bottom: 0;
    }
  }
  .multiSelectDropdown .p-multiselect-panel {
    border: 2px solid #E0E1E5;
    box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
    border-radius: 4px;
    top:inherit !important;
    bottom:calc(100% + 8px)!important;
    transform-origin:center bottom !important;
    //width:calc(100% + 4px);
    left: -2px !important;
    .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter {
      border: none;
      border-bottom: 2px solid #E0E1E5;
      border-radius: 0;
      padding: 0px 16px;
      height: 50px;
      font-size: 18px;
    }
    .p-multiselect-items {
      padding-bottom:16px;
      .p-multiselect-empty-message{
        padding: .75rem 1.25rem;
        color: #495057;
        background: transparent;
      }
      .p-multiselect-item-group {
        margin: 0;
        padding: 10px 16px 5px 16px;
        background: #ffffff;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #444444;
      }
      .p-multiselect-item {
        padding: 5px 16px 5px 16px;
      }
    }
  }

  .multiSelectDropdown .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item {
    padding: 5px 16px 5px 16px;
    word-break: break-word;
    white-space: normal;
    display: flex;
  }


  .pi-times-circle:before {
    display: none;
  }

  .p-multiselect-label {
    display: block;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: .75rem;
  }
   .p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 12px 28px 10px 16px;
    align-items: center;
    height: 100%;
     &.p-placeholder{
       font-style: normal;
       font-weight: 400;
       @include remy(16);
       line-height: 21px;
       color: $fc-hint;
     }
  }

}
.property_access_dropdown .multiselect_group_by{
   .p-multiselect-items-wrapper{
    //max-height: 64px !important;
  }
}

.onboarding_form_wrap{
  .phone-field-wrapper .p-inputtext{
    @include remy(16);
    font-family:"Helvetica Neue";
    &::placeholder{
      @include remy(16);
    }
  }
}


.account_type_dropdown{
  margin: 0 -20px;
  display: none;
  @include phonel{
    display: block;
  }
  .singleSelectDropdown{
    border: none !important;
    border-bottom: 1px solid #E0E1E5 !important;
  }
  .p-dropdown-header{
    display: none;
  }
}

//.col-lg-5{
//
//}

.pac-container{
  background: #FFFFFF;
  border: 2px solid #E0E1E5;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-family: 'Helvetica Neue';
}
.pac-item > span:last-child {
  //display: none;
  //width: 24px !important;
  //display: inline-block;
  //overflow: hidden;
  //vertical-align: middle;
}
.hdpi.pac-logo:after{
  display: none !important;
}
.pac-item {
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  border:none;
  color:$fc-primary;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  font-size: 16px;
  font-family: 'Helvetica Neue';
  padding: 7px 14px;
  &:hover{
    background: rgba(#5592A0 , 0.1);
  }
   .pac-icon{
    background-image:url("/assets/images/account_mg/ic_map.svg") !important;
     width: 14px;
     height: 17px;
     background-size: 100%;
     margin: 0;
     background-position: center;
     top: 3px;
     position: relative;
     left: -1px;
     margin-right: 10px;
  }
  .pac-item-query{
    font-weight: 400;
    line-height: 22px;
    font-size: 16px;
    font-family: 'Helvetica Neue';
    color:$fc-primary;
  }
}


.onboarding_form_wrap{
  .form-group {
    position: relative;
    .fg-inner {
      margin-bottom: 0;
    }
  }
}


.account_type_dropdown{
  border-bottom: 1px solid #E0E1E5;
  .multiSelectDropdown{
    max-width: 100%;
    border: none;
    &.p-dropdown{
      box-shadow: none;
      border: none;
      .p-dropdown-items {
        padding-top: 0;
          .p-element{
            &:first-child{
              //.p-dropdown-item {
              //  padding: 8px 20px 14px 56px;
              //}
            }
          }
        .p-dropdown-item {
          padding: 14px 20px 14px 56px;
          &.p-highlight {
            background: transparent;
            display: none;
          }
        }
      }
    }
    .p-dropdown-label{
      padding: 22px 20px 17px 60px;
      @include phonel{
        padding: 22px 20px 17px 60px;
      }
    }
    .p-dropdown-trigger{
      margin-right:4px;
    }
    .p-dropdown-panel{
      box-shadow: 0 5px 6px rgba(0, 0, 0, 0.02);
      border-radius: 0;
      margin-top: -6px;
    }
    .country-item{
      font-style: normal;
      //font-weight: 300;
      font-size: 26px;
      line-height: 31px;
      font-family: $font_Souvenir_light;
      @media (max-width:360px){
        font-size: 23px;
        line-height:28px;
      }

      .dropdown_icons{
        &:before {
          background: url("/assets/images/account_mg/ic_account.svg") no-repeat;
          width: 25px;
          position: absolute;
          content: "";
          height:25px;
          left: 20px;
          top:calc(50% + 2px);
          transform: translateY(-50%);
          background-size: contain;
        }

      }

      &.account_icon{
        .dropdown_icons:before{
          background: url("/assets/images/account_mg/ic_account.svg") no-repeat;
          background-size: contain;
        }
      }
      &.property_icon{
        .dropdown_icons:before{
          background: url("/assets/images/account_mg/ic_property.svg") no-repeat;
          background-size: contain;
        }
      }
      &.user_icon{
        .dropdown_icons:before{
          background: url("/assets/images/account_mg/ic_user.svg") no-repeat;
          background-size: contain;

        }
      }

      &.shipping_icon{
        .dropdown_icons:before{
          background: url("/assets/images/account_mg/ic_shipping.svg") no-repeat;
          background-size: contain;
        }
      }


    }

    .p-dropdown-items-wrapper{
      .country-item {
        .dropdown_icons:before {
         top: calc(50% - 2px);
          //top: 50%;
          //@include phonel {
          //  top: calc(50% - 2px)
          //}
        }
        &.account_icon{
          .dropdown_icons:before {
            //top: calc(50% - 2px)
          }
        }
        //
        &.property_icon{
          .dropdown_icons:before {
           // top: calc(50% - 3px)
          }
        }
        //
        //&.user_icon{
        //  .dropdown_icons:before {
        //    top: calc(50% - 2px)
        //  }
        //}
      }
    }

  }

  .p-inputwrapper-filled{
    .p-dropdown {
      .pi-chevron-down {
        background: url("/assets/images/account_mg/ic_mob_angel_active.svg") no-repeat;
        width: 16px;
        height: 9px;
        margin-top: 14px !important;
      }
      &.p-dropdown-open .pi-chevron-down{
        background: url("/assets/images/account_mg/ic_mob_angel.svg") no-repeat;
      }
    }
    .p-dropdown-label{
      .country-item{
        color:$orange;
        &.account_icon{
          .dropdown_icons:before{
            background: url("/assets/images/account_mg/ic_account_active.svg") no-repeat;
            background-size: 100%;
            top:calc(50% + 1px);

          }
        }
        &.property_icon{
          .dropdown_icons:before{
            background: url("/assets/images/account_mg/ic_property_active.svg") no-repeat;
            background-size: 100%;
            top:calc(50% + 1px);

          }
        }
        &.user_icon{
          .dropdown_icons:before{
            background: url("/assets/images/account_mg/ic_user_active.svg") no-repeat;
            background-size: 100%;
            top:calc(50% + 1px);

          }
        }

        &.shipping_icon{
          .dropdown_icons:before{
            background: url("/assets/images/account_mg/ic_shipping_active.svg") no-repeat;
            background-size: 100%;
            top:calc(50% + 1px);

          }
        }
      }
    }
  }
}

.property_access_dropdown{
  .multiSelectDropdown {
    .p-multiselect-panel .p-multiselect-items-wrapper {
      max-height: 206px !important;
      min-height: 206px !important;
    }
    &.cstm_multiselect_dropdown{
      .p-multiselect-panel .p-multiselect-items-wrapper{
        max-height: 120px !important;
        min-height: 120px !important;
      }
    }
  }
  .multiselect-ui-outer{
    .p-multiselect-label{
      .country-item{
        width: 100%;
      }
    }
  }
}

.onboarding_form_wrap .form-group{
  .autocomplete_ui{
    &:hover{
      border-color:#E0E1E5;
    }
    &:focus{
      box-shadow: none;
      border-color: #96999F;
    }

  }
  &.autocomplete_wrapper{
    .p-autocomplete-panel {
      border: 2px solid #E0E1E5;
      filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2));
      border-radius: 4px;
      .p-autocomplete-items {
        padding: 4px 0px;
        .p-autocomplete-item {
          padding: 5px 14px;
          &.p-highlight{
            background-color: rgba(85, 146, 160, 0.1);
          }
          &:hover{
            background: rgba(85, 146, 160, 0.05);
          }
          span{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color:$fc-primary;
          }
        }
      }
    }
  }
}


.cstm_toast{
  &.p-toast-message.p-toast-message-success{
    background: #E3FFF5;
    box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.06), 0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 12px;
    border: none;
    //height: 75px;
    .p-toast-message-content{
      padding:20px 27px 20px 76px;
      min-height: 75px;
      display: flex;
      align-items: center;
      @include phonel{
        padding: 20px 20px 20px 57px;;
        min-height: 65px;
      }
    }
    .p-toast-summary{
      display: none;
    }
    .p-toast-message-text{
      margin-left: 0;
    }
    .p-toast-detail{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      font-family: 'Helvetica Neue';
      color: #333333;
      margin-top: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      a{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #333333;
        text-decoration: underline;
        word-break: break-word;
      }
    }
    .pi-check:before{
      background:url("/assets/images/account_mg/toast_icon.svg");
      width:34px;
      height:34px;
      content: "";
      position: absolute;
      left: 27px;
      top: 50%;
      transform: translateY(-50%);
      background-size: 100%;
      @include phonel{
        width:25px;
        height:25px;
        left: 20px;
      }
    }

    .p-toast-icon-close{
      display: none;
    }
    &.p-toast-message{
      min-width:275px;
      max-width:350px;
      @include phones{
        min-width: initial;
      }
    }
  }

  &.p-toast-message.p-toast-message-error{
    //background:rgba(#EA1212 , 0.2);
    background: #FBD0D0;
    box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.06), 0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 12px;
    border: none;
    .p-toast-message-content{
      padding:20px 27px 20px 76px;
      min-height: 75px;
      display: flex;
      align-items: center;
      @include phonel{
        padding: 20px 20px 20px 57px;;
        min-height: 65px;
      }
    }
    .p-toast-summary{
      display: none;
    }
    .p-toast-message-text{
      margin-left: 0;
    }
    .p-toast-detail{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      font-family: 'Helvetica Neue';
      color: #333333;
      margin-top: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }
    .pi-times-circle:before{
      background:url("/assets/images/account_mg/toast_error.svg");
      width:34px;
      height:34px;
      content: "";
      position: absolute;
      left: 27px;
      top: 50%;
      transform: translateY(-50%);
      background-size: 100%;
      @include phonel{
        width:25px;
        height:25px;
        left: 20px;
      }

    }

    .p-toast-icon-close{
      display: none;
    }
  }
}
.p-toast {
  width: auto !important;
  min-width: 280px;
  max-width: 350px;
  opacity: 1;
  @include phonel{
    min-width: initial;
  }
}
.onboarding_wrapper {
  .user_role_dropdown {
    .singleSelectDropdown:not(.p-disabled).p-dropdown-open.p-focus .p-dropdown-label.p-placeholder {
      opacity: 1;
    }
  }
}


.ac_main_wrapper {
  .form-group {

    .autocomplete_ui {
      &:hover {
        border-color: #E0E1E5;
      }

      &:focus {
        box-shadow: none;
        border-color: #96999F;
      }
    }
    &.autocomplete_wrapper {

      .p-autocomplete{
        width: 100%;
      }
      .p-autocomplete-panel {
        border: 2px solid #E0E1E5;
        filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2));
        border-radius: 4px;

        .p-autocomplete-items {
          padding: 4px 0px;

          .p-autocomplete-item {
            padding: 5px 14px;
            background: transparent;
            color:$fc-secondary;
            &.p-highlight{
              background-color: rgba(85, 146, 160, 0.1);
            }
            span{
              //color:$fc-secondary !important;
            }

            &:hover {
              //background: rgba(85, 146, 160, 0.1);
              background: rgba(85, 146, 160, 0.04);
            }

            span {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: $fc-primary;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 991px) {
  .error_wrapper .error-1 {
    //white-space: nowrap;
  }
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message{
  color:#96999F;
  font-style: italic;
}

 .p-multiselect-label .country-item  div {
    display: block;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
  }


.phone_field_ui {
  .tooltip_outer {
    .p-tooltip {
      max-width: 230px;
      width: 230px !important;
      left: -51px !important;
      top:-72px !important;

      .p-tooltip-text {
        padding: 12px 14px;
      }
      &.p-tooltip-top .p-tooltip-arrow{
        left:28%;
      }
    }
    .tooltip_wrapper{
      position: relative;
      display: inline-flex;
    }
  }
  .form-label .info_icon{
    margin-left: 5px;
  }
  .info_icon{
   padding:2px 2px 0 0;
  }
}

.cstm_toast_wrapper{
  min-height: 75px;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
p-multiselect.ng-dirty.ng-invalid > .p-multiselect{
border: 2px solid #E0E1E5;
}












.custom_input_number{
  app-qty-input {
    display: inline-flex;
    border: 2px solid #E0E1E5;
    border-radius: 4px;
    //width: 100px;
    height: 50px;
    @include trans2s;
    &.p-inputwrapper-focus{
      border: 2px solid #96999F;
    }
    &[ng-reflect-model="1"]{

      .p-inputnumber  .p-inputnumber-button-down{
        .pi-minus:before {
          background:url("/assets/images/quotes/quote_quantity_delte.svg") no-repeat;
        }
        &:hover{
          .pi-minus:before {
            background:url("/assets/images/quotes/quote_quantity_delte_hover.svg") no-repeat;
          }
        }
      }
    }
  }
  .p-inputnumber-buttons-horizontal .p-inputnumber-input {
    width: 40px!important;
    border: 0px solid #FFFFFF;
    @include phonel{
      width: 58px!important;
    }


  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #3B82F6;
  }
  .p-inputtext {
     padding: 12px 5px;
    text-align: center;
  }
  .p-button.p-button-icon-only {
    width: 33px;
    padding: 0.75rem 0;
  }
  .p-buttonset.p-button-danger:focus{

  }
  .p-button.p-button-danger:enabled:focus,
  .p-button.p-button-success:enabled:focus,
  .p-button.p-button-danger:enabled:active,
  .p-buttonset.p-button-danger > .p-button:enabled:active,
  .p-splitbutton.p-button-danger > .p-button:enabled:active{
    outline: none !important;
    box-shadow: none !important;
    background: #fff !important;
    border: none !important;
  }
  .p-button.p-button-danger,
  .p-buttonset.p-button-danger > .p-button,
  .p-splitbutton.p-button-danger > .p-button {
    color: #ffffff;
    background: #FFFFFF;
    border: 0px solid #FFFFFF;

  }
  .p-button.p-button-danger:enabled:hover,
  .p-buttonset.p-button-danger > .p-button:enabled:hover,
  .p-splitbutton.p-button-danger > .p-button:enabled:hover {
    background: #FFFFFF;
    color: #ffffff;
    border-color: #DC2626;
  }
  .p-button.p-button-success,
  .p-buttonset.p-button-success > .p-button,
  .p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: #FFFFFF;
    border: 0px solid #FFFFFF;
  }
  .p-button.p-button-success:enabled:active,
  .p-buttonset.p-button-success > .p-button:enabled:active,
  .p-splitbutton.p-button-success > .p-button:enabled:active {
    background: #FFFFFF;
    color: #ffffff;
  }
  .p-button.p-button-success:enabled:hover,
  .p-buttonset.p-button-success > .p-button:enabled:hover,
  .p-splitbutton.p-button-success > .p-button:enabled:hover {
    background: #FFFFFF;
    color: #ffffff;
  }
  .pi-delete{
    &:before {
      background: url("/assets/images/quotes/quote_quantity_delte.svg") no-repeat;
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      background-position: center;
    }
    &:disabled, &.disabled{
      cursor: auto;
      &:before {
        opacity: 0.6;
      }
      &:hover{
        background: url("/assets/images/quotes/quote_quantity_delte.svg") no-repeat;
      }
    }
  }
  .pi-minus{
    &:before {
      background:url("/assets/images/quotes/ic_minus.svg") no-repeat;
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      background-position: center;
    }
    &:disabled, &.disabled{
      cursor: auto;
      &:before {
        background: url("/assets/images/quotes/minusedisable.svg") no-repeat;
      }
      &:hover{
        background:url("/assets/images/quotes/minusedisable.svg") no-repeat;
      }
    }
  }

  .pi-plus:before {
    background:url("/assets/images/quotes/quote_qty_add.svg") no-repeat;
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    transition:0.2s ease-in-out;
  }

  .p-inputnumber-buttons-horizontal {
    display: flex;
      .p-button.p-inputnumber-button-up {
        order: 3;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

    .p-button.p-inputnumber-button-down {
      order: 1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .p-inputnumber-input {
      order: 2;
      border-radius: 0;
    }
  }
}

.p-inputnumber-button-up{
  @include phonel-m {
    &:hover, &:focus-visible {
      .pi-plus:before {
        background: url("/assets/images/quotes/quote_qty_add_hover.svg") no-repeat;
      }
    }
  }
  @include phonel{
    &:active {
      .pi-plus:before {
        background: url("/assets/images/quotes/quote_qty_add_hover.svg") no-repeat;
      }
    }
  }
}


.p-inputnumber-button-down{
  @include phonel-m {
    &:hover, &:focus-visible {
      .pi-minus:before {
        background: url("/assets/images/quotes/ic_minus_hover.svg") no-repeat;
        position: absolute;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
        background-position: center;
      }
      .pi-minus.disabled:before{
        background: url("/assets/images/quotes/minusedisable.svg") no-repeat;
      }

      .pi-delete {
        &:before {
          background: url("/assets/images/quotes/quote_quantity_delte_hover.svg") no-repeat;
        }

        &:disabled, &.disabled {
          &:before {
            background: url("/assets/images/quotes/quote_quantity_delte.svg") no-repeat;
          }
        }
      }
    }
  }
  @include phonel{
    &:active {
      .pi-minus:before {
        background: url("/assets/images/quotes/ic_minus_hover.svg") no-repeat;
        position: absolute;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
        background-position: center;
      }
      .pi-minus.disabled:before{
        background: url("/assets/images/quotes/minusedisable.svg") no-repeat;
      }

      .pi-delete {
        &:before {
          background: url("/assets/images/quotes/quote_quantity_delte_hover.svg") no-repeat;
        }

        &:disabled, &.disabled {
          &:before {
            background: url("/assets/images/quotes/quote_quantity_delte.svg") no-repeat;
          }
        }
      }
    }
  }
}

.quotelist_inner_ui {
  .quote_list {
    border: none;
    border-radius: 0;

    .dropdown_header {
      padding: 0;
      border: none;
      background: no-repeat;
      border-radius: 0;
      position: absolute;
      width:100%;
      left: 0;
      top: 0;
      background: #fff;
      z-index: 4;
      height: 45px;
      .search_bar{
        align-items: center;
        width:100%;
        display: flex;
        height:100%;
        border-bottom: 1px solid #E0E1E5;
        padding: 0 12px;
         .close_btn{
           position: relative;
           .pi-search{
             transform: translateY(-50%);
             background-size: 100%;
             background-repeat: no-repeat;
             width:100%;
             height: 100%;
             right: 0 !important;
             top:50%;
             position: absolute;
             z-index: 1;
           }
         }

        .p-inputgroup{
          height:100%;
          input{
            outline: none;
            box-shadow: none;
          }
        }
      }

      .close_btn{
        background: none;
        border: none;
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include trans2s;
        &:hover,
        &:focus{
          opacity: 0.7;
        }
      }

    }
    .listbox-list-wrapper{
      padding-top: 0px;

      ul{
        padding: 0;
        &.empty_list{
          li{
            &:hover{
              background: transparent !important;
            }
          }
        }
      }
      li{
        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 22px;
        color:$fc-primary;
        padding: 10px 30px;
        cursor: pointer;
        word-break: break-word;
        &:last-child{
        }
        &:hover{
          background: rgba(#5592A0 , 0.1) !important;
        }
        &:focus,
        &:active{
          background: rgba(#5592A0 , 0.1);
        }
      }
    }
    .p-listbox-footer{
      padding-bottom: 30px;
      .archived_list{
        a{
          font-weight: 500 !important;
        }
      }
    }
  }
}
.archived_inner_listing{
  position: absolute;
  width: 100%;
  left: 100%;
  top: 0;
  background: #fff;
  min-height:315px;
  max-height:315px;
  opacity:0;
  visibility: hidden;
  @include trans2s;
  &.active{
    opacity: 1;
    left: 0;
    visibility: visible;
    z-index: 6;
  }
  .dropdown_header{
    z-index: 2;
  }
}
.quotelist_inner_ui .quote_list .archived_list .dropdown_header{
  z-index: 5;
}

.quotelist_inner_ui {
  position: fixed;
  width: 100%;
  left: 100%;
  top: 0;
  background: #fff;
  min-height: 260px;
  opacity:0;
  visibility: hidden;
  @include trans2s;
  &.active{
    opacity: 1;
    left: 0;
    visibility: visible;
  }
  .p-listbox-header{
    padding: 0;
  }
  .search_bar{
    position: relative;
    display: flex;
    .back_list{
      padding: 0;
      width:24px;
      height:24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      &:hover,
      &:focus,
      &:active{
        opacity: 0.6;
      }
    }
    input{
      border:none;
      padding:0 12px;
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 22px;
      color:$fc-primary;
      &::-webkit-input-placeholder{
        color: $fc-hint;
      }
      &:-ms-input-placeholder{
        color: $fc-hint;
      }
      &::placeholder{
        color: $fc-hint;
      }
    }
  }


  .quote_list{
    max-height: 260px;
    min-height: 215px;
    overflow-y: auto;
    padding:0px 0 20px;

    li{
      a{
        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 22px;
        color:$fc-primary;
      }
    }
  }

}

.listbox-list-wrapper {
  .empty_list {
    padding-top: 5px !important;

    li {
      text-align: center;

      label {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: $fc-primary;
      }

      p {
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: $fc-secondary;
      }
    }
  }
}

.account_type_dropdown .multiSelectDropdown .p-dropdown-items-wrapper .country-item .dropdown_icons:before{
  top: calc(50% - 2px);
  background-size: 100%;
}


.sidebar_shipping_card_wrap{
  display: inline-block;
  width: 100%;
  .info_text{
    margin-bottom:12px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    word-break: break-word;
    color: #666666;
  }
  .link-primary{
    .el-text{
      @include phonem{
        font-size: 15px;
        line-height: 23px;
      }
    }

  }
}







.p-sidebar-mask{
  background: rgba(40, 49, 63, 0.6);
}
.p-sidebar{
  &.cstm_sidebar{
    background: #FFFFFF;
    box-shadow: -12px 0px 16px rgba(16, 24, 40, 0.12), -4px 0px 6px rgba(16, 24, 40, 0.07);
    max-width: 587px;
    width:100%;
  }
  .p-sidebar-header{
    display: none;
    +  .p-sidebar-content{
      padding-top: 30px;
      @include phonem{
        padding-top: 20px;
      }
    }
  }
  .p-sidebar-content{
    padding: 30px 0;
    overscroll-behavior: contain;
    .headerbar{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding:0 30px;
      @include phonem{
        padding:0 20px;
      }
      .close_btn{
        background: none;
        border: none;
        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 19px;
        text-align: right;
        color:$fc-secondary;
        display: inline-flex;
        align-items: center;
        @include trans2s;
        &:hover{
          color:$orange;
          svg path,
          svg line{
            stroke:$orange;
          }
        }

        svg{
          margin-left: 6px;
          path, line{
            @include trans2s;
          }
        }

      }
    }
  }
}


.p-accordion {
  .accordian_card {
    &.active{
      .p-accordion-header{
        .address_default_ui{
          display: none;
        }
      }
      .accordian_header_wrapper {
        .link-primary {
          opacity: 1;
          visibility: visible;
        }
      }
      .p-accordion-header-link{
        &:hover {
          .accordian_header_wrapper {
            .link-primary {
              opacity: 1;
              visibility: visible;
            }
             .header_top_ui,
             .header_active_ui p,
             .header_active_ui .info_text{
                color:#666666;
            }


          }


        }
      }
      .p-accordion-header {
        .step_count {
          background: #3ED29F;
          font-size: 0;

          &:after {
            position: absolute;
            content: "";
            background: url("/assets/images/quotes/quotes_active.svg");
            width:22px;
            height:22px;
            top: 0px;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }
    .accordian_header_wrapper {
      position: relative;
      width: 100%;
      padding-right:0px;
      .header_top_ui{
          padding-right: 80px;
      }
    }
    .p-accordion-tab{
      margin-bottom: 0;
      //&.p-accordion-tab-active{
      //  .p-toggleable-content{
      //    margin-top: -20px;
      //  }
      //}
    }
    .p-accordion-tab{
      .p-toggleable-content{
        @include trans2s;
        &[aria-hidden="false"]{
       // margin-top: -10px;
        }
      }
    }
    .p-accordion-header{
      .header_top_ui{
        font-style: normal;
        font-weight: 700;
        @include remy(20);
        line-height: 22px;
        color: #444444;
        font-family:var(--helvetica700);
        @include phonem{
          @include remy(18);
        }
      }
      .step_count{
        width: 22px;
        height: 22px;
        background:$fc-primary;
        border-radius: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: -31px;
        top: 0px;
        @include phonem{
          top: -1px;
        }
      }

      &:not(.p-disabled).p-highlight{
        .p-accordion-header-link{
          background: transparent;
          //padding-bottom: 8px;
        }
      }
      &:hover{
        background: none;
        .p-accordion-header-link {
          border: none;
          background: transparent;
        }
      }
      .p-accordion-header-link {
        border: none;
        background: transparent;
        padding:26px 32px 26px 64px;
        color:$fc-primary;
        @include phonem{
          padding:25px 20px 25px 46px;
        }
        &:hover{
          color:#666666;
        }
        .p-accordion-toggle-icon,
        .p-accordion-header-text{
            display: none;
        }

        &:focus,
        &:hover {
          outline: none;
          box-shadow: none;
          background: transparent !important;
        }

        &.active{
          &:hover{
            .link-primary{
              opacity: 1;
              visibility: visible;
            }
          }
          .header_active_ui{
            display: block;
          }
         .step_count{
              background: #3ED29F;
              font-size: 0;
              &:after{
                position: absolute;
                content: "";
                background: url("/assets/images/quotes/quotes_active.svg");
                width: 13px;
                height: 10px;
                top:50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: auto;
              }
         }
        }
      }
    }
  }
  .p-accordion-content{
    border: none;
    background: none;
    padding:0px 24px 0px 64px;
    .accordian_content_wrapper{
      padding-bottom: 20px;
    }
    @include phonem{
      padding:0px 20px 0px 46px;
    }
    .quotes_detail_info{
      position: relative;
      margin-top:15px;
    }
  }
}
.border8{
  height: 8px;
  width: 100%;
  background:#F5F5F5;
}

.cstm_accordian_wrapper{
  .link-primary{
    display: inline-flex;
    align-items: center;
    margin-bottom: 30px;
    @include phonem{
      margin-bottom: 24px;
    }
    .el-icon{
      margin-right: 7px;
    }
    &:hover{
      svg path{
        stroke:$orange-tint;
      }
    }
    &:focus{
      svg path{
        stroke:$orange-shade;
      }
    }
  }
}




.quotes_detail_info{
  .singleSelectDropdown .p-dropdown-label{
    @include remy(16);
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
    background:rgba(85, 146, 160, 0.1);
  }
}

.date_picker_wrapper{
  position: relative;
  p-calendar{
    .p-button-icon-only{
      position: absolute;
      left: 0;
      top: 0;
      border: none;
      background: transparent;
      padding: 0;
      width: 24px;
      height: 30px;
      display: flex;
      align-items: center;
    }

  }
  .custom-datepicker {
    height: auto !important;
    .p-button:enabled{
      border: none;
      border-radius: 0;
    }
    .p-inputtext {
      border: none;
      height: 30px;
      padding: 0 0 0 26px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      cursor: pointer;
      &::-webkit-input-placeholder { /* Edge */
       @include remy(15);
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        @include remy(15);
      }

      &::placeholder {
        @include remy(15);
      }
    }


    &.highlightToday{
        table td{
          &.p-datepicker-today{
            > span{
              background: #F8DEDB;
              border-radius: 69.0525px;
              font-weight: 700;
              color:$orange;
              &:hover{
                color:$orange;
                background: #F8DEDB !important;
              }
              &:not(.p-highlight):not(.p-disabled):focus{
                box-shadow: none !important;
                outline:none !important;
              }

            }
          }
        }
    }

    &.doNotHighlightToday{
      table td{
        &.p-datepicker-today{
          > span{
            background: transparent;
            border-radius: 69.0525px;
            font-weight: 400;
            color:#000;
            &.p-highlight{
              background: #F8DEDB;
              font-weight: 700;
              color:$orange;
            }
            &:hover{
              color:$orange;
              //background: #F8DEDB !important;
            }
            &:not(.p-highlight):not(.p-disabled):focus{
              box-shadow: none !important;
              outline:none !important;
            }

          }
        }
      }
    }
    .p-inputtext,
    .p-button-icon{
      @include trans2s;
    }
    &.blackText{
      .p-inputtext,
      .p-button .p-button-icon{
        //color:#C7C7C7;
        color:$orange;
      }
      .p-inputtext + button{
        .expedited_icon{
          svg{
            path{
              stroke:$orange;
            }
          }
        }

      }
      &.p-calendar-disabled{
        &:after{
          background: url("/assets/images/che-icon-grey.svg") no-repeat;

        }
        .p-inputtext,
        .p-button .p-button-icon{
          color:$fc-hint;
        }
        .p-inputtext + button{
          .expedited_icon{
            svg{
              path{
                stroke:$fc-hint;
              }
            }
          }

        }
        &:hover{
          .p-inputtext,
          .p-button .p-button-icon{
            color:$fc-hint;
            cursor: default;
          }
          .p-inputtext + button{
            .expedited_icon{
              svg{
                path{
                  stroke:$fc-hint;
                }
              }
            }

          }
        }
      }
      &:hover{
        .p-inputtext,
        .p-button .p-button-icon{
          color:$orange;
        }
        .p-inputtext + button{
          .expedited_icon{
            svg{
              path{
                stroke:$orange;
              }
            }
          }

        }
      }
    }
    &.orangeText{
     .p-inputtext{
       color:$orange;
       &::-webkit-input-placeholder { /* Edge */
         color:$orange;
       }

       &:-ms-input-placeholder { /* Internet Explorer 10-11 */
         color:$orange;
       }

       &::placeholder {
         color:$orange;
       }
     }
      .p-button .p-button-icon{
        color:$orange;
      }
    }

  }

  .p-datepicker {
    width: 254px;
    min-width:254px!important;
    padding: .5rem;
    border-radius: 6px;
    @include tabv{
      transform: none !important;
      position: absolute !important;
      top: 30px !important;
      left: 0 !important;
    }
    .p-datepicker-header {
      padding: 4px 0 ;
      .p-datepicker-prev,
      .p-datepicker-next{
          width:28px;
          height: 28px;
          background: #F5F5F5;
          border-radius: 50%;
        .p-icon-wrapper{
          svg{
            path{
              fill:$orange;
            }
          }
        }
        &:focus{
          outline: none !important;
          box-shadow: none !important;
        }
        .pi{
          @include remy(10);
          color:$orange;
        }
      }
      .p-datepicker-title {
        .p-datepicker-month,
        .p-datepicker-year{
          font-style: normal;
          font-weight: 500;
          font-size: 16.74px;
          color: #000000;
          margin: 0;
          padding: 0 3px;
          &:hover{
            color: #000000;
          }
        }

      }

    }

    table {
      font-size: 1rem;
      margin: .5rem 0;
      th{
        padding: .5rem
      }
    }


    table th > span{
          width: 33px;
          height: 33px;
          font-style: normal;
          font-weight: 500;
          font-size: 9.765px;
          letter-spacing: 0.018em;
          color: #818181;
          padding: 0;
          text-align: center;
     }
      table td {
        padding: 0;

        > span {
          font-style: normal;
          font-weight: 400;
          font-size: 11.16px;
          color: #000;
          letter-spacing: 0.0168em;
          width: 33px;
          height: 33px;
          &:hover{
            background: #F5F5F5 !important;
          }
          &.p-highlight{
            color:$orange;
            background: #F8DEDB;
            font-weight: 700;
            outline: none;
            box-shadow: none;
            &:hover{
              background: #F8DEDB !important;
            }
          }
        }
      }
  }
}

.date_picker_wrapper .custom-datepicker.orangeText .p-inputtext + button {
  .expedited_icon{
    svg{
      path{
        stroke: #DC5A4B;
      }
    }
  }

}


.expedited_icon{
  height: 30px;
  display: inline-flex;
  align-items: center;
}


.quotes_detail_info{
  .multiselect_w_check {
    .multiSelectDropdown {
      @include phonem{
        height: 48px;
      }
      &.p-multiselect .p-multiselect-label{
        @include phonem{
          @include remy(15);
        }
      }
    }}
}

comma-list-tooltip{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  p{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }
  .more_property{
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19px;
    color: #96999F;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    &.custom_tooltip{
      margin-left: 10px;
      text-align: left;
    }
  }
}



.quote_member_drop {
  .dropdown_listing_Quote_heading{
      &.property_dropdown_listing{
        .p-listbox .p-listbox-list-wrapper {
          max-height: 175px !important;
          li {
            padding: 8px 10px;
            &.p-listbox-empty-message{
              padding-top: 3px;
            }
          }
        }
        .p-listbox .p-listbox-list .p-listbox-item .p-checkbox{
          height: 20px;
        }
      }
    .p-listbox {
      background: #ffffff;
      color: #495057;
      border: 0px solid;
      border-radius: 6px;
      position: relative;
      padding: 0 15px 10px;
      .p-listbox-header {
        padding:0px 10px 0px 10px;
        border-bottom: 0px solid!important;
        color: #343a40;
        background: #FFFFFF;
        margin: 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        .p-listbox-filter-icon{
          position: absolute;
          top: 50%;
          margin-top: 0;

          &.pi-times{
            background: url("/assets/images/quotes/ic_cross.svg");
            width:22px;
            height:22px;
            transform: translateY(-50%);
            background-size: 100%;
            cursor: pointer;
            margin-right: 4px;
            &:before{
              content: "";
            }
          }
        }
        .select_all_prop{
          position: absolute;
          z-index: 3;
          top: 71px;
          left: 53px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #444444;
          word-break: break-word;

        }


        &:first-child {
          //position: absolute !important;
          //z-index: 1;
          //top: 0px !important;
          //left:0px !important;
          //background: transparent;
          //padding-left: 0;
          //width: 100%;
          //background: transparent;

        }

        &:nth-child(2){
          .p-checkbox .p-checkbox-box{
            width: 100% !important;
            border: none;
            z-index: 2;
            background: transparent;
            &.p-highlight{
              background: transparent !important;
              &:before{
                border-color: #5592A0 ;
                background: #5592A0;
              }
            }
            .p-checkbox-icon{
              position: absolute;
              left: 3px;
            }
            &:before{
              position: absolute;
              content: "";
              border: 2px solid #ced4da;
              background: #ffffff;
              width: 20px;
              height: 20px;
              color: #495057;
              border-radius: 4px;
              transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
              left: 0;
            }
          }
        }


      }

    }
    .p-listbox-filter-container {
      position: relative;
      flex: 1 1 auto;
      width: 100% !important;
    }
    .p-listbox{
      .p-listbox-header {
        .p-checkbox-box{
          //margin-right: 0.5em;
        }
        .p-checkbox {
          margin-right: 0px;
          margin-top: 21px;
          margin-bottom: 8px;
          width: 100%;
          .p-checkbox-box{
            margin-right: 0.5em;
            position: relative;
            top: 2px;
          }
        }
      }
      .p-listbox-list {
        padding: 0px;
        .p-listbox-item {
          margin: 0;
          padding: 10px 10px 10px 10px;
          border: 0 none;
          color: #495057;
          transition: box-shadow 0.2s;
          border-radius: 0;
          display: flex;
          align-items: flex-start;
          .country-item{
            cursor: pointer;
          }
          &.p-highlight {
            color: #444444;
            background: #FFFFFF!important;
          }
        }
      }
    }

    .p-checkbox .p-checkbox-box.p-highlight {
      border-color:#5592A0!important;
      background: #5592A0!important;
      &:hover{
        border-color:#5592A0!important;
      }
    }
    //hover color on li
    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
      color: #495057;
      background:transparent;
    }
    //checkbox border color on hover
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      border-color: #ced4da;
    }
    .p-checkbox .p-checkbox-box {
      width: 20px;
      height: 20px;
      border-radius: 4px;
    }
    .country-item{
      .quote_member_name{
        font-style: normal;
        font-weight: 400;
       @include remy(16);
        line-height: 18px;
        color:$fc-primary;
        word-break: break-word;
      }
      .quote_member_email_id{
        p{
          font-style: normal;
          font-weight: 400;
          @include remy(13);
          line-height: 18px;
          color:$fc-primary;
          word-break: break-word;
        }
      }
    }
    .p-inputtext {
      padding: 0.75rem 0.75rem;
      border: 2px solid #E0E1E5;
      border-radius: 4px;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      height: 50px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      width: 100%;
      padding-right: 40px;
      &::-webkit-input-placeholder { /* Edge */
        color: $fc-hint;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $fc-hint;
      }

      &::placeholder {
        color: $fc-hint;
      }

    }
    .p-inputtext:enabled:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow:none;
      border: 2px solid $border-dark;
    }
    .p-listbox .p-listbox-list .p-listbox-item:focus {
      box-shadow: none;
    }
    .p-listbox-header {
      .p-checkbox {
        &.p-checkbox-disabled{
          //display: none;
        }
      }
    }
    .p-listbox-list-wrapper {
     max-height: 227px !important;
      overflow-y: auto;
      .p-listbox-empty-message{
        font-style: italic;
        font-weight: 400;
        @include remy(16);
        line-height: 18px;
        color:$fc-secondary;
        text-align: center;
        padding-top:3px;
        //min-height: 280px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        border-radius:4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background:transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #F5F5F5;
      }
    }


  }

}


.tooltip_outer_quote_drop{
  .p-tooltip {
    padding: 0.25em 0.5rem;
    width: 238px!important;
    max-width: 238px!important;
    left: -8px !important;
    top: 26px!important;
  }
  .p-tooltip-bottom .p-tooltip-arrow {
    top: -7px;
    left: 28px;
    margin-left: -0.25rem;
    border-width:0 0.55em 0.75rem;
  }
  .p-tooltip .p-tooltip-text {
    background: #1D2939;
    color: #ffffff;
    padding: 8px 10px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius:2px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
  }

  .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #1D2939;
  }
}

.cstm_accordian_wrapper {
  .p-accordion .accordian_card .p-accordion-tab .p-toggleable-content {
    top: -18px;
    position: relative;
  }
}

.edit_heading {
  .p-tooltip {
    top: 48px !important;
    left: 0 !important;

    .p-tooltip-text {
      background: #1D2939;
      border-radius: 2px;
      padding: 5px 7px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .p-tooltip-bottom .p-tooltip-arrow {
    top: -5px;
    left: 15px;
    border-width: 0 .45em .65rem;
    border-bottom-color: #1D2939;
  }
}


.mobile_cart_summary{
  border-top: 1px solid $border-light;
  &.for_quote{
    border-top: 0px;
  }
  .p-accordion .accordian_card {
    &.quote_accordian_icon{
      .p-accordion-header {
        .p-accordion-header-link:after {
          top: 28px;
        }
      }
    }
    .p-accordion-header {
      .p-accordion-header-link{
        padding: 15px 0 10px 0;
        margin-bottom: 12px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &:after{
          position: absolute;
          content: "";
          background: url("/assets/images/quotes/ic_downangel.svg") no-repeat;
          width: 15px;
          height:9px;
          right: 0;
          top: 52%;
          transform: translateY(-50%);
          transform-origin: center
        }
      }
    }
    .p-toggleable-content{
        position: relative;
        top:-10px;
    }
    .p-accordion-tab-active{
      .p-accordion-header {
        .p-accordion-header-link {
          &:after{
            transform:translateY(-50%) rotate(-180deg);
            transform-origin: center
          }
          }
        }
      }
    }
  }






.mobile_cart_summary .p-accordion .p-accordion-content{
  padding:0;
}



.pagination_wrapper{
  text-align: center;
  padding-bottom: 30px;
  .page_info{
    font-style: normal;
    font-weight: 400;
    @include remy(14);
    line-height: 22px;
    color:$fc-secondary;
  }
  .pagination{
    margin-bottom: 20px;
      li{
        margin: 0 4px;
        &.active{
          a{
            background: $orange;
            color:#fff;
            border:1px solid $orange;
            @include trans2s;
            &:hover{
              background:$orange;
            }
          }
        }
        a{
          min-width: 32px;
          height: 32px;
          padding: 0 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $border-light;
          border-radius: 4px;
          font-style: normal;
          font-weight: 400;
          @include remy(16);
          line-height: 22px;
          color:$fc-secondary;
          @include trans2s;
          &:focus{
            outline: none;
            box-shadow: none;
          }
          &:hover{
            background: $bg-gray;
          }

          &[aria-label="Previous"]{
            background: transparent;
            border: none;
            min-width:32px !important;
            height: 32px;
            display: flex;
            align-items:center !important;
            svg path{
              fill:$fc-secondary;
            }
            &:hover{
              svg path{
                fill:$orange;
              }
            }
          }
          &[aria-label="Next"]{
            background: transparent;
            border: none;
            min-width:32px !important;
            height: 32px;
            display: flex;
            align-items:center !important;
            &:hover{
              svg path{
                fill:$orange;
              }
            }
            svg path{
              fill:$fc-secondary;
            }
          }

          &[aria-disabled="true"]{
            background: transparent;
            border: none;
            padding: 0;
            min-width:24px;
            height: 32px;
            display: flex;
            align-items: flex-end;

            svg path{
              fill:$border-light;
            }
          }
        }
      }
  }
}

//to hide search bar in order list filter
.hide_search{
  .p-listbox-header{
    display: none;
  }
}

.filter_dropdown_sec {
  &.show {
    .dropdown_btn {
      color: $orange;
      .el-text{
        color: $orange;
      }
      .icon {
        svg {
          path {
            stroke: $orange;
          }
        }
      }

      .caret-icon svg path {
        fill: $orange;
      }
    }
  }
  &.show{
    .dropdown_btn{
      color:$orange;
    }
    .caret-icon{
      svg{
        transform: rotate(180deg);
      }
    }
  }
  &.status_dropdown{
    .dropdown-menu{
      min-width: 200px;
      max-width: 210px;
    }
  }
  &.brand_dropdown{
    .dropdown-menu{
      min-width: 296px;
      max-width: 296px;
      .p-listbox-header {
        padding: 0;
        background: #fff;
        border-bottom: 1px solid $border-light;
        .p-listbox-filter-icon{
            color: $fc-hint;
        }
        .p-checkbox {
          display: none;
          &.p-checkbox-disabled{
            +  .p-listbox-filter-container{
              //.p-listbox-filter-icon{
              //  width:18px;
              //  height:18px;
              //  right: 0;
              //  top: 0;
              //  background: url("/assets/images/quotes/quotelist/filter_cross.svg");
              //}
            }
          }
        }
        .p-listbox-filter-container{
          input{
            border: none;
            box-shadow: none;
            padding:10px 50px 10px 16px;
            font-style: normal;
            font-weight: 400;
            @include remy(18);
            line-height: 21px;
            height: 50px;
            color:$fc-primary;
            &::-webkit-input-placeholder { /* Edge */
              color: $fc-hint;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: $fc-hint;
            }

            &::placeholder {
              color: $fc-hint;
            }

            &:focus{
              box-shadow: none;
              outline: none;
            }
          }
        }
      }
    }
  }
  &.property_dropdown{
    .dropdown-menu{
      min-width: 300px;
      max-width: 332px;
    }
  }

  &.userlist_dropdown{
    .dropdown-menu{
      min-width: 300px;
      max-width: 332px;
      .p-listbox-header {
        padding: 0;
        background: #fff;
        border-bottom: 1px solid $border-light;
        .p-listbox-filter-icon{
          //color: $fc-hint;
          &.pi-times{
            background: url("/assets/images/quotes/ic_cross.svg");
            width: 22px;
            height: 22px;
            transform: translateY(-50%);
            background-size: 100%;
            cursor: pointer;
            margin-right: 4px;
            margin-top: 0;
            &:before{
              content: "";
            }
          }
        }
        .p-checkbox {
          display: none;
          &.p-checkbox-disabled{
            +  .p-listbox-filter-container{
              //.p-listbox-filter-icon{
              //  width:18px;
              //  height:18px;
              //  right: 0;
              //  top: 0;
              //  background: url("/assets/images/quotes/quotelist/filter_cross.svg");
              //}
            }
          }
        }
        .p-listbox-filter-container{
          input{
            border: none;
            box-shadow: none;
            padding:10px 50px 10px 16px;
            font-style: normal;
            font-weight: 400;
            @include remy(18);
            line-height: 21px;
            height: 50px;
            color:$fc-primary;
            &::-webkit-input-placeholder { /* Edge */
              color: $fc-hint;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: $fc-hint;
            }

            &::placeholder {
              color: $fc-hint;
            }

            &:focus{
              box-shadow: none;
              outline: none;
            }
          }
        }
      }
      .p-listbox {
        .p-listbox-list {
            li{
              align-items: flex-start;
              .p-checkbox{
                position: relative;
                top:2px
              }
              .member_name{
                font-style: normal;
                font-weight: 400;
                @include remy(16);
                line-height: 18px;
                color:$fc-primary;
                //text-transform: capitalize;
              }
              .member_email_id{
                font-style: normal;
                font-weight: 400;
                @include remy(13);
                line-height: 18px;
                color:$fc-primary;
                word-break: break-word;
              }
            }
          }
        }
    }
  }
  .dropdown-menu{
    background: #FFFFFF;
    border: 1px solid $border-gray100;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.12), 0px 4px 6px -2px rgba(16, 24, 40, 0.07);
    border-radius: 4px;
    padding: 0;
    transform: translate3d(0px, 32px, 0px) !important;
    max-height: 320px;
    overflow: hidden;
  }
  .p-listbox {
    border: none;
    box-shadow: none;
    .p-listbox-list{
      padding: 6px 0 7px;
      .p-listbox-empty-message{
        height:200px;
        font-style: italic;
        font-weight: 400;
        @include remy(14);
        line-height: 18px;
        color:$fc-secondary;
        text-align: center;
      }
      li{
        padding: 8px 18px;
        color:$fc-primary;
        font-weight: 400;
        @include remy(16);
        line-height: 22px;
        align-items: flex-start;

        &:focus{
          outline: none !important;
          box-shadow: none !important;
        }
        &:hover{
          background: transparent !important;
          .p-checkbox {
            .p-checkbox-box{
              border-color:$fc-secondary;
              &.p-highlight{
                background: $teal-tint;
                border-color: $teal-tint;
              }
            }
          }
        }
        &.p-highlight{
            background: transparent;
          color:$fc-primary;
        }
      }
    }
    .p-checkbox {
      width:20px;
      height: 20px;
      top: 2px;
      .p-checkbox-box {
        width:20px;
        height: 20px;
        border-radius: 4px;
        border-color:2px solid $border-light;
        @include trans2s;
        &.p-highlight{
          box-shadow: none;
          outline: none;
          background: $teal-tint;
          border-color: $teal-tint;
        }
       }
    }
  }

  .quote_member_name{
    word-break: break-word;
  }
}


.cstm_sidebar {
  .multiSelectDropdown {
    .p-overlay{
      left: -2px !important;
    }
    .p-multiselect-panel {
      .p-multiselect-items-wrapper {
        max-height: 195px !important;
        .country-item{
          .quote_member_name{
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 18px;
            color: #444444;
            word-break: break-word;
          }
          .quote_member_email_id{
            p{
              font-style: normal;
              font-weight: 400;
              font-size: 0.8125rem;
              line-height: 18px;
              color: #444444;
              word-break: break-word;
            }
          }
        }
      }
    }
  }
  .p-sidebar-footer{
    display: none;
  }
  .p-sidebar-content{
    height: 100%;
  }

}

.date_picker_wrapper .custom-datepicker {
  width:100%;
  .p-inputtext {
    width: 100%;
  }
}
.multiSelectDropdown.p-multiselect{
    min-height: 50px;
}

.filter_dropdown_sec {
  .p-listbox-list-wrapper {
    min-height: 241px;
  }
}


.brand_dropdown .p-listbox .p-listbox-header .p-listbox-filter-icon.pi-times,
.property_dropdown .p-listbox .p-listbox-header .p-listbox-filter-icon.pi-times,
.for_item_modal .p-listbox .p-listbox-header .p-listbox-filter-icon.pi-times,
.user_dropdown .p-listbox .p-listbox-header .p-listbox-filter-icon.pi-times{
  background: url("/assets/images/quotes/ic_cross.svg");
  width: 22px;
  height: 22px;
  transform: translateY(-50%);
  background-size: 100%;
  cursor: pointer;
  margin-right: 4px;
  margin-top: 0;
  &:before{
    content: "";
  }
}


.card_tooltip{
  display:flex;
  margin-left: 6px;
  position: relative;
  .p-tooltip {
    padding: 0.25em 0.5rem;
    width: 290px!important;
    max-width: 290px!important;
    left: -140px !important;
    top: -75px!important;
    .p-tooltip-arrow {
      bottom: -6px;
      left: 50%;
      margin-left: -.25rem;
      border-width: 0.65em 0.45em 0;
      border-top-color: #292929;
    }
  }
  .p-tooltip-bottom .p-tooltip-arrow {
    bottom: -6px;
    left: 50%;
    margin-left: -.25rem;
    border-width: 0.65em 0.45em 0;
    border-top-color: #292929;
  }
  .p-tooltip .p-tooltip-text {
    background: #1D2939;
    color: #ffffff;
    padding: 8px 10px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius:2px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
  }

  .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #1D2939;
  }
  .icon{
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
}

.mobile_filter_wrapper {
  .p-accordion {
    .status_filter {
      .p-accordion-content {
        .body_content {
          position: relative;
          top: -10px;

          .p-listbox {
            padding-bottom: 0;
          }
        }
      }
    }

    .brand_filter .p-accordion-content {
      padding-left: 30px;

      .body_content {
        top: 0;

        .p-listbox {
          width: 100%;
          padding-bottom: 22px;

          .p-listbox-list {
            padding: 0;

            li {
              padding: 8px 0;
            }
          }
        }

        .filter_dropdown_sec.brand_dropdown {
          width: 100%;
        }

        .p-listbox-list-wrapper {
          margin-top: 14px;
          @include phonel{
            max-height: 230px !important;
            min-height:230px !important;
          }
        }
      }
    }
    .property_filter .p-accordion-content {
      padding-left: 30px;

      .body_content {
        top: 0;

        .p-listbox {
          width: 100%;
          padding-bottom: 22px;

          .p-listbox-list {
            padding: 0;

            li {
              padding: 8px 0;
            }
          }
        }

        .filter_dropdown_sec.property_dropdown {
          width: 100%;
        }

        .p-listbox-list-wrapper {
          margin-top: 14px;
          @include phonel{
            max-height: 230px !important;
            min-height:230px !important;
          }
        }
      }
    }

    .user_filter .p-accordion-content{
      padding-left: 33px;
      .p-listbox .p-listbox-list li{
        padding: 0 0 8px;
      }
      .p-listbox-list-wrapper{
        overflow-y: auto;
        margin-top: 14px;
        @include phonel{
          max-height: 230px !important;
          min-height:230px !important;
        }
      }
    }
      .p-accordion-content {
      padding-left: 15px;

      .body_content {
        //position: relative;
        //top: -10px;
        .p-listbox{
          padding-bottom: 22px;
          width:100% !important;
        }

        .p-listbox-header {
          background: none;
          border: none;
          padding: 0;

          .p-checkbox {
            display: none;
          }

          .p-listbox-filter-container input {
            border: none;
            box-shadow: none;
            padding: 10px 50px 10px 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 21px;
            height: 50px;
            color: #444444;
            border: 2px solid $border-light;
            &:focus{
              border: 2px solid $fc-secondary;
            }
            &::-webkit-input-placeholder { /* Edge */
              color:$fc-hint;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color:$fc-hint;
            }

            &::placeholder {
              color:$fc-hint;
            }
          }
        }
      }
    }
  }
  .p-accordion-tab{
    border-bottom: 1px solid $border-light;
  }
  .p-accordion-header {
    .p-accordion-header-link {
      border: none;
      background: #fff!important;
      border-radius: 0;
      padding: 16px 0;
      box-shadow: none !important;
      .p-accordion-header-text{
        display: none;
      }
      .p-accordion-toggle-icon{
        position: absolute;
        right: 0;
        transform: rotate(90deg);
        color:$fc-primary;
        &.pi-chevron-down{
          background: none;
          right: 0 !important;
          transform: rotate(-180deg);
          &:before {
          @include remy(16);
          background: none;
            color:$orange;
        }
        }
      }
      .acc_header{
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        @include remy(20);
        line-height: 24px;
        color:$fc-primary;
        .icon{
          display: inline-flex;
          min-width: 20px;
          align-items: center;
          justify-content: center;
          margin-right: 11px;
        }
        .select_count{
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          color:$fc-secondary;
          margin-left:4px;
        }
      }
    }
    &.p-highlight{
      .p-accordion-header-link{
        background: #fff !important;
        .acc_header{
          color:$orange;
          .icon{
            svg path{
              stroke:$orange;
            }
          }
          .select_count{
            color:$orange;
          }
        }
      }
    }
  }
}

.unvailable{
  //position: relative;
  &.p-tooltip {
    padding: 0.25em 0.5rem;
    width: 180px!important;
    max-width: 180px!important;
    //left: -8px !important;
    //top: -50px!important;
  }

  &.p-tooltip-right .p-tooltip-arrow {
   // bottom: -7px;
    top: 50%;
    left: -2px;
    margin-top: 0px;
    transform: translateY(-50%);
    border-width: 8px 12px 8px 0;
  }
  &.p-tooltip .p-tooltip-text {
    background: #1D2939;
    color: #ffffff;
    padding: 8px 10px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius:2px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
  }

  &.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #1D2939;
  }
  .icon{
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
}
.cart_page {
  .mobile_cart_summary .p-accordion .accordian_card .p-accordion-header .p-accordion-header-link:after {
    top: 28px;
  }
}

.aap_toast_wrap{
  position: fixed;
  bottom: 85px;
  right: 20px;
  z-index: 12000;
  @include phones{
    right: 12px;
    bottom: 65px;
  }
}
.move-toast{
  .aap_toast_wrap{
    bottom: 90px;
  }
}
.cstm_toast_success{
  &.toast:not(.show){
    display: flex;
  }
  padding: 20px 23px 20px 76px;
  min-height: 75px;
  @include phonel{
    padding: 20px 20px 20px 57px;
    min-height: 65px;
  }
  display: flex;
  align-items: center;
  background: #E3FFF5;
  box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.06), 0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 12px;
  border: none;
  transform: translateX(100%);
  margin:0;
  z-index: 5000;
  opacity: 0;
  visibility: hidden;
  display: flex;
  transition-delay: 3s;
  margin-bottom: 10px;
  @include trans2s;
  min-width: 275px;
  max-width: 350px;
  width: auto;
  @include phonel{
    min-width: initial;
   @include phones{
     max-width: 290px;
   }
  }
  &:last-child{
    margin-bottom: 0;
  }
  &.show{
    right:0px;
    opacity: 1;
    visibility: visible;
    display: flex;
    transform: translateX(0px);
  }
  .toast-body{
    padding: 0;
    .toast_content_wrapper{
      display: flex;
      align-items: center;
      .p-toast-detail{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        font-family: 'Helvetica Neue';
        color: #333333;
        margin-top: 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        .toast-text{
          display: inline-block;
          vertical-align: middle;
          margin-right: 3px;
        }
        a{
          position: relative;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          vertical-align: middle;
          line-height: 22px;
          display: inline-block;
          max-width: 247px;
          color: #333333;
          text-decoration: none;
          white-space: nowrap;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc(100% - 6px);
            height: 1px;
            background-color: #444;
          }
        }
      }
    }
  }
  .pi-check:before{
    background:url("/assets/images/account_mg/toast_icon.svg");
    width:34px;
    height:34px;
    content: "";
    position: absolute;
    left: 27px;
    top: 50%;
    transform: translateY(-50%);
    background-size: 100%;
    @include phonel{
      width:25px;
      height:25px;
      left: 20px;
    }
  }
}

.cstm_toast_error{
  &.toast:not(.show){
    display: flex;
  }
  padding: 20px 27px 20px 76px;;
  min-height: 75px;
  @include phonel{
    padding: 20px 20px 20px 57px;;
    min-height: 65px;
  }
  display: flex;
  align-items: center;
  background: #FBD0D0;
  box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.06), 0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 12px;
  border: none;
  transform: translateX(100%);
  margin:0;
  z-index: 5000;
  opacity: 0;
  visibility: hidden;
  display: flex;
  transition-delay: 3s;
  margin-bottom: 10px;
  min-width: 275px;
  max-width: 350px;
  width: auto;
  @include phonel{
    min-width: initial;
   @include phones{
     max-width: 290px;
   }
  }
  @include trans2s;
  &:last-child{
    margin-bottom: 0;
  }
  &.show{
    right:0px;
    opacity: 1;
    visibility: visible;
    display: flex;
    transform: translateX(0px);
  }
  .toast-body{
    padding: 0;
    .toast_content_wrapper{
      display: flex;
      align-items: center;
      .p-toast-detail{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        font-family: 'Helvetica Neue';
        color: #333333;
        margin-top: 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        a{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #333333;
          text-decoration: underline;
          word-break: break-word;
        }
      }
    }
  }
  .pi-times-circle:before{
    background:url("/assets/images/account_mg/toast_error.svg");
    width:34px;
    height:34px;
    content: "";
    position: absolute;
    left: 27px;
    top: 50%;
    transform: translateY(-50%);
    background-size: 100%;
    @include phonel{
      width:25px;
      height:25px;
      left: 20px;
    }
  }
}


.unavailable_items .product_image_list .product_img.mob_show{
  .p-tooltip-left .p-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -.25rem;
    border-width: 8px 0px 8px 9px;
    border-left-color: #1D2939;
  }

  .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #1D2939;
    border-width: 8px 8px 0px 8px;
    bottom: -3px;
  }
}


.intercom-namespace .intercom-1dwjqh,
.intercom-lightweight-app,
.intercom-messenger-frame{
  z-index:990 !important;
}


btn:first-child:active{
  background-color: #c65144;
}




.referal_accordian{
  display: none;
    @include phonel{
      display: block;
      .mobile_cart_summary {
        border-top: none;
        .p-accordion .accordian_card {
           .p-toggleable-content{
             top: 0;
           }
          .p-accordion-header .p-accordion-header-link {
            padding: 0px 0 10px 0;

            &:after {
              top: 20px;
            }
          }
        }
      }
        .summary_header_wrapper {
          h3{
            font-style: normal;
            font-weight: 300;
            font-family:$font_Souvenir_light;
            @include remy(28);
            line-height: 34px;
            color:$fc-primary ;
            margin-bottom: 10px;
          }
          p {
            font-style: normal;
            font-weight: 400;
            @include remy(16);
            line-height: 19px;
            color: $fc-secondary;
          }
        }

    }
    .container{
      padding: 0;
    }

  .referral_card_summary{
      border-top: 1px solid #E0E1E5;
      padding-top: 24px;
    padding-bottom: 24px;
    .link_4{
      font-style: normal;
      font-weight: 400;
      @include remy(15);
      line-height: 18px;
      color:$fc-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: underline;
    }
  }

}


.brandbar_bottom_wrapper{
    .p-tooltip {
      padding: 0.25em 0.5rem;
      width: 323px !important;
      max-width: 323px !important;
      bottom: 30px !important;
      top:initial !important;
      z-index: 8 !important;
      @media (min-width: 991px){
        left: -156px !important;
      }
      @media (min-width:620px) and (max-width:830px){
        right: -50px;
        left: initial !important;
      }
      @media (min-width:480px) and (max-width:600px){
        right:initial;
        left: -40px !important;
      }
      @include phones{
        width: 250px !important;
        max-width: 250px !important;
      }
      @media (max-width:390px){
        right: -30px !important;
        left: initial !important;
        @media (max-width:329px){
          right: initial !important;
          left: -30px !important;
        }
      }
      .p-tooltip-text {
        background: #1D2939;
        color: #ffffff;
        padding:12px 14px;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        border-radius:2px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
      }
      .p-tooltip-arrow {
        top: initial !important;
        bottom: -5px !important;
        left: 50%;
        margin-left: -0.25rem;
        border-width: 0.65em 0.45em 0 !important;
        @media (min-width:620px) and (max-width:830px){
          left:81%;
        }
        @media (min-width:480px) and (max-width:600px){
          left:14%;
        }

        @media (max-width:390px){
          left: 85%;
          @media (max-width:329px){
            left:14%;
          }
        }
      }
       &.p-tooltip-bottom .p-tooltip-arrow {
          border-bottom-color: #1D2939;
       }
      &.p-tooltip-left .p-tooltip-arrow,
      &.p-tooltip-right .p-tooltip-arrow{
        border-top-color: #1D2939;
        border-left-color:transparent;
        border-right-color:transparent;
      }

      &.p-tooltip-top .p-tooltip-arrow {
        border-top-color: #1D2939;
      }
    }
}

.delivery_fee .percentage_off{

  p{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 10px;
    @include phonel{
      padding-left: 0;
    }
  }
  br{
      display: none;
  }
}


.p-virtualscroller-content{
  margin-top: 12px;
}
.p-virtualscroller-item{
  height: initial !important;

}


@include phonel {
  p-virtualscroller {
    height:calc(100% - 200px) !important;
    display: block;
    .p-scroller {
      //height:calc(100% - 200px) !important;

    }
  }
}


//action btns align
.quote_action_btn_outer{
  display: flex;
  align-items: center;

  .move_to_icon{
    margin-right: 17px;
  }
  .edit_icon{
    margin-right: 22px;
  }
  .remove_icon{

  }

}

.quote_move_wrap {
  .quote_move_btn {
    border: none;
    background: none;
    margin-right: 22px;
    @include phonel{
      margin-right: 42px;
      @media screen and (max-width: 369px) {
        margin-right: 22px;
      }
    }

    &:after {
      display: none;
    }
  }
  .dropdown-menu{
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.12), 0px 4px 6px -2px rgba(16, 24, 40, 0.07);
    border-radius: 4px;
    min-width: 300px;
    transform: translate3d(0px, 32px, 0px) !important;
    padding: 0;
    //min-height: 260px;
    //min-height: 275px;
    min-height:276px;
    overflow: hidden;
    z-index: 2;
    @include phonel{
      transform: translate3d(-10px, 32px, 0px) !important;
    }
    @include phone-sm{
      min-width: 269px;
    }
    .dropdown_listing{
      max-height:215px;
      overflow-y: auto;
    }
    .dropdown_header{
      /*height:45px;
      border-bottom: 1px solid #E0E1E5;
      display: flex;
      align-items: center;
      padding: 0 12px;*/
      .close_btn{
        width:20px;
        min-width: 20px;
        height:20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        margin-left: auto;
        @include trans2s;
        &:hover,
        &:focus{
          opacity: 0.7;
        }
      }
    }
    .dropdown_listing{
      padding: 15px 0px 15px;
      li{
        margin-bottom:0px;
        a{
          padding: 0;
          font-style: normal;
          font-weight: 400;
          @include remy(16);
          line-height: 22px;
          display: flex;
          align-items: center;
          @include trans2s;
          padding: 10px 30px;
          &:hover{
            background: rgba(#5592A0 , 0.1);
          }
          &:focus{
            background: rgba(#5592A0 , 0.1);
          }
          &:focus{
            background: rgba(#5592A0 , 0.1);
          }
          &.active,
          &:active{
            color:$fc-primary;
          }
          .icon{
            display: inline-flex;
            margin-right: 8px;
            width:22px;
            justify-content: center;
          }
          .arrow_icon{
            margin-left:6px;
          }
        }
      }
    }
  }
}

//quote action button
.quote_action_btn {
  display: inline-flex;
  align-items: center;
  line-height: 19px;
  transition: .2s all ease;
  border: none;
  background: transparent;
  &:disabled{
    opacity: 0.3;
    &:hover{
      color:$fc-primary;
      .el_icon path{
        stroke: $fc-primary;
      }
      .el_text{
        color:$fc-primary;
      }
      .el_icon_1 path{
        fill:$fc-primary;
      }
    }
  }
  .el_icon {
    display: inline-flex;
    align-items: center;
    margin-right: 6px;
  }

  .el_text {
    display: inline-flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    @include remy(16);
    line-height: 19px;
    text-decoration-line: underline;
    color: $fc-primary;
  }

  .el_icon_1 {
    display: inline-flex;
    align-items: center;
    margin-left: 6px;
  }

  &:hover {
    .el_icon {
      path {
        stroke: $orange;
      }
    }

    .el_text {
      color: $orange;
    }

    .el_icon_1 {
      path {
        fill: $orange;
      }
    }
  }
  &:disabled, &.disabled {
    background-color: transparent;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-primary;

  }
  &.show{
    .el_text,
    .el_icon path{
      color:$orange;
      stroke:$orange;
    }
    .el_icon_1 path{
      fill: $orange;
    }
  }

}

.quotelist_inner_ui {
  // position: fixed;
  position: absolute;
  width: 100%;
  left: 100%;
  top: 0;
  background: #fff;
  min-height: 260px;
  opacity:0;
  visibility: hidden;
  @include trans2s;
  &.active{
    opacity: 1;
    left: 0;
    visibility: visible;
  }
  .p-listbox-header{
    padding: 0;
  }
  .search_bar{
    position: relative;
    display: flex;
    .back_list{
      padding: 0;
      width:24px;
      height:24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
    }
    input{
      border:none;
      padding:0 12px;
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 22px;
      color:$fc-primary;
      &::-webkit-input-placeholder{
        color: $fc-hint;
      }
      &:-ms-input-placeholder{
        color: $fc-hint;
      }
      &::placeholder{
        color: $fc-hint;
      }
    }
  }


  .quote_list{
    max-height: 230px;
    min-height: 230px;
    overflow-y: auto;
    padding:0px 0 50px 0;
    margin-top: 45px;
    li{
      a{
        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 22px;
        color:$fc-primary;
      }
    }
    .listbox-list-wrapper{
      li{
        &:focus{
          background: rgba(#5592A0 , 0.1);
        }
      }
    }
  }

}


.move_new_quote {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  .new_quote_btn{
    padding: 13px 30px 18px;
    width: 100%;
    background: #fff;
    border: none;
    border-top:1px solid #E0E1E5;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    @include remy(15);
    line-height: 18px;
    color:$orange;
    .icon{
      display: inline-flex;
      margin-right: 8px;
    }
    &:active,
    &:focus{
      color:rgba($orange , 0.8);
      .icon{
        svg path{
          stroke:rgba($orange , 0.8);
        }
      }
    }
  }
}

.mob_quote_detail_wrapper{
  .multiselect_w_check {
   .multiSelectDropdown .p-overlay{
      min-width: 100% !important;
    }
    .p-multiselect-items .p-multiselect-item{
    .country-item{
      .quote_member_name{
        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 18px;
        color:$fc-primary;
        word-break: break-word;
      }
      .quote_member_email_id {
        p {
          font-style: normal;
          font-weight: 400;
          @include remy(13);
          line-height: 18px;
          color: $fc-primary;
          word-break: break-word;
        }
      }
    }
  }
  }
}

.order_detail_page {
  .quote_move_wrap .dropdown-menu {
    @include phonel {
      transform: translate3d(-155px, 32px, 0px) !important;
    }
  }
}



.cstm_zindex{
  z-index: 21 !important;
}

.cstm_zindex_1000 {
  z-index: 1000 !important;
}

.a-management-modal {
  .autocomplete_wrapper {
    .p-autocomplete {
      display: flex;

      input {
        &:hover {
          border-color: #E0E1E5;
        }

        &:focus {
          box-shadow: none;
          border-color: #96999F;
        }
      }
    }
    p-autocomplete.ng-touched.ng-invalid > .p-autocomplete > .p-inputtext {
      border-color: #e24c4c;
    }
  }

  .autocomplete_wrapper {
    .p-autocomplete{
      width: 100%;
    }
    .p-autocomplete-panel {
      border: 2px solid #E0E1E5;
      filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2));
      border-radius: 4px;

      .p-autocomplete-items {
        padding: 4px 0px;

        .p-autocomplete-item {
          padding: 5px 14px;
          background: transparent;
          color:$fc-secondary;
          &.p-highlight{
            background-color: rgba(85, 146, 160, 0.1);
          }
          span{
            //color:$fc-secondary !important;
          }

          &:hover {
            //background: rgba(85, 146, 160, 0.1);
            background: rgba(85, 146, 160, 0.04);
          }

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $fc-primary;
          }
        }
      }
    }
  }
}



.quote_cart_middle_inner {
  .cstm-input-number .p-inputnumber {
    min-width: 80px;
    height: 40px;
    .p-inputtext{
      height:100%;
      padding:0 8px;
      text-align: right;
      @include remy(16);
      font-weight: 400;
      font-family: 'Helvetica Neue';

    }
  }
}

//quote and order credit tooltip starts here
.minoan_credit_tooltip{
  position: relative;
  .tooltip{
    transform: translate3d(-5px, -33px, 0px) !important;
    --bs-tooltip-max-width: 237px!important;
    width: 237px;
    --bs-tooltip-bg: #292929!important;
    border-radius: 2px;
    padding: 0px;
    --bs-tooltip-opacity: 1!important;
    opacity: 1;
    .tooltip-arrow{
      transform: translate3d(104px, -1px, 0px) !important;
      &:before{
        top: 1px;
        left: 2px;
        border-top-width: 19px;
        border-left-width: 11px;
        border-right-width: 11px;
        border-bottom-width: 7px;
      }
    }
    .tooltip-inner{
      padding: 9px 17px 12px 20px;
      .tooltip_credit_text,.check_credit_text{
        font-size: 15px!important;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        color: #fff!important;
        white-space: initial!important;
        text-align: left;
        margin-bottom: 7px;
        span{
          font-size: 15px;
          font-style: normal;
          text-align: left;
          font-weight: 700;
          color: #fff;
        }
        &:last-child{
          margin-bottom: 0px;
          text-align: left!important;
          padding-left: 0px!important;
        }
      }

    }
  }
  &.showroom_prop_tooltip{
     .tooltip {
       transform: translate3d(-51px, -90px, 0px) !important;
       width: 257px !important;
       inset: initial !important;
       .tooltip-arrow {
         transform: translate3d(24px, 0px, 0px) !important;
       }
       .tooltip-inner {
         padding:15px 14px 15px 20px;
         max-width: 100%;
         border-radius: 2px;
         background: #292929;
         text-align:left;
       }
     }

  }
}

.showroom_bottom_tooltip{
  position: relative;
  &.viewers{
    @include phonel{
      .tooltip {
        transform: translate3d(-11px, 36px, 0px) !important;
        .tooltip-arrow:before {
          left: -40px;
        }
      }
    }
  }
  &.clicks{
    .tooltip {
      transform: translate3d(-75px, 36px, 0px) !important;
    }
  }
  &.earnings{
    .tooltip {
      width: 251px!important;
      transform: translate3d(-57px, 36px, 0px) !important;
      @include phonel{
        transform: translate3d(-164px, 36px, 0px) !important;
      }
      .tooltip-arrow {
        @include phonel{
          transform: translate3d(207px, -30px, 0px) !important;
      }
      }
    }
  }
  &.orders{
    .tooltip {
      transform: translate3d(-69px, 36px, 0px) !important;
    }
  }
  .tooltip {
    transform: translate3d(-63px, 36px, 0px) !important;
    width: 246px!important;
    --bs-tooltip-max-width: 246px!important;
    --bs-tooltip-bg: #292929!important;
    border-radius: 2px;
    padding: 0px;
    --bs-tooltip-opacity: 1!important;
    opacity: 0;
    transition: 0.3s ease-in-out;
    .tooltip-arrow {
      transform: translate3d(104px, -30px, 0px) !important;
      &:before {
        top: 15px;
        left: 17px;
        border-top-width: 5px;
        border-left-width: 7px;
        border-right-width: 7px;
        border-bottom-width: 10px;
      }
    }

    .tooltip-inner {
      padding: 11px 12px 14px 19px;
      max-width: 100%;
      border-radius: 2px;
      background: #292929;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #ffffff;

    }
  }
  .tooltip.show {
    transition: 0.3s ease-in-out;
    opacity: 1;
  }

}

//quote and order credit tooltip ends here

//checkout credit tooltip starts here

.ckeckout_credit_tooltip{
  position: relative;
  .tooltip{
    transform: translate3d(92.25px, 44px, 0px) !important;
    --bs-tooltip-max-width: 237px!important;
    width: 237px;
    --bs-tooltip-bg: #292929!important;
    border-radius: 2px;
    padding: 0px;
    --bs-tooltip-opacity: 1!important;
    opacity: 1;
    @include tabv{
      transform: translate3d(47.25px, 44px, 0px) !important;
    }
    .tooltip-arrow{
      transform: translate3d(104px, 2px, 0px)!important;
      &:before{
        bottom: 2px;
        border-top-width: 8px;
        border-bottom-width: 19px;
        border-left-width: 12px;
        border-right-width: 12px;
      }
    }
    .tooltip-inner{
      padding: 9px 17px 12px 20px;
      .check_credit_text{
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        color: #fff;
        text-align: left;
        margin-bottom: 7px;
        span{
          font-weight: 700;
        }
        &:last-child{
          margin-bottom: 0px;
        }
      }

    }
  }
}
//checkout credit tooltip ends here



//Estimated Pickup Availability tooltip starts here

.estimate_delivery_tooltip{

  position: relative;
  .tooltip{
    transform: translate3d(-108.75px, 40px, 0px) !important;
    --bs-tooltip-max-width: 229px!important;
    width: 229px;
    --bs-tooltip-bg: #fff!important;
    border-radius: 2px;
    --bs-tooltip-color: #444!important;
    padding: 0;
    --bs-tooltip-opacity: 1!important;
    opacity: 1;
    filter: drop-shadow(0px 4px 6px rgba(16, 24, 40, 0.07)) drop-shadow(0px 2px 24px rgba(16, 24, 40, 0.25));

    //transform: translate3d(-63.75px, 55px, 0px)!important;
    //--bs-tooltip-max-width: 237px!important;
    //width: 237px;
    //--bs-tooltip-bg: #292929!important;
    //border-radius: 2px;
    //padding: 0px;
    //--bs-tooltip-opacity: 1!important;
    //opacity: 1;
    @include phonel{
      width: 80vw;
      //transform: translate3d(-183.75px, 35px, 0px) !important;
      transform: translate3d(-156.75px, 35px, 0px) !important;
      @media (max-width: 420px){
        width: 90vw;
      }

    }
    .tooltip-arrow{
      transform: translate3d(104px, 0px, 0px)!important;
      @include phonel{
        display: none;
      }
      &:before{
        bottom: 0;
        border-top-width: 4px;
        border-bottom-width: 20px;
        border-left-width: 13px;
        border-right-width: 13px;

      }
    }
    .tooltip-inner{
      //padding: 9px 17px 12px 20px;
      padding: 18px 21px 21px 29px;
      @include phonel{
        max-width: 70%;
        min-width: 363px;
        padding: 23px 28px;
        @include phones{
          min-width: 360px;
          @media (max-width: 420px){
            max-width: 99%;
            min-width: initial;
          }

        }
      }
      .tooltip_content_outer{
        color: #444;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        position: relative;
        .cross-icon-pickup{
          position: absolute;
          top: -9px;
          right: -9px;
        }
        .pickup_location{
            font-weight: 700;
          margin-bottom: 4px;
        }
        .pickup_store{
          font-weight: 500;
        }
        .pickup_address{

        }
      }
    }
  }
}
.estimate_delivery_tooltip{
  .mob_hide{
   @include phonel{
     display: none;
   }
  }
  .mob_show{
    display: none;
    @include phonel{
      display: inline-block;
    }

  }

}





//Estimated Pickup Availability tooltip ends here


.date_picker_wrapper{
  p-calendar{
    width: 100%;
    position: relative;
    display: inline-flex;
    .p-calendar{
      max-width:312px;
      min-width:312px;
      .p-calendar-w-btn{
        .p-focus{
          opacity: 1;
          visibility: visible;
        }
      }
      &.highlightToday{
        max-width:268px;
        min-width:268px;
      }
      &.greyText{
        max-width:268px;
        min-width:268px;
        color:#C7C7C7;
        &:after{
          background: url("/assets/images/che-icon-grey.svg") no-repeat;
          transform: initial;
          top: 14px;
        }
      }
      &:after{
        position: absolute;
        content: "";
        display: inline-block;
        width: 8px;
        top: 13px;
        right:-13px;
        height: 5px;
        background: url("/assets/images/che-icon.svg") no-repeat;
        background-size: contain;
        transform: rotate(180deg);
      }
      &.blackText{
        &:after{
          transform: initial;
          top: 14px;
        }
      }
    }


    //&.ng-valid{
    //  &:after{
    //    top: 13px;
    //    right: 150px;
    //    transform: initial;
    //  }
    //}
    //&.p-inputwrapper-focus{
    //  &:after{
    //    top: 13px;
    //    right:-14px;
    //    transform:initial;
    //  }
    //}

  }


}

//.custom-datepicker highlightTod{
//  &:after{
//    position: absolute;
//    content: "";
//    display: inline-block;
//    width: 10px;
//    top: 14px;
//    right: 150px;
//    height: 5px;
//    background: url(/assets/images/che-icon.svg) no-repeat;
//    background-size: contain;
//
//  }
//
//}
//.custom-datepicker blackText{
//  &:after{
//    top: 13px;
//    right: 150px;
//
//  }
//
//}

.delivery_request_txt{
  max-width: 287px;
}


.quote_banner_outer{
  .quote_banner_mobile{
    .mobile_cart_summary{
      .p-accordion {
        .accordian_card {
          .p-accordion-header {
            .p-accordion-header-link {
              padding: 23px 0 6px 0!important;
              &:after {
                background: url("/assets/images/quote-banner-icon1.svg") no-repeat;
                top: 39px;
              }

            }
          }
        }
      }
    }
  }
}






.white_glove_tooltip{
  position: relative;
  .tooltip{
    transform:translate3d(116.25px, 40px, 0px) !important;
    --bs-tooltip-max-width: 251px!important;
    width: 251px;
    --bs-tooltip-bg: #292929;
    border-radius: 2px;
    padding: 0;
    --bs-tooltip-opacity: 1!important;
    opacity: 1;
    @include tabv{
      transform: translate3d(70.25px, 40px, 0px) !important;
      @include phonel{
        transform: translate3d(20.25px, 37px, 0px) !important;
      }
    }

    .tooltip-arrow{
      transform: translate3d(169px, 0px, 0px) !important;
     @include tabv{
       transform: translate3d(215px, 0px, 0px) !important;
       @include phonel{
         transform: translate3d(226px, 0px, 0px)!important;
       }
     }
      &:before{
        bottom: 6px;
        border-top-width: 8px;
        border-bottom-width: 10px;
        border-left-width: 7px;
        border-right-width: 7px;
      }
    }
    .tooltip-inner{
      padding: 12px 19px 13px 19px;
      border-radius: 2px;
      p{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        color: #fff;
        margin-bottom: 7px;
      }

    }
  }
}



.product_carousel_wrapper{
  margin-bottom: 0px;
  @include phonel{
    margin-bottom: 22px;
    display: none;
  }
  .slick-slide {
    padding: 0px 31px 0px 0px;
  }
  &.lastslide{
    .slick-list{
      &:after{
        display: none;
      }
    }
  }
  .slick-arrow.slick-disabled{
    + .slick-list{
      &:before{
        display: none;
      }
    }
  }
  .slick-list{
    .slick-slide{
      &:first-child {
        .brand_card {
          padding-left: 0px;
        }
      }
    }
    @include phonem{
      margin: 0 -20px;
      .slick-slide{
        &:first-child{
          //padding-left: 20px;
          margin-left:20px
        }
      }
    }
    &:after{
      display: none;
      position: absolute;
      content: "";
      background: linear-gradient(270deg, #FFFFFF 6.58%, rgba(255, 255, 255, 0) 100%);
      height: 64px;
      width:40px;
      top:0;
      right:0px;
      opacity: 1;
      @include phonel{
        right:-3px;
        // width:40px;
        height:100%;
      }
    }

    &:before{
      display: none;
      position: absolute;
      content: "";
      background: linear-gradient(270deg, #FFFFFF 6.58%, rgba(255, 255, 255, 0) 100%);
      transform:rotate(180deg);
      height: 64px;
      width:40px;
      top:0;
      left:0px;
      z-index: 1;
      opacity: 1;
      @include phonel{
        left:-3px;
        // width:60px;
        height:100%;
      }
    }
  }
  .slick-arrow{
    display: none;
    background: #FFFFFF;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid #C7C7C7;
    z-index: 3;
    @include  trans2s;
    @include phonel{
      width:36px;
      height:36px;
      opacity: 0;
      &:focus{
        opacity: 1;
      }
    }
    &:hover{
      border: 1px solid $orange;
      transition: 0.2s ease-in-out;
      //box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    }
    &:before{
      display: none;
    }
    img{
      height: 14px;
      @include phonel{
        height:14px;
      }
    }
    &.slick-disabled{
      display:none !important;
    }
    &.slick-next{
      &:after{

      }
    }

    &.slick-next{
      right: -11px;
      @include phonel{
        right:-10px;
      }
    }
    &.slick-prev{
      left:-14px;
      @include phonel{
        left:10px;
      }
    }
  }

}



.add_item_modal_check{
  .custom-checkbox-group {
    align-items: center;
    .custom-checkbox-button {
      width: 20px;
      height: 20px;
      .p-checkbox-box {
        width: 20px;
        height: 20px;
      }
    }
    .checkbox-button-label {
      font-family: 'Helvetica Neue md';
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #444444;
      display: inline-flex;
      align-items: center;

    }
    .space_icons{
      margin-right: 5px;
      display: inline-flex;
      align-items: center;
    }
  }

}


.new_property_wrapper{
  margin-top: 54px;
  .multiselect_group_by .multiSelectDropdown {
    height: 73px;
  }
  .multiselect_group_by .p-multiselect.p-multiselect-chip .p-multiselect-label {
    //padding: 12px 28px 10px 16px;
    padding: 23px 30px 10px 16px;
    align-items: center;
    height: 100%;
    font-size: 22px!important;
    font-weight: 300;
    line-height: 23px;
    text-align: left;
    color: #444444;
  }

}


.showroom_prop_drop{
  border: 2px solid #E0E1E5;
  border-radius: 4px;
  @media (max-height:915px){
    transform: translate(0px, -4px);
  }

  @media (min-height:915px){
    margin-top:2px;
  }
  .p-multiselect-header {
    background: #ffffff !important;
    padding: 0;
    border-bottom: none!important;
    .p-checkbox {
      display: none;
    }
    .p-multiselect-close {
      display: none;
    }
    .p-multiselect-filter-container{
      .p-multiselect-filter{
        border: 0px ;
        box-shadow: none;
        border: none;
        border-bottom: 2px solid #E0E1E5;
        border-radius: 0;
        padding: 0 50px 0 16px;
        height: 50px;
        font-size: 18px;
        line-height: normal;
        font-weight: 400;
        &::placeholder {
          color:$fc-hint;
        }
        &:enabled{
          &:focus{
            box-shadow: none;
          }
        }
      }
      .p-multiselect-filter-icon{
        transform:translateY(-50%);
      }
    }
  }
  .p-multiselect-items-wrapper {
    max-height: 206px !important;
    min-height: 206px !important;
  }
  .p-multiselect-items {
    padding-bottom: 16px;
    box-shadow:none ;
    padding-top: 0px;
    .p-multiselect-item-group {
      margin: 0;
      padding: 10px 16px 5px;
      background: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-family: 'Helvetica Neue md';
      font-size: 16px;
      line-height: 22px;
      color: #444;
    }
    .p-multiselect-empty-message {
      padding: .75rem 1.25rem;
      color: #495057;
      background: transparent;
    }
  }

  .p-multiselect-items-wrapper {
    .p-multiselect-items {
      .p-multiselect-item {
        padding: 8px 16px;
        word-break: break-word;
        white-space: normal;
        display: flex;
        background: none;
        &:hover{
          color: #495057;
          background: #e9ecef;
        }
        &[aria-checked="true"] {
          color: #444;
          background: rgba(85, 146, 160, .1019607843);
        }
        .p-checkbox {
          display: inline-flex;
          cursor: pointer;
          -webkit-user-select: none;
          user-select: none;
          vertical-align: bottom;
          position: relative;
          width: 22px;
          height: 22px;
          align-self: flex-start;
          margin-right: 0.5rem;
          .p-checkbox-box {
            border: 2px solid #E0E1E5;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            top: 2px;
            position: relative;
          }

        }
      }
      .p-multiselect-item>span {
        color: #444;
        font-size: 1rem;
        line-height: 22px;
        font-weight: 400;
      }
    }
  }
  .p-multiselect-items {
    .p-checkbox-box.p-highlight {
        color: #444444;
        border: 2px solid #5592A0 !important;
        background-color: #5592a0;
    }
    .p-multiselect-item:focus {
      box-shadow: none;
    }
  }
}


.form_error_ui{
  position:relative;
  &.mb-45{
    margin-bottom:45px !important;
  }
  .error_msg_ui{
    position:absolute;
    top:100%;
    left:0;
    width:100%;
  }
}



.add_item_assign_dropdown{
  .multiSelectDropdown .p-multiselect-panel .p-multiselect-items-wrapper {
    max-height: 170px !important;
    min-height: 170px !important;
    .p-multiselect-items .p-multiselect-item {
      padding: 7.5px 16px 7.5px 16px;
    }
  }
  .multiselect-ui-outer{
    .p-multiselect-label{
      .country-item{
        width: 100%;
      }
    }
  }
  .p-multiselect .p-multiselect-label {
    padding-right: 27px;
  }
  .country-item{
    color: #444444;
    font-size: 1rem;
    line-height: 22px;
  }
}

.showroom_commissions{
  &.payment_tooltip , &.minoan_credit_tooltip, &.ckeckout_credit_tooltip{
    .tooltip{
      width: 300px;
      .tooltip-inner{
        max-width: 100%;
        padding: 9px 15px 12px 15px;
      }
    }
  }
  &.ckeckout_credit_tooltip .tooltip{
    transform:translate3d(45.25px, 44px, 0px) !important;
    .tooltip-arrow {
      transform: translate3d(154px, 2px, 0px) !important;
    }
  }
}


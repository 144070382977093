@import "/src/assets/scss/layout/filters";

.height_100{
  height: 100%;
}
.loginpage_wrapper{
  width: 100%;
  .btn_primary{

    &:focus{
      background:$orange;

    }

    &:active{
      background:$orange-tint;
    }

  }
}
.container-fluid{
  padding: 0 15px;
}

.login_left_wrap{
  height:100vh;
  width:100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center ;
  position: sticky;
  top: 0;
  left: 0;
  @include phonel{
    display: none;

  }
}

.login_form_wrap{
  padding: 50px;
  @include tabm{
    padding: 30px;
    @include phonel{
      padding: 30px 24px;
    }
  }
  &.account_setup_wrapper{
    .header_wrap{
      margin-top:65px;
      @include phonel{
        margin-top:0px;
      }
    }
    .body_content_wrap{
      padding-top: 75px;
      @include phonel{
        padding-top: 45px;
      }
    }

  }
  .header_wrap{
    text-align: center;
    margin-top: 129px;
    display: flex;
    justify-content: center;
    @include phonel{
      margin-top: 0px;
    }
    .logo_ui {
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      img{
        width: 180px;
        @include phonel{
          width: 108px;
        }

      }
    }
  }
  .body_content_wrap{
    padding-top: 108px;
    max-width: 372px;
    margin: auto;
    position: relative;
    @include tabm{
      padding-top: 55px;
      @include phonel{
        padding-top:38px;
        max-width: 450px;
      }
    }
    &.mx_384{
      max-width: 384px;
    }
    &.forget_pass_wrap{
      padding-top: 144px;
      @include tabm {
        padding-top: 45px;
      }
      .para2{
        margin-top: 7px;
        margin-bottom: 31px;
        @include phonel{
          margin-top: 5px;
          margin-bottom: 24px;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  .login-pswd-error{
    top:51px;
    position: absolute;
    left: 0;
    &.mob_show{
      display: none;
    }
    .long_error{
      position: relative;
      top: -20px;
      a{
        color:#EA1212;
        text-decoration: underline;
      }
      @include tabm{
        top:0;
      }
    }
    .long_error2{
      font-size: 14px !important;
      //position: relative;
      //top: 8px;
    }
    @include tabm{
      top: 0;
      position: relative;
      margin-bottom: 30px;
      @include phonel{
        margin-bottom: 12px;
      }

      @include phonel{
        &.mob_hide{
          display: none;
        }
        &.mob_show{
          display: block;
        }
      }
    }
    .error-1{
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 19px;
      color:$fc-red;
      &.f14{
        @include remy(14);
      }
    }
  }
    .welcom_txt{
      font-style: normal;
      font-weight: 300;
      @include remy(20);
      line-height: 24px;
      color: $border-dark;
      margin-bottom: 11px;
      @include phonel{
        margin-bottom: 5px;
      }
    }

    h3{
      font-style: normal;
      font-weight: 300;
      @include remy(32);
      line-height: 38px;
      color: $fc-primary;
      font-family: $font_Souvenir_light;
      margin-bottom: 22px;
      @include tabm{
        @include remy(30);
        line-height: 36px;
        @include phonel{
          margin-bottom: 19px;

        }
      }
    }
    .btn_primary{
      width: 100%;
      height: 44px;
    }
    .back_to_lgn{
      justify-content: left;
    }
    .term_conditions{
      font-style: normal;
      font-weight: 400;
      @include remy(15);
      line-height: 21px;
      color: $fc-primary;
      a{
        color: #2B7FFF;
        @include  transition(0.2s ease-in-out);
        &:hover{
          text-decoration: underline;
          opacity:0.8;
        }
      }
    }
  }



  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:-internal-autofill-previewed {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background: none;
    font-size: 16px !important;
    font-weight: 400;

  }

  .link-btns{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s all ease;
    .el-icon{
      display: inline-flex;
      vertical-align: middle;
      margin-right: 7px;
      svg{
        max-width: 13px;
      }
    }
    .el-text{
      display: inline-flex;
      vertical-align: middle;
      font-style: normal;
      font-weight: 400;
      @include remy(15);
      line-height: 18px;
      color: $orange;
      @include phonel{
        @include remy(16);
        line-height: 19px;
      }

    }
    &:hover{
      .el-text{
        color:$orange-tint;
      }
      .inner-svg{
        stroke:$orange-tint;
      }
    }
    &:focus{
      .el-text{
        color: #C65144;
      }
      .inner-svg{
        stroke: #C65144;
      }
    }
  }

  .floating-label input[type="text"]{
    ~ .eye-icon{
      svg path{
        fill:$fc-primary;
      }
    }
  }

  .eye-icon{
    position: absolute;
    right: 0px;
    top:24px;
    padding: 0;
    display: inline-flex;border: 0px;
    padding-right: 0px;
    width:24px;
    height:14px;
    justify-content: flex-end;
    path{
      @include  trans2s;
    }
    &:hover{
      svg path{
        fill: $orange;
        @include trans2s;
      }
    }
    &.active{
      svg path{
        fill:$fc-primary;
      }
    }
    &:focus{
      outline: none;
      box-shadow: none;
    }
    &:active{
      svg path{
        fill: $fc-primary;
      }

    }

  }


}

.spacer25{
  height:25px;
}

.floating-label {
  position: relative;
  margin-bottom:30px;
  //margin-bottom:24px;

  .form-label{
    font-style: normal;
    font-weight: 400;
    @include remy(16);
    line-height: 19px;
    //padding: 10px 0px;
    color:$fc-secondary;
    position: absolute;
    top:20px;
    left: 0;
    pointer-events: none;
    transition: .4s;
    margin-bottom: 0;
    @include phonel{
      @include remy(18);
      line-height: 21px;
    }
  }

  .floating-input {
    padding: 16px 30px 0 0;
    background-color: $fc-white;
    border-bottom: 1px solid $border-light;
    border-top: none;
    border-left: none;
    border-right: none;
    height: 30px;
    color: $fc-primary;
    &:focus{
        outline: none;
        box-shadow: none;
      }
  }

  .floating-input,
  .floating-select {
    display: block;
    width: 100%;
    height: 50px;
    border: none;
    border-bottom: 1px solid $border-light;
    border-radius: 0px;
    @include remy(16);
    font-weight: 400;
    transition: all 0.25s ease;
    background: #FFFFFF;
    color:$fc-primary;

    &.filled{
      //background-color: $grey_1;
    }

    &:focus{
      outline: none;
      background-color: #ffffff;
      border-color: $border-light;
    }
  }

  .floating-select {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: calc(100% - 18px) center;
  }

  label,
  .highlight label {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 18px;
    -webkit-transition: 0.2s ease all;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;
    font-style: normal;
    @include remy(16);
    line-height: 19px;
    letter-spacing: 0.12px;
    color: #5F6B7C;
  }

  .floating-input:not(:placeholder-shown)
  {
    background-color:#FFFFFF;
    //border-color: $border-light;
  }

  .floating-input:focus ~ label,
  .floating-input:not(:placeholder-shown) ~ label{
    top: 5px;
    line-height: normal;
    @include remy(12);
    font-weight: 400;
    color: $fc-secondary;
    padding: 0px;
  }

  .floating-select:focus ~ label,
  .floating-select:not([value=""]):valid ~ label{
    top: 5px;
    @include remy(12);
    font-weight: 600;
    color: $fc-secondary;
    background-color: #fff;
    padding: 0 6px;
  }

  .floating-textarea {
    min-height: 130px;
    max-height: 260px;
    overflow: hidden;
    overflow-x: hidden;
    resize: none;
    padding: 16px 0 16px;
  }

}






.mobile_banner{
margin: 20px -24px 0px -24px;
  display: none;
  @include phonel{
    display: block;
  }
  .mobile_back_img{
    height:250px;
    width:100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center ;

  }
}


.floating-label .floating-input.phone-field-ui{
  color: rgba(#96999F , 1);
  &:focus{
    &::placeholder{
      color: rgba(#C7C7C7 , 1);
    }
  }
  &::placeholder{
    color: rgba(#96999F , 0);
  }
}
.floating-label .phone-field-ui.p-inputwrapper-focus:placeholder-shown ~ .form-label{
  top:5px;
  line-height: normal;
  font-size: 0.75rem;
  font-weight: 500;
  color: #96999F;
  padding: 0px;
}

.floating-label .phone-field-ui{
  padding: 0;
  border: none;
  color: rgba(#96999F , 0) ;
    input {
      width: 100%;
      padding:16px 0 0;
      height:100%;
      border: none;
      border-bottom: 1px solid #E0E1E5;
      border-radius: 0px;
      font-size: 1rem;
      font-weight: 400;
      transition: all 0.25s ease;
      background: #FFFFFF;
      color:#fff;
      &:-webkit-autofill{
        -webkit-text-fill-color: white;
      }

      &:hover{
        border-color: #E0E1E5;
      }
      &:focus{
        box-shadow: none;
        outline: none;
        border-color: #E0E1E5;
        color:$fc-primary;
      }
      &::placeholder{
        color: rgba(#96999F , 0) ;
      }
    }
  &.floating-input {
    &.p-inputwrapper-focus,
    &.p-inputwrapper-filled{
          input{
            color: $fc-primary;
          }
      + .form-label{
        top:5px;
        line-height: normal;
        font-size: 0.75rem;
        font-weight: 400;
        color: #96999F;
        padding: 0px;
      }
        input::placeholder{
          color: rgba(#96999F , 1);
        }
      }
    + .form-label {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 19px;
      color: #96999F;
      position: absolute;
      top: 20px;
      left: 0;
      pointer-events: none;
      transition: 0.4s;
      margin-bottom: 0;
    }
    }
  }



.account_setup_wrapper{
  padding-top: 0;
  @include phonel{
    padding-top: 30px;
  }
}

.loginpage_wrapper{
  .form-group .fg-inner{
    margin-bottom: 0;
  }
}

  .error_wrapper {
    position: absolute;
    //bottom: -23px;
    bottom: initial;
    top:100%;
    left: 0;
    margin-bottom: 0;
  }


  .signup_wrapper{
    @include remy(16);
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
    color: $fc-primary;
    margin-top: 25px;
    @include phonel{
      margin-top: 30px;
    }
    .sign_up{
      font-family: 'Helvetica Neue md';
      color: $orange;
      &:hover{
        color: #C65144;
        text-decoration: underline;
        text-underline-offset: 3px;
      }

    }
  }

  .help_login_text{
    margin-top: 12px!important;
    @include phonel{
      margin-top: 19px!important;
    }
    .el-text{
      color: $fc-secondary!important;
     font-size: 14px!important;
      line-height: 17px!important;
      &:hover{
        color: #666666!important;
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }

.heading1{
  font-style: normal;
  font-weight: 300;
  @include remy(54);
  line-height: 65px;
  color:$fc-primary;
}

.heading2{
  font-style: normal;
  font-weight: 300;
  @include remy(48);
  line-height: 58px;
  color:$fc-primary;

  @include tabm{
    @include remy(42);
    line-height: 52px;
    @include phonel{
      @include remy(32);
      line-height: 39px;
    }
  }
}
.heading3{
  font-style: normal;
  font-weight: 300;
  @include remy(42);
  line-height: 51px;
  color:$fc-primary;
  @include phonel{
    @include remy(28);
    line-height: 34px;
  }
}
.heading4{
  font-style: normal;
  font-weight: 700;
  @include remy(32);
  line-height: 39px;
  color:$fc-primary;
}
.heading5{
  font-style: normal;
  font-weight: 700;
  @include remy(20);
  line-height: 24px;
  color:$fc-primary;
  @include phonel{
    @include remy(18);
    line-height: 22px;
  }
}



.heading6{
  font-style: normal;
  @include remy(18);
  line-height: 22px;
}
.heading7{
  font-style: normal;
  font-weight: 300;
  @include remy(68);
  line-height: 82px;
}
.heading8{
  @include remy(28);
  font-style: normal;
  line-height: 34px;
}
.heading9{
  font-style: normal;
  font-weight: 300;
  @include remy(30);
  line-height: 36px;
  color: $fc-primary;
  font-family: $font_Souvenir_light;
}

.heading10{
  font-style: normal;
  //font-weight: 300;
  @include remy(40);
  line-height: 32px;
  color: $fc-primary;
  font-family:$font_Souvenir_light;
  @include tabl{
    @include remy(36);
    @include tabm{
      @include remy(30);
    }
  }
}

.heading11{
  font-style: normal;
  font-weight: 300;
  @include remy(46);
  line-height: 48px;
  font-family:$font_Souvenir_light;
  color: $fc-primary;
}

.heading12{
  font-style: normal;
  font-weight: 300;
  @include remy(40);
  line-height: 48px;
  font-family:$font_Souvenir_light;
  color: $fc-primary;
}



.brands-heading{
  @include remy(28);
  font-weight: 700;
  font-style: normal;
  line-height: 34px;
  color: $fc-primary;
  @include phonel{
    @include remy(22);
    line-height:27px;
  }
  .result-found, p{
    font-style: normal;
    font-weight: 300;
    @include remy(22);
    line-height: 27px;
    text-align: center;
    color:#666666;
    @include phonel{
      @include remy(14);
      line-height: 17px;

    }
  }
}

.h_title{
  font-style: normal;
  font-weight: 700;
  @include remy(24);
  line-height: 22px;
  color:$fc-primary;
}

.form-title{
  font-style: normal;
  font-weight: 700;
  @include remy(20);
  line-height: 24px;
  color: $fc-primary;
}

.para1{
  font-style: normal;
  font-weight: 400;
  @include remy(20);
  line-height: 28px;
  color: $fc-primary;
  @include tabm{
    @include remy(18);
    line-height: 26px;
  }
}

.para2{
  font-style: normal;
  font-weight: 400;
  @include remy(18);
  line-height: 25px;
  color: $fc-secondary;
  @include phonel{
    @include remy(16);
    line-height: 20px;
  }
}

.para5{
  font-style: normal;
  font-weight: 400;
  @include remy(18);
  line-height: 24px;
  color:#667078;
  @include phonel{
    @include remy(16);
    line-height: 20px;
  }
}

.para3{
  font-style: normal;
  font-weight: 400;
  @include remy(16);
  line-height: 22px;
  color: $fc-secondary;
}

.para4{
  font-style: italic;
  font-weight: 400;
  @include remy(14);
  line-height: 20px;
  color: $fc-primary;
}

pre.box{
  background: #f4f4f4;
}


.fw_900{ font-weight: 900;}
.fw_800{ font-weight: 800;}
.fw_700{ font-weight: 700;}
.fw_600{ font-weight: 600;}
.fw_500{ font-weight: 500;}
.fw_400{ font-weight: 400;}
.fw_300{ font-weight: 300;}

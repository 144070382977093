
.showroom_entry_outer {
  padding: 110px 0 112px 0;
  background: #fff;
  @include phonel {
    padding: 90px 0;
  }

  .explore_showroom_container {
    padding-bottom: 30px;
    @include phonel {
      padding-bottom: 35px;
    }

    .gallery_heading {
      font-family: $font_Souvenir_light;
      font-size: 38px;
      font-weight: 300;
      line-height: 60px;
      text-align: left;
      color: $fc-primary;
      margin-bottom: 10px;
      @include phonel {
        font-size: 28px;
        font-weight: 300;
        line-height: 34px;
        margin-bottom: 12px;
      }
    }

    .inner-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      color: $fc-primary;
      width: calc(100% - 110px);
      @include phonel {
        font-size: 18px;
        line-height: 26px;
        width: 100%;
      }
    }
  }

  .showroom_entry_carousal {
    padding-bottom: 45px;
    position: relative;
    ngx-slick-carousel{
      @include phonel{
        margin-right: -20px;
        margin-left: -20px;
      }
    }
    @include phonel {
      padding-bottom: 60px;
    }

    .carousel {
      @include phonel {
        display: flex;
        overflow-x: auto;
        padding-bottom: 10px;
        padding-right: 13.5px;
        &::-webkit-scrollbar {
          width: 4px;
          height: 3px;
        }
        &::-webkit-scrollbar-thumb{
          background: $fc-secondary;
          border-radius: 3px;
        }
      }
      .slide{
        @include phonel{
          @include phones{
            min-width: 90%;
          }
          &:first-child{
            margin-left: 13.5px;
          }
        }
      }
    }

    .slick-list {
      margin-left: -8.5px;
      margin-right: -8.5px;

      .slick-track {
        margin-left: 0;
      }
    }
  }

  .showroom_btn_wrapper {
    display: flex;
    justify-content: center;

    .primary_btn {
      height: 56px;
      @include remy(20);
      line-height: 24px;
      padding: 10px 22px 10px 22px;
      letter-spacing: 0.01em;
      text-align: center;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      @include phonel{
        @include remy(18);
        padding: 10px 24px 10px 24px;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    transition: 0.25s ease-in-out;
    width: 40px;
    height: 40px;

    svg circle, svg path {
      transition: 0.25s ease-in-out;
    }

    svg g {
      opacity: 1;
    }

    &.slick-disabled {
      svg g {
        opacity: 0.3;
      }

      &:hover {
        svg circle {
          fill: #fff;
          stroke: #222222;
        }

        svg path {
          stroke: #222222;
        }
      }

    }

    &:hover {
      svg circle {
        fill: $orange;
        stroke: $orange;
      }

      svg path {
        stroke: #fff;
      }
    }

    &.slick-next {
      top: -50px;
      right: 3px;
    }

    &.slick-prev {
      top: -50px;
      right: 65px;
      left: initial;
    }

    &:before {
      display: none;
    }
  }

  .btn_less3_item {
    position: absolute;
    top: -70px;
    right: 3px;
    gap: 23px;
    display: flex;
    @include phonel {
      display: none;
    }

    button {
      border: none;
      background: transparent;
      cursor: initial !important;
    }
  }

}

.showroom_entry_inner {
  margin: 0 8.5px;
  @include phonel {
    margin: 0 6.5px;
  }
  /*gallery card start scss*/
  .showroom_gallery_card {
    max-width: 415px;
    width: 100%;
    overflow: hidden;
    position: relative;
    @include phonel {
      min-width: 302px;
      @include phones{
        min-width: initial;
      }
    }

    &:has(p-skeleton) {
      max-width: 100%;
    }

    &:hover {
      figure img {
        transform: scale(1.06);
      }

      .carousel_image img {
        transform: scale(1.06);
      }

      .p-carousel-indicators, .p-ripple {
        opacity: 1;
        visibility: visible;
      }

    }

    figure {
      //height:220px;
      aspect-ratio: 16/10;
      width: 100%;
      //min-width:352px;
      overflow: hidden;
      margin-bottom: 0px;

      img {
        width: 100%;
        height: 100%;
        transition: transform 2s cubic-bezier(.215, .61, .355, 1);
      }
    }

    figcaption {
      padding: 12px 4px 8px;
      @include phonel {
        padding: 10px 4px 4px;
      }

      .top_sec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;
        width: 100%;
        @include phonel {
          margin-bottom: 5px;
        }

        .tag {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          text-align: right;
          color: #96999F;
          display: inline-flex;
          align-items: center;
          max-width: 50px;
          min-width: 42px;
          justify-content: end;
          cursor: default;
          z-index: 2;

          .icon {
            display: inline-flex;
            margin-right: 6px;
          }
        }
      }

      .sub_title {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #444444;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        @include phonel {
          font-size: 13px;
          line-height: 13.73px;
        }
      }

      .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 24.48px;
        text-align: left;
        color: #444444;
        font-family: 'Helvetica Neue md';
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        @include phonel {
          font-size: 16px;
          line-height: 21.76px;
        }
      }
    }

    .gallery_card_url {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
    }

  }

  /*gallery card end scss*/

  .showroom_card_slick_wrapper {
    position: relative;

    .carousel_image {
      aspect-ratio: 16/10;
      overflow: hidden;

      img {
        aspect-ratio: 16/10;
        transition: transform 2s cubic-bezier(.215, .61, .355, 1);
      }
    }

    .p-carousel-indicators {
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      opacity: 0;
      visibility: hidden;
      transition: 0.25s ease-in-out;
      z-index: 3;
      @include phonel {
        visibility: visible;
        opacity: 1;
      }

      li {
        list-style: none;

        &.p-highlight {
          button {
            background: #fff;
          }
        }

        button {
          background: rgba(224, 225, 229, 0.5);
          width: 8px;
          height: 8px;
          border-radius: 100%;
          display: flex;
          font-size: 0;
          border: none;

        }
      }
    }

    .p-ripple {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      width: 40px;
      height: 40px;
      border: none;
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: 0;
      gap: 6px;
      border-radius: 100%;
      background: rgba(255, 255, 255, 0.6);
      opacity: 0;
      visibility: hidden;
      transition: 0.25s ease-in-out;
      @include phonel {
        opacity: 1;
        visibility: visible;
      }

      span {
        display: inline-flex;
        align-items: center;
      }

      &.p-disabled {
        display: none !important;
      }

      svg path, svg {
        //@include trans2s;
      }

      &:hover {
        svg {
          transform: scale(1.2);
        }

        background: #fff;

        svg path {
          stroke: $orange;
        }
      }

      &.p-carousel-next {
        right: 8px;
      }

      &.p-carousel-prev {
        left: 8px;
      }
    }
  }

  .p-tooltip {
    .p-tooltip-arrow {
      display: none;
    }

    .p-tooltip-text {
      background: #292929;
      color: #ffffff;
      padding: 8px;
      border-radius: 4px;
      font-size: 12px;
      font-family: 'Helvetica Neue md';
      font-weight: 500;
      line-height: 12px;
      text-align: center;
    }
  }

  .product_skeleton {
    aspect-ratio: 16/10;

    .responsive-skeleton {
      max-width: 415px;
      width: 100%;
    }
  }
}














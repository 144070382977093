@import "/src/assets/scss/base/colors";
@import "/src/assets/scss/helpers/mixins";
.mx-769{
  max-width:799px;
  padding:0 15px;
  margin:auto;
}
.mx-697{
  max-width:697px;
  padding:0 15px;
  margin:auto;
}
.self_serve_footer{
  .container-fluid{
    padding:0 30px;
  }
}

.self_serve_accordion_wrapper{
  padding-top:45px;
  .title{
    @include remy(28);
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color:$fc-primary;
    margin-bottom:11px;
  }
}



.add_items_button {
  border: 1px dashed #C7C7C7;
  background: #fff;
  border-radius: 7.64px;
  padding:24px 20px 24px 65px;
  position: relative;
  min-width:365px;
  @include trans2s;
  &:hover{
    border-color:$fc-secondary;
  }
  .icon{
    position:absolute;
    left:28px;
    top:50%;
    transform:translateY(-50%);
  }
  .content_sec{
    text-align: left;
    .label{
      font-family: 'Helvetica Neue md';
      font-size: 14px;
      line-height: 16.8px;
      text-align: left;
      color:#000;
      margin-bottom:5px;
      display:flex;
    }
    .desc{
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: left;
      color:$fc-secondary;

    }
  }
}




.space-accordion {
  .btn_wrapper{
    padding-top:14px;
  }
  .product_count{
    display:inline-flex;
    align-items:center;
    margin-right: 40px;
  }
  .right_sec{
    position:absolute;
    right:62px;
    display:inline-flex;
    align-items: center;
    .icon{
      display:inline-flex;
      margin-right:5px;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 12.66px;
      letter-spacing: -0.05em;
      text-align: left;
      color:#666666;
    }
  }
  .p-accordion {
    .p-accordion-tab{
      margin-bottom:10px;
      border: 1px solid #EAECF0;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 2px 3px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

      &.p-accordion-tab-active{
        .p-toggleable-content{
          border-top:1px solid #EAECF0;
        }
      }

      .p-accordion-content{
        padding:0;
      }
      .accordion_content_wrapper{
        padding:24px 30px 28px;
      }
    }
    .p-accordion-header .p-accordion-header-link {
      flex-direction: row-reverse;
      justify-content: space-between;
      border-radius: 4px;
      background: #FBFBFB !important;
      border:none;
      padding:22px 33px 21px 13px;
      &:focus{
        outline:none;
        box-shadow:none;
        background: #fff;
      }
      &:hover{
        background:#FBFBFB !important;
        .more_dropdown_wrapper{
          opacity:1;
        }
        .cdk-drag-handle{opacity:1;}
      }
    }
  }
  .accordion_header{
    display:flex;
    align-items: center;
    width:80%;
    .cdk-drag-handle{
      cursor:move;
      opacity:0;
      @include trans2s;
    }
    .link-primary .el-icon{
      margin-right:8px;
    }
    .accordian_header_wrapper{
      .header_top_ui{
        font-size: 18px;
        font-weight: 700;
        line-height: 21.98px;
        text-align: left;
        color:$fc-primary;
        //padding-right:65px;
      }
    }
  }
  .p-accordion-toggle-icon{
    min-width:15px;
  }
  .down_arrow{
    background:url("/assets/images/down_icon.svg") no-repeat;
    width:15px;
    height:8px;
    background-size:100%;
    margin: 0 !important;
  }
  .up_arrow{
    background:url("/assets/images/up_icon.svg") no-repeat;
    width:15px;
    height:8px;
    background-size:100%;
    margin: 0 !important;
  }
}


.more_dropdown_wrapper{
  margin-right: 15px;
  opacity: 0;
  @include trans2s;
  &.show{
    opacity:1;
  }
  .more_btn{
    border:none;
    background:none;
    &:after{
      display:none;
    }
  }
  .dropdown-menu{
    z-index:4;
    border: 1px solid #EAECF0;
    background: #FFFFFF;
    border-radius:4px;
    box-shadow:0px 4px 6px -2px rgba(16, 24, 40, 0.07), 0px 12px 16px -4px rgba(16, 24, 40, 0.12);
    min-width:214px;
    //transform: translate3d(0px, 43px, 0px) !important;
    height:0;
    padding:8px 0;
    @include trans2s;
    &.show{
      height:auto;
    }
    .dropdown-item{
      padding:6px 19px;
      color:$fc-primary;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      display:inline-flex;
      align-items:center;
      flex-wrap:wrap;

      @include trans2s;
      .icon{
        margin-right:10px;
        display:inline-flex;
      }
      &:hover{
        background:#EEF4F6;
        //background: #E4EFF180;
      }
      &:active{
        background:#F9FAFB;
      }
    }

  }
}

.space-accordion{
  .link-primary-btn{
    svg path{
      @include trans2s;
    }
    &:hover svg path {
      fill: #E37B6F;
      stroke: none;
    }
  }
}


.primary_btn{
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  background:$orange;
  height:55px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-family: 'Helvetica Neue md';
  border:none;
  color:#fff;
  min-width:112px;
  @include trans2s;
  &.btn_md{
    height:50px;
    line-height:normal;
    @include remy(16);
  }
  &:hover{
    background-color:$orange-tint;
    color: $fc-white;
    @include phonel{
      background-color:$orange;
    }
  }
  &:focus{
    background-color:$orange;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-white;
    &:active{
      border-color: transparent;
      outline: none;
      box-shadow: none;
      color: $fc-white;
    }
  }
  &:focus-visible{
    background-color:#c44c3e;
    //background:$orange-shade;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-white;
  }
  &:focus-within{
    background-color:$orange;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-white;
  }
  &:active{
    background-color: $orange-shade !important;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-white !important;
    @include phonel{
      background-color: #E37B6F;
    }
  }

  &:disabled{
    background-color:$orange-light;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    color: $fc-white;
    &:hover,
    &:focus,
    &:active{
      background-color:$orange-light !important;
      border-color: transparent;
      outline: none;
      box-shadow: none;
      color: $fc-white;
    }
  }
}
.primary_outline_btn{
  padding: 0px 25px 0px 25px;
  border-radius: 5px;
  background:#fff;
  border:2px solid $orange;
  height:45px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  font-family: 'Helvetica Neue md';
  color:$orange;
  min-width:112px;
  @include trans2s;
  &:hover{
    border-color:$orange-tint;
    color: $orange-tint;
    background:#fff;
  }
  &:after{
    display:none;
  }
}
.back_btn{
  font-family: 'Helvetica Neue md';
  font-size: 15px;
  line-height: 18.32px;
  text-align: left;
  color:$fc-primary;
  background:none;
  border:none;
  padding:0 24px;
  height:45px;
  @include trans2s;
  &:hover{
    opacity:0.8;
  }
}
.skip_btn{
  font-family: 'Helvetica Neue';
  color:#DC5A4B;
  background:none;
  border:none;
  padding:0 24px;
  margin-right: 5px;
  height:45px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: right;
  margin-left: auto;
  @include trans2s;
  &:hover{
    opacity:0.8;
  }
}

.footer_product_counter{
  font-family: 'Helvetica Neue';
  color:#96999F;
  background:none;
  border:none;
  padding:0 25px;
  height:41px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  margin-left: auto;
  border-radius: 6px;
  @include trans2s;
  &:hover{
    opacity:0.8;
  }
  span{
    font-family: 'Helvetica Neue md';
    font-weight:500;
  }
}

.cstm_progress_bar{
  display:flex;
  gap:4.49px;
  .progressbar{
    background:$border-light;
    height:6px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    .progressbar-inner{
      border-radius:10px;
    }
    &:first-child{
      border-radius: 0 10px 10px 0;
      .progressbar-inner{
        border-radius: 0 10px 10px 0;
      }
    }
    &:last-child{
      border-radius: 10px 0px 0px 10px;
      .progressbar-inner{
        border-radius: 10px 0px 0px 10px;
      }
    }
    &.progressbar-success{
      .progressbar-inner{
        background:$fc-primary;
        width:100%;
        height:100%;
        position: absolute;
        left:0;
        top:0;
        transition:width 600ms linear;
      }
    }
  }
}


.outline_btn {
  border: 2px solid $border-light;
  height: 45px;
  background: #fff;
  padding: 0 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  color:$fc-primary;
  @include trans2s;
  span{
    display: inline-flex;
    align-items: center;
    margin-right:6px;
    position: relative;
    top:-1px;
  }
  &:hover{
    border-color:$fc-primary;
  }
}


/*modal scss */
.mx-985{
  max-width:985px;
  width:100% !important;
}
.custom_mask{
  background:rgba(68,68,68,0.6);
  //transition: .4s ease-in-out;
}
.product_modal{
  background:#FFFFFF;
  border-radius:16px;
  //transform: scale(1)!important;
  //transition: .4s ease-in-out;
  .p-dialog-header-icons{
    display:none !important;
  }
  .p-dialog-header{
    padding:30px 45px 0;
  }
  .p-dialog-content{
    padding:0 45px;
  }
  .modal_footer{
    display: flex;
    align-items:center;
    justify-content: space-between;
    width:100%;
    border-radius: 0 0 16px 16px;
    background:#fff;
    padding:17px 32px;
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
.dialog_header{
  width:100%;
  .search_wrap{
    display:inline-flex;
    position:relative;
    top:3px;
  }
  .left_ui{
    display:flex;
    align-items: center;
  }
  .top_sec{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding-bottom: 13px;
    .modal_title{
      @include remy(32);
      font-weight: 400;
      line-height: 46px;
      text-align: left;
      color:$fc-primary;
    }
  }
}
.filter_sorting_sect{
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product_filtered_tag{
  ul{
    display:inline-flex;
  }
  li{
    position: relative;
    list-style: none;
    margin-right: 12px;
    .p-radiobutton{
      position: absolute;
      left:0;
      top:0;
      opacity:0;
    }
    label{
      border: 1px solid #E0E1E5;
      background:#fff;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color:#96999F;
      padding:8px 13px;
      border-radius: 34px;
      @include trans2s;
      cursor:pointer;
      line-height: 18px;
      letter-spacing:0.3px;
      @include trans2s;
      &:hover{
        background: #E4EFF180;
      }
      .inner_counts{
        @include trans2s;
        color: #C7C7C7;
      }
      &.active{
        background:$teal-active;
        color:$fc-primary;
        border-color:transparent;
        .inner_counts{
          color:$fc-secondary;
        }
      }
    }
  }
}

.self_product_wrapper{
  // display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: hidden;
  //max-height:72dvh;
  padding:2px 2px 0px;
  align-content:flex-start;
  .self_product_card{
    .cdk-drag-preview, .cdk-drag-dragging {
      cursor:grabbing !important;
    }
  }
}
.self_product_card{
  width:18.2%;
  //height: max-content;
  min-height: auto;
  position: relative;
  cursor:pointer;
  @include trans2s;
  z-index:1;
  &.cdk-drag{
    overflow:initial;
  }

  &.product_card_items{
    transition:background-color .2s ease;
    figure{
      &::before{
        transition:background-color .2s ease;
      }
    }
    .tag_wrapper {
      top: 21px;
      left: 18px;
      transition: all 0.2s ease;
    }

    .check_icon {
      right: 20px;
      bottom: auto;
      height: 18px;
      width: 18px;
      top: 22px;
      border-radius: 3px;
      z-index: 11;
    }
    .card_menu {
      top: 117px;
      right: 19px;
      width: 30px;
      height: 30px;
      transition:all .2s ease;
    }
    p-checkbox{
      position:absolute;
      left:auto;
      top:22px;
      right: 20px;
      z-index: 1;
      opacity: 1;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      border-color: #E0E1E5;
      background: #ffffff;
      color: #E0E1E5;
    }
    .p-checkbox{
      width: 18px;
      height: 18px;
      justify-content: center;
      align-items: center;
      .p-checkbox-box {
        background: #ffffff;
        width: 18px;
        height: 18px;
        border-radius: 3px;
        border: 1.5px solid #E0E1E5;
        &.p-focus{
          box-shadow: none !important;
        }
      }
    }
    .card_menu .card_menu_btn {
      width: 30px;
      height: 30px;
    }
    figure{
      &::before{
        content: "";
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color: #00000033;
        @include trans2s;
        opacity:0;
        visibility: hidden;

      }
    }
    &:hover{
      .card_menu {
        opacity: 1;
        visibility: visible;
      }
      .card_ui{
        border-color: #444444;
      }

      p-checkbox{
        opacity: 1;
      }
      figure:before{
        opacity:1;
        visibility:visible;
      }
    }
  }
  @media (max-width:1282px){
    width:23.1%;
    @media (max-width:1150px){
      width:22.7%;
    }
  }
  @include tabv{
    width:22.4%;
  }
  &:hover{
    .tag_wrapper .tag_ui{
      opacity:1;
    }
    .card_ui{
      border: 0.95px solid #C7C7C7;
    }
  }
  .check_icon {
    position: absolute;
    right: -7px;
    bottom: -5px;
    background: #fff;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: inline-flex;
    opacity:0;
    visibility:hidden;
    transition:0.2s ease-in-out;
  }
  .card_ui{
    cursor:pointer;
    border: 0.95px solid #E0E1E5;
    padding:15px 12px;
    border-radius: 7.64px;
    outline:1px solid transparent;
    height:100%;
    width:100%;
    @include trans2s;
    &:hover{
      background: #F5F5F54D;
    }
    &.for_add_product{
      border: 1.5px dashed #E0E1E5;
      //max-height: 242px;
      .product_name{
        font-family: 'Helvetica Neue md';
        font-size: 14px;
        line-height: 19px;
        text-align: center;
      }
    }
    &.checked{
      border-color:$fc-primary;
      //outline:1px solid $fc-primary;
      //+ .check_icon, + .check_icon + .card_menu{
      + .check_icon, .check_icon {
        opacity:1;
        visibility:visible;
      }
      + .check_icon svg path, .check_icon svg path{
        fill:$fc-primary
      }
      .favourite{
        .tag_wrapper{
          .tag_ui {
            background: #000000;
          }
        }
      }

    }
  }
  p-checkbox{
    position:absolute;
    left:0;
    top:0;
    opacity:0;
  }
  figure{
    width:140px;
    height:140px;
    max-width: 100%;
    overflow: hidden;
    border-radius: 7.64px;
    margin-bottom: 10px;
    position: relative;
    @media (max-width:1282px) and (min-width:1081px){
      width:100%;
      height:auto;
      aspect-ratio:1/1;
    }
    img{
      width:100%;
      //height:100%;
      object-fit:contain;
      object-position: center;
      aspect-ratio:1/1;
    }
  }
  .content_wrap{
    .product_name{
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      text-align: left;
      color:$fc-primary;
      margin-bottom:4.77px;
      font-family: 'Helvetica Neue md';
      flex-wrap:wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .desc{
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-align: left;
      color:$fc-primary;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap:break-word;
    }
  }
  &:hover{
    //.card_menu, .check_icon{
    //  opacity:1;
    //  visibility:visible;
    //}
    .tag_wrapper .tag_ui{
      background:#fff;
      border:0.5px solid #E0E1E5;
      &.fav_tag{
        //path{ stroke:#96999F;}
      }
      path{
        fill:#96999F;
      }
      &:hover{
        &.fav_tag{
          //path{ stroke:#222222;}
        }
        path{
          fill:#222222;
        }
      }
    }
  }
  .card_menu{
    z-index:10;
  }
  .tag_wrapper .tag_ui{
    width:25px;
    height:25px;
    cursor:pointer;
  }
}


.product_filtered_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left:16px;
  position: relative;
  padding-top:2px;
}
.product_filter_dropdown {
  display: flex;
  .spacer22{
    display:none;
  }

  .p-listbox-empty-message{
    min-height:175px;
    padding-top:22px !important;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon{
    left: 0px;
  }
  .dropdown_listing_Quote_heading{
    .p-listbox {
      background: #ffffff;
      color: #495057;
      border: 0px solid;
      border-radius: 6px;
      position: relative;
      padding: 0px!important;
      width:100% !important;
      min-height:265px;
    }
    .p-listbox .p-listbox-header {
      padding: 0;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      &:hover{
        background:#fff !important;
      }
      .header-content{
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color:$fc-primary;
      }
    }
    .p-listbox .p-listbox-list .p-listbox-item {
      padding: 9px 17px!important;
      cursor: pointer;
      &:hover{
        background:#fff!important;
        .p-checkbox .p-checkbox-box {
          border-color:$fc-secondary;
        }
      }
      .p-checkbox {
        height: 20px;
      }
    }
    .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
      color: $orange;
      .quote_member_name{
        color:$fc-primary!important;
      }
    }
    .p-listbox-filter-container {
      //display: none;
    }
    .p-listbox .p-listbox-header {
      .p-checkbox {
        margin-right: 0px;
        margin-top: 10px;
        margin-bottom: 8px;
        width: 100%;
        display: flex;
        padding: 0 17px;
      }
      .p-inputtext{
        border:none;
        border-bottom:1px solid #E0E1E5;
        border-radius:0;
        padding-left:17px;
      }
    }

    .country-item .quote_member_name {
      font-style: normal;
      font-weight: 400;
      @include remy(15);
      line-height: 21px;
      color: #444444;
      word-break: break-word;
      text-align: left;
    }
    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
      color:$fc-primary;
      background:#fff!important;
    }

  }
  .property_dropdown_wrap {
    &:before{
      display: none;
      position: absolute;
      content: "";
      width: 27px;
      height: 18px;
      background: url(/assets/images/quotes/drop_after.svg) no-repeat;
      top: -17px;
      left:27px;
    }
    padding-bottom: 0;
    padding-top: 0px;
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.12), 0px 4px 6px -2px rgba(16, 24, 40, 0.07);
    border-radius: 4px;
    min-width: 332px !important;
    max-width: 332px !important;
    transform: translate3d(0px, 33px, 0px) !important;
    .btn_wrapper{
      width:100%;
      padding:11px 17px 11px;
      border-top:1px solid #E0E1E5;
      .primary_btn{
        width:100%;
        height:45px;
        line-height:normal;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.09px;
        text-align: center;
      }
    }
  }

  .filter_action_btn{
    font-family: 'Helvetica Neue';
    display: inline-flex;
    align-items: center;
    transition: 0.2s all ease;
    border: none;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.6000000238418579px;
    text-align: left;
    text-decoration:none;
    color:$fc-primary;
    &[aria-expanded="true"]{
      .angel_icon{
        svg{
          transform:rotate(180deg);
        }
      }
    }
    &:hover,&:focus, &[aria-expanded="true"]{
      .el_text, .custom_tooltip_quote_dropdown{
        color: $orange;
      }
      .el_icon{
        svg{
          path{
            stroke: $orange;
          }
        }
      }
      .angel_icon{
        svg{
          path{
            fill: $orange;
          }
        }
      }
    }
    &:after{
      display:none;
    }
    .angel_icon{
      margin-left:7px;
      svg path{
        @include transition(0.2s ease-in-out);
      }
    }
    .el_icon{
      display:inline-flex;
      align-items:center;
      margin-right:5px;
      svg path{
        @include transition(0.2s ease-in-out);
      }
    }
    .el_text, .custom_tooltip_quote_dropdown{
      font-size: 15px;
      font-weight: 400;
      line-height: 16px;
      display: inline-flex;
      align-items: center;
      font-style: normal;
      text-decoration-line: none;
      color: #444444;
      @include transition(0.2s ease-in-out);
      letter-spacing:0.3px;
    }
    .custom_tooltip_quote_dropdown{
      margin-left:5px;
      color:#96999F;
    }
    .content-ui{
      display: flex;
      align-items: center;
      .el_text{
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        line-height: 17px;
      }
    }
  }
}


.sorting_filtered_wrapper{
  .sorting_btn{
    display:flex;
    align-items:center;
    border: none;
    background:#fff;
    width:24px;
    height:24px;
    justify-content: flex-end;
    margin-left: 15px;
    svg{
      path{
        @include trans2s;
      }
    }

    &:hover,&:focus{
      p{
        color: $orange;
      }
      .icon{
        svg{
          path{
            stroke: $orange;
          }
        }
      }
    }
    p{
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.6000000238418579px;
      text-align: left;
      color:$fc-primary;
      font-family: 'Helvetica Neue md';
      @include transition(0.2s ease-in-out);

    }
    .icon{
      display:inline-flex;
      width:24px;
      height:24px;
      align-items:center;
      justify-content:center;
      //margin-left:5px;
      svg path{
        @include transition(0.2s ease-in-out);
      }
    }
    &:after{
      display:none;
    }
  }
  .dropdown-menu{
    width: 230px;
    padding-top:14px;
    padding-bottom: 14px;
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.12), 0px 4px 6px -2px rgba(16, 24, 40, 0.07);
    border-radius: 4px;
    transform: translate3d(0px, 35px, 0px) !important;
    left: initial !important;
    right: 0 !important;
    .brands_heading{
      font-size: 15px;
      font-weight: 700;
      display: inline-block;
      line-height:11px;
      width: 100%;
      text-align: left;
      padding: 12px 20px 9.5px;
    }
    .dropdown-item{
      font-size: 15px;
      font-weight: 400;
      line-height:11px;
      text-align: left;
      color:$fc-primary;
      @include trans2s;
      padding:10px 20px;
      &.active{
        color: $orange;
        background-color: transparent;
      }
      &:hover{
        background:#EEF4F6;
      }
      &:active{
        color: $orange;
        background-color: transparent;
      }
    }
  }
}


.confirmation_modal .p-dialog {
  width:590px;
  box-sizing: border-box;
  background: #fff;
  padding: 36px 40px;
  border-radius: 16px;
  .p-dialog-header{
    padding:0;
    .p-dialog-title{
      font-style: normal;
      @include remy(28);
      font-family: 'Helvetica Neue md';
      line-height: 36px;
      text-align: left;
      color: #444444;
      font-weight:normal;
    }
  }
  .p-dialog-header-icons{
    display:none;
  }
  .p-dialog-footer{
    padding:10px 0 0 ;
    justify-content:center;
    display:flex;
    .primary_btn{
      margin:0 5px;
      .p-button-icon{
        display:none;
      }
      &:hover{
        background-color:$orange-tint;
        color: $fc-white;
        @include phonel{
          background-color:$orange;
        }
      }
      &:focus{
        background-color:$orange;
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: $fc-white;
        &:active{
          border-color: transparent;
          outline: none;
          box-shadow: none;
          color: $fc-white;
        }
      }
      &:focus-visible{
        background-color:#c44c3e;
        //background:$orange-shade;
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: $fc-white;
      }
      &:focus-within{
        background-color:$orange;
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: $fc-white;
      }
      &:active{
        background-color: $orange-shade !important;
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: $fc-white !important;
        @include phonel{
          background-color: #E37B6F;
        }
      }

      &:disabled{
        background-color:$orange-light;
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: $fc-white;
        &:hover,
        &:focus,
        &:active{
          background-color:$orange-light !important;
          border-color: transparent;
          outline: none;
          box-shadow: none;
          color: $fc-white;
        }
      }
    }
    .back_btn{
      &:hover, &:focus, &:active{
        opacity:0.8;
        background:#fff;
        color:$fc-primary;
        outline:none;
        box-shadow: none;
      }
    }
  }
}

.custom_confirmation_modal{
  padding:40px 45px 40px;
  &:empty{
    display:none;
  }
  .p-dialog-header{
    .p-dialog-header-icons{
      position:absolute;
      top:6px;
      right:10px;
      display:flex !important;
      width:30px;
      height:30px;
    }
    .p-dialog-header-icon{
      width:30px;
      height:30px;
      display:flex !important;
      align-items:center;
      justify-content:center;
      &:hover{
        background:transparent !important;
      }
    }
    .p-dialog-title{
      margin-bottom:18px;
    }
  }
  .p-confirm-dialog .p-dialog-content{
    padding:0;
    .p-confirm-dialog-message{
      margin:0 0 40px;
    }
  }
  .p-dialog .p-dialog-footer button{
    border-radius:4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    height:45px;
    padding:0 25px;
    .p-button-label{
      font-weight: 400;
      top:0;
    }
    &.outline_btn{
      &:hover{
        border-color:#444444;
        color:#444444;
        background:#fff;
      }
      .p-button-label{
        top:1px;
        margin:0;
      }
    }
  }
}


.confirmation_modal .p-dialog {
  width:590px;
  box-sizing: border-box;
  background: #fff;
  padding: 36px 40px;
  border-radius: 16px;
  .p-dialog-header{
    padding:0;
    .p-dialog-title{
      font-style: normal;
      @include remy(28);
      font-family: 'Helvetica Neue md';
      line-height: 36px;
      text-align: left;
      color: #444444;
      font-weight:normal;
    }
  }
  .p-dialog-header-icons{
    display:none;
  }
  .p-dialog-footer{
    padding:10px 0 0 ;
    justify-content:center;
    display:flex;
    .primary_btn{
      margin:0 5px;
      .p-button-icon{
        display:none;
      }
      &:hover{
        background-color:$orange-tint;
        color: $fc-white;
        @include phonel{
          background-color:$orange;
        }
      }
      &:focus{
        background-color:$orange;
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: $fc-white;
        &:active{
          border-color: transparent;
          outline: none;
          box-shadow: none;
          color: $fc-white;
        }
      }
      &:focus-visible{
        background-color:#c44c3e;
        //background:$orange-shade;
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: $fc-white;
      }
      &:focus-within{
        background-color:$orange;
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: $fc-white;
      }
      &:active{
        background-color: $orange-shade !important;
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: $fc-white !important;
        @include phonel{
          background-color: #E37B6F;
        }
      }

      &:disabled{
        background-color:$orange-light;
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: $fc-white;
        &:hover,
        &:focus,
        &:active{
          background-color:$orange-light !important;
          border-color: transparent;
          outline: none;
          box-shadow: none;
          color: $fc-white;
        }
      }
    }
    .back_btn{
      &:hover, &:focus, &:active{
        opacity:0.8;
        background:#fff;
        color:$fc-primary;
        outline:none;
        box-shadow: none;
      }
    }
  }
}

.dont_show_custom_modal{
  .p-dialog-mask{
    z-index: 9991 !important;
  }
  &:empty{
    display:none;
  }
  .p-dialog{
    width: 493px;
    padding:40px 45px 30px 45px;
    max-width: 100%;
    box-shadow: 16px 24px 40px 0px #10182840;
    @include phonel{
      width: auto;
      padding:18px 20px 40px 20px;
      max-width: 90%;
    }
    .p-dialog-header{
      .p-dialog-header-icons{
        @include phonel{
          top: 18px;
          right:20px;
        }
      }
      .p-dialog-header-icon{
        &:focus{
          box-shadow: none;
        }
      }
      .p-dialog-title{
        font-family: 'Helvetica Neue';
        font-size: 26px;
        font-weight: 700;
        line-height: 22.4px;
        margin-bottom: 18px;
        color: #444444;
        @include phonel{
          line-height: 31.75px;
          margin-bottom: 20px;
          margin-top: 38px;
        }
      }
    }
  }
  .p-confirm-dialog .p-dialog-content{
    padding:0;
    .p-confirm-dialog-message{
      margin:0 0 33px;
      font-size: 18px;
      font-weight: 400;
      line-height: 25.2px;
      color: #444444;
      @include phonel{
        margin-bottom: 30px;
        padding-right: 8px;
      }
    }
  }
  .p-dialog{
    .p-dialog-footer{
      padding: 0;
      display: flex;
      gap:13px;
      align-items: center;
      @include phonel{
        gap:20px;
      }
      button{
        &.dialog_dont_show_btn{
          font-family: Helvetica Neue;
          font-size: 18px;
          font-weight: 400;
          line-height: 21.47px;
          border-radius: 34px;
          height: 42px;
          min-width: 125px;
          text-align: center;
          margin:0;
          @include phonel{
            min-width: 146.5px;
          }
        }
        &.outline_btn{
          &.dialog_dont_show_btn{
            border:2px solid #DC5A4B;
            color:#DC5A4B;
            margin:0;
            transition: 0.2s all ease;
            &:active{
              background-color: #ffffff;
            }
            &:focus{
              box-shadow: none;
            }
            &:hover{
              background-color: #FFFFFF;
              color: $orange-tint;
              border:2px solid $orange-tint;
              transition: 0.2s all ease;
              -moz-transition: 0.2s all ease;
              -webkit-transition: 0.2s all ease;
              -o-transition: 0.2s all ease;
            }
            .p-button-label{
              top:0;
              margin:0;
              display: inline-flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.cdk-drag{
  @include trans2s;
  overflow:hidden;
  //cursor:grab;
}

.cdk-drag-preview {
  background: #f1f7f8 !important;
  z-index: 11160 !important;
  width:287.5px !important;
  border: 1px solid #5592A04D !important;
  box-shadow: none !important;
  border-radius: 4px;
  display: inline-flex;
  align-items: flex-start;
  font-size: 14px;
  color: #444;
  box-sizing: border-box;
  padding:22px 33px 21px 13px !important;
  line-height: normal !important;
  height:65px;
  cursor:grabbing !important;
  pointer-events: auto !important;
  .link-primary{
    opacity:1 !important;
  }
  .space_title_wrapper{
    color:$teal-primary !important;
  }
  .content_sec{
    padding:0;
    display:flex;
    align-items: center;
  }
  svg{
    width:10px;
  }
  .move_icon{
    width: 34px;
    height: 34px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #E6E6E6;
    margin-right: 10px;
    color:red !important;
  }

  .header_top_ui{
    font-size: 18px;
    font-weight: 700;
    line-height: 21.98px;
    text-align: left;
    color:$fc-primary;
    //padding-right:65px;
  }

  .accordion_header {
    display: flex;
    align-items: center;
    width: 80%;
  }
  .p-accordion-header-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    text-decoration: none;
  }
  .right_sec {
    position: absolute;
    right: 62px;
    display: inline-flex;
    align-items: center;
    opacity:0;
    .icon {
      display: inline-flex;
      margin-right: 4.5px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 12.66px;
      letter-spacing: -0.05em;
      text-align: left;
      color: #666666;
    }
  }
  .product_count {
    display: inline-flex;
    align-items: center;
  }
}


.product_card_container{
  display:flex;
  gap:15px;
  padding-bottom: 15px;
  overflow-x: overlay;
  width: 100%;
  .self_product_card{
    width:23.3%;
    min-height:242px;
  }
}

.showroom_link_btn{
  margin-left:20px;
  @include trans2s;
  &:hover{
    svg path{
      stroke:$orange;
    }
  }
}

.search-showroom_product{
  width:254px;
  position: relative;
  @include phonel{
    width:100%;
    margin-bottom: 20px;
  }
  .fg-inner{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  input{
    padding: 0;
    border: none;
    border-bottom: 1px solid #E0E1E5;
    height:30px;
    padding-bottom:0px;
    @include remy(16);
    line-height: 19px;
    font-weight: 400;
    color:$fc-primary;
    width:260px;
    @include trans2s;
    padding-right: 0px;
    border-radius:0;
    text-overflow: ellipsis;
    &:disabled{
      opacity: 0.3;
      background: transparent;
    }

    @include phonel{
      text-overflow: ellipsis;
      @include remy(15);
    }
    &::placeholder{
      color:$fc-secondary;
      @include trans2s;
      @include remy(16);
      @include phonel{
        text-overflow: ellipsis;
        @include remy(14);
        line-height: 17px;
      }
    }
    &:hover{
      box-shadow: none;
      //color:$fc-hint;

    }
    &:focus {
      border-bottom: 1px solid #E0E1E5;
      box-shadow: none;
      //border-radius: 0;
      @include phonel{
        border-bottom: 2px solid #E0E1E5;
      }

      &::placeholder {
        color: $fc-hint;
      }

      +.search_btn{
        svg path {
          stroke: $orange;
          //stroke: $fc-primary;
        }
      }

    }

    &.space{
      padding-right: 30px;
      border-bottom:1px solid #E0E1E5;
      @include phonel{
        padding-right: 40px;
      }
      + .cross_btn +.search_btn{
        svg path{
          stroke:$orange;
        }
        @include phonel{
          opacity: 0;
          visibility: hidden;
          display: none;
        }
      }
    }
    &:valid{
      // border-bottom:1px solid #E0E1E5;

      @include phonel{
        //border-bottom:2px solid #E0E1E5;
        border-bottom:1px solid #E0E1E5;
      }
      + .cross_btn{
        opacity: 1;
        visibility: visible;
      }

    }

    &.ng-touched.ng-invalid{
      //border: none;
      //border-bottom:1px solid #E0E1E5;
    }

    @include phonel{
      width:100%;
      border: 1px solid #E0E1E5;
      height: 42px;
      font-size: 14px;
      line-height: 17px;
      border-radius: 32px;
      padding:0 40px 0 15px;
      text-overflow: ellipsis;

    }

  }

  .cross_btn{
    position: absolute;
    width:24px;
    height:24px;
    right:30px;
    top: 1px;
    border: none;
    background: none;
    opacity: 0;
    visibility:hidden;
    @include trans2s;
    .mob_cross{
      display: none;
    }
    @include  phonel{
      right:10px;
      top:21px;
      transform: translateY(-50%);
      width:25px;
      height: 25px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .mob_cross{
        display: inline-flex;
        svg{
          width:100%;
        }
      }
      .dsktp_cross{
        display: none;
      }
    }
  }
  .search_btn{
    border: none;
    background: none;
    @include trans2s;
    width:24px;
    height: 24px;
    min-width:24px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    @include trans2s;
    &.active{
      svg path {
        stroke: $orange;
      }
    }
    @include phonel{
      width:25px;
      height: 25px;
    }
    &:disabled{
      opacity: 0.3;
      background: transparent;
      &:hover{
        svg path {
          stroke: #444444;
        }
      }
    }
    .mob_search{
      display: none;
    }
    @include  phonel{
      position: absolute;
      right: 12px;
      svg{
        @include trans2s;
      }
      .mob_search{
        display: inline-flex;
      }
      .dsktp_search{
        display: none;
      }
    }
    &:hover{
      svg path{
        stroke:$orange;
      }
    }
  }
}



.manage_items_wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  .link-primary-btn {
    .el-icon {
      margin-right: 5px;
      svg {
        max-width: 24px;
      }
    }
    .el-text{
      font-size: 16px;
      line-height: 22px;
    }
  }

}




//.search-showroom_product{
//  width:254px;
//  position: relative;
//  @include phonel{
//    width:100%;
//    margin-bottom: 20px;
//  }
//  .fg-inner{
//    display: flex;
//    align-items: center;
//    justify-content: flex-end;
//  }
//  input{
//    padding: 0;
//    border: none;
//    height:30px;
//    padding-bottom:0px;
//    @include remy(16);
//    line-height: 19px;
//    font-weight: 400;
//    color:$fc-primary;
//    width:260px;
//    @include trans2s;
//    padding-right: 0px;
//    border-radius:0;
//    text-overflow: ellipsis;
//    &:disabled{
//      opacity: 0.3;
//      background: transparent;
//    }
//
//    @include phonel{
//      text-overflow: ellipsis;
//      @include remy(15);
//    }
//    &::placeholder{
//      color:$fc-secondary;
//      @include trans2s;
//      @include remy(16);
//      @include phonel{
//        text-overflow: ellipsis;
//        @include remy(14);
//        line-height: 17px;
//      }
//    }
//    &:hover{
//      box-shadow: none;
//      //color:$fc-hint;
//
//    }
//    &:focus {
//      border-bottom: 1px solid #E0E1E5;
//      box-shadow: none;
//      //border-radius: 0;
//      @include phonel{
//        border-bottom: 2px solid #E0E1E5;
//      }
//
//      &::placeholder {
//        color: $fc-hint;
//      }
//
//      +.search_btn{
//        svg path {
//          stroke: $orange;
//          //stroke: $fc-primary;
//        }
//      }
//
//    }
//
//    &.space{
//      padding-right: 30px;
//      border-bottom:1px solid #E0E1E5;
//      @include phonel{
//        padding-right: 40px;
//      }
//      + .cross_btn +.search_btn{
//        svg path{
//          stroke:$orange;
//        }
//        @include phonel{
//          opacity: 0;
//          visibility: hidden;
//          display: none;
//        }
//      }
//    }
//    &:valid{
//      // border-bottom:1px solid #E0E1E5;
//
//      @include phonel{
//        //border-bottom:2px solid #E0E1E5;
//        border-bottom:1px solid #E0E1E5;
//      }
//      + .cross_btn{
//        opacity: 1;
//        visibility: visible;
//      }
//
//    }
//
//    &.ng-touched.ng-invalid{
//      //border: none;
//      //border-bottom:1px solid #E0E1E5;
//    }
//
//    @include phonel{
//      width:100%;
//      border: 1px solid #E0E1E5;
//      height: 42px;
//      font-size: 14px;
//      line-height: 17px;
//      border-radius: 32px;
//      padding:0 40px 0 15px;
//      text-overflow: ellipsis;
//
//    }
//
//  }
//
//  .cross_btn{
//    position: absolute;
//    width:24px;
//    height:24px;
//    right:30px;
//    top: 1px;
//    border: none;
//    background: none;
//    opacity: 0;
//    visibility:hidden;
//    @include trans2s;
//    .mob_cross{
//      display: none;
//    }
//    @include  phonel{
//      right:10px;
//      top:21px;
//      transform: translateY(-50%);
//      width:25px;
//      height: 25px;
//      display: inline-flex;
//      align-items: center;
//      justify-content: center;
//      .mob_cross{
//        display: inline-flex;
//        svg{
//          width:100%;
//        }
//      }
//      .dsktp_cross{
//        display: none;
//      }
//    }
//  }
//  .search_btn{
//    border: none;
//    background: none;
//    @include trans2s;
//    width:24px;
//    height: 24px;
//    align-items: center;
//    display: flex;
//    justify-content: center;
//    margin-left: 10px;
//    @include trans2s;
//    @include phonel{
//      width:25px;
//      height: 25px;
//    }
//    &:disabled{
//      opacity: 0.3;
//      background: transparent;
//      &:hover{
//        svg path {
//          stroke: #444444;
//        }
//      }
//    }
//    .mob_search{
//      display: none;
//    }
//    @include  phonel{
//      position: absolute;
//      right: 12px;
//      svg{
//        @include trans2s;
//      }
//      .mob_search{
//        display: inline-flex;
//      }
//      .dsktp_search{
//        display: none;
//      }
//    }
//    &:hover{
//      svg path{
//        stroke:$orange;
//      }
//    }
//  }
//}



.manage_items_wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  .link-primary-btn {
    .el-icon {
      margin-right: 5px;
      svg {
        max-width: 24px;
      }
    }
    .el-text{
      font-size: 16px;
      line-height: 22px;
    }
  }

}


/*card menu scss start here */
.card_menu{
  position:absolute;
  top: 7px;
  right:8px;
  opacity:0;
  visibility: hidden;
  width:28px;
  .p-listbox-filter-container{
    display:none;
  }
  .quote_member_drop .dropdown_listing_Quote_heading .p-listbox{
    padding:0 !important;
    width:100% !important;

  }

  &.show{
    opacity:1;
    visibility: visible;
    .card_menu_btn{
      background:$orange;
      circle{
        fill:#fff;
      }
    }
  }
  .card_menu_btn{
    width: 28px;
    height: 28px;
    border-radius:100%;
    background:#fff;
    border: none;
    box-shadow: 1.14px 1.14px 2.5px 1.14px rgba(0,0,0,0.05);
    transition:0.2s ease-in-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    circle{
      transition:0.2s ease-in-out;
    }
    &:after{
      display:none;
    }
    &:hover{
      background:$orange;
      circle{
        fill:#fff;
      }
    }

  }
  .card_menu_dropdown{
    transform: translate3d(0px, 37px, 0px) !important;
    overflow: hidden;
    display: none;
    &.show{
      display:block;
    }
    .dropdown-toggle{
      &:after{
        display:none;
      }
    }
    ul{
      li{
        display: flex;
        .dropdown_item{
          padding:10px 18px 9px;
          display:flex;
          @include remy(15);
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          font-family: 'Helvetica Neue';
          color:$fc-primary;
          width:100%;
          @include trans2s;
          background: #fff;
          border: none;
          align-items: center;
          .icon{
            margin-right:10px;
            width:16px;
            height:16px;
            display: flex;
            align-items: center;
          }
          &:hover{
            background:#EEF4F6;
          }
        }
      }

      &.tags_list{
        li{
          .dropdown_item{
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            color:$fc-secondary;
            display:flex;
            justify-content: space-between;
            .left_ui{
              display:flex;
              align-items:center;
            }
            svg path, svg rect, svg g{
              @include trans2s;
            }
            .active_ui{
              @include trans2s;
              opacity:0;
              display: inline-flex;
              align-items: center;
              .remove_icon{
                display:none;
              }
              i{
                display:inline-flex;
                align-items:center;
              }
            }
            &:hover,
            &.checked{
              background:transparent;
              color:$fc-primary;
              svg rect{
                fill:$fc-primary;
              }
              .active_ui{
                opacity:1;
              }

            }
            &.checked{
              &:hover{
                .active_ui{
                  .active_icon{
                    display:none;
                  }
                  .remove_icon{
                    display:inline-flex;
                  }

                }
              }
            }
          }
        }
      }
    }
    .dropdown_seprater{
      background:#F5F5F5;
      height:1.03px;
      width: calc(100% - 36px);
      margin: 9px 18px;
    }
    .inner_menu {
      position: fixed !important;
      width: 100%;
      height: 100%;
      background: #fff;
      top: 0 !important;
      transform: translate3d(0px, 0px, 0px) !important;
      right: -100% !important;
      left: initial !important;
      transition:0.2s ease-in-out;
      padding:0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.show{
        right:0 !important;
      }
      .content_group{
        height:calc(100% - 68px);
        .inner_dropdown_list{
          height: calc(100% - 42px) !important;
          overflow-y: auto;
        }
      }
      .header_wrapper{
        width:100%;
        .back_menu{
          width: 100%;
          border: none;
          background: #fff;
          display: flex;
          padding: 0 18px;
          height: 42px;
          align-items: center;
          border-bottom: 1px solid #F5F5F5;

        }
      }
      .dropdown_footer{
        padding:11px 20px 11px;
        border-top:1px solid #F5F5F5;
        width:100%;
        .primary_outline_btn{
          width:100%;
          font-size: 16px;
          font-weight: 400;
          line-height: 19.09px;
          text-align: center;
          &:disabled{
            opacity:0.6;
          }
        }
        .primary_btn{
          @include remy(16);
          font-weight: 400;
          line-height: 19.09px;
          text-align: center;
          padding:10px 20px 10px 20px;
          width:100%;
          height:45px;
        }
      }
      .inner_dropdown_list{
        .p-listbox{
          border:none;
          background:#fff;
          .p-checkbox{
            width:18px;
            height:18px;
            border-radius:4px;
          }
          .country-item{
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            .quote_member_name{
              display: -webkit-box;
              max-width: 100%;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          li{
            padding:10px 18px;
            align-items: center;
            background: #fff !important;
            outline: none;
            color:$fc-primary;
            box-shadow: none !important;
            .p-checkbox-box{
              height:18px;
              width:18px;
              border-radius:4px;
              &:hover{
                border-color:#96999F;
              }
              &.p-highlight{
                border-color:$teal-tint;
                background:$teal-tint;
              }
              .p-checkbox-icon{
                width: 11px;
                height: 8px;
                background-position: center;
                background-size: 100%;
              }
            }
          }
          .p-listbox-list{
            padding:2px 0 0;
          }
        }
      }
    }
    .p-listbox-header{
      display:none !important;
    }
    .p-listbox-list-wrapper{
      max-height: 100% !important;
      overflow-y: auto;
    }
  }
  .dropdown_bg{
    border: 1px solid #EAECF0;
    background: #FFFFFF;
    box-shadow: 0 4px 6px -2px #10182812, 0px 12px 16px -4px #1018281F;
    width:214px;
    border-radius:4px;
    padding:11px 0;
    max-height:324px;
    overflow-y: auto;
    &:has(.inner_menu.show){
      overflow:hidden;
    }
    .space_chips{
      gap:0;
    }
  }
}
.body_dropdown{
  .card_menu_dropdown{
    transform: translate3d(0px, 0px, 0px) !important;
    overflow: hidden;
    display: none;
    .dropdown-toggle{
      &:after{
        display:none;
      }
    }
    ul{
      li{
        display: flex;
        .dropdown_item{
          padding:10px 18px 9px;
          display:flex;
          @include remy(15);
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          font-family: 'Helvetica Neue';
          color:$fc-primary;
          width:100%;
          @include trans2s;
          background: #fff;
          border: none;
          align-items: center;
          .icon{
            margin-right:10px;
            width:16px;
            height:16px;
            display: flex;
            align-items: center;
          }
          &:hover{
            background:#EEF4F6;
          }
        }
      }

      &.tags_list{
        li{
          .dropdown_item{
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            color:$fc-secondary;
            display:flex;
            justify-content: space-between;
            .left_ui{
              display:flex;
              align-items:center;
            }
            svg path, svg rect, svg g{
              @include trans2s;
            }
            .active_ui{
              @include trans2s;
              opacity:0;
              display: inline-flex;
              align-items: center;
              .remove_icon{
                display:none;
              }
              i{
                display:inline-flex;
                align-items:center;
              }
            }
            &:hover,
            &.checked{
              background:transparent;
              color:$fc-primary;
              svg rect{
                fill:$fc-primary;
              }
              .active_ui{
                opacity:1;
              }

            }
            &.checked{
              &:hover{
                .active_ui{
                  .active_icon{
                    display:none;
                  }
                  .remove_icon{
                    display:inline-flex;
                  }

                }
              }
            }
          }
        }
      }
    }
    .dropdown_seprater{
      background:#F5F5F5;
      height:1.03px;
      width: calc(100% - 36px);
      margin: 9px 18px;
    }
    .inner_menu {
      position: fixed !important;
      width: 100%;
      height: 100%;
      background: #fff;
      top: 0 !important;
      transform: translate3d(0px, 0px, 0px) !important;
      right: -100% !important;
      left: initial !important;
      transition:0.2s ease-in-out;
      padding:0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.show{
        right:0 !important;
      }
      .content_group{
        height:calc(100% - 68px);
        .inner_dropdown_list{
          height: calc(100% - 42px) !important;
          overflow-y: auto;
        }
      }
      .header_wrapper{
        width:100%;
        .back_menu{
          width: 100%;
          border: none;
          background: #fff;
          display: flex;
          padding: 0 18px;
          height: 42px;
          align-items: center;
          border-bottom: 1px solid #F5F5F5;

        }
      }
      .dropdown_footer{
        padding:11px 20px 11px;
        border-top:1px solid #F5F5F5;
        width:100%;
        .primary_outline_btn{
          width:100%;
          font-size: 16px;
          font-weight: 400;
          line-height: 19.09px;
          text-align: center;
          &:disabled{
            opacity:0.6;
          }
        }
        .primary_btn{
          @include remy(16);
          font-weight: 400;
          line-height: 19.09px;
          text-align: center;
          padding:10px 20px 10px 20px;
          width:100%;
          height:45px;
        }
      }
      .inner_dropdown_list{
        .p-listbox{
          border:none;
          background:#fff;
          .p-checkbox{
            width:18px;
            height:18px;
            border-radius:4px;
          }
          .country-item{
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            .quote_member_name{
              display: -webkit-box;
              max-width: 100%;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          li{
            padding:10px 18px;
            align-items: center;
            background: #fff !important;
            outline: none;
            color:$fc-primary;
            box-shadow: none !important;
            .p-checkbox-box{
              height:18px;
              width:18px;
              border-radius:4px;
              &:hover{
                border-color:#96999F;
              }
              &.p-highlight{
                border-color:$teal-tint;
                background:$teal-tint;
              }
              .p-checkbox-icon{
                width: 11px;
                height: 8px;
                background-position: center;
                background-size: 100%;
              }
            }
          }
          .p-listbox-list{
            padding:2px 0 0;
          }
        }
      }
    }
    .p-listbox-header{
      display:none !important;
    }
    .p-listbox-list-wrapper{
      max-height: 100% !important;
      overflow-y: auto;
    }
  }
  .card_menu_dropdown.dropdown_bg{
    max-height:280px ;
    @include trans2s;
    &.dropdown_opened{
      min-height:300px;
      &.item-0{
        min-height:150px;
      }
      &.item-1{
        min-height:160px;
      }
      &.item-2{
        min-height:200px;
      }
      &.item-3{
        min-height:243px;
      }
      &.item-4{
        min-height:283px;
      }
      &.item-5{
        min-height:320px;
      }
      &.item-6{
        min-height:360px;
      }
    }
    .dropdown_inner_cstm{
      max-height:100%;
    }
  }
  .dropdown_bg{
    &:has(.inner_menu.show){
      overflow:hidden;
    }
  }
}

.card_menu_dropdown{
  transform: translate3d(0px, 10px, 0px) !important;
  overflow: hidden;
  display: none;
  &.show{
    display:block;
  }
  .dropdown-toggle{
    &:after{
      display:none;
    }
  }
  ul{
    li{
      display: flex;
      .dropdown_item{
        padding:10px 18px 9px;
        display:flex;
        @include remy(15);
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        font-family: 'Helvetica Neue';
        color:$fc-primary;
        width:100%;
        @include trans2s;
        background: #fff;
        border: none;
        align-items: center;
        .icon{
          margin-right:10px;
          width:16px;
          height:16px;
          display: flex;
          align-items: center;
        }
        &:hover{
          background:#EEF4F6;
        }
      }
    }

    &.tags_list{
      li{
        .dropdown_item{
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          color:$fc-secondary;
          display:flex;
          justify-content: space-between;
          .left_ui{
            display:flex;
            align-items:center;
          }
          svg path, svg rect, svg g{
            @include trans2s;
          }
          .active_ui{
            @include trans2s;
            opacity:0;
            display: inline-flex;
            align-items: center;
            .remove_icon{
              display:none;
            }
            i{
              display:inline-flex;
              align-items:center;
            }
          }
          &:hover,
          &.checked{
            background:transparent;
            color:$fc-primary;
            svg rect{
              fill:$fc-primary;
            }
            .active_ui{
              opacity:1;
            }

          }
          &.checked{
            &:hover{
              .active_ui{
                .active_icon{
                  display:none;
                }
                .remove_icon{
                  display:inline-flex;
                }

              }
            }
          }
        }
      }
    }
  }
  .dropdown_seprater{
    background:#F5F5F5;
    height:1.03px;
    width: calc(100% - 36px);
    margin: 9px 18px;
  }

  .inner_menu {
    position: fixed !important;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0 !important;
    transform: translate3d(0px, 0px, 0px) !important;
    right: -100% !important;
    left: initial !important;
    transition:0.2s ease-in-out;
    padding:0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.show{
      right:0 !important;
    }

    .header_wrapper{
      width:100%;
      .back_menu{
        width: 100%;
        border: none;
        background: #fff;
        display: flex;
        padding: 0 18px;
        height: 42px;
        align-items: center;
        border-bottom: 1px solid #F5F5F5;

      }
    }

    .dropdown_footer{
      padding:11px 20px 11px;
      border-top:1px solid #F5F5F5;
      width:100%;
      .primary_outline_btn{
        width:100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.09px;
        text-align: center;
        &:disabled{
          opacity:0.6;
        }
      }
      .primary_btn{
        @include remy(16);
        font-weight: 400;
        line-height: 19.09px;
        text-align: center;
        padding:10px 20px 10px 20px;
        width:100%;
        height:45px;
      }
    }

    .inner_dropdown_list{
      .p-listbox{
        border:none;
        background:#fff;
        .p-checkbox{
          width:18px;
          height:18px;
          border-radius:4px;
        }
        .country-item{
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          .quote_member_name{
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        li{
          padding:10px 18px;
          align-items: center;
          background: #fff !important;
          outline: none;
          color:$fc-primary;
          box-shadow: none !important;
          &:hover{
            background: #EEF4F6 !important;
          }
          .p-checkbox-box{
            height:18px;
            width:18px;
            border-radius:4px;
            &:hover{
              border-color:#96999F;
            }
            &.p-highlight{
              border-color:$teal-tint;
              background:$teal-tint;
            }
            .p-checkbox-icon{
              width: 11px;
              height: 8px;
              background-position: center;
              background-size: 100%;
            }
          }
        }
        .p-listbox-list{
          padding:2px 0 0;
        }
      }
    }
  }

  .p-listbox-header{
    display:none !important;
  }

  .p-listbox-list-wrapper{
    max-height: 130px !important;
    overflow-y: auto;
  }

}
.card_menu_dropdown.dropdown_bg{
  border: 1px solid #EAECF0;
  background: #FFFFFF;
  box-shadow: 0 4px 6px -2px #10182812, 0px 12px 16px -4px #1018281F;
  width:214px;
  border-radius:4px;
  padding:11px 0;
  max-height: 350px;
  overflow-y: auto;

  &:has(.inner_menu.show){
    overflow-y: hidden;
  }
}

.tag_wrapper {
  top: 23px;
  position: absolute;
  left:18px;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  .tooltip-inner{
    display:flex;
    align-items:center;
    //min-width:86px;
    font-size: 13px;
    font-weight: 400;
    line-height:9px;
    text-align: center;
    white-space:nowrap;
  }
  &.only_local{
    flex-direction: row-reverse;
  }
  .tag_ui{
    width: 18px;
    height: 18px;
    border-radius: 3.2px;
    display:inline-flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    margin-right:4px;
    @include trans2s;
    border:0.5px solid #E0E1E5;
    opacity:0;
    &.active{
      opacity:1;
    }
    svg path{
      @include trans2s;
    }

    &.active{
      background: #000000 !important;
      border:0.5px solid #000 !important;
      svg path{
        fill:#fff !important;
      }
      &.fav_tag{
        svg path{
          //stroke:#fff !important;
        }
      }
    }

  }
}
.for_fav_tag{
  top: 20px;
  left: 18px;
  .tag_ui{
    background: #C7C7C7;
    border-color:#C7C7C7;
  }

}

.space_chips{
  padding:0 18px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap:10px;
  li {
    cursor: pointer;
    list-style: none;
    border: 1px solid $fc-hint;
    border-radius: 8px;
    /*padding: 6px 7px 6px;*/
    padding:4.5px 7px 4.5px;
    font-style: normal;
    font-family: Helvetica Neue md;
    line-height: 16.8px;
    @include remy(14);
    min-height: 28px;
    display: inline-flex !important;
    align-items: center;
    @include trans2s;
    //white-space: nowrap;
    margin-bottom: 12px;
    .chip_name {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &:hover{
      background:$bg-gray;
      border-color:rgba(199,199,199,0.25);
    }
    .cross_btn{
      display:inline-flex;
      margin-left:6px;
    }
  }
}



//setup and publish custom showroom css starts here

.custom_showroom_wrapper{
  padding-top: 129px;
  .mx-1320px{
    width:100%;
  }
  @media (min-height: 650px) {
    display:flex;
    height:calc(100dvh - 190px);
    padding-top:0;
  }
  @include tabm{
    padding-top: 79px;
  }
  .left_showroom_wrapper{
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 64px;
    .custom_heading{
      font-family:$font_Souvenir_light;
      font-size: 48px;
      font-weight: 300;
      line-height: 62px;
      text-align: left;
      max-width: 512px;
      @include showroomresponsive-lg{
        max-width:365px;
      }
    }
  }
}

.showroom_service_wrapper{
  display: flex;
  align-items: flex-start;
  padding-bottom: 40px;
  @include tabm{
    justify-content: center;
  }
  .img_secton{
    margin-top: 6px;
    width: 58px;

  }
  .service_description{
    margin-left: 18px;
    .service_heading{
      font-family: 'Helvetica Neue md';
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.004em;
      text-align: left;
      color: #222222;
      margin-bottom: 11px;
    }
    .content_desc{
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.004em;
      text-align: left;
      padding-left: 25px;
      max-width: 390px;
      color: #666666;
    }
  }
}
//setup and publish custom showroom css ends here


//about you property css starts here

.showroom_step1_wrapper{
  padding-top: 59px;
  @media (min-width: 1515px) {
    padding-top: 0;
  }
  @include tabl{
    padding-top: 50px;
  }
  .showroom_inner{
    @media (min-width: 1320px) {
      margin-right: -31px;
    }
  }
  .step1_left_sect{
    display: flex;
    height: 100%;
    align-items: center;
    padding-bottom: 32px;
    .left_inner_wrapper{
      .heading_step{
        font-family:$font_Souvenir_light;
        font-size: 24px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
        color: #5592A0;
        margin-bottom: 19px;
      }
      .heading_step1{
        font-family:$font_Souvenir_light;
        font-size: 48px;
        font-weight: 300;
        line-height: 62px;
        text-align: left;
        color: #222222;
        max-width: 362px;
        margin-bottom: 13px;
        @include tabm{
          max-width: initial;

        }
      }
      .text_des{
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.004em;
        text-align: left;
        color: #222;
        @include showroomresponsive-lg{
          max-width: 267px
        }
      }

    }
  }
  .step2_left_sect{
    .img_sectons{
      max-width: 857px;
      img{
        display: inline-block;
        width: 100%;
      }
    }

  }


  .col_right{
    padding-left: 0px;
    padding-right: 0px;
    @include tabl{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
.step_2_wrapper{
  .step2_left_sect .img_sectons {
    max-width: 835px;
    margin-left: auto;
    @media (min-width: 1400px) {
      margin-right: -40px;
    }
  }

}

.step1_updates{
  .step2_left_sect{
    .img_sectons{
      max-width: 820px;
    }
  }
}

//about you property css ends here

.error_container{
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  color:#EA1212;
  margin-top:17px;
  &.text-left{
    text-align: left;
  }
}



/*simulater ui scss start here */
.showroom_name_wrapper{
  padding-top:27.71px;
  .content_ui{
    .heading{
      @include remy(28);
      font-weight: 400;
      line-height: 36px;
      text-align: left;
      color:$gray-800;
      margin-bottom:14px;
      width:calc(100% + 20px);
    }
    .subtitle{
      @include remy(18);
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color:#666666;
      margin-bottom:49px;
    }
  }
  .form_wrapper{
    position:relative;
    .error_msg_ui{
      position:absolute;
      top:100%;
      left:0;
    }
  }
}
.simulator_wrapper{
  max-width:100%;
  overflow: hidden;
  img{
    width:100%;
  }
}
.preview_wrapper{
  max-width:333.56px;
  margin-left:auto;
  position:relative;
  margin-right:40px;
  //overflow:hidden;
  .action_buttons {
    display: inline-flex;
    position: absolute;
    right: -50px;
    top: 118px;
    width:40px;
    flex-wrap: wrap;
    display: none;
    .btn{
      width: 36px;
      height: 36px;
      border: 1px solid #E0E1E5;
      border-radius:100%;
      background:#fff;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      margin-bottom:13px;
      @include trans2s;
      &:hover{
        border: 1px solid $fc-primary;
      }
    }
  }
}
.simulator_content_ui{
  position: absolute;
  width: 100%;
  left: -1px;
  padding:0 11px 0 12px;
  top: 97px;
  overflow: hidden;
  height: 434px;
  //overflow-y: auto;
  .banner_image{
    width:100%;
    height:174px;
    img{
      width:100%;
      height:100%;
      object-fit:cover;
    }
  }
  .brand-name_sec{
    padding:15px 20px 15px;
    .brand_name{
      font-family: 'itc_souvenir_stdlight';
      font-size: 25.18px;
      font-weight: 300;
      line-height: 29.9px;
      text-align: left;
      color:$gray-800;
      margin-bottom:11px;
      word-wrap:break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .desc{
      font-family: 'Helvetica Neue md';
      font-size: 11.02px;
      line-height: 15.74px;
      letter-spacing: 1.1803845167160034px;
      text-align: left;
      color:$gray-800;
      text-transform:uppercase;
    }
  }
}

.form_wrapper{
  .custom-input-1{
    height:73px;
    font-size: 22px;
    font-weight: 300;
    line-height: 22px;
    text-align: left;
  }
  .info_sec{
    display:inline-flex;
    align-items: flex-start;
    justify-content:space-between;
    padding-top: 10px;
    width:100%;
    .left_sec{
      display: flex;
      align-items: flex-start;
      width: 80%;
      flex-wrap: wrap;
      white-space: normal;
      position: relative;
      padding-left: 22px;
      .icon{
        display:inline-flex;
        margin-right:4px;
        align-items: flex-start;
        top: 3px;
        position: absolute;
        left: 0;
        cursor: pointer;

      }
      p{
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color:$fc-secondary;
        display: inline-block;
        min-height: 45px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width:100%;
        position:relative;
        top:1px;
        word-break:break-word;
        //.light_ui{
        //  color:#e0e1e4;
        //  text-indent: 360px;
        //  margin-top: -23px;
        //  overflow: hidden;
        //  text-overflow: ellipsis;
        //  display: -webkit-box;
        //  -webkit-line-clamp: 2;
        //  -webkit-box-orient: vertical;
        //  width:100%;
        //  position:relative;
        //  top:1px;
        //  word-break: break-all;
        //}
      }
    }
    .character_count{
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: right;
      color:#666666;
      min-width:60px;
      transition:0.2s ease-in-out;
    }
  }
}


.dummy_listing{
  .heading_ui{
    width: 91.3px;
    height: 14.3px;
    border-radius: 2.2px;
    background:#EAEAEA;
    margin-bottom:12px;
    margin-left:20px;
  }
  .dummy_card_list{
    display:flex;
    gap:6px;
    overflow-x: overlay;

    .dummy_card{
      width: 115.5px;
      min-width:115.5px;
      height: 115.5px;
      background:#F5F5F5;
      aspect-ratio: 1/1;
      &:first-child{
        margin-left:20px;
      }
      &:last-child{
        margin-right:20px;
      }
    }
  }
}


.image_upload_wrapper{
  max-width:455px;
  .info_text{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color:#666666;
    margin-top:20px;
    span{
      display: block;
    }
    &.error{
      color:#EA1212;
    }
  }

  .upload_inner_ui{
    background: #FFFFFF;
    border: 2px dashed #C7C7C7;
    width:455px;
    padding:30px 30px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    @include trans2s;
    overflow: hidden;
    min-height:255.62px;
    &.drag{
      border-color:$fc-primary;
    }
    &.error{
      border-color:#EA1212;
    }
    .file_upload{
      position: absolute;
      top:-50px;
      opacity:0;
      visibility: visible;
      cursor:pointer;
      height:calc(100% + 50px);
      left:-2px;
      width:455px;
    }
    .upload_content{
      .icon{
        display:inline-flex;
        width:58px;
        margin-bottom: 17px;
        img{
          width:100%;
        }
      }
      p{
        font-size: 20px;
        font-weight: 400;
        line-height: 14px;
        text-align: center;
        color:#222222;
        margin-bottom: 13px;
      }
      .desc{
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        text-align: center;
        color:#222222;
        display:inline-flex;
        margin-bottom: 22px;
      }
      .primary_outline_btn{
        height:38px;
        padding:0 18px;
        min-width:94px;
        font-size: 16px;
        padding-bottom: 2px;
        font-weight: 400;
        line-height: 19.09px;
        text-align: center;
        font-family: 'Helvetica Neue';
        opacity: 1;
        transition: opacity 0.3s ease, color 0.3s ease, border-color 0.3s ease;
        position:relative;
        z-index:1;
        &:hover{
          opacity: 0.7;
        }
      }
    }
  }
}

.content_bottom_sec{
  .logo_content_wrapper{
    padding:0 20px;
    margin-top:-55px;
    .subtitle1{
      font-size: 15.4px;
      line-height: 22px;
      text-align: center;
      color:#222222;
      font-family: 'Helvetica Neue md';
      margin-bottom:13px;
      a{
        color:#222222;
        display:inline;
        pointer-events:none;
      }
      .powered_by{
        display:inline-flex;
      }
    }
    .desc{
      font-size: 15.4px;
      font-weight: 300;
      line-height: 22px;
      text-align: center;
      color:#222222;
      width:calc(100% + 10px);
    }
  }
  .brand_logo_sec{
    //margin-top:-55px;
    width: 105.33px;
    height: 105.33px;
    background:#fff;
    border-radius: 100%;
    display:inline-block;
    box-shadow: 2.64px 2.64px 15.84px 0px #0000001A;
    margin-bottom:18px;
    overflow:hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    p{
      font-size: 16px;
      line-height: 21.78px;
      letter-spacing: 1.6994174718856812px;
      text-align: center;
      color:#000000;
      font-family: 'Helvetica Neue md';
      text-transform:uppercase;
    }
    img{
      max-width:100%;
      max-height:100%;
      border-radius: 100%;
      &.default_image{
        max-width:52px;
        border-radius:0;
      }
    }
  }
}
.page_title_wrap{
  .heading {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: #222222;
    margin-bottom: 14px;
  }
  .subtitle{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color:#666666;
  }
}
.pt-67{
  padding-top:67px;
}
.pt-46{
  padding-top:46px;
}

.showroom_listing_wrapper{
  padding-top:105px;
  .mx-1320{
    max-width:730px
  }
  .custom-input-1{
    height:73px;
    font-size: 22px;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-align: left;
    &::placeholder {
      font-size: 22px;
      font-weight: 300;
      line-height: 27px;
      letter-spacing: 0.01em;
    }

    &::-ms-input-placeholder { /* Edge 12 -18 */
      font-size: 22px;
      font-weight: 300;
      line-height: 27px;
      letter-spacing: 0.01em;
    }
  }
}
.custom_error1{
  @include remy(16);
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color:#EA1212;
  display: flex;
  margin-top: 8px;
}


.image_cropper_modal{
  max-width:776px;
  margin:auto;
  width:100%;
  .p-dialog-content{
    padding:0 0 35px;
    border-radius:16px !important;
  }
  .p-dialog-header{
    display:none !important;
    position: absolute;
    .p-dialog-title{
      display:none;
    }
  }
  .modal_body{
    position:relative;
    &.isUploadingFile:after {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      background: rgba(255,255,255,0.6);
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 16px;
    }
  }
  .modal_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width:100%;
    padding: 17px 28px 17px; //padding: 26px 28px 10px;
    .close-btn{
      position:absolute;
      right: 22px;
      top: 16px;
      z-index: 2;
      display: inline-flex;
      background: transparent;
      border: none;
      @include trans2s;
      svg path{
        @include trans2s;
      }
      &:hover{
        svg path{
          stroke:$fc-primary;
        }
      }
    }
    .rotate_btn{
      background: transparent;
      border: none;
      display: inline-flex;
      align-items: center;
      @include trans2s;
      &:hover{
        opacity:0.7;
      }
      .icon{
        display:inline-flex;
        margin-right:10px;
        align-items: center;
      }
    }
  }
  .modal_footer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top:40px;
    .outline_btn{
      height:45px;
      border-radius:5px;
      min-width:100px;
      padding:0 20px;
      justify-content: center;
      margin:0 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      text-align: center;
      font-family: 'Helvetica Neue';
    }
    .primary_btn{
      height:45px;
      border-radius:5px;
      min-width:100px;
      padding:0 20px;
      justify-content: center;
      margin:0 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      text-align: center;
      font-family: 'Helvetica Neue';
      position:relative;
      .loader_icon{
        display:none;
      }
    }
    .loader_icon {
      width: 10px;
      aspect-ratio: 1;
      border-radius: 50%;
      animation: l5 1s infinite linear alternate;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
    }
    @keyframes l5 {
      0%  {box-shadow: 20px 0 #FFFFFF, -20px 0 #fff5;background: #FFFFFF }
      33% {box-shadow: 20px 0 #FFFFFF, -20px 0 #fff5;background: #fff5}
      66% {box-shadow: 20px 0 #fff5,-20px 0 #FFFFFF; background: #fff5}
      100%{box-shadow: 20px 0 #fff5,-20px 0 #FFFFFF; background: #FFFFFF }
    }
  }
}

.image_cropper_modal .isUploadingFile + .modal_footer .primary_btn {
  font-size: 0;
  position:relative;
  z-index:2;
  display:inline-flex;
  align-items:center;
  .loader_icon{
    display: flex;
  }
}

.image_cropper_wrapper{
  width:100%;
  max-width:100%;
  height:434.66px;
  margin:auto;
  display: flex;
  align-items: center;
  justify-content: center
}


.zoom_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px; //padding-top: 10px;
  .zoom_range_slider{
    max-width:347px;
    width:100%;
    margin:0 20px;
    .p-slider {
      height:5.76px;
      background:$border-light;
      .p-slider-handle{
        border:3.6px solid $fc-primary;
        width: 14.4px;
        height: 14.4px;
        top:62%;
        margin-top: -0.5715rem;
        margin-left: -0.5715rem;
        &:hover{
          background:#fff !important;
        }
        &:focus{
          outline: none !important;
          box-shadow: none !important;
        }
      }
      .p-slider-range{
        background:$fc-primary;
        border-radius:23.04px;
      }
    }
  }

  button{
    background:transparent;
    border:none;
    width:30px;
    height:30px;
    display:inline-flex;
    align-items: center;
    justify-content: center;
    @include trans2s;
    &:hover{
      opacity:0.8;
    }
  }

}

.custom_textarea{
  padding-top:20px;
  resize:none;
  padding-bottom:20px;
  display:flex;
  height:auto;
  min-height:73px !important;
}

.typing_result_listing{
  font-size: 16px;
  font-weight: 400;
  line-height: 24.04px;
  text-align: left;
  color:#1B72F3;
  padding-top:13px;
}

.mx-675{
  max-width:675px;
  margin:auto;
}
.mx-1026{
  max-width:1026px;
  margin:auto;
}

.space_images_wrapper{
  padding-top:37px;
  .content_wrapper{
    text-align:center;
    margin-bottom:52px;
    .desc{
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      color: #666666;
      span{display: block}
    }
  }
  .space_image_listing{
    padding-bottom:20px;
  }
}

.self_heading{
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  color:$gray-800;
  margin-bottom:17px;
  font-family: 'Helvetica Neue';
}
.sub_heading{
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color:#666;
}
.list_title{
  @include remy(20);
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  font-family: 'Helvetica Neue md';
  color:$gray-800;
}

.space_image_listing{
  display:flex;
  flex-wrap:wrap;
  grid-gap: 28px 33px;
  align-items:flex-end;
  .image_upload_wrapper {
    width:100%;
    &.image_uploaded{
      .upload_inner_ui{
        padding:0;
        border:none;
        img{
          width:100%;
          height:100%;
          object-fit:cover;
          object-position: center;
        }
      }
    }
    .upload_inner_ui {
      width:100%;
      padding:20px 30px 21px;
      overflow: hidden;
      max-height: 181px;
      min-height:181px;
      input{
        width:100%;
        height: 228px;
        left: 0;
      }
      .outer_ui{
        display: inline-flex;
        height:181px;
        width: 100%;
      }
      .upload_content {
        p{
          @include remy(16);
          line-height:14px;
          margin-bottom:8px;
        }
        .desc{
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          text-align: center;
          margin-bottom:15px;
          display:flex;
          align-items:center;
          justify-content:center;
        }
        .icon {
          width: 36px;
          margin-bottom: 10px;
        }
      }
      &:hover{
        .hover_ui{
          opacity:1;
          visibility:visible;
        }
      }
      .hover_ui{
        position:absolute;
        left:0;
        top:0;
        background:rgba(0,0,0,0.3);
        width:100%;
        height:100%;
        @include trans2s;
        opacity:0;
        visibility: hidden;
        .buttons_wrap{
          position:absolute;
          right:24px;
          top:16px;
          display: flex;
          align-items: center;
          gap: 15px;
          .btn{
            width: 36px;
            height: 36px;
            border-radius:100%;
            display:inline-flex;
            align-items:center;
            box-shadow: 4px 4px 4px 0px #00000040;
            background:#fff;
            padding:0;
            justify-content: center;

          }
        }
        .browse_btn {
          position: absolute;
          bottom: 33px;
          margin: auto;
          left: 0;
          right: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width:148px;
          overflow: hidden;
          input{
            position:absolute;
            left:0;
            top:0;
            opacity:0;
            &::-webkit-file-upload-button {
              cursor:pointer;
            }
          }
          label{
            border: 2px solid #FFFFFF;
            background:rgba(0,0,0,0.25);
            font-size: 16px;
            font-weight: 400;
            line-height: 19.2px;
            text-align: center;
            cursor:pointer;
            color:#fff;
            //padding:0 10px;
            padding: 0px 10px 2px 10px;
            height:38px;
            display:inline-flex;
            align-items:center;
            justify-content: center;
            border-radius:5px;
            min-width:148px;
          }
        }
      }
    }
  }
  .error_container{
    font-size: 13px;
    line-height: normal;
    position: absolute;
    left: 0;
    bottom: -21px;
    text-align: left;
  }
  .space_image_card{
    display:flex;
    flex-direction:column;
    width:100%;
    max-width:321px;
    position: relative;
    .content_ui{
      display:flex;
      align-items: flex-end;
      justify-content:space-between;
      margin-bottom:10px;
      .property_name{
        font-size: 20px;
        font-family: 'Helvetica Neue md';
        line-height: 28px;
        letter-spacing: 0.004em;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
      }
      .items_count{
        display:flex;
        align-items: center;
        color: #666666;
        min-width:86px;
        justify-content: flex-end;
        .count_icon {
          display:inline-flex;
          align-items:center;
          margin-right:5px;
        }
      }
    }
  }
}

.inner_footer {
  .new_start_btn{
    display:inline-flex;
    align-items: center;
  }
  .primary_btn {
    height: 45px;
    border-radius: 5px;
    min-width: 100px;
    padding: 0 24.5px;
    justify-content: center;
    margin:0;
    font-size: 18px;
    font-family: 'Helvetica Neue';
    line-height: 28px;
    text-align: center;
    position: relative;
    .next_icon{
      display: inline-flex;
      align-items: center;
      margin-left: 8px;
      svg{
        display: inline-flex;
        align-items: center;
      }
    }
    @media (max-width:1300px){
      margin:0;
    }

    //.loader_btn_ui {
    //  display: none;
    //}
  }

  .loader_btn_ui {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    background: #DC5A4B;
    border-radius: 5px;
  }

  .loader_icon {
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  @keyframes l5 {
    0% {
      box-shadow: 20px 0 #FFFFFF, -20px 0 #fff5;
      background: #FFFFFF
    }
    33% {
      box-shadow: 20px 0 #FFFFFF, -20px 0 #fff5;
      background: #fff5
    }
    66% {
      box-shadow: 20px 0 #fff5, -20px 0 #FFFFFF;
      background: #fff5
    }
    100% {
      box-shadow: 20px 0 #fff5, -20px 0 #FFFFFF;
      background: #FFFFFF
    }
  }
}


/*product assignment*/

.self_product_assignment{
  .heading_wrapper{
    padding-top: 43px;
    padding-bottom:40px;
    z-index:2;
    position:relative;
    background:#fff;
    @media (min-height:1100px){
      padding-top:7vh;
    }
  }
}


.space_list_card{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  border: 1px solid #EAECF0;
  box-shadow: 0px 1px 2px 0px #1018280F;
  background: #FBFBFB;
  margin-bottom:15px;
  //padding:21px 17px 21px 20px;
  position: relative;
  overflow: visible;
  border-radius: 4px;
  max-width: 275px;
  @include trans2s;
  cursor: pointer;
  .space_section{
    display:flex;
    align-items:center;
    justify-content: space-between;
    width:100%;
    padding:21px 17px 21px 20px;
    &.cdk-drop-list-receiving,
    &.cdk-drop-list-dragging{
      .self_product_card{
        display:none !important;
      }
      app-product-card{
        display: none !important;
      }
    }
  }
  &.add_space_btn{
    border:none;
    background:transparent;
    padding:10px 0 ;
    margin-bottom:20px;
    margin-bottom:0;
    box-shadow: none;
    &:hover{
      border:none;
      background:transparent;
      .icon_text_btn{
        color:$orange;
        .icon{
          svg path{
            fill:$orange;
          }
        }
      }
    }
    .icon_text_btn{
      border:none;
      display:inline-flex;
      align-items:center;
      font-size: 15px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color:$fc-secondary;
      padding:0;
      @include trans2s;
      .icon{
        margin-right:9.74px;
      }
      svg path{
        @include trans2s;
      }
    }
  }
  &:last-child{
    margin-bottom:0px;
  }
  &:hover, &.active{
    background: #E4EFF180;
    border: 1px solid #5592A04D;
    .link-primary{
      opacity:1;
    }
    .space_title_wrapper{
      color:$teal-primary;
    }
    .product_count{
      opacity:0;
    }
    .more_dropdown_wrapper{
      opacity:1;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index:2;
    }

  }
  &.selected{
    background: #E4EFF180;
    border: 1px solid #5592A04D;
    .cdk-drag-handle{
      opacity:0;
    }
    .space_title_wrapper{
      color:$teal-primary;
    }
    .product_count{
      opacity:1;
      color:$teal-primary;
      //z-index: 3;
      p{
        color:$teal-primary;
      }
      .fill{
        fill:$teal-primary;
      }
      .stroke{
        stroke:$teal-primary;
      }
    }
    .more_dropdown_wrapper{
      opacity:0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index:1;
    }
    &.active{
      .more_dropdown_wrapper{
        opacity:1;
      }
      .product_count{
        opacity:0;
      }
    }
    &:hover{
      .more_dropdown_wrapper{
        opacity:1;
      }
      .product_count{
        opacity:0;
      }
    }

  }
  &:has(.drop_receiving){
    box-shadow:0 0 0 1px #93bac3;
    border-color:#93bac3;
    background:#f1f6f7 !important;
  }
  .space_title_wrapper{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    color:$fc-primary;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;

  }
  .product_count{
    display:inline-flex;
    align-items:center;
    @include trans2s;
    .icon{
      display:inline-flex;
      align-items:center;
      margin-right:6.57px;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 12.66px;
      letter-spacing: -0.05em;
      text-align: left;
      color:$fc-secondary;
    }
  }
  .more_dropdown_wrapper{
    position:absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include trans2s;
  }
  .link-primary{
    position:absolute;
    left:-22px;
    opacity:0;
  }
  .right_sec{
    min-width:60px;
    display:inline-flex;
    align-items: center;
    justify-content: flex-end;
  }
}


.filter_sec{
  display:flex;
  align-items:center;
  justify-content: space-between;
  width:100%;
  padding-bottom: 20px;
  .right_ui{
    display:flex;
    align-items:center;
  }
}

.spaces_list_wrapper{
  overflow-x:hidden;
  overflow-y:hidden;
  width: calc(100% + 30px);
  padding-left: 40px;
  margin-left: -30px;
  position: relative;
  .spaces_list_inner_wrapper{
    overflow:hidden;
    width: calc(100% + 30px);
    padding-left: 30px;
    padding-right:2px;
    padding-top:1px;
    margin-left: -30px;
    min-height:300px;
  }
}

.self_serve_page{
  @include showroomresponsive{
    width:1079px;
  }

  .showroom_name_wrapper,.showroom_live_wrapper{
    @media (min-height: 650px){
      display: flex;
      height: calc(100dvh - 190px);
    }
    .mx-1320{
      width:100%;
    }
  }
  .showroom_live_wrapper{
    @media (min-height: 650px){
      height: calc(100dvh - 157px);
    }
  }
  .showroom_step1_wrapper {
    @media (min-height: 650px) {
      display: flex;
      height: calc(100dvh - 190px);
    }
  }

  @media (max-width:1200px) {
    .mx-1320 {
      padding: 0 50px;
    }
  }
}

.scroll-hidden{
  .self_serve_edit_section .tabs_content_wrapper .self_product_assignment .row .self_product_wrapper{
    overflow-y:auto;
  }
  .spaces_list_wrapper, .self_product_assignment .row .self_product_wrapper{
    overflow-y:auto;
  }

}
.header_overlay{
  .header_sec{
    &:before{
      opacity:1 !important;
      visibility:visible !important;
    }
  }
}
.self_product_assignment {
  .add_item_fh_step2{
    .row{
      overflow: visible;
    }
  }
}

.self_product_assignment {
  .row {
    //max-height: calc(100vh - 200px);
    max-height: calc(100vh - 164px);
    overflow: hidden;

    &.new_add_item_model{
      overflow: visible;
    }

    .col-lg-9, .my_items_wrapper, .col-lg-3, .sidebar_wrapper {
      height: 100%;
    }

    .self_product_wrapper {
      //max-height: calc(100vh - 300px);
      //height: calc(100vh - 300px);
      max-height: calc(100vh - 250px);
      height: calc(100vh - 250px);
      position: relative;
      padding-bottom:20px;
      min-height:390px;
      overflow:hidden;
    }
    .spaces_list_wrapper {
      //max-height: calc(100vh - 270px);
      max-height: calc(100vh - 225px);
    }
  }
}

.self_product_assignment{
  &.my_space{
    .my_items_wrapper{
      height:100vh;
      .empty_product_wrapper{
        height: calc(100vh - 460px);
      }
      .cdk-virtual-scroll-content-wrapper{
        .product_card_blk:last-of-type{margin-bottom: 20px}
      }
    }

    .snak_wrap:has(.products_checked){
      .my_items_wrapper{
        .cdk-virtual-scroll-content-wrapper{
          .product_card_blk:last-of-type{margin-bottom: 60px}
        }
      }
    }
  }
}

.more_btn_wrapper{
  margin-right:20px;
  &.btn_hover{
    position:relative;
    &:hover, &.show{
      &:after{
        opacity:1;
      }
    }
    &:after{
      position:absolute;
      content:'';
      height:38px;
      width:38px;
      background:#F5F5F5;
      left:-7px;
      top:-7px;
      z-index:-1;
      border-radius:100%;
      @include trans2s;
      opacity:0;
    }
    > .dropdown-toggle{
      width:24px;
      height:24px;
      border-radius:100%;
      display:flex;
      align-items:center;
      justify-content:center;
      @include trans2s;
      &:hover{
        //background:#F5F5F5;
      }
    }
  }
  &.card_menu {
    position:relative;
    top:initial;
    right:initial;
    opacity:1;
    visibility:visible;
    width:auto;
  }
  .dropdown-toggle{
    width:20px;
    height:20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    &:after{
      display:none;
    }
    &.dropdown_item{
      justify-content: flex-start;
      height:auto;
    }
  }
  .card_menu_dropdown{
    width:214px;
    right:-17px !important;
    left:initial !important;
  }
}

.assign_space_wrapper{
  .primary_outline_btn{
    @include remy(15);
    line-height: 18px;
    text-align: center;
    font-family: 'Helvetica Neue md';
    padding:0 17px;
    height:38px;
  }
  .dropdown-menu{
    width: 196px;
    border: 1px solid #EAECF0;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px -2px #10182812, 0px 12px 16px -4px #1018281F;
    border-radius:4px;
    padding:0;
    overflow: hidden;

    .p-listbox{
      background: #ffffff;
      border:none;
      border-radius: 0px;
      width:100% !important;
      .p-listbox-list{
        padding:0;
        .p-listbox-item{
          display:flex;
          align-items:center;
          padding:8px 16px;
          font-size: 15px;
          font-weight: 400;
          line-height: 22.4px;
          text-align: left;
          color:$fc-primary;
          cursor: pointer;

          &.p-highlight{
            background:transparent;
          }
          &:focus, &:hover{
            outline:none;
            background:#fff;
            box-shadow:none;
          }
          &:hover{
            background:#EEF4F6;
          }
          .p-checkbox{
            width:20px;
            height:20px;
          }
          .p-checkbox-box{
            width:20px;
            height:20px;
            border-radius:4px;
            &:hover{
              border-color:$fc-secondary;
            }
            &.p-highlight{
              border-color:#5592A0;
              background:#5592A0;
            }
          }
        }
      }
      .p-listbox-list-wrapper{
        overflow-y: auto;
        padding-top:10px;
      }
    }

    .btn_wrapper{
      border-top:1px solid #E0E1E5;
      padding:13px 16px 11px;
      .primary_btn{
        width:100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.09px;
        text-align: center;
        padding: 0 10px;
        height:38px;
      }
    }
  }
}

//space chip start here

.space_chips_outer{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 22px;
  &.add_item_modal{
    margin-bottom: 10px;
    gap: 8px;
  }
}

.space_chips_ui{
  .chip_outer{
    position: relative;
    p-checkbox{
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
    .chip_label{
      font-family: 'intermedium';
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #222222;
      padding: 6px 12px;
      border: 2px solid #E0E1E5;
      border-radius: 6px;
      cursor: pointer;
      &.checked{
        background: #5592A0;
        border: 2px solid #5592A0;
        color: #ffffff;
        &:hover{
          background: #5592A0;
          border: 2px solid #5592A0;
          color: #ffffff;
        }
      }
      &:hover{
        background-color: #E4EFF1;
        border: 2px solid #E4EFF1;
      }
    }
  }

}

//space chip ends here

.selected_item_wrapper{
  display:inline-flex;
  align-items: center;
  margin-left:21px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color:$fc-secondary;
  @include trans2s;
  cursor: pointer;
  .hover_image{
    display:none;
  }
  &:hover{
    color:$orange;
    .default_image{
      display:none;
    }
    .hover_image{
      display:inline-flex;;
    }
  }
  .icon{
    margin-right:5px;
    display: inline-flex;
    align-items: center;
  }
}

.self_serve_space_creation_wrapper{
  .heading_wrapper{
    padding-top: 45px;
    margin-bottom:50px;
  }
}


.fav_product_wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding:2px 2px 0px;
  .self_product_card{
    width: 18.652%;
    &:hover{
      .tag_wrapper {
        .tag_ui{
          background: #C7C7C7;
          border: 0.5px solid #C7C7C7;
          &.fav_tag{
            path{
              fill:#FFF;
            }
          }
        }
      }
    }
  }
  .tag_wrapper{
    .self_product_card{}
    .tag_ui{
      opacity: 1;
      background: #C7C7C7;
      svg path {
        fill: #fff;
      }
      &:hover{
        &.fav_tag{
          path{
            //stroke: #fff;
            fill:#96999F;
          }
        }

      }
    }
  }
}

.empty_product_wrapper{
  border: 1.5px dashed #E0E1E5;
  border-radius:7.64px;
  text-align:center;
  height:calc(100% - 230px);
  min-height:407px;
  display:flex;
  align-items:center;
  flex-direction: column;
  justify-content: center;
  padding-top:78px;
  padding-bottom:52px;
  background:#fff;
  @media (min-height: 800px){
    height:calc(93vh - 400px);
    padding-top:0px;
    padding-bottom:0px;
  }

  &.spaces_showroom{
    min-height: 460px;
    padding-top: 71px;
    padding-bottom: 70px;
    .content_ui{
      p{
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 20px;
      }
    }
  }

  .empty_icon{
    margin-bottom:20px;
    img{
      width:150px;
      height:121.15px;
    }
    img.any_store_img{
      width: 376.37px;
      height: 133.66px;
      max-width: 100%;
      margin-bottom: 24.34px;
    }
    img.unassigned{
      width: 184px;
      height: 110px;
      max-width: 100%;
    }
    img.assigned{
      width: 152.21px;
      height: 120px;
    }
  }
  .content_ui{
    label {
      font-size: 21px;
      font-weight: 400;
      line-height: 31.5px;
      text-align: center;
      margin-bottom: 10px;
      color: #666;
      display: block;
    }
    .match_result{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.30000001192092896px;
      text-align: center;
      color:#96999F;
      a{
        color:#96999F;
      }
    }
    p{
      font-size: 15px;
      font-weight: 400;
      line-height: 17.9px;
      text-align: center;
      color:$fc-secondary;
      margin-bottom:30px;
      .text-decoration-underline{
        font-family: 'Helvetica Neue md';
        text-decoration-skip-ink:none;
        cursor:pointer;
      }
    }

    .un_assigned{
      p{
        margin-bottom:20px;
      }
    }
    .assigned{
      p{
        margin-bottom:0;
      }
    }

    .any_store{
      .loader_wrapper{
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(255, 255, 255, 0.85);
        backdrop-filter: none;
        border-radius: 4px;
        .loader_positioning{
          max-width: 100%;
          .loader2{
            &:before, &:after {
              left: 0;
            }
          }
        }
      }
      label{
        font-size: 22px;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 31px;
      }
      .add-item-url{
        position: relative;
        width: 524px;
        max-width: 100%;
        .custom-input-1{
          padding-left: 53.87px;
          font-size: 16px;
          line-height: 19.09px;
          &::placeholder{
            @include remy(16);
            line-height: 19.09px;
            color: #C7C7C7;
          }
          &:focus{
            box-shadow: none;
            border-color: #96999F;
            &::placeholder{
              color: #C7C7C7;
            }
          }
        }
        .link_icons{
          width: 18.3px;
          height: 19px;
          left: 19.08px;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          svg{
            vertical-align: inherit;
          }
        }
      }
      .error-1{
        text-align: left;
      }
    }
  }
  .link-primary-btn{
    @include remy(16);
    font-family: 'Helvetica Neue md';
    margin-top:10px;
    &.new_link_btn{
      padding: 10.5px 15px;
      margin: 0;
      .el-icon{
        margin-right: 8px;
        svg{
          max-width: 100%;
        }
      }
      .el-text{
        font-size:16px;
        line-height: 19.54px;
        font-weight: 500;
      }
    }
    &:hover{
      color:#E37B6F !important;
    }
  }
  .primary_outline_btn{
    @include remy(16);
    line-height: 19.54px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding:0 16px;
    height:42px;
    .icon{
      display:inline-flex;
      align-items:center;
      margin-right: 5.34px;
    }
    svg path{
      @include trans2s;
    }
    &:hover{
      svg path {
        stroke:#E37B6F;
      }
    }
  }
}

.back_btn_wrapper{
  display: inline-flex;
  align-items: center;
  .back_space{
    height:38px;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    margin-right:10px;
    background:none;
    border:none;
    @include trans2s;
    border-radius:100%;
    font-family: 'Helvetica Neue md';
    font-size: 20px;
    line-height: 16px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    color:#222222;
    @media (max-width:1282px){
      font-size: 16px;
    }
    .icon{
      display:inline-flex;
      align-items:center;
      width:22px;
      height:22px;
      justify-content: center;
      svg path{
        transition: 0.2s ease-in-out;
      }
    }
    span{
      text-decoration:underline;
      text-decoration-skip-ink:none;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
      transition: 0.2s ease-in-out;
    }
    &:hover{
      .icon{
        svg path{
          stroke: #DC5A4B;
        }
      }
      span{
        color:#DC5A4B;
      }

    }
  }
  .space_name{
    font-size: 20px;
    font-family: 'Helvetica Neue md';
    line-height: 25px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    color:#222222;
    padding-left:10px;
    position:relative;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width:1282px){
      max-width: 205px;
    }
    @media (max-width:1110px){
      max-width: 180px;
    }
    &:after{
      position:absolute;
      content:"";
      height:20px;
      width:2px;
      background:#C7C7C7;
      left:0;
      top:50%;
      transform:translateY(-50%);
    }
    @media (max-width:1282px){
      font-size: 16px;
    }
  }
}

.remove_from_space{
  .icon_text_btn{
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color:#DC5A4B;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    @include trans2s;
    &:hover{
      color:#E37B6F;
    }
    .icon{
      margin-right:10px;
      display: inline-flex;
      align-items: center;
    }
  }
}

.gray_tooltip_wrap{
  opacity:1 !important;
  &.show{
    opacity:1 !important;
  }
  .tooltip-inner{
    background:rgba($fc-primary, 0.8);
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    line-height:9px;
    text-align: center;
    color:#fff;
    padding:6px 8px;
    @include trans2s;
  }
  .tooltip-arrow{
    &:before{
      display:none;
    }
  }
}

.filter_sort{
  display: flex;
  margin-bottom: 20px;
  margin-top: -4px;
  .search_result_wrap, .result_btn{
    font-size: 14px;
    font-weight: 400;
    line-height: 16.7px;
    text-align: left;
    font-family: 'Helvetica Neue';
  }
  .search_result_wrap {
    color:#96999F;
  }
  .result_btn{
    padding-left:0;
  }
}


/*modal css*/
.modal_590{
  max-width:590px;
  .modal-header .heading3{
    margin-bottom:32px;
  }
  .modal-footer{
    padding-bottom:30px;
  }
}


//showroom live css starts here

.showroom_live_wrapper{
  padding-top: 48px;
  overflow-x: hidden;
  padding-bottom:40px;
  //padding-bottom: 32px;
  .showroom_live_left{
    .head_1{
      @include remy(28);
      font-weight: 400;
      line-height: 36px;
      text-align: left;
      color: #222222;
      &.for_live{
        color:#006878;
      }
    }
    .text_desc{
      @include remy(18);
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      color: #666666;
      margin-top: 20px;
      width:calc(100% + 8px);
    }
    .showroom_live_form_wrapper{
      margin-top: 30px;
      max-width: 672px;
      .form-group{
        margin-bottom: 25px;
        .form-label{
          font-size: 15px;
          font-family: 'Helvetica Neue md';
          line-height: 18.32px;
          text-align: left;
          font-weight:500;
        }
        .custom-input-1{
          height:50px;
          @include remy(22);
          font-weight: 300;
          line-height: 27px;
          text-align: left;
          color: #444444;
          &::placeholder{
            color: #C7C7C7;
          }
        }
      }
    }
    .footer_container{
      margin-top: 13px;
      p{
        @include remy(15);
        font-style: italic;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
        color: #666666;
      }
    }
  }
  .qr_image_wrapper{
    max-width:442px;
    margin-left:auto;
    @include tabl{
      display: flex;
      height: 100%;
      align-items: center;
    }

    img{
      width: 100%;
      max-width:442px;
      min-width: 323px;
    }

  }
}
.showroom_live_form_wrapper{
  .form-group .fg-inner{
    margin-bottom:0;
  }
  .row {
    --bs-gutter-x: 20px;
  }
}

.live_shipping_address{
  //max-width: 536px;
  padding-right: 20px;
  width: calc(100% - 242px);
}
.live_apt{
  padding-left: 0px;
  min-width:242px;
}
.state_field{
  min-width:97px;
  padding-left:0;
}
.col-2.zip_field{
  min-width:145px;
}
//showroom live css ends here

.preview_page_outer{
  padding-top:40px;
  @media (min-height: 650px){
    display: flex;
    height: calc(100dvh - 170px);
  }
  @media (min-width: 1515px) {
    padding-top: 40px;
  }
  .final_preview_simulator{
    &:hover{
      .preview_btn{
        background: #DC5A4B;
        border: 2px solid #DC5A4B;
      }
    }
    .preview_btn{
      top: 162px;
      transition:0.3s ease-in-out;
    }
    .simulator_content_ui {
      //padding: 0 7px 0 10px;
      .banner_image{
        position: relative;
        &:after{
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          z-index: 0;
        }
      }
    }

  }

}

.preview_page_wrapper{
  width: 100%;
  margin: auto;
}

.final_preview_simulator{
  width:333px;
  height:555px;
  margin: auto;
  overflow: hidden;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  .simulator_img{
    width:100%;
    height:100%;
  }
  .final_simulator_content{
    position: absolute;
    width: 100%;
    left: 0;
    padding:0 7px 0 8px;
    top:0px;
    overflow: hidden;
    height:534px;
    overflow-y: auto;
    .simulator_content_ui{
      //height:100%;
      .banner_image{
        //height:128px;
      }
      .brand-name_sec{
        //.brand_name{
        //  font-size:19.4px;
        //  line-height:23.04px;
        //}
        //.desc{
        //  font-size: 8.49px;
        //  line-height: 12.13px;
        //  letter-spacing: 0.9094821214675903px;
        //  text-align: left;
        //}
      }
      .dummy_listing {
        .heading_ui {
          margin-left: 11px;
        }
        .dummy_card{
          width: 84px;
          height:84px;
          min-width:84px;
          &:first-child {
            margin-left: 11px;
          }
        }
      }
    }


  }
}

.product_listing_wrapper{
  .heading_sec{
    padding:0 20px 8px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    .left_sec, .right_sec{
      display:inline-flex;
      align-items:center;
    }
    h5{
      font-size: 14px;
      max-width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .count_wrapper{
      display:inline-flex;
      align-items:center;
      margin-left:4px;
    }
    .inner_count{
      font-size:12px;
      display:inline-flex;
      align-items: center;
      .icon{
        margin-right: 2px;
        img{
          width:12px;
        }
      }
    }
    .view_all_btn{
      display:inline-flex;
      .text_link1{
        font-size:12px;
        color:#444444;
        font-weight: 500;
        line-height: 21px;
        letter-spacing:normal;
        text-align: center;
        text-transform: uppercase;
        text-decoration:underline;
      }
      .mobile_arrow{
        display: inline-flex;
        margin-left: 4px;

        img{
          width:5px;
        }
      }
    }
  }

  .product_list{
    display:flex;
    gap:6px;
    overflow-x: overlay;

    .product_card{
      width:110px;
      min-width:110px;
      height:110px;
      background:#F5F5F5;
      aspect-ratio: 1/1;
      margin-bottom: 0;
      img{
        width:100%;
        height:100%;
        object-fit:cover;
      }
      &:first-child{
        margin-left:20px;
      }
      &:last-child{
        margin-right:20px;
      }
    }
  }
}



.cdk-drag-placeholder {
  opacity: 0;
}


.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging{
  cursor: grabbing !important;
  .space_list_card{
    cursor: grabbing !important;
  }
}

.iframe_preview_wrapper{
  background: #EFEFF0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top:0;
  left:0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y:hidden;
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  @include showroomresponsive{
    min-width:1079px;
  }
  .iframe_inner_sec{
    width: 100%;
    height: 100%;
    overflow-y:auto;
    padding:50px 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @media (min-height:800px){
      align-items:center;
    }
  }

  @include trans2s;
  &.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
  @media (min-height:750px){
    align-items: center;
  }
  .btn_switcher_wrapper{
    top: 34px;
    left:30px;
    position:fixed;
    display:flex;
    flex-direction: column;
    gap:12px;
    .switcher_btn{
      width: 52px;
      height: 52px;
      background:#E3E3E3;
      display:flex;
      align-items: center;
      justify-content: center;
      border:1px solid #E3E3E3;
      border-radius:100%;
      @include trans3s;
      svg path{
        @include trans3s;
      }
      &:hover{
        border:1px solid #222222;
        background:transparent;
        svg path{
          fill:#222222;
        }
      }
      &.active{
        background:#000;
        border:1px solid #000;
        &:hover{
          border-color:#000;
        }
        svg path{
          fill:#fff;
        }
      }
    }
  }
  .close_preview_btn{
    width: 52px;
    height: 52px;
    background:#fff;
    border-radius:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    border:none;
    position:fixed;
    top:34px;
    right:40px;
    @include trans3s;
    svg{
      @include trans3s;
    }
    &:hover{
      opacity:0.7;
      svg{
        opacity:0.7;
      }
    }
  }
}

.iframe_sec{
  text-align: center;
  padding:0px 0;

  &.desktop_frame{
    .iframe_inner_area{
      width:1110px;
      height:750px;
    }
  }
  .iframe_inner_area{
    width:342px;
    background:#fff;
    height:660px;
    border-radius:8px;
    display:inline-flex;
    iframe{
      width:100%;
    }
  }
  .url_link{
    @include remy(20);
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color:$fc-secondary;
    margin-top:16px;
  }
}

//dashboard section  starts here

.dashboard_wrapper{
  background-color: #F9FAFB;
  height: 100%;
  min-height: calc(100dvh - 60px);
}
.dashboard_top_section{
  background-color: #ffffff;
  @include phonel{
    border-bottom: 1px solid #E0E1E5;
  }
  .mw_940{
    @include phonel {
      padding: 0;
    }
  }
}
.dashboard_header_wrapper{
  padding: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include phonel {
    padding: 14px 20px 13px 20px;
  }
  .dashboard_heading{
    font-family: $font_Souvenir_light;
    @include remy(46);
    font-weight: 300;
    line-height: 48px;
    text-align: left;
    color: $fc_primary;
    @include phonel{
      @include remy(28);
      line-height: 33px;
    }
  }
  .create_showroom_btn{
    display: flex;
    gap: 20px;
    @include phonel{
      display: none;
    }
    .link-primary-btn{
      .el-text{
        font-family: 'Helvetica Neue md';
        @include remy(15);
        line-height: 18.31px;
        text-align: left;
        font-weight:500;
      }

    }

  }
}

.dashboard_content_wrapper{
  padding-bottom: 20px;
  //@include phonel{
  //  display: none;
  //}
}
.spacer_for_mob_dashboard{
  width: 100%;
  height: 9px;
  background: #F9FAFB;
  border-top: 1px solid #E0E1E5;
  border-bottom: 1px solid #E0E1E5;
  display: none;
  @include phonel {
    display: block;
  }
}
.text_desc_mob{
  display: none;
  padding-bottom: 29px;
  @include phonel {
    padding: 0px 20px 25px;
    display: block;
  }
  .inner_text{
    @include remy(15);
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: $fc-secondary;
  }
  .mob_gallery_btn{
    margin-top: 20px;
  }

}
.dashboard_card{
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
  border: 1px solid #F5F5F5;
  margin-bottom: 20px;
  @include phonel {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #E0E1E5;
    border-radius: 0px;
  }
  //height: 207px;
  .cstm_progress_bar{
    gap:5px;
    .progressbar {
      background:#EBEBEB;
      height: 4px;
      border-radius: 34px;
      &:first-child{
        border-bottom-left-radius: 0px;
        @include phonel {
          border-bottom-left-radius: 34px;
        }
      }
      &:last-child{
        border-bottom-right-radius: 0;
        @include phonel {
          border-bottom-right-radius: 34px;
        }
      }
      &.progressbar-success{
        &:first-child{
          .progressbar-inner{
            border-bottom-left-radius: 0px;}
        }
        &:last-child{
          .progressbar-inner{
            border-bottom-right-radius: 0;
          }
        }
        .progressbar-inner{
          border-radius: 34px;

        }
      }
    }

  }
  .dasboard_card_inner{
    padding: 24px 10px 27px 30px;
    display: flex;
    justify-content: space-between;
    position: relative;
    @include phonel {
      flex-direction: column-reverse;
      padding: 20px 20px 36px;
    }
    .left_contents{
      @include phonel{
        padding-right: 0;
        padding-top: 25px;
      }
      .finish_set{
        @include remy(18);
        font-weight: 700;
        line-height: 24.04px;
        text-align: left;
        color: $fc_primary;
        margin-bottom: 11px;
        @include phonel {
          margin-bottom: 20px;
        }
      }
      .add_space{
        @include remy(16);
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: $fc-secondary;
      }
      .continue_btn_wrapper{
        padding-top: 52px;
        @include phonel {
          display: none;
        }
        .primary_btn{
          height: 42px;
          padding: 5px 30px 5px 30px;
          @include remy(16);
          line-height: 28px;
          text-align: center;

        }
      }

    }
    .right_content_inner{
      display: flex;
      &.white_space{
        padding-right:36px;
        @include phonel{
          padding-right:0;
        }
      }
      .img_container{
        width:267px;
        border-radius: 9.63px;
        @include phonel {
          width: 100%;
        }
        img{
          width: 100%;
          height: 150px;
          object-fit: cover;
          border-radius: 9.63px;
          aspect-ratio:16/9;
          @include phonel {
            height: 235px;
            border-radius: 7.95px;
            @media (max-width: 480px) {
              height: 175px;

            }
          }
        }
        p-skeleton {
          @include phonel {
            height: 235px !important;
            display: inline-block;
            width: 100%;
            @media (max-width: 480px) {
              height: 175px !important;
            }
          }
        }
      }
      .more_btn_wrapper{
        margin-right: 0px;
        margin-left: 8px;
        @include phonel{
          display: none;
        }
        &.hidden_contents{
          visibility: hidden;
          opacity: 0;
        }
        .dropdown-toggle {
          width: 28px;
          height: 28px;
          transition: 0.3s ease-in-out;
          &:hover, &:focus{
            background: initial;
            .circle_icons{
              svg{
                circle{
                  fill: #DC5A4B;
                }
              }
            }
          }
        }
        .card_menu_dropdown {
          transform: translate3d(144px, 36px, 0px) !important;
          padding: 7px 0;
          width: 184px;
          @media (max-width: 1216px) {
            transform: translate3d(-26px, 36px, 0px) !important;

          }
          ul li .dropdown_item{
            transition: 0.3s ease-in-out;
            .icon{
              svg path{
                transition: 0.3s ease-in-out;
              }
            }
            &:hover {
              background: transparent;
              color: #DC5A4B;
              .icon{
                svg path{
                  stroke: #DC5A4B;
                  transition: 0.3s ease-in-out;

                }
              }
            }
          }
        }
      }
    }
  }

}

.dashboard_bottom_section{
  padding-top: 42px;
  background-color: #F9FAFB;
  padding-bottom: 140px;
  @include tabm{
    padding-top: 8px;
  }
}

.live_showroom_card{
  width: 100%;
  border-radius: 4px;
  border: 1px solid #EAECF0;
  //box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  margin-bottom:30px;
  cursor: pointer;
  @include phonel{
    margin-bottom:8px;
  }
  &:last-child{
    margin-bottom:0;
  }
  &.unpublished_card{
    cursor: default;
    .live_showroom_left{
      .live_left_top{
        .cove_subtitle{
          color: #E0E1E5;
          pointer-events: none;
          &:hover{
            text-decoration: none;
            color:#E0E1E5;
            cursor: auto;
          }
          .cove_icons{
            svg path{
              stroke:#E0E1E5 !important;
            }
          }
        }
      }
    }
    .live_showroom_right{
      .showroom_badge{
        .bage_circle{
          background-color: #96999F
        }
      }
    }
    &:hover{
      @include phonel-m{
        box-shadow:none;
        .live_showroom_left{
          .live_left_top{
            .little_heading{
              color: #444444;
            }
          }
        }
      }
    }

  }
  @include tabm{
    flex-direction: column-reverse;
    border-radius: 0px;
    border-left: 0;
    border-right: 0;
  }
  &:hover{
    @include phonel-m{
      box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.12) ,0px 4px 6px 0px rgba(0, 0, 0, 0.07);
      .live_showroom_left{
        .live_left_top{
          .little_heading{
            color: #006878;
          }
          .icon_text_btn{
            opacity:1;
            visibility:visible;
          }
        }
      }
    }

  }
  .live_showroom_left{
    padding: 25px 20px 25px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .live_left_top{

    }
    //height: 235px;
    @include phonel{
      padding: 20px 20px 37px 20px;
      position: relative;
    }
    .live_left_top{
      .icon_text_btn{
        position:absolute;
        right:0;
        top:0;
        color:$fc-primary;
        @include remy(16);
        border:none;
        background:transparent;
        display:inline-flex;
        align-items: center;
        @include trans2s;
        opacity:0;
        visibility: hidden;
        .icon{
          display:inline-flex;
          align-items: center;
          margin-right:6px;
          width:16px;
          svg path{
            @include trans2s;
          }
        }
        &:hover{
          color:$orange;
          svg path{
            stroke:$orange;
          }
        }
        @include phonel{
          display:none;
        }
      }
      .little_heading{
        @include remy(26);
        font-weight: 700;
        line-height: 24.04px;
        text-align: left;
        color: $fc-primary;
        margin-bottom: 6px;
        position:relative;
        transition: 0.3s ease-in-out;
        word-break:break-word ;
        @media (max-width: 480px) {
          padding-right: 33px!important;
        }
        &.lh_28{
          line-height: 28px;
        }
        @include tabl-m{
          padding-right:55px;
        }
        @include phonel{
          @include remy(22);
          line-height: 26px;
          margin-bottom: 7px;
          padding-right:30px;
        }
      }
      .cove_subtitle{
        @include remy(15);
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: $fc-secondary;
        transition: 0.3s ease-in-out;
        display: inline-block;
        @include phonel{
          font-size: 15px!important;
          text-decoration: underline;
          text-underline-offset: 3px;
          p{
            word-break:break-word ;
          }
          @media (max-width:480px){
            padding-right: 33px!important;
          }
        }
        .cove_icons{
          margin-left: 9px;
        }
        &:hover{
          @include phonel-m{
            text-decoration: underline;
            color: $fc-primary;
            .cove_icons{
              //svg path{
              //    stroke: $fc-primary;
              //}
            }
          }

        }
        @include phonel{
          @include remy(16);
        }
      }
    }
  }
}
.live_showroom_contents{
  //margin-top: 39px;
  @include phonel{
    padding-top: 20px;
  }
  .live_content_detail{
    padding: 7px 27px 3px 16px;
    border-right: 1px solid #E0E1E5;
    display: inline-block;
    @include phonel{
      padding: 0px 18px 7px 0px;
      border-right: none;
    }
    .items_counts{
      font-size: 28px;
      font-weight: 300;
      line-height: 40px;
      letter-spacing: -0.01em;
      text-align: left;
      color: $fc-primary;
      @include phonel{
        font-size: 26px;
        font-weight: 400;
        letter-spacing: -0.02em;
        margin-bottom: 3px;
      }
    }
    .detail_wrapper{
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.2px;
      text-align: left;
      color: #666666;
      display: inline-flex;
      align-items: center;
      @include phonel{
        font-size: 15px;
        letter-spacing: -0.2px;
        text-align: left;
      }
      .detail_icons{
        margin-left: 3px;
        display: inline-flex;
        align-items: center;
        &.for_desk{
          @include phonel{
            display: none;
          }
        }
        &.for_mob{
          display: none;
          @include phonel{
            display: inline-flex;
          }

        }
      }
    }
  }

}
.live_earning_detail{
  padding-right: 0px!important;
  border-right: none!important;
}

.live_showroom_right{
  //height: 235px;
  position: relative;
  .live_showroom_image_sect{
    .liv_showroom_img{
      width: 418px;
      @include tabm{
        width: 100%;
      }
      img{
        width: 100%;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        height: 235px;
        object-fit: cover;
        aspect-ratio:16/9;
        @include tabm{
          height: auto;
          @include phonel{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            @media (max-width:480px){
              height: 222px;
            }
          }
        }
      }
    }
    .items_count{
      padding: 5px 9px 5px 6px;
      font-size: 15.51px;
      font-weight: 400;
      line-height: 11.48px;
      letter-spacing: -0.005em;
      display: inline-flex;
      align-items: center;
      text-align: left;
      background-color: rgba(#222222, 0.65);
      border-radius: 15px;
      color: #ffffff;
      position: absolute;
      right: 30px;
      bottom: 25px;
      @include phonel{
        right: 19px;
      }
      .count_icon {
        margin-right:5px;
      }
    }
  }
}

.showroom_badge{
  .bage_circle{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: #12B76A;
    margin-right: 7px;
    &.grey{
      background-color: #96999F
    }
  }
  @include remy(16);
  font-weight: 400;
  line-height: 11px;
  text-align: center;
  color: $fc-primary;
  background-color: #ffffff;
  display: inline-flex;
  align-items: center;
  padding: 9px 12px 10px;
  border-radius: 8px;
}

.badge_position{
  position: absolute;
  top: 20px;
  right: 30px;
  @include phonel{
    right: 19px;
  }
}
.dashboard_badge_pos{
  position: absolute;
  top: 40px;
  right: 40px;
  display: none;
  &.unpub{
    background: #E0E1E5;
  }
  @media (max-width: 650px) {
    display: inline-flex;
  }
}

.share_btn{
  display: none;
  @include phonel{
    display: inline-flex;
    position: absolute;
    top: 21px;
    right: 18px;
    cursor: pointer;
  }

}
//dashboard section  ends here

.self_serve_edit_section{
  .tabs_content_wrapper{
    background:$gray300;
    .space_image_listing .image_upload_wrapper .upload_inner_ui{
      border:1px solid #E0E1E5;
      &:has(.outer_ui){
        border:none;
      }
    }
    .showroom_name_wrapper{
      padding-top:40px;
    }
    .self_product_assignment{
      padding-top:45px;
      //max-height:calc(100vh - 90px);
      max-height:100%;
      .float_card_stack{
        bottom: 9px;
        .mx-1320{
          border: none;
          padding-top:0;
        }
      }
      .mx-1320{
        width:100%;
        background:#fff;
        border: 1px solid #EAECF0;
        padding-top:20px;

        .link-primary .el-icon svg{
          width:18px;
        }
      }
      .row{
        height:100%;
        overflow:hidden;
        max-height:calc(100vh - 70px);
        .self_product_wrapper{
          max-height:calc(100vh - 180px);
          height:calc(100vh - 180px);
          overflow:hidden;
          &.space_update{
            max-height: calc(100vh - 118px) !important;
            height: calc(100vh - 118px) !important;
          }
        }
      }
    }
    .my_items_wrapper .header_sec,
    .sidebar_wrapper .header_ui{
      //background:transparent !important;
      .list_title{
        background:transparent !important;
        padding-left:2px;
      }
    }
    .self_product_assignment .row .spaces_list_wrapper{
      max-height:calc(100vh - 120px);
      min-height:300px;
      .spaces_list_inner_wrapper{
        padding-left:22px;
      }
      .space_list_card .link-primary{
        left:-21px;
      }
    }
    .space_list_card:last-child{
      margin-bottom:20px;
    }
    .sorting_filtered_wrapper .sorting_btn{
      background:transparent;
    }
    .share_showroom_wrapper {
      .share_showroom_header_outer{
        padding-bottom:0;
      }
      .share_showroom_header{
        display:none;
      }
      padding-top:55px;
      @media (max-height: 550px) {
        padding-bottom:30px;
      }
      @media (min-height: 650px) {
        height: calc(100vh - 230px);
      }
    }

  }
  .property_information{
    padding:30px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .property_name_wrapper{
      h3{
        font-size: 40px;
        font-weight: 300;
        line-height: 25.16px;
        text-align: left;
        color:$fc-primary;
        font-family: 'itc_souvenir_stdlight';
      }
    }
  }
  @media (max-width:1100px) {
    .breadcrumb_wrap {
      width:100%;
      .update_ui {
        display: none;
      }
    }
  }
}
.property_status_wrapper{
  display:flex;
  align-items: center;
  .card_menu_dropdown{
    top:27px !important;
    button{
      @include trans2s;
      display: inline-flex;
      align-items: center;
      path{
        @include trans2s;
      }
      &:hover{
        background:transparent;
        color:$orange;
        svg path{
          stroke:$orange;
        }
      }
    }
    .icon{
      display:inline-flex;
      margin-right:8px;
      align-items:center;
    }
  }
  .more_btn_wrapper{
    margin-right:0;
    .dropdown-toggle{
      svg circle{
        @include trans2s;
      }
      &:hover{
        svg circle {
          fill: $orange;
        }
      }
    }
  }
  .unpublish_btn{
    display:inline-flex;
    align-items: center;
    .icon{
      display:inline-flex;
      align-items:center;
    }
  }
  .property_status{
    margin-right:12px;
  }
  .showroom_badge{
    background:#F6F6F6;
    font-size: 16px;
    font-weight: 400;
    line-height:13px;
    text-align: center;
    &.unpublished{
      background:#E0E1E5;
      .bage_circle{
        background:#96999F;
      }
    }
  }
}

.custom_dropdown_wrap.show{
  .view_showroom svg {
    transform:rotate(180deg);
  }
}

.view_showroom{
  &:after{display:none;}
  .arrow_icon{
    margin-left:4px;
    svg path{
      stroke:none;
    }
  }
}

.scan_dropdown{
  text-align: center;
  &.dropdown_bg{
    border: 1px solid #EAECF0;
    background: #FFFFFF;
    box-shadow: 0 4px 6px -2px #10182812, 0px 12px 16px -4px #1018281F;
    width:240px;
    border-radius:4px;
    padding:20px 0;
    right:0 !important;
    transform:translate(0px, 36px) !important;
  }
  .info{
    @include remy(15);
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color:$fc-secondary;
    margin-bottom:10px;
  }
  .qr_code{
    margin-bottom:20px;
    .qr_code_url{
      height: 158px;
      width: 158px;
    }
  }
  .primary_text_btn{
    .el-icon{
      position:relative;
      top:1px;
    }
  }
}

.scan_dropdown{
  .primary_text_btn{
    display: flex;
    justify-content:center;
    align-items: center;
    transition: .2s all ease;
    background: transparent;
    border: none;
    color:$orange !important;
    .el-icon{
      display: inline-flex;
      vertical-align: middle;
      margin-right: 7px;
      svg{
        max-width: 18px;
      }
    }
    &:disabled, &.disabled{
      background-color:transparent;
      border-color:transparent;
      outline: none;
      box-shadow: none;
      color:$orange-light1;
      .el-icon{
        .inner-svg{
          fill:$orange-light1;
        }
      }
      .el-text{
        color:$orange-light1;
      }
      &:hover{
        .el-icon{
          .inner-svg{
            fill:$orange-light1;
          }
        }
        .el-text{
          color:$orange-light1;
        }
      }
    }
    .el-text{
      display: inline-flex;
      font-style: normal;
      font-family: 'Helvetica Neue md';
      font-weight:500;
      @include remy(16);
      line-height: 19.2px;
      color: $orange;
    }
    &:hover{
      .el-text{
        color:$orange-tint;
      }
      svg path{
        stroke:$orange-tint;
      }
    }
    &:focus{
      .el-text{
        color: #C65144;
      }
      .inner-svg{
        fill: #C65144;
      }
    }

  }
}

.tabs_header{
  background: #fff;
  position: sticky;
  top:0;
  padding-top: 15px;
  transition: 0.4s ease-out;
  z-index:8;
  .nav-tabs{
    border: none;
    li{
      margin-left:60px;
      &:first-child{
        margin-left:0;
      }
      .nav-link{
        font-size: 16px;
        font-weight: 400;
        line-height: 19.09px;
        text-align: center;
        color:$fc-secondary;
        padding: 0 0 11px;
        border: none;
        position: relative;
        transition: 0.2s ease-in-out;
        &:after{
          position:absolute;
          content:"";
          width:100%;
          height:3px;
          background:$fc-primary;
          left:0;
          bottom:0;
          opacity:0;
          visibility:hidden;
        }
        &:hover{
          border:none;
          color:$fc-primary;
        }
        &.active{
          border:none;
          color:$fc-primary;
          &:after{
            opacity:1;
            visibility:visible;
          }
        }
      }
    }
  }
}

.form_outer{
  width:100%;
  max-width:649px;
  margin:auto;
}

.white_wrapper{
  border:1px solid #EAECF0;
  background:#fff;
  padding:30px 40px;
  border-radius:4px;
  margin-bottom:44px;
  .wrapper_title{
    font-size: 24px;
    font-weight: 400;
    line-height: 28.63px;
    text-align: left;
    color:$fc-primary;margin-bottom:22px;
  }
  .col-md-12{
    &:last-child{
      .form-group{
        margin-bottom:0;
      }
    }
  }
}


.tab-content{
  .form-group {
    margin-bottom: 25px;
    .form-label {
      font-family: 'Helvetica Neue md';
      font-weight: initial;
      .icon{
        display:inline-flex;
        margin-left:5px;
      }
    }
  }
  .custom-input-1{
    height:48px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.47px;
    text-align: left;
    &:disabled{
      color:$fc-secondary;
    }
  }
  .custom_textarea{
    min-height:48px !important;
    height:auto;
    padding:11px 14px !important;
  }
  .brand_logo_upload{
    width:160px;
    height:160px;
    border:1px solid $border-light;
    border-radius:100%;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    overflow: hidden;
    img{
      max-width: 100%;
      max-height: 100%;
      &.br-100 {
        border-radius:100%;
      }
    }
  }
  .logo_upload_wrapper{
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:22px;
    .more_btn_wrapper{
      margin-right:0;
      .card_menu_dropdown{
        right:5px !important;
      }
    }
    .wrapper_title{
      margin-bottom:0;
    }
  }
  .space_images_wrapper{
    .mx-675{
      max-width:1026px;
      .items_count{
        display:none;
      }
    }
  }
  .search-showroom_product{
    margin-bottom:0;
    .custom-input-1{
      background:transparent !important;
      height:30px;
      &:-internal-autofill-selected, &:-webkit-autofill{
        -webkit-box-shadow:0 0 0 50px #F9FAFB inset !important;
      }
    }
  }
  .space_image_listing{
    grid-gap:40px 33px;
  }
}

.showroom_info{
  .tooltip-inner{
    min-width:304px;
    padding:14px 15px 18px 20px;
    text-align:left;
    border-radius:2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

.logo_default_wrapper{
  .desc{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color:#666666;
    margin-bottom:26px;
  }
  .image_upload_wrapper {
    max-width:320px;
      .upload_inner_ui {
        width:100%;
        padding:26px 30px 30px;
        min-height:auto;
        .upload_content {
          p{
            margin-bottom:8px;
            @include remy(16);
            line-height:14px;
          }
          .desc{
            margin-bottom:20px;
            @include remy(12);
            line-height:14px;
            display:flex;
            justify-content:center;
          }
          .icon {
            width: 36px;
            margin-bottom:15px;
            }
        }
      }
  }
}


.featured_image_wrapper {
  &.space_image_listing{grid-gap: 28px 46px;}
  .space_image_card {
    max-width: 490px;
    &:last-child{
      max-width:487px;
    }
  }
  .image_upload_wrapper {
    max-width:100%;
    .upload_inner_ui {
      min-height:275.28px;
      display: flex;
      justify-content: center;
      align-items: center;

      .outer_ui {
        height:275.28px;
      }
    }
  }
  .property_name{
    display: inline-flex;
    align-items: center;
    .icon {
      display: inline-flex;
      align-items: center;
      width: 16px;
      height: 16px;
      position: relative;
      top: 2px;
      margin-left: 4px;
    }
  }
}


.preview_modal_wrapper{
  width:100%;
  height:100vh;
  max-height:100vh !important;
  @include showroomresponsive{
    overflow-x:auto;
    background:#EFEFF0;
  }
  @media (max-width: 1350px){
    &.desktop_wrapper{
      overflow-x:auto;
      .iframe_preview_wrapper{
        min-width:1450px
      }
    }
  }
}

.sticky-header{
  .header_container{
    top:-60px !important;
  }
}


.counts_updates{
  visibility: hidden;
  opacity: 0;
}
.modal_custom_space{
  &:focus{
    + .counts_updates{
      visibility: visible;
      opacity: 1;
    }
  }
}


.featured_info{
  .tooltip-inner{
    min-width:334px;
    padding:14px 15px 14px 20px;
    text-align:left;
    border-radius:2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    font-family: 'Helvetica Neue';
  }
}

.edit_tabs_wrapper{
  height: calc(100vh - 190px);
  background: #F9FAFB;
  .self_product_card .card_ui{
    background:#fff;
    &:hover{
      background:rgba(245, 245, 245, 0.3019607843);
    }
  }
  .space_list_card{
    background:#FBFBFB;
    &:hover, &.selected, &.active{
      background:rgba(228, 239, 241, 0.5019607843);
    }
  }
  .share_card .card_bottom_sec{
    background:#fff;
  }
}

.edit_image_outer{
  padding-bottom:30px;
  .featured_image_wrapper{
    .browse_btn {
      bottom: initial;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .mx-675 .space_image_card{
    max-width:318px;
    .upload_inner_ui{
      max-height:178.65px;
      min-height:178.65px;
    }
  }
}



//showroom step 3 page starts here
.showroom_for_step3{
  padding-top: 40px;
  @media (min-width: 1515px) {
    padding-top: 0;
  }
  .showroom_inner{
    margin-right: 0px;
    .step1_left_sect{
      padding-bottom: 0;
      .heading_step1{
        max-width: 443px;
      }
      .text_des{
        max-width: 385px;
      }
    }
    .step2_left_sect{
      .img_sectons{
        max-width: 605px;
        display: inline-flex;
        img{
          display: inline-block;
          width: 100%;
          height: 100%;

        }
      }

    }
  }
  .col_right{
    padding-left: 5px;
    text-align:right;
  }

}

//showroom step 3 page ends here

.space_open {
  .self_product_assignment .row .self_product_wrapper{
    max-height:calc(100vh - 220px) !important;
    &.space_update{
      max-height: calc(100vh - 195px) !important;
      height: calc(100vh - 195px) !important;
    }
  }
}


.body_dropdown{
  z-index:1 !important;
}

.self_product_assignment {
  .cdk-drag-placeholder {
    opacity: 1;
  }
  .drop_ui{
    display:none;
  }
}
.showroom .logo_ui{
  pointer-events:none;
}
/* virtual scroll fixes*/
.cdk-virtual-scroll-content-wrapper{
  width:100%;
  .virtual_scroll_wrapper{
    position: relative;
    padding: 2px 2px 30px;
    min-height: 390px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: hidden;
    align-content: flex-start;
    gap: 20px;
    overflow-x: hidden;
  }
  .product_card_blk{
    display: flex;
    width: 100%;
    gap: 20px
  }
  .archived_products{
    margin-top: 10px;
    width: 100%;
    .section_heading{
      text-align: center;
      position: relative;
      z-index: 1;
      display: inline-flex;
      width: 100%;
      justify-content: center;
      .archived_items{
        color:#96999F;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap:7px;
        background: #ffffff;
        padding: 0 10px;
        border: none;
        span{
          display:inline-flex;
          align-items: center;
        }
        &.active{
          span{
            transform: scaleY(-1);
          }
        }
      }
      &:after{
        content: "";
        background: #E0E1E5;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: -1;
      }
    }
  }
}

.image_skelton{
  width:100%;
  height:156px;
  background: #E5E5E5;
  opacity: 65%;
  &.animate {
    animation: skelton 2s infinite linear;
    background: linear-gradient(90deg, #E5E5E5 0%, #F5F5F5 100%);
    background-size: 1000px 100%;
  }
}

@keyframes skelton {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}


.space_list_card.cdk-drag-preview{
  .link-primary{
    cursor:grabbing;
  }
}
.card_menu_dropdown{
  .space_chips {
    padding-top:11px;
    padding-bottom:14px;
    li {
      margin-bottom: 0;
    }
  }
}

.my_items_wrapper {
  position: relative;
  .loader_wrapper {
    top: 100px;
    height: calc(100vh - 100px);
    position: absolute;
    z-index:2;
  }
}
.space_open{
  .my_items_wrapper {
    .loader_wrapper {
      top:45px;
    }
  }
}


.sidebar_wrapper {
  .header_ui {
    position: relative;
    width:100%;
    &:before {
      position: absolute;
      content: "";
      left: 9px;
      top: calc(100% + 6px);
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      width: calc(100% - 9px);
      height: 10px;
      opacity: 0;
      visibility: hidden;
      z-index: 1;
      pointer-events: none;
    }
  }
  &.scrolled{
    .header_ui:before{
      opacity:1;
      visibility:visible;
    }
  }
}

.upload_image_wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  position: relative;
  input[type="file"]::-webkit-file-upload-button{
    cursor: pointer;
  }
  .file_upload{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    visibility: visible;
  }
  .link-primary-btn{
    cursor: pointer;
    .el-icon{
      margin-right: 10px;
      svg{
        max-width: initial;
      }
    }
    .el-text{
      position: initial;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.3px;
      text-align: left;
    }
  }
}

.view_btn_wrapper{
  margin-left: 9px;
}

#productCheckbox {
  .p-hidden-accessible {
    display: none;
  }
}

.dropdown_inner_cstm {
  max-height: 336px;
  overflow-y: auto;
}

.preview_wrapper {
  .product_listing_wrapper {
    .heading_sec{
      padding:0 20px 8px;
      h5{
        font-size: 14px;
      }
      .inner_count{
        font-size:12px;
        .icon img{
          width:12px;
        }
      }
      .view_all_btn {
        .text_link1{
          font-size:12px;
        }
        .mobile_arrow img{
          width:5px;
        }
      }

    }
    .product_card{
      width:110px;
      min-width: 110px;
      height:110px;
      &:first-child {
        margin-left: 20px;
      }
    }
  }
}
.showroom_name_wrapper {
  .property_logo_box{width: 558px}
  .fix_col{flex:1;
    .preview_wrapper{margin-right: 0}
  }

}

.showroom_step1_wrapper{
  .assign_products{min-width: 390px}
  .fix_col{flex:1}
}

@include tabl{
  .self_product_assignment {
    .fixed_tabs{width: 302px}
    .fix_col{flex:1; width: 68% !important;}
  }
}

.showroom_live_wrapper {
  .prod_publish{width: 726px}
  .fix_col{flex:1}
}

.p-dialog-mask{padding: 0}


.space_open{
  .showroom_items_modal{
    .my_items_wrapper{
      .loader_wrapper {
        top: 0;
        height: calc(100% + 68px);
        z-index: 1;
        .text_loader{
          display: none;
        }
      }
    }
  }
}

.showroom_items_modal{
  .my_items_wrapper{
    &:has(.filter_sort) .loader_wrapper {
      top: auto;
    }
    &:has(.empty_product_wrapper) .loader_wrapper{
      top: auto;
    }
  }
}

.my_items_wrapper{
  &:has(.filter_sort) .loader_wrapper {
    top: 80px;
  }
  &:has(.empty_product_wrapper) .loader_wrapper{
    top: 40px;
  }
}

.drag_cursor {
  *{
    cursor: url(https://storage.googleapis.com/minoan-images/minoan%20website%20redesign/cusror.png), auto !important;
  }
  .self_product_card .card_ui {
    cursor: url(https://storage.googleapis.com/minoan-images/minoan%20website%20redesign/cusror.png), auto !important;
  }
  .self_product_card.product_card_items .p-checkbox{
    display: none;
  }
}


.dashboard_wrapper:has(.dashboard_content_wrapper) .dashboard_bottom_section {
  @include phonel{
    padding-top: 0px;
  }
}


.non-scrollbar .body_dropdown{
  z-index:2!important;
}


body:has(.scrolling_top_for_chrome) {
  .intercom-lightweight-app-launcher.intercom-launcher {
    bottom: 20px !important;
    @include trans2s;
  }
  .back-top-wrapper.scrolled{
    bottom:25px;
    @include trans2s;
  }
  .intercom-namespace .intercom-with-namespace-52k34s {
    @include phonem {
      bottom: 20px !important;
    }
  }
}
body:has(.scrolling_bottom_for_chrome) {
  .intercom-namespace .intercom-with-namespace-52k34s {
    @include phonem {
      bottom: 95px !important;
    }
  }
}

body:not(.non-showroom) {
  .intercom-namespace .intercom-with-namespace-52k34s,
  .intercom-lightweight-app-launcher.intercom-launcher {
    @include phonem {
      bottom: 20px !important;
    }
  }
}

.image_cropper_wrapper{
  image-cropper{
    padding:0!important;
  }
}
body:has(.float_card_stack){
  .fixed_tabs {
    position: relative;
    z-index: 12;
  }
  .self_serve_footer{
    z-index: 13;
  }
}
.snak_wrap{
  .float_card_stack {
    position: fixed;
    bottom: 89px;
    left: 0;
    right: 0;
    z-index: 11;
    @media(max-width: 1079.98px){
      position: absolute;
      width: 1079px;
    }
    .remove_icon:hover {
      opacity: 0.6;
    }
    .header_sec {
        background: transparent !important;
        display: flex;
        justify-content: flex-end;
      .top_sec {
        margin-bottom: 0 !important;
        width: calc(75% - 22px);
        border-radius: 4px;
        padding-right: 26px;
        padding-left: 26px;
        padding-top: 9px;
        padding-bottom: 9px;
        margin-bottom: 0 !important;
        max-height: 50px !important;
        opacity: 0;
        visibility: hidden;
        transform: translateY(89px);
        transition: all 0.5s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 2px;
        @media(max-width: 1200px){
          width: calc(100% - 315px);
        }
        .right_sec{
          display: flex;
          align-items: center;
        }
        &.products_checked{
          margin-bottom: 0 !important;
          background: #444444 !important;
          transform: translateY(0);
          visibility: visible;
          opacity: 1;
        }
        .primary_outline_btn {
          background: transparent;
          border: 0;
          height: 32px;
          font-weight: 500;
          color: #fff;
          font-size: 16px;
          line-height: 19.54px;
          text-align: left;
          padding: 0 8px;
          transition: all 0.2s ease;
          min-width: auto;
          .add_icon {
            margin-right: 10px;
            width: 16px;
            height: 16px;
            display: inline-flex;
            vertical-align: middle;
            margin-bottom: 1px;
          }
          &:hover{
            background: #FBFBFB33;
          }

        }
      }
    }
    .selected_item_wrapper {
      margin-left: 0;
      color: #fff;
      display: flex;
      &:hover{
        color: #fff;
      }
    }
    .text_dd{
      .p-listbox-header {
        display: none;
      }
      .dropdown_footer {
        padding: 12px 22px 12px;
        border-top: 1px solid #F5F5F5;
        width: 100%;
      }
      .primary_btn {
        padding: 8px 15px 8px 15px;
        border-radius: 4px;
        height: 45px;
        min-width: 172px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.09px;
        text-align: center;
      }
      .icon_text_btn{
        padding: 0 8px;
        margin-right: 9px;
        .icon {
          width: 16px;
          height: 16px;
          display: inline-block;
          transform: translateY(-1px);
          margin-right: 10px;
        }
      }
    }
    .remove_from_space {
      .icon_text_btn {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.54px;
        padding: 0 8px;
        height: 32px;
        border-radius: 3.46px;
        &:hover{
          background: #FBFBFB33;
        }
      }
    }
    .more_btn_wrapper {
      margin-right:0;
      margin-left: 25px;
      position: relative;
       .card_menu_dropdown {
        bottom: calc(100% + 54px) !important;
        top: initial !important;
         right: 0 !important;
      }
      &::before{
        content: '';
        position: absolute;
        width:1px;
        height:25px;
        background-color: #FFFFFF33;
        top: 50%;
        left: -13px;
        transform: translateY(-50%);
      }
      &.btn_hover {
        > .dropdown-toggle{
            width: 32px;
            height: 32px;
            border-radius: 0;
          }
        &::after {
          position: absolute;
          content: "";
          height: 32px;
          width: 32px;
          background: #FBFBFB33;
          left: 0;
          top: 0px;
          z-index: -1;
          border-radius: 3.46px;
          transition: all 0.2s;
        }
      }
    }
    .assign_space_wrapper .dropdown-menu {
      top:auto !important;
      bottom: calc(100% + 18px) !important;
      transform: none !important;
      right: 0 !important;
      width: 214px;
      left: auto !important;
        .btn_wrapper {
          border-top: 1px solid #F5F5F5;
          padding: 13px 20px 12px;
          .primary_btn {
            height: 45px;
          }
        }
      .p-listbox{
        .p-listbox-list{
          .p-listbox-item{
            padding: 10px 18px;
            line-height: 21px;
            .p-checkbox {
              width: 18px;
              height: 18px;
              margin-right: 10px;
            }
            .p-checkbox-box {
              width: 18px;
              height: 18px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
.btn_step_text {
  margin-left: auto;
  margin-right: 16px;
  border: 0;
  background: transparent;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color:#96999F;
  .sub_bold{
    font-weight: 500;
  }
}

.archived_collapse_wrapper{
  width:100%;
  .product_card_blk{
    padding-top:20px;
  }
  .collapse_seprater{
   display:flex;
   justify-content:center;
    position:relative;
    z-index: 1;
    &:after{
      position: absolute;
      left: 0;
      content: "";
      top: 50%;
      background: #E0E1E5;
      height: 1px;
      width: 100%;
      z-index:-1;
    }
  }
  .collapse_btn{
    display:inline-flex;
    justify-content:center;
    align-items:center;
    background:#fff;
    padding:0 10px;
    cursor: pointer;
    h4{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.30000001192092896px;
      text-align: left;
      color:#96999F;
    }
    .icon{
      display:inline-flex;
      margin-left:7px;
    }
  }
}

.magnifying_icon_wrapper{
  .empty_icon{
    margin-bottom:20px;
  }
  &:has(.match_result) .content_ui .sub_title{
    margin-bottom:15px
  }
}

.showroom_items_modal{
  .top_sec{
    margin-bottom: 20px !important;
    .left_sec{
      .list_title{
        font-size: 28px;
        font-weight: 400;
        line-height: 33.6px;
        font-family: "Helvetica Neue";
      }
      .product_filtered_wrapper{
        padding-left: 31px;
        .product_filter_dropdown{
          .filter_action_btn{
            .angel_icon{
              margin-left: 5px;
              svg{
                width: 11.51px;
                height: 6.95px;
              }
            }
            .content-ui{
              .el_text {
                font-size: 16px;
                font-weight: 500;
                line-height: 19.2px;
                letter-spacing: 0.30000001192092896px;
                font-family: "Helvetica Neue md";
              }
            }
          }
        }
      }
    }
  }
  .filter_sec{
    padding-bottom: 20px;
    .product_filtered_tag{
      li{
        label{
          padding: 5px 12px;
        }
      }
    }
  }
  .empty_product_wrapper{
    border: none;
    min-height: 520px;
  }
  .modal-header{
    .close-btn{
      position: fixed;
      right: 24.05px;
      top: 25px;
      background: none;
      border: none;
      z-index: 4;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        svg{
          path{
            stroke: #444
          }
        }
      }
    }
  }
  figure{
    width: 100%;
    height: auto;
  }
  .modal-footer{
    box-shadow: 0px -3px 10.4px 0px #0000000D;
    background: #ffffff;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    padding: 22px 45px;
    justify-content: space-between;
    z-index: 2;
    .new_start_btn{
      border-radius: 5px;
      max-width: 321px;
      padding: 0 14px;
      span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Helvetica Neue md';
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
      }
    }
    .selected_item_wrapper{
      margin: 0;
      &:hover{
        color:#96999F;
      }
    }
    .p-checkbox:not(.p-checkbox-disabled){
      .p-checkbox-box{
        &:hover{
          border: none;
        }
        &.p-focus{
          box-shadow: none !important;
        }
      }
    }
  }
  .cdk-virtual-scroll-content-wrapper {
    .virtual_scroll_wrapper {
      padding: 2px 2px 120px;
    }
  }
}


.external_link_wrap{
  padding: 9px 27px 10px 14px;
  background: rgba(228, 239, 241, 0.61);
  border-radius: 4px;
  margin-top: 10px;
  @include phonel{
    padding: 9px 11px 12px 14px;
  }
  .external_link_inner{
    display: flex;
    align-items: flex-start;
    .icons{
      width: 15px;
      height: 22px;
      display: inline-flex;
      align-items: center;
      margin-right: 8px;
    }
    .link_text{
      @include remy(14);
      font-weight: 400;
      line-height: 19px;
      text-align: left;
      color:$fc-primary;
      .link_inner_text{
        text-decoration: underline;
        color:$fc-primary;
      }
    }
  }
}


/** Helpers includes all variabls and config files **/
@import
	'helpers/_mixins.scss';

/** Base includes all basic requirements for every page **/
@import
	'base/_icons.scss',
	'base/_fonts.scss',
	'base/_colors.scss';
	 /** Adding icons to the site **/

/** Components includes buttons, carousel, dropdown etc.. **/
@import
  'components/_loader.scss',
	'components/_buttons.scss',
	'components/_cards.scss',
	'components/_modal.scss',
	'components/_headings.scss',
	'components/_timeline.scss',
	'components/_carousel.scss';

/** Layout folder styles for major sections of the layout like a header, footer and styles for a grid system **/
@import
	'layout/_header.scss',
	'layout/_footer.scss',
	'layout/_cursors.scss',
	'layout/_alignment.scss',
	'layout/_forms.scss',
  'layout/_layout.scss';

@import
 	'pages/_home.scss',
 	'pages/_about.scss',
 	'pages/_brands.scss',
 	'pages/_contact.scss',
 	'pages/_privacy.scss',
 	'pages/_spaces.scss',
  'pages/_shopbrowse.scss',
  'pages/_onboarding.scss',
  'pages/_chrome-onboarding.scss',
  'pages/_login.scss',
  'pages/_onboarding_new.scss',
  'pages/_ftux.scss',
  'pages/self_serve_space.scss',
  'pages/showroom_landing.scss',
  'pages/showroom_gallery',
  'pages/_shop.scss';


/** custom writter css **/
@import
	'main.scss';
	/** all common styles written in main.scss **/

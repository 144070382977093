@import "/src/assets/scss/layout/filters";

.height_100{
  height: 100%;
}
.spacer-35{
  height: 35px;
  @include phonel{
    display: none;
  }
}

.onbarding_welcome_banner{
  background-image: url("/assets/images/onboarding/welcome_bg.jpg")
}

.onbarding_account_banner{
  background-image: url("/assets/images/onboarding/account_all_person.png")
}

.onbarding_property_banner{
  background-image: url("/assets/images/onboarding/property_str.png")
}

.onbarding_user_banner{
  background-image: url("/assets/images/onboarding/user_person.png")
}
.onbarding_finish_banner{
  background-image: url("/assets/images/onboarding/finish_banner1.png");
  background-color:#5592A0;
  background-size:87% !important;
  background-position: center left !important;
}
@media (min-width:1600px){
  .onbarding_finish_banner{
    background-size:80% !important;
  }
}
.onbarding_account_user{
  background-image: url("/assets/images/onboarding/welcome_bg.jpg")
}
.onboarding_wrapper{
  width:100%;
  .col-md-7{
    position: relative;
  }
  .btn_back{
    position: absolute;
    left: 38px;
    top: 46px;
    z-index: 2;
    -webkit-appearance: none;
    @include phonel{
    position: relative;
    left: initial;
    top: initial;
  }

  }
  .mob_hide{
    @include phonel{
      display: none;
    }
  }

  .mob_show{
    display: none;
    @include phonel{
      display: inline-flex;
      margin-bottom: 20px;
    }
  }

  .container-fluid{
    padding: 0 15px;
  }
  .onboarding_left_wrap{
    height:100vh;
    width:100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    position: sticky;
    top: 0;
    left: 0;
    @include phonel{
      display: none;
    }
  }


  .body_content_wrap{
    padding-top: 90px;

    @include phonel{
      padding-top: 40px;
    }
    .form_inner_wrapper{

    }
  }
}

.onboarding_form_wrap{
  max-width: 670px;
  margin: auto;
  padding: 50px 50px 45px;
  position: relative;
  @include phonel{
    //padding: 30px 20px 45px;
    padding: 30px 20px 85px;
  }
  .add-property-btn{
    margin-top: 10px;
  }
  .header_wrap{
    text-align: center;
  }
  .logo_ui{
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    @include phonel{
      margin-bottom:25px;
      max-width: 108px;
      img{
        width:100%;
      }
    }
  }
  .heading_ui{
    margin-bottom: 24px;
    .heading9{
      margin-bottom: 16px;
    }
    .para2{
      @include phonel{
        @include remy(18);
        line-height: 25px;
      }
    }
  }
}
.welcome_wrapper{
  .heading_ui{
    margin-bottom:40px;
    @include phonel{
      margin-bottom: 32px;
    }
  }
}



.steps_wrapper{
  @include phonel {
    margin-bottom: 20px;
  }
  ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    list-style: none;
    padding: 0;
    width:100%;
    //max-width: 530px;
    @include phonel{
      width: 90%;
      margin: auto;
    }
    li{
      width:100%;
      text-align: center;
      position: relative;
      &:first-child{
       /* width:calc(100% - 40px);*/
        a:after,
        a:before{
          display: none !important;
        }
        a{
          align-items:flex-start;
          .icon{
            left: 22px;
          }
        }
      }

      &:nth-child(2){
        /*width:calc(100% + 40px);*/
        a{
          margin-left:-32px;
          @include tabm{
            margin-left:-18px;
            @include phones{
              margin-left:0;
            }
          }
        }
        a:after{
          width:165px !important;
          left:-77%;
          @media (max-width:1100px){
            width:131%;
            @include tabm{
              left:-74%;
            }
            @include phonel{
              width:133%;
              left:-79%;
              @include phones{
                width:110% !important;
                left:-66%;
              }
            }
          }
        }
      }

      &:nth-child(3){
       /* width:calc(100% + 40px);*/
        a{
          margin-left:21px;
          @include tabm{
            margin-left:16px;
            @include phones{
            margin-left:6px;
          }
          }
        }
        a:after{
          width:170px !important;
          left:-59%;
          @media (max-width:1100px){
            width:131%;
            @include tabm{
              left:-54%;
            }
            @include phonel{
              width:133%;
              left:-56%;
              @include phones{
                width:115% !important;
                left:-60%;
              }
            }
          }
        }
      }

      &:last-child{
        width:calc(100% - 40px);
        a{
          align-items:flex-end;
          .icon{
            right: 17px;
            @include phonel{
              right:15px;
            }
          }


          &:after{
            width:176px;
            left:-50%;
            @media (max-width:1100px){
              width:131%;
              @include phonel{
                width:130%;
                left:-46%;
                @include phones{
                  width:115%;
                  left:-44%;
                }
              }
            }
          }
          p{
            position: relative;
            left:-5px;
          }
        }
      }
      a{
        color: $fc-secondary;
        display: flex;
        align-items:center;
        flex-direction: column;
        @include transition(0.2s ease-in-out);

        .icon{
          width:12px;
          height:12px;
          background: #fff;
          border-radius: 100%;
          border:3px solid $border-light;
          display: inline-flex;
          z-index:1;
          position: relative;
          margin-bottom: 7px;
        }
        p{
          font-style: normal;
          font-weight: 400;
          @include remy(14);
          line-height: 17px;
          max-width: 182px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          @include transition(0.2s ease-in-out);
        }

        &:after{
          position: absolute;
          content: "";
          background: $border-light;
          height:2px;
          ////width:calc(100% + 70%);
          //left:-84%;
          top:5px;

        }



        &.active{
          color:$fc-primary;

          p{
            &:hover{
              color:$orange;
            }
          }
          .icon{
            border-color: $teal-primary;
          }
          &:after{
            background: $teal-primary;
          }
          //&:before{
          //  z-index: 2;
          //  animation: progressbar 5s forwards;
          //  display: block;
          //}

          }

      }
      a:not([href]):not([class]){
        color:$fc-secondary;
      }



    }
  }
}

.onboarding_form_wrap {
  .multiple_property_wrapper {
    .p-autocomplete{
      width:100%;
    }
  }
}

.multiple_property_wrapper{
  .select_property{
    position: relative;

    .delete_btn_wrap{
      display: inline-flex;
      align-content: center;
      position: absolute;
      width: auto;
      right:-20px;
      top:38px;
      padding: 0;
      @include tabm{
        display: inline-flex;
        align-content: center;
        position: relative;
        width: 100%;
        right: initial;
         top: initial;
        justify-content: flex-end;
        padding: 0px 15px;
        margin-bottom: 24px;
      }
      .delete_btn{
        path,p{
          @include transition(0.2s ease-in-out);
        }
        &:hover{
          svg{
            path{
              fill:$orange;
            }
          }
          p{
            color: $orange;
          }
        }
      }
    }
  }
}




.user_role_dropdown{
  position: relative;
  .p-dropdown{
    position: relative;
    width:100%;
    .p-dropdown-clear-icon{
      display: none;
    }
  }
  .p-dropdown-panel[style="z-index: 1001;transform-origin: center bottom;"]{
    display: none !important;
  }
  .singleSelectDropdown {

    width: 100%;
    position: relative;
    height: 50px;
    .p-overlay{
      left:-2px !important;
    }
    .p-dropdown-label{
      @include remy(16);
      padding:0.75rem 0.75rem;
    }

    .p-dropdown-panel {
      .p-dropdown-item {
        padding: 15px 10px 8px 16px;
        @include phonel{
          padding-left: 14px;
        }

        &.p-highlight {
          //background: rgba(85, 146, 160, 0.1);
          background: #fff;
        }
      }
      .p-element {
        &:last-child {
        .p-dropdown-item{
            padding: 8px 10px 19px 16px;
          }
        }
    }
  }

    .p-dropdown-label.p-placeholder{
      @include remy(16);
    }
  }
  .p-dropdown-panel{
    width:100%;
    min-width: 210px !important;
    left: -2px !important;
    max-height:200px
  }
    .dropdown_text{
      white-space: normal;
      label{
        font-style: normal;
        font-weight: 500;
        @include remy(16);
        line-height: 22px;
        margin-bottom: 2px;
      }
      p{
        font-style: normal;
        font-weight: 400;
        @include remy(12);
        line-height: 17px;
      }
    }
}
.mw-432{
  width:100%;
  max-width:432px;
}
.mw-142{
  width:100%;
  max-width:142px;
  @include phonel{
    max-width:432px;
  }
}
.mw-415{
  width:100%;
  max-width:415px;
  @include tabm{
    max-width: initial;
    width:auto;
  }
  @include phonel{
    max-width:initial;
  }

}
.multiselect_w_check {
  width: 100%;
    .p-checkbox{
      width:20px;
      height:20px;
      align-self: flex-start;
    }
  .multiSelectDropdown {
    .p-overlay{
      left: -2px !important;
    }
    .p-multiselect-panel {
      top:53px !important;

      .p-multiselect-header {
          padding: 15px 17px 7.5px;
          justify-content: initial;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: $fc-primary;
          position: relative;
          flex-direction: row-reverse;
          align-items: flex-start;
          justify-content: flex-end;
            @include phonel{
              padding: 15px 13px 7.5px;
            }
          p{
            position: absolute;
            left:44px;
          }

      .p-checkbox {
        display: block;
        width:100%;
        height:22px;

        .p-checkbox-box.p-highlight:after{
          border: 2px solid $teal-tint;
          background-color: $teal-tint;
        }

        .p-checkbox-box {
          //height: 20px;
          //width: 20px;
          //border-radius: 4px;
          //border: 2px solid #E0E1E5;
          //box-shadow: none;
          width:100%;
          position: relative;
          border: none;
          background: none;
          height: 20px;
          display: inline-block;
          text-align: left;
          z-index: 1;
          .p-checkbox-icon{
            left:4px;
            top:-1px;
          }
          &:after{
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            border-radius: 4px;
            border: 2px solid #E0E1E5;
            box-shadow: none;
            z-index: -1;
            left: 0;
            top:1px;
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

      }
    }
      .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item{
        padding: 7.5px 17px;
        word-break: break-word;
        white-space: normal;
        @include phonel{
          padding: 7.5px 13px;
        }
        &.p-highlight{
          color:$fc-primary;
          word-break: break-word;
          white-space: normal;
          display: flex;
        }
      }
  }
    &.p-multiselect .p-multiselect-label{
      padding-right: 30px;
      padding-left: 14px;
      @include remy(16);
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      .country-item{
        height:100%
      }
    }
    }
   .multi-select-count{
    background: rgba(#E0E1E5 , 0.6);
     color:$fc-secondary;
  }
  .multiSelectDropdown{
    width:100%;
    max-width: 100%;
  }
}




.onboarding_form_wrap {
  .form-group {
    margin-bottom: 24px;
    @include tabm{
      //margin-bottom: 30px;
    }

    .form-label {
      font-weight: 500;
      line-height: 18px;
      @include remy(15);
    }

    .custom-input-1 {
      @include remy(16);

      &::placeholder {
        @include remy(16);
      }
    }

  }

  .border_seprater {
    margin: 20px 0 35px;
    height: 1px;
    width: 100%;
    background: $border-light;
  }

}

.delete_btn{
  border: none;
  background: none;
  display: inline-flex;
  align-items: center;
  p{
    display: none;
    @include tabm{
      display: block;
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 19px;
      color: $fc-secondary;
      padding-left: 8px;
    }
  }
}


.welcome_banner{
  display: none;
  @include phonel{
    display:block;
    margin: 6px -20px 0px;
    img{
      width:100%;
    }
  }
}

.add-property-btn{
  margin-top: 10px;
  @include phonel{
    margin-top: 10px;
  }
}



.au_space{
  height: 20px;
  @include phonel{
    height: 0px;
  }
}



.counter_error{
  padding-right: 40px;
  position: relative;
  @media (max-width: 1199px) and (min-width: 991px){
      @include remy(13);
  }
  @media (max-width:400px){
    padding-right: 28px;
    @include remy(12);
    line-height: normal;
    top:-4px;
    .message_ui{
      @include remy(12);
    }
    .counter_ui{
      @include remy(12);
    }
  }

  @media (max-width:360px){
    padding-right: 28px;
    @include remy(10.5);
    line-height: normal;
    top:-4px;
    .message_ui{
      @include remy(10.5);
    }
    .counter_ui{
      @include remy(10.5);
    }
  }
  .counter_ui{
    float: right;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.error_wrapper{
  &.counter_error{
    .counter_ui{
      top: 2px;
    }
  }
}

.spacer-10{
  height:10px;
}

.finish_setup{
  display: flex;
  margin-top:26px;
  margin-left: 10px;
  .el-text{
    .icon{
      margin-left:6px
    }
  }
  &:hover{
    .icon{
      svg{
        path{
          stroke:#E37B6F;
        }
      }
    }
  }
}

.link-primary.gray_btn {
  .el-text{
    color:$fc-secondary;
  }
  &:hover {
    .icon {
      svg path{
        stroke:#96999F;
      }
    }
  }
}

@media (max-width:1440px) and (max-height:935px){
  .onbarding_finish_banner{
    background-size:93% !important;
  }
}

.onboarding_form_wrap{
  .form_inner_wrapper{
    .btn_wrap{
        .btn_secondary{
          min-width:189px;
        }
      .btn_primary{
        min-width:173px;
        margin-left:14px;
      }
    }
  }
}

.shoppage_wrapper{
  padding:28px 0px;
  .outer_nav{
      border-bottom:1px solid #E0E1E5;
    padding-bottom:20px;
  }
  .nav-pills .nav-link{

  }
}
.carousel_header{
  display:flex;
  align-items:flex-end;
  justify-content: space-between;
  .title{
    font-size: 26px;
    font-weight: 400;
    line-height:32px;
    text-align: left;
    margin-bottom:5px;
    color:$fc-primary;
    @include phonel{
      font-size:20px;
      line-height:36px;
      margin-bottom:0px;
    }
    .fa-pencil-alt{
      font-size:16px;
      margin-left:10px;
      display:inline-flex;
      align-items:center;
      color:$fc-secondary;
      position:relative;
      top:-1px;
      cursor:pointer;
    }

  }
  .subtitle{
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color:$fc-secondary;
    @include phonel{
      font-size:17px;
      line-height:20px;
    }
  }

  .more_btn_wrapper{
    display: flex;
    .dropdown-toggle{
      border:none;
      background:none;
      display:inline-flex;
      align-items: center;
      justify-content: center;
      &:after{
        display:none;
      }
      &.vertical_btn{
        width:28px;
        height:28px;
      }
    }
    .dropdown-menu{
      box-shadow: 5px 5px 10px 0px #0000001A;
      border: 1px solid #E0E1E5;
      min-width:174px;
      border-radius: 4px;
      top:22px !important;
      .dropdown-item{
        display: inline-flex;
        padding:7.5px 20px;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color:$fc-primary;
        transition:0.2s ease-in-out;
        svg path{
          transition:0.2s ease-in-out;
        }
        &:hover{
          background:transparent;
          color:$orange;
          svg path{
            stroke:$orange;
          }
        }
        .icon{
          display:inline-flex;
          margin-right:13px;
        }
      }
    }
  }
  .left_sec{
    position: relative;
    padding-right:110px;
    width:100%;
    @include phonel{
      padding-right:70px;
    }
  }
  .carousel_default_btn{
      display: flex;
      align-items: center;
      right: 0;
      bottom: -2px;
      gap: 23px;
      width: 107px;
      height: 40px;
      position: absolute;
    @include phonel{
      display:none;
    }

    .btn{
      border:1px solid #222222;
      width:40px;
      height:40px;
      display:inline-flex;
      align-items:center;
      justify-content:center;
      border-radius:100%;
      position:relative;
      opacity:0.3;
      pointer-events: none;
    }
  }
}
.product_card_carousel{
  margin-bottom: 25px;
  &:has(.publish_btn_wrap){
    .move_down_wrapper{
      margin-top:10px;
    }
  }
  .loader_only{
    overflow: hidden;
    @media (max-width:1564px){
      margin-right:-100px;
      padding-right:100px;
    }
    &.gap-4{
      @include phonel{
        gap:10px !important;
        .content_wrapper{
          padding-top:0;
        }
      }
    }
  }
  &.isB2B{
    margin-bottom: 45px;
    border-bottom: 1px solid #E0E1E5;
    padding-bottom: 45px;
    @include phonel{
      padding-bottom:30px;
      margin-bottom:25px;
    }

    .carousel_body .slick-arrow {
      &.slick-next {
        right: 0;
      }
      &.slick-prev{
        right:63px;
      }
    }
  }
}
.carousel_body{
  padding-top:30px;
  @include phonel{
    padding-top:20px;
  }
  .slick-list{
    margin-right:-40px;
    margin-left:-12.5px;
    padding-right:40px;
    .slick-track{
      margin-left:initial;
    }
    @media (min-width:1280px){
      margin-right:-100px;
      padding-right:100px;
    }
    @media (min-width:1565px){
      margin-right:0;
      padding-right:0;
    }
  }
  .card_ui_product{
    //width: 265px;
    margin: 0 12.5px;
    //width:240px;
    @include phonel{
      margin:0 6px;
      width:auto;
    }
    .product-card {
      //overflow:hidden;
      position: relative;
      &:hover {
        .hover_area {
          opacity: 1;
          visibility: visible;
        }
        .hover_btn_area{
          opacity: 1;
          visibility: visible;
          .link_btn{
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
          }
        }
        .product_img_wrapper{
          img{
            transform: scale(1.06);
          }
        }
      }
      @include phonel{
        position:relative;
      }
    }
    .product_img_wrapper{
      //width:240px;
      //height:240px;
      border-radius: 5px;
      position: relative;
      overflow:hidden;
      aspect-ratio: 1 / 1;
      @include phonel{
        width:175px;
        height:175px;
        @media (max-width:400px){
          width:165px;
          height:165px;
        }
      }
      img{
        width:100%;
        border-radius: 5px;
        height:100%;
        object-fit:contain;
        aspect-ratio:1/1;
        transition:transform 2s cubic-bezier(.215,.61,.355,1)
      }
      .badge_group{
        position: absolute;
        left:14px;
        top:13.33px;
        display: inline-block;
        width: calc(100% - 14px);
        padding-right: 14px;
        z-index:1;
      }
      .rating_badges{
        background: #222222;
        font-size: 12px;
        font-family: 'Helvetica Neue md';
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0.03em;
        text-align: left;
        color:#fff;
        padding:4px;
        border-radius:2.44px;
        text-transform:uppercase;
        max-width:100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
      .rating_badges:nth-child(2){
        margin-top: 4px;
      }
      .hover_area {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.3);
        width: 100%;
        height: 100%;
        border-radius: 5px;
        gap:7px;
        display: flex;
        justify-content: flex-end;
        padding:12px 15px;
        opacity:0;
        visibility: hidden;
        transition:0.2s ease-in-out;
        .icon_action_btn{
          width:40px;
          height:40px;
          display: inline-flex;
          align-items:center;
          justify-content:center;
          padding:0;
          background:#fff;
          border-radius:100%;
          &:focus{
            outline:none;
            box-shadow:none;
          }
        }
      }
      .hover_btn_area{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        gap:7px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        padding:12px 15px 20px;
        opacity:0;
        visibility: hidden;
        transition:0.2s ease-in-out;
        @include phonel{
          display:none;
        }
        .link_btn{
          width: 40px;
          height: 40px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          position: absolute;
          right: 10px;
          top:11px;
          z-index:1;
          @include trans2s;
          svg path{
            @include trans2s;
          }
          &:hover{
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
            svg path{
              stroke:$orange;
            }
          }

        }
        .add_btn{
          height: 45px;
          padding:0 22px 0 18px;
          background:$orange;
          border:none;
          border-radius: 5px;
          color:#fff;
          font-size: 16px;
          font-weight: 500;
          line-height: 28.62px;
          text-align: center;
          font-family: 'Helvetica Neue md';
          @include trans2s;
          &:hover{
            background:#e37b6f;
            color:rgba(255,255,255,0.8);
          }
          .icon{
            margin-right: 8px;
            display:inline-flex;
            align-items:center;
          }

        }
      }
    }
    .product_content_section{
      padding-top:12px;
      font-family: 'Helvetica Neue';
      max-width:250px;
      .brand-name{
        font-size: 14px;
        font-family: 'Helvetica Neue md';
        line-height: 21px;
        letter-spacing: 0.01em;
        text-align: left;
        font-weight: 500;
        color:$fc-primary;
      }
      .product-name{
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color:$fc-primary;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top:5px;
        a{
          color:$fc-primary;
          position: relative;
          z-index: 1;
          display: inline-block;
        }
        @include phonel{
          -webkit-line-clamp: 3;
        }
      }
      .price_of_product{
        font-size: 15px;
        font-family: 'Helvetica Neue md';
        line-height: 18.32px;
        font-weight: 500;
        text-align: left;
        color:$fc-primary;
        margin-top: 12px;
        margin-bottom:5px;
        display: flex;
        flex-wrap:wrap;
        &.strikeout{
          text-decoration: line-through;
        }
      }
      .percentage_off{
        color:#006878 !important;
        margin-left:-6px;
          span{
            background: #E4EFF1;
            display:inline-flex;
            align-items:center;
            font-family: 'Helvetica Neue md';
            font-size: 14px;
            font-weight: 500;
            line-height: 17.09px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#006878 !important;
            padding:2px 6px;
            border-radius:6px;


          }
      }
    }

        .mob_link_ui{
          position:absolute;
          left:0;
          top:0;
          width:100%;
          height:100%;
          cursor:pointer;
        }
  }
  .slick-arrow{
    position: absolute;
    transition:0.2s ease-in-out;
    width:48px;
    height:48px;
    svg circle, svg path{
      transition:0.2s ease-in-out;
    }
    svg g{
      opacity:1;
    }
    &.slick-disabled{
      svg g{
        opacity:0.3;
      }
      &:hover{
        svg circle{
          fill:#fff;
          stroke:#222222;
        }
        svg path{
          stroke:#222222;
        }
      }

    }
    &:hover{
      svg circle{
        fill:$orange;
        stroke:$orange;
      }
      svg path{
        stroke:#fff;
      }
    }
    &.slick-next{
      right: 64px;
      top: -48px;
    }
    &.slick-prev{
      right: 119px;
      top: -48px;
      left: initial;
    }
    &:before{
      display:none;
    }
  }
  .carousel{
    @include phonel{
      display:flex;
      overflow-x:auto;
      margin:0 -20px;
      .slide{
        &:first-child{
          margin-left:20px;
          .card_ui_product{
            margin-left:0;
          }
        }
        &:last-child{
          margin-right:20px;
          .card_ui_product{
            margin-right:0;
          }
        }
      }
    }
  }
}

.move_down_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  border-bottom: 1px solid #E0E1E5;
  padding-bottom: 10px;
  font-family: 'Helvetica Neue';
  .left_sec{
    display: flex;
    gap:20px;
  }
  .item_counts_text{
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    text-align: right;
    color:$fc-secondary;
  }
  .reorder_btn_wrap{
    display:inline-flex;
    align-items: center;
    border:none;
    background:none;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    text-align: center;
    color:$fc-secondary;
    transition:0.2s ease-in-out;
    svg path{
      transition:0.2s ease-in-out;
    }
    &:hover{
      color:$fc-primary;
      svg path{
        stroke:$fc-primary;
      }
    }
    .icon{
      display: inline-flex;
      align-items: center;
      margin-right: 4px;
    }

  }
}

.product_empty_screens{
  min-height: 371px;
  border: 1px solid #E0E1E5;
  background:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.publish_btn_wrap{
  margin-top: 30px;
  display:flex;
  align-items:center;
  justify-content:center;
}

/*button scss*/
.gray_text_btn{
  font-size: 26px;
  font-weight: 300;
  line-height: 31.43px;
  text-align: center;
  color:$fc-secondary;
  display:inline-flex;
  align-items: center;
  padding:0;
  transition:0.2s ease-in-out;
  svg path{
    transition:0.2s ease-in-out;
  }
  &:focus{
    outline:none;
    box-shadow:none;
  }
  &:hover{
    color:$orange;
    svg path{
      stroke:$orange;
    }
  }
  .icon{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right:4px;
    position: relative;
  }
}
.green_outline_btn{
  font-family: 'Helvetica Neue';
  font-size: 17px;
  font-weight: 400;
  line-height: 20.28px;
  text-align: center;
  color:$teal-primary;
  border: 1px solid $teal-primary;
  height:42px;
  padding:0 27px;
  border-radius: 4px;
  background:#fff;
  transition:0.2s ease-in-out;
  outline: none;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  &:hover{
    opacity:0.8;
  }
  &:disabled{
    opacity:0.4;
  }
}

  /*  --shop banner scss start here--   */

.shop_banner {
  margin-bottom: 84px;
  position: relative;
  .link_ui{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    cursor: pointer;
  }
  @media (max-width: 1198px) {
    margin-bottom: 60px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .shape_float {
    position: absolute;
    z-index: 1;
    &.shape_left {
      left: 0px;
      top: 0;
      width: 288px;
      height: 289px;
      @media (max-width: 1199.98px) {
        left: 0;
        width: 200px;
        height: 200px;
      }
    }
    &.shape_right {
      right: 0;
      bottom: 60px;
      width: 312px;
      height: 313px;
      @media (max-width: 1199.98px) {
        width: 250px;
        height: 250px;
        bottom: 30px;
      }
    }
  }
  .banner_bg_prop {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0;
    position: relative;
    overflow: hidden;
    @media (min-width:768px) {
      aspect-ratio:1360/440;
      border-radius:5px;
    }
    @media (min-width:600px) and (max-width:767px){
      aspect-ratio:520/330;
    }
    @media (max-width:599px){
      border-radius:0px;
      aspect-ratio:430/330;
    }
  }
  .banner_inner_container {
    //background: linear-gradient(
    //    180deg,
    //    rgba(0, 0, 0, 0) 0%,
    //    rgba(0, 0, 0, 0.2) 100%
    //);
    //min-height: 440px;
    display: flex;
    flex-direction: column;
    @media (max-width:1000px) and (min-width:835px){
      min-height:336px;
    }

    @media (max-width:834px) and (min-width:600px){
      min-height:290px;
    }
    @media (max-width:599px){
      min-height:250px;
    }
  }
  .banner_action_group {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    align-items: center;
    margin-top: 20px;
    position: relative;
    padding-left: 51px;
    padding-right: 20px;
    @media (max-width: 1199.98px) {
      padding-left: 30px;
    }
  }
  .action_btn {
    background-color: #fff;
    border-color: #fff;
    padding: 8px 8px;
    height: 40px;
    transition: all 0.2s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.delete_btn {
      width: 40px;
    }
    &.edit_btn {
      width: 40px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .status_btn {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 400;
    color:$orange;
  }
  .banner_content {
    margin-top: auto;
    max-width: 50%;
    position: relative;
    padding: 0 51px 55px;
    @media (max-width: 1199.98px) {
      padding: 0 30px 30px;
    }
  }
  .banner_label {
    font-size: 13px;
    font-weight: 700;
    line-height: 15.6px;
    letter-spacing: 0.1em;
    color: #fff;
    display: inline-block;
    margin-bottom: 10px;
    &.mb-38 {
      margin-bottom: 22px;
    }
  }

  .banner_heading {
    margin-bottom: 24px;
    &.mb-13 {
      margin-bottom: 13px;
    }
  }
  .banner_desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: #fff;
    margin-bottom: 24px;
  }
  .cta_btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    background: transparent;
    color: #fff;
    border: 2px solid #ffffff;
    padding: 10px 16px;
    min-height: 54px;
    min-width: 143px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all 0.2s;
    &:hover {
      background-color: #006878;
      color: #ffffff;
      border-color: #006878;
    }
  }
}

.carousel_default_btn{
  .btn{
    position: absolute;
    transition: 0.2s ease-in-out;
    width: 48px;
    height: 48px;
  }
}

/*  -- shop banner scss end here--   */
.space_80{
  height:80px;
}
.featured_brands_section{
  .space_60{
    height:63px;
  }
}
.space_60{
  height:60px;
  &.mob_space_30{
    @include phonel{
      height:30px;
    }
  }
  &.mob_space_40{
    @include phonel{
      height:30px;
    }
  }
}
.shop_page{
  .banner_sec{
    margin-top:30px;
    @media (max-width:767px){
      margin-top:0;
    }
    .mw_1665, .mw_1440{
      @media (max-width:767px){
        padding:0;
      }
    }
      .isFirst{
        .carousel-control-prev{
          display:none;
        }
      }
    .isLast{
      .carousel-control-next{
        display:none;
      }
    }
    ngx-slick-carousel{
      &.slick-dotted{
        margin-bottom:0;
      }
      &:hover {
        .slick-arrow {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .slick-dots{
        display: inline-flex;
        gap: 15px;
        width: 100px;
        margin: auto;
        padding-bottom: 0px;
        padding-top: 0px;
        bottom: -21px;
        left: 0;
        right: 0;
      justify-content: center;

      li{
        width: auto;
        height: auto;
        margin:0;
        &.slick-active{
          button{
            opacity:1;
            background:#5592A0;
          }

          ~ li button{
              opacity:0.5;
          }
        }
        button{
          width: 7px;
          height: 7px;
          opacity: 1;
          background: #C7C7C7;
          display: inline-flex;
          border: none;
          border-radius: 100%;
          margin: 0;
          padding: 0;
          &:before{
           display:none;
          }
        }

        &:first-of-type:is(:last-of-type) {
          display:none;
        }
      }
      }
    .slick-arrow{
      width: 41.67px;
      height: 41.67px;
      box-shadow: 0 2px 8.7px #00000026;
      background: #fff;
      border-radius: 100%;
      opacity: 0;
      visibility: hidden;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      @media (max-width: 991px){
        display:none !important;
      }
      img{
        display:none;
      }
      &:before{
        content:"";
        width:9px;
        height:15px;
        display:inline-block;
      }
      &.slick-prev{
        left:-20px;
      }
      &.slick-next{
        right:-20px;
      }
      &.slick-next:before{
        background:url("/assets/images/shop/ic_next.svg") no-repeat center;
      }
      &.slick-prev:before{
        background:url("/assets/images/shop/ic_prev.svg") no-repeat center;
      }

      &.slick-disabled{
        display:none !important;
      }
    }
    .slick-slide{
      transition:opacity 1000ms !important;
    }
  }
  .banner_sec .carousel{
    &:hover{
      .carousel-control-prev,
      .carousel-control-next{
        opacity:1;
        visibility: visible;
      }
    }
  }
  .carousel-control-prev{
    left:-20px;
  }
  .carousel-control-next{
    right:-20px;
  }
  .carousel-control-prev,
  .carousel-control-next{
    width: 41.67px;
    height: 41.67px;
    box-shadow: 0px 2px 8.7px 0px #00000026;
    background:#fff;
    border-radius:100%;
    opacity:0;
    visibility:hidden;
    top:50%;
    transform:translateY(-50%);
    @media (max-width:767px){
      display:none;
    }
  }
  .carousel-control-next-icon{
    background:url("/assets/images/shop/ic_next.svg") no-repeat center;
  }
  .carousel-control-prev-icon{
    background:url("/assets/images/shop/ic_prev.svg") no-repeat center;
  }
  .carousel-indicators{
    display: flex;
    gap:15px;
    width: 100px;
    margin: auto;
    padding-bottom: 16px;
    padding-top: 5px;
    button[data-bs-target]{
      width: 6px;
      height: 6px;
      opacity: 0.5;
      background:#fff;
      display: inline-flex;
      border:none;
      border-radius: 100%;
      margin: 0;
      &.active{
        opacity:1;
      }
    }
  }

}


.carousel_wrapper{
  &:last-child{
    .product_card_carousel {
      border: none;
    }
  }
  &:has(.type_banner) .product_card_carousel {
    border: none;
    margin-bottom: 20px;
  }
}

.mob_view_btn{
  @media (min-width:768px){
    display:none;
  }
  position: absolute;
  right: 0;
  top: 0;
  .view_btn{
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color:$orange;
    background:none;
    border:none;
    text-decoration: underline;
  }
}




.view_all_products{
  position:fixed;
  top:0;
  left:100%;
  padding-top:60px !important;
  background:#fff;
  width:100%;
  height:100vh;
  z-index:3;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  @media (min-width:768px){
    display:none;
  }
  @include trans2s;
  &.active{
    opacity:1;
    visibility:visible;
    left:0;
  }
  .title{
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 36.97px;
    padding: 26px 0 23px 0;
    margin-bottom:0px;
    color:#444;
    svg{
      width: 25px;
      height: 25px;
    }
  }
  .card_ui_product{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    gap:10px;
    @media (max-width:550px){
      gap:20px;
    }
    .product-card{
      max-width:32%;
      margin-bottom: 25px;
      @media (max-width:550px){
        max-width:47%;
        @media (max-width:375px){
          max-width:46%;
        }
      }
      .product_img_wrapper{
        @include phonel{
          width:100%;
          height:100%;
          aspect-ratio: 1/1;
        }
      }
      .product_content_section{
        @media (max-width: 767px) {
          padding-top: 15px;
        }
      }
      .brand-name{
        @media (max-width: 767px) {
          font-family: 'Helvetica Neue md';
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0.01em;
        }
      }
      .product-name{
        @media (max-width: 767px) {
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          padding-top: 3px;
        }
      }
      .price_of_product{
        @media (max-width: 767px) {
          font-size: 15px;
          font-weight: 500;
          line-height: 18.32px;
          text-align: left;
        }
      }
      .product-img{
        width: 100%;
        border-radius: 3.39px;
        height: 100%;
        object-fit: contain;
      }
      .percentage_off{
        span{
          @media (max-width: 767px) {
            font-size: 14px;
            font-weight: 500;
            line-height: 17.09px;
            letter-spacing: -0.01em;
          }
        }
      }
      &:nth-child(odd){
        //padding-right: 10px;
      }
      &:nth-child(even){
        //padding-left: 10px;
      }
    }
  }
  .back_btn{
    padding:0;
    width: 25px;
    height: 25px;
  }
  .outline_secondary_btn{
    margin: 35px auto 63px auto;
    background: none;
    display: flex;
    gap:4px;
    padding: 15px 22px 14px 14px;
    border: 1.5px solid #E0E1E5;
    border-radius: 4px;
    background: #ffffff;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
    height: 45px;
    justify-content: center;
    color:#444444;
  }
}


.product_modal_content{
  .view_product_btn{
    display:none;
  }
}

.bottom_modal_ui {
  @include phonel {
    .modal-header{
      padding-top:48px;
    }
    .p-dialog-mask {
      align-items: flex-end;
    }
    .p-dialog {
      position: fixed;
      bottom: 0;
      margin:0 !important;
    }
    .p-dialog-content {
        border-radius:8px 8px 0 0 !important;
    }
    .modal-content .modal-footer{
      padding:25px 20px 28px !important;
      .view_product_btn{
        display:none;
      }
      .btn_primary, .add_item_quotes_btn{
        border-radius:5px;
      }
    }
    .heading_wrap{
      display:none;
    }
    .add_product_row{
      flex-direction: initial;
      .col-md-4{
        flex: 0 0 auto;
        width: 180px;
        padding-right:0;
        @media (max-width:420px){
          width:150px;
          @media (max-width:360px){
            width:135px
          }
        }
      }
      .col-md-8{
        flex: 0 0 auto;
        width:calc(100% - 180px);
        padding-left:20px;
        padding-right:20px;
        @media (max-width:420px){
          width:calc(100% - 150px);
          @media (max-width:360px){
            padding-left:15px;
            width:calc(100% - 135px);
          }
        }
        .brand_name{
          font-family: 'Helvetica Neue md';
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0.01em;
          text-align: left;
          color:$fc-primary;
          margin-bottom:0;
        }
        .product_name{
          font-size: 15px;
          font-weight: 400;
          line-height: 22.5px;
          text-align: left;
          font-family: 'Helvetica Neue';
          margin-bottom:20px;
          -webkit-line-clamp:3;
        }
        .price_sec{
          flex-direction: column-reverse;
          display:inline-flex;
          .price{
            font-family: 'Helvetica Neue md';
            font-size: 16px;
            font-weight: 500;
            line-height: 19.54px;
            text-align: left;
            color:$fc-primary;
            margin-bottom:5px;
          }
          .save_and_off{
            background:#E4EFF1;
            padding:3px 5px;
            border-radius:4px;
            font-family: 'Helvetica Neue md';
            font-size: 14px;
            font-weight: 500;
            line-height: 17.09px;
            letter-spacing: -0.01em;
            text-align: left;
            margin-bottom:0;
            display:inline-block;
          }
        }
      }
      .col-md-9{
        .space_top_30{
          margin-top:30px;
        }
      }
      }
    &:has(.add_product_row .col-md-9){
      .modal-footer{
        .quote_wish_btn_wrapper{
          margin-top:18px;
        }
      }
      .brand_name{
        font-family: 'Helvetica Neue md';
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.01em;
        text-align: left;
        color:$fc-primary;
        margin-bottom:0;
      }
      .product_name{
          font-size: 15px;
          font-weight: 400;
          line-height: 22.5px;
          text-align: left;
          font-family: 'Helvetica Neue';
        margin-bottom:20px;
        -webkit-line-clamp:3;
        }
      .price_sec{
        flex-direction: column-reverse;
        display:inline-flex;
        align-items: flex-start;
        .price{
          font-family: 'Helvetica Neue md';
          font-size: 16px;
          font-weight: 500;
          line-height: 19.54px;
          text-align: left;
          color:$fc-primary;
          margin-bottom:5px;
        }
        .save_and_off{
          background:#E4EFF1;
          padding:3px 5px;
          border-radius:4px;
          font-family: 'Helvetica Neue md';
          font-size: 14px;
          font-weight: 500;
          line-height: 17.09px;
          letter-spacing: -0.01em;
          text-align: left;
          margin-bottom:0;
          display:inline-block;
        }
      }
      .quote_wish_btn_wrapper{
        //gap:33px;
      }
    }
    .quote_wish_btn_wrapper{
      gap:20px;
      .add_item_quotes_btn{
        height:50px;
      }
    }
    .cart_modal .modal-footer .btn_primary{
      height:50px;
      .el-icon{
        display:none;
      }
    }
    .product_img_warapper .product_inner_img{
      width:165px;
      height:165px;
      @media (max-width:420px){
        width:135px;
        height:135px;
        @media (max-width:360px){
          width:120px;
          height:120px;
        }
      }
      img{
        width:100%;
        height:100%;
        object-fit: cover;
      }
    }
    .product_modal_content{
      .view_product_btn{
        display:flex;
        justify-content: flex-start;
        .el-icon svg{
          max-width:18px;
        }
        .el-text{
          text-transform: initial;
          font-family: 'Helvetica Neue md';
          font-size: 16px;
          font-weight: 500;
          line-height: 28.62px;
          text-align: left;
          letter-spacing:0;
          @media (max-width:360px){
            font-size:14px;
          }
        }
      }
    }
    .cart_quantity_section_modal{
      .form-group{
        margin-top:5px;
      }
    }
    .dropdown_listing{
      height:100%;
      .btn_wrapper .add_item_quotes_btn{
        margin-top:0 !important;
      }
    }
    .filter_dropdown_sec.for_item_modal{
      margin:0 !important;
      .p-listbox{
        height:calc(100% - 198px);
        .p-listbox-filter-container {
          input {
              height: 42px;
            }

          .p-listbox-filter-icon{
            width:20px;
            height:20px;
            background-size:100%;
          }
        }
      }
      .dropdown-menu {
        height: 100%;
        max-height: 100%;
        border-radius: 8px 8px 0 0;
        padding-bottom:22px;
      }

    }
    .cart_modal .close-btn svg{
      width:18px;
      height:18px;
      path{
        stroke:$fc-primary;
      }
    }
  }
}


body:has(.getting_start_wrapper) .banner_sec {
  margin-top: 40px;
  @include phonel{
    margin-top:0;
  }
}

/* All icons Defined Here*/
.icons{
	display: inline-block;
  vertical-align: middle;
  &:before{
    /*background-image:url('');*/
    content: "";
    background-repeat: no-repeat;
  }
}
/* All icons Defined Here*/